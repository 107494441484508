import React, { FC } from "react";
import { Grid, Select } from "@epignosis_llc/gnosis";
import { Controller, UseFormReturn } from "react-hook-form";
import { UnitOrderingSVG } from "@epignosis_llc/gnosis/icons";
import { CourseRulesFormData, TraversalRule } from "@views/CourseEdit/types";
import { TextWithIcon } from "@components";
import { SelectOption } from "types/common";
import { useApplyTranslations } from "@hooks";

type UnitsOrderingProps = {
  form: UseFormReturn<CourseRulesFormData>;
};

const UnitsOrdering: FC<UnitsOrderingProps> = ({ form }) => {
  const { t } = useApplyTranslations();
  const { control } = form;

  const traversalRuleOptions: SelectOption[] = [
    { label: t("courseEdit.anyOrder"), value: TraversalRule.Any },
    { label: t("courseEdit.sequentialOrder"), value: TraversalRule.Sequential },
  ];

  return (
    <div>
      <TextWithIcon icon={<UnitOrderingSVG height={32} />} label={t("courseEdit.unitsOrdering")} />

      <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} className="grid-container">
        <Grid.Item colSpan={1}>
          <Controller
            name="traversal_rule"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => {
              const selectedValue = traversalRuleOptions.find((option) => option.value === value);

              return (
                <Select
                  id="traversal-rule"
                  value={selectedValue}
                  options={traversalRuleOptions}
                  label={t("courseEdit.showUnits")}
                  onChange={(option): void => {
                    const { value } = option as SelectOption;
                    onChange(value);
                  }}
                />
              );
            }}
          />
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default UnitsOrdering;

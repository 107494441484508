import { PalletThemeColors, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["calendar"] => {
  return {
    primaryColor: hexToRGBA(colors.primary.base, 0.25),
    eventColor: colors.primary.base,
    pastEventColor: colors.secondary.light,
    pastEventTextColor: colors.black,
    pastDayColor: colors.secondary.lighter,
    eventDrawerBorderColor: colors.secondary.lighter,
  };
};

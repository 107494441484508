import React, { FC, useEffect, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import localStorageKeys from "@constants/localStorageKeys";
import { getPublicCourse } from "@views/Courses/api";
import { startButtonStyles } from "../styles";
import { useApplyTranslations, useIsPublicCourse, useSearchQuery } from "@hooks";
import { useConfigurationStore } from "@stores";
import { Course } from "types/entities";
import { generalNotification } from "@utils/helpers";
import { getCourseButtonText } from "./helpers";
import { URLS } from "@constants/urls";
import { handleEnrollmentErrors } from "@errors";
import queryKeys from "@constants/queryKeys";
import { postEnrollment } from "@api/catalog";
import SubscribeButton from "./components/SubscribeButton";
import CatalogButton from "./components/CatalogButton";
import PaymentModal from "@components/Course/components/PaymentModal";
import { getPaymentNotificationTranslation } from "@components/Course/helpers";

type CourseButtonProps = {
  course: Course;
  isCatalogCourseView: boolean;
  isVisible: boolean;
  isDisabled: boolean;
  unlocksOnHigherLevel: boolean;
  categoryText: string;
  continueUnitId: number | null;
};

type SearchQueryFilters = {
  status: string;
  processor: string;
  type: string;
};

const CourseButton: FC<CourseButtonProps> = ({
  course,
  isCatalogCourseView,
  isVisible,
  isDisabled,
  unlocksOnHigherLevel,
  categoryText,
  continueUnitId,
}) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const { catalogSettings } = useConfigurationStore();
  const navigate = useNavigate();
  const isPublicCourse = useIsPublicCourse();
  const { status, processor, type } = useSearchQuery() as SearchQueryFilters;

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const { id, enrolled, price, progress, public_key, name } = course;
  const { completion_status } = progress ?? {};
  const courseId = id.toString();
  const isUserEnrolled = Boolean(enrolled);
  const hasPrice = Boolean(price && price.amount !== 0);
  const hasPublicKey = !!public_key;

  const isSubscriptionEnabled = Boolean(catalogSettings?.subscription.enabled);
  const showSubscribeButton = isSubscriptionEnabled && isCatalogCourseView && hasPrice;
  const showCatalogButton = isCatalogCourseView;

  const unitLink = continueUnitId
    ? URLS.user.createUnitLink({ courseId, unitId: continueUnitId.toString() })
    : "";

  const { mutate: enrollmentMutation, isLoading: enrollmentMutationLoading } = useMutation(
    [queryKeys.courseEnrollment],
    postEnrollment,
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("courseCatalog.youEnrolledSuccessfully")}</p>);
        queryClient.invalidateQueries([queryKeys.course, courseId]);
        queryClient.invalidateQueries([queryKeys.catalog]);
        queryClient.invalidateQueries([queryKeys.myFiles]);
      },
      onError: (error: AxiosError) => {
        handleEnrollmentErrors(error);
      },
    },
  );

  const { mutateAsync: getPublicCourseMutation, isLoading: isPublicCourseMutationLoading } =
    useMutation([queryKeys.publicCourseKey], (key: string) => getPublicCourse(key), {
      onSuccess: (res) => {
        localStorage.setItem(localStorageKeys.PUBLIC_COURSE, JSON.stringify(res._data.token));
      },
    });

  const handleSubscribeEnroll = (): void => {
    // If the user has active subscription check whether he is already enrolled to the course.
    // If he is enrolled then navigate to the course. Else enroll the user to the course.
    isUserEnrolled ? navigate(unitLink) : enrollmentMutation({ id: id, type: "course" });
  };

  const handleButtonClick = (): void => {
    if (!continueUnitId) return;

    if (isPublicCourse) {
      const publicUnitLink = URLS.publicCourse.createPublicUnitLink({
        courseId,
        unitId: continueUnitId.toString(),
      });
      navigate(publicUnitLink);
      return;
    }

    navigate(unitLink);
  };

  const handleStartPublicCourse = async (): Promise<void> => {
    if (hasPublicKey) {
      const existingKey = localStorage.getItem(localStorageKeys.PUBLIC_COURSE);

      if (!existingKey) {
        await getPublicCourseMutation(public_key);
      }

      navigate(
        URLS.publicCourse.createPublicUnitLink({ courseId, unitId: continueUnitId?.toString() }),
      );
    }
  };

  const handleCatalogEnroll = (): void => {
    // If course is cancelled, allow the user to enroll in the course or buy the course.
    const isCourseCancelled =
      !course.availability?.is_available && course.availability?.reason == "cancelled";

    if (isUserEnrolled && !isCourseCancelled) {
      navigate(unitLink);
      return;
    }

    // If no payment processors are available or the course has no price, enroll the user.
    if (!catalogSettings?.processors?.length || !hasPrice) {
      enrollmentMutation({ id: id, type: "course" });
      return;
    }

    setIsPaymentModalOpen(true);
  };

  // Here we check if the user has the payment status local storage key in order to show the payment notification.
  // This functionality is introduced in oder to prevent the user from publishing payment notifications though url without any prior payment
  useEffect(() => {
    if (
      status === "success" &&
      localStorage.getItem(localStorageKeys.PAYMENT_STATUS) === "pending"
    ) {
      const translationText = getPaymentNotificationTranslation(
        type,
        isUserEnrolled,
        processor,
        name,
      );

      generalNotification(
        "success",
        <Text fontSize="sm" as="p" dangerouslySetInnerHTML={{ __html: translationText }} />,
        5000,
      );

      if (type === "subscription") {
        enrollmentMutation({ id: id, type: "course" });
      }
      queryClient.invalidateQueries([queryKeys.course]);
    }

    queryClient.invalidateQueries([queryKeys.catalog]);
    queryClient.invalidateQueries([queryKeys.userProfile]);
    localStorage.removeItem(localStorageKeys.PAYMENT_STATUS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isVisible) return null;

  if (showSubscribeButton) {
    return (
      <SubscribeButton
        isDisabled={isDisabled}
        isUserEnrolled={isUserEnrolled}
        completionStatus={completion_status}
        isLoading={enrollmentMutationLoading}
        onEnroll={handleSubscribeEnroll}
      />
    );
  }

  if (showCatalogButton) {
    return (
      <>
        <CatalogButton
          course={course}
          isDisabled={isDisabled}
          isUserEnrolled={isUserEnrolled}
          completionStatus={completion_status}
          unlocksOnHigherLevel={unlocksOnHigherLevel}
          isPublicCourse={hasPublicKey}
          isLoading={isPublicCourseMutationLoading || enrollmentMutationLoading}
          onStartPublicCourse={handleStartPublicCourse}
          onEnroll={handleCatalogEnroll}
        />

        {isPaymentModalOpen && (
          <PaymentModal
            paymentData={course}
            isOpen={isPaymentModalOpen}
            categoryText={categoryText}
            onClose={(): void => setIsPaymentModalOpen(false)}
          />
        )}
      </>
    );
  }

  return (
    <div css={startButtonStyles}>
      <Button
        color="primaryDarker"
        variant="outline"
        className="start-button"
        disabled={isDisabled}
        onClick={handleButtonClick}
      >
        {getCourseButtonText(completion_status)}
      </Button>
    </div>
  );
};

export default CourseButton;

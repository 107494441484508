import React, { FC } from "react";
import { ConfirmationModal } from "@components";
import { useApplyTranslations } from "@hooks";
import { IltSession, SubmissionListing } from "types/entities";

type ResetModalProps = {
  selectedSession: SubmissionListing | IltSession | null;
  isResetModalOpen: boolean;
  type: "assignment" | "session";
  closeResetModal: () => void;
  handleOnConfirmResetModal: () => void;
};

const ResetModal: FC<ResetModalProps> = ({
  selectedSession,
  isResetModalOpen,
  type,
  handleOnConfirmResetModal,
  closeResetModal,
}) => {
  const isSession = type === "session";
  const { t } = useApplyTranslations();

  if (!selectedSession || !isResetModalOpen) return <></>;

  return (
    <ConfirmationModal
      id={selectedSession.id}
      isOpen={isResetModalOpen}
      header={t("assignment.resetStatus")}
      bodyTitle={
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              isSession
                ? "assignment.resetStatusWarningSession"
                : "assignment.resetStatusWarningAssignment",
              {
                name: selectedSession?.user.login,
              },
            ),
          }}
        />
      }
      bodyText={t("assignment.resetStatusBody")}
      footerButton={t("general.actions.reset")}
      confirmationButtonColor="primary"
      onConfirm={handleOnConfirmResetModal}
      onClose={closeResetModal}
    />
  );
};

export default ResetModal;

import { config } from "@config";

const FACEBOOK_APP_ID_DEV = 746434125748439;
const FACEBOOK_APP_ID_PROD = 1307807343100008;

const facebookID = config.isProd() ? FACEBOOK_APP_ID_PROD : FACEBOOK_APP_ID_DEV;

const social = {
  facebookID,
};

export default social;

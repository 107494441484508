import { css } from "@emotion/react";

export const modalFooter = css`
  display: flex;
  gap: 1rem;
`;

export const linkedUnitsStyles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .linked-units-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .linked-unit-title {
      margin-bottom: 0.25rem;
    }
    .linked-unit-course-link {
      display: flex;
      align-items: center;
    }
  }
`;

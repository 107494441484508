import { yup } from "./validation";

export const cancellationSurveyValidationSchema = yup.object().shape({
  reason: yup.string().required(),
  support_rating: yup.number().required(),
  reason_justification: yup
    .string()
    .max(5000)
    .when("reason", {
      is: (val: string) => ["missing_features", "other"].includes(val),
      then: yup.string().required(),
    }),
  reason_for_initial_purchase: yup.string().max(5000),
  improvements: yup.string().max(5000),
});

import React, { FC } from "react";
import { Heading } from "@epignosis_llc/gnosis";
import { Section } from "types/entities";
import { getDelayInSeconds, unitHasDelayedAvailability } from "@utils/helpers";
import { AvailabilityText } from "@components/ReusableComponents";

type CourseTocSectionItemProps = {
  section: Section;
  isLearnerView: boolean;
};

const CourseTocSectionItem: FC<CourseTocSectionItemProps> = ({ section, isLearnerView }) => {
  const { availability = null, name } = section;

  let delay, showAvailability;
  const absolute_delay = availability?.absolute_delay;

  if (isLearnerView) {
    const isSectionAvailable = !unitHasDelayedAvailability(availability);
    delay = getDelayInSeconds(availability);
    showAvailability = !isSectionAvailable && delay && absolute_delay;
  } else {
    delay = availability?.delay;
    showAvailability = absolute_delay && delay;
  }

  const showSection = name || availability;

  if (!showSection) return null;

  return (
    <Heading as="h5" className="section-title">
      {name}
      {showAvailability && delay && <AvailabilityText delay={delay} weight="400" fontSize="xs" />}
    </Heading>
  );
};

export default CourseTocSectionItem;

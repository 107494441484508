import { t } from "@utils/i18n";
import { GroupedOption, SelectOption } from "types/common";
import { CustomFieldDropdownItem } from "types/entities/Common";
import { Branch } from "types/entities";

export const optionsToCustomFieldsIds = (options: SelectOption[]): number[] => {
  return options.map((option) => Number(option.value));
};
export const customFieldDropdownItemsToOptions = (
  options: CustomFieldDropdownItem[],
): SelectOption[] => {
  return options.map((option) => {
    const isDefault = option.default;
    const defaultValue = `[${option.item}]`;
    return isDefault
      ? { value: defaultValue, label: defaultValue }
      : { value: option.item, label: option.item };
  });
};

export const optionsToCustomFieldDropdownItems = (
  options: SelectOption[],
): CustomFieldDropdownItem[] => {
  return options.map(({ value }) => {
    const isDefault = value.startsWith("[") && value.endsWith("]");

    const regex = /\[(.*?)\]/; // Regular expression to match text inside square brackets
    const match = value.match(regex);
    const textValue = match ? match[1] : value;

    return { item: textValue, default: isDefault };
  });
};

export const branchesToGroupedOptions = (branches: Branch[]): GroupedOption[] => {
  const branchOptions = branches.map(({ id, name }) => ({ value: id.toString(), label: name }));

  const groupedOptions: GroupedOption[] = [
    {
      label: t("myBranches.mainPortal"),
      options: [{ label: t("myBranches.mainPortal"), value: "0" }],
    },
    {
      label: t("branches.branches"),
      options: branchOptions,
    },
  ];
  return groupedOptions;
};

export const branchesIdToOptions = (ids: number[], branches: Branch[]): SelectOption[] => {
  return ids
    .map((id) => {
      if (id === 0) return { label: t("myBranches.mainPortal"), value: "0" };

      const branch = branches.find((b) => b.id === id);
      return branch
        ? { label: branch.name, value: branch.id.toString() }
        : { label: "", value: id.toString() };
    })
    .filter((option) => option.label);
};

export const optionsToBranchIds = (options: SelectOption[]): number[] => {
  return options.map((option) => Number(option.value));
};

import { css } from "@emotion/react";

export const TabContainer = () => {
  return css`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  `;
};

import React, { FC } from "react";
import classNames from "classnames";
import { Button, Heading, Tooltip } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { isBefore } from "date-fns";
import { certificateItemContainer } from "./styles";
import { truncate, formatUtcDate, downloadFile } from "@utils/helpers";
import { Certificate } from "types/entities";
import { CertificateWithViewboxSVG, DownloadIconSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

type CertificatesProps = {
  certificate: Certificate;
};

const CertificateItem: FC<CertificatesProps> = ({ certificate }) => {
  const { course_name, expires_at, url } = certificate;
  const { t } = useApplyTranslations();
  const { sm } = useResponsive();

  const expirationClassNames = (isExpired: boolean): string =>
    classNames({
      "expiration-date": true,
      expired: isExpired,
    });

  return (
    <div css={certificateItemContainer}>
      <div className="list-item-info">
        {sm && (
          <figure>
            <CertificateWithViewboxSVG height={32} />
          </figure>
        )}
        <div>
          <Heading as="h3" size="sm">
            {t("certificates.certificate")}
          </Heading>
          <Heading as="h3" size="sm" className="certification-name">
            {sm ? course_name : truncate(course_name, 25)}
          </Heading>
          {expires_at !== null ? (
            <div className={expirationClassNames(isBefore(Date.parse(expires_at), new Date()))}>
              {t("certificates.expiration", { date: formatUtcDate(expires_at) })}
            </div>
          ) : (
            <div className={expirationClassNames(false)}>{t("certificates.expirationNever")}</div>
          )}
        </div>
      </div>
      <div className="list-item-icons">
        <Tooltip content={t("general.download")}>
          <Button
            variant="ghost"
            noGutters
            onClick={async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
              e.preventDefault();
              await downloadFile({ fileUrl: url, fileName: course_name });
            }}
          >
            <DownloadIconSVG height={32} />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default CertificateItem;

import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { ChartIconSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type ReportsActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  onClick: (e: MouseEvent) => void;
};

const ReportsAction: FC<ReportsActionProps> = ({
  tooltipContent,
  icon: Icon = ChartIconSVG,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("users.tooltip.reports")}
      onClick={onClick}
      data-testid="reports-action"
    >
      <Icon height={30} />
    </ActionButton>
  );
};

export default ReportsAction;

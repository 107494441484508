import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, ArrowRightSVG, HamburgerSVG, CloseSVG } from "@epignosis_llc/gnosis/icons";
import { SerializedStyles } from "@emotion/react";
import { Variants, m } from "framer-motion";
import { tocTriggerContainerStyles, tocTriggerContainerMobileStyles } from "./styles";
import { i18n } from "@utils/i18n";
import { useResponsive } from "ahooks";

type ToCTriggerProps = {
  isOpen: boolean;
  toggleTocMenu: () => void;
};

const asideContentCVariants: Variants = {
  expanded: (screenWidth) => ({
    insetInlineStart:
      screenWidth >= 504
        ? "calc(31.5rem - 40px - 1rem)" // drawer width - button width - drawer top padding
        : "calc(100% - 40px - 1rem)", // screen width - button width - drawer top padding
    transition: {
      duration: 0.3,
    },
  }),
  hidden: () => ({
    insetInlineStart: "0.5rem",
    transition: {
      duration: 0.3,
    },
  }),
};

const ToCTrigger: FC<ToCTriggerProps> = ({ isOpen, toggleTocMenu }) => {
  const { lg } = useResponsive();
  const dir = i18n.dir();
  const ArrowIconOpened =
    dir === "rtl" ? <ArrowRightSVG height={24} /> : <ArrowLeftSVG height={24} />;
  const ArrowIconClosed =
    dir === "rtl" ? <ArrowLeftSVG height={24} /> : <ArrowRightSVG height={24} />;

  if (!lg) {
    return (
      <m.div
        css={tocTriggerContainerMobileStyles}
        variants={asideContentCVariants}
        initial="expanded"
        animate={isOpen ? "expanded" : "hidden"}
        custom={window.screen.width}
      >
        {isOpen ? (
          <Button onClick={toggleTocMenu} noGutters color="primaryLight" variant="link">
            <CloseSVG className="close-icon" height={25.5} />
          </Button>
        ) : (
          <Button
            className="toc-trigger"
            onClick={toggleTocMenu}
            aria-label={`${isOpen ? "Close" : "Open"} table of contents`}
            noGutters
            color="secondary"
          >
            <HamburgerSVG height={32} />
          </Button>
        )}
      </m.div>
    );
  }

  return (
    <div css={(theme): SerializedStyles => tocTriggerContainerStyles(theme, { isOpen })}>
      <Button
        className="toc-trigger"
        onClick={toggleTocMenu}
        aria-label={`${isOpen ? "Close" : "Open"} table of contents`}
        noGutters
        color="secondary"
      >
        {!isOpen ? ArrowIconClosed : ArrowIconOpened}
      </Button>
    </div>
  );
};

export default ToCTrigger;

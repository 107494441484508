import React, { FC, useState, ReactNode, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { icons } from "@constants/assets";

import { SpotlightSearch, Toolbar } from "@layouts";
import {
  useAuth,
  useGoogleTagManager,
  useIntercom,
  usePreventIframeEmbedding,
  useScrollToPageTop,
} from "@hooks";
import { useConfigurationStore } from "@stores";

type PageProps = {
  pageTitle: string;
  siteNamePrefix?: boolean;
  toolbar?: ReactNode;
  children: ReactNode;
};

const Page: FC<PageProps> = ({ pageTitle, siteNamePrefix = true, toolbar = null, children }) => {
  const [toolbarEl, setToolbarEl] = useState<null | Element>(null);

  const { isAuthenticated } = useAuth();
  const {
    site_name = "",
    favicon = null,
    load_in_frame = false,
    javascript_integrations: jsIntegrationsSettings,
    hide_from_search_engines: shouldHideFromSearchEngines = false,
    main_portal: isMainPortal = false,
  } = useConfigurationStore((state) => state.domainSettings) || {};
  const { userProfileData } = useConfigurationStore();
  const { email: userEmail = "", is_owner: isPortalOwner = false } = userProfileData ?? {};
  const title = siteNamePrefix ? `${site_name} | ${pageTitle}` : pageTitle;

  const { google_tag_manager, intercom } = jsIntegrationsSettings ?? {};
  const { is_active: gtmIsActive = false, key: gtmKey = "" } = google_tag_manager ?? {};
  const { initializeGTM, removeGTMTags } = useGoogleTagManager(gtmKey, gtmIsActive);
  const shouldInitializeGTM = gtmIsActive && isAuthenticated && isMainPortal;

  const {
    is_active: intercomIsActive = false,
    key: intercomKey = "",
    app_id: intercomAppId = "",
    user_hash: intercomUserHash = "",
  } = intercom ?? {};
  const { initializeIntercom, removeIntercomTags } = useIntercom({
    intercomIsActive,
    intercomKey,
    intercomAppId,
    userEmail,
    intercomUserHash,
  });

  const shouldInitializeIntercom =
    intercomIsActive && isAuthenticated && isMainPortal && !isPortalOwner;

  useScrollToPageTop();
  usePreventIframeEmbedding(load_in_frame);

  useEffect(() => {
    setToolbarEl(document.querySelector("#toolbar"));
  }, [setToolbarEl]);

  useEffect(() => {
    shouldInitializeGTM ? initializeGTM() : removeGTMTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtmKey, gtmIsActive, isAuthenticated, isMainPortal]);

  useEffect(() => {
    if (intercomAppId && intercomUserHash && shouldInitializeIntercom) {
      initializeIntercom();
    } else {
      removeIntercomTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    intercomKey,
    intercomAppId,
    intercomUserHash,
    intercomIsActive,
    isAuthenticated,
    isMainPortal,
  ]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {shouldHideFromSearchEngines && <meta name="robots" content="noindex" />}
          <link rel="icon" href={favicon ?? icons.favicon} />
          <title>{title}</title>
        </Helmet>

        {/* For now he hide the Spotlight for admins and instructors unit its fixed */}
        {toolbarEl && (
          <Toolbar toolbarEl={toolbarEl}>{toolbar ? toolbar : <SpotlightSearch />}</Toolbar>
        )}
        {children}
      </HelmetProvider>
    </>
  );
};

export default Page;

import React from "react";
import { DropdownItem } from "@epignosis_llc/gnosis";
import {
  GripLinesSVG,
  TrashAltSVG,
  RenameSVG,
  GearIconSVG,
  UpdateSVG,
  CopyIconSVG,
  PrintIconSVG,
  CircleArrowUpSVG,
  CircleArrowDownSVG,
  ScheduleSVG,
  DurationIconSVG,
} from "@epignosis_llc/gnosis/icons";

import { getUnitIconByType } from "@utils/helpers";
import { getOptionsTitle } from "@views/CourseEdit/helpers";
import { t } from "@utils/i18n";
import { UnitType } from "types/entities";
import { SectionDropdownOptions, UnitActions, UnitDropdownOptions } from "./types";
import userRoles from "@constants/userRoles";

export const getSectionDropdownOptions = ({
  // coursePolicies,
  sectionPolicies,
  isReorderingSection,
}: SectionDropdownOptions): DropdownItem[] => {
  // not return any options when a section is selected for reordering
  if (isReorderingSection) return [];

  // const { can_update_content = false } = coursePolicies ?? {};
  const {
    can_delete = false,
    can_edit_name_status = false,
    can_set_delay = false,
  } = sectionPolicies ?? {};

  const renameOption = {
    label: t("general.rename"),
    value: UnitActions.SectionRename,
    icon: <RenameSVG height={16} />,
  };

  // TODO: uncomment reorder functionality when designs are ready
  // const sectionReorderOption = {
  //   label: t("courseEdit.fullSectionReorder"),
  //   value: UnitActions.sectionReorder,
  //   icon: <GripLinesSVG height={16} />,
  // };

  const deleteOption = {
    label: t("general.delete"),
    value: UnitActions.Delete,
    icon: <TrashAltSVG height={16} />,
  };

  const delayOption = {
    label: t("general.delayAvailability"),
    value: UnitActions.Delay,
    icon: <DurationIconSVG height={32} />,
  };

  const options = [];
  can_edit_name_status && options.push(renameOption);
  // can_update_content && options.push(sectionReorderOption);
  can_set_delay && options.push(delayOption);
  can_delete && options.push(deleteOption);

  return options;
};

export const getUnitDropdownOptions = ({
  unitPolicies,
  courseIsActive,
  unitIsActive,
  unitType,
  hasUnpublishedChanges,
  shouldAllowUpdate,
  isSelected,
  courseIsLocked,
  roleInCourse,
}: UnitDropdownOptions): DropdownItem[] => {
  const isInstructor = roleInCourse === userRoles.INSTRUCTOR;

  const {
    can_edit_name_status: canEditUnit = false,
    can_edit_content: canEditContent = false,
    can_delete: canDeleteUnit = false,
    can_clone: canCloneUnit = false,
    can_export: canExportUnit = false,
    can_view_unit_versions: canViewUnitVersions = false,
  } = unitPolicies ?? {};

  const publishOption = {
    id: "unit-publish-update",
    label: t("unitEdit.publish"),
    value: UnitActions.UnitPublish,
    icon: <CircleArrowUpSVG height={32} />,
    isDisabled: !shouldAllowUpdate && isSelected,
    className: "publish-option",
  };

  const unPublishOption = {
    id: "unit-unpublish",
    label: t(`unitEdit.unPublish`),
    value: UnitActions.UnitUnPublish,
    icon: <CircleArrowDownSVG height={32} />,
    isDisabled: !shouldAllowUpdate && isSelected,
  };

  const optionsOption = {
    id: "unit-options",
    label: getOptionsTitle(unitType),
    value: UnitActions.UnitOptions,
    icon: <GearIconSVG height={32} />,
  };

  const publishChangesOption = {
    id: "unit-publish-changes-update",
    label: t("courseEdit.publishChanges"),
    value: UnitActions.UnitPublish,
    icon: <UpdateSVG height={32} />,
    isDisabled: !shouldAllowUpdate && isSelected,
  };

  const renameOption = {
    id: "unit-rename",
    label: t("general.rename"),
    value: UnitActions.UnitRename,
    icon: <RenameSVG height={16} />,
  };

  const cloneOption = {
    id: "unit-clone",
    label: t("general.clone"),
    value: UnitActions.UnitClone,
    icon: <CopyIconSVG height={32} />,
  };

  const printOption = {
    id: "unit-test-print",
    label: t("test.print"),
    value: UnitActions.UnitPrint,
    icon: <PrintIconSVG height={32} />,
  };

  const deleteOption = {
    id: "unit-delete",
    label: t("general.delete"),
    value: UnitActions.Delete,
    icon: <TrashAltSVG height={16} />,
  };

  const versionHistoryOption = {
    id: "unit-version-history",
    label: t("unitEdit.versionHistory"),
    value: UnitActions.UnitVersionHistory,
    icon: <ScheduleSVG height={32} />,
  };

  const isCraftUnit = unitType === "craft";
  const isIltUnit = unitType === "ilt";
  const isAssignmentUnit = unitType === "assignment";
  const canPublish = canEditUnit && !unitIsActive;
  const canUnPublish = canEditUnit && unitIsActive;
  const unitWithOptions = !isCraftUnit && !isIltUnit && !isAssignmentUnit;
  const showOptionsOption = unitWithOptions && canEditContent;
  const canPublishChanges = canEditUnit && hasUnpublishedChanges && courseIsActive && unitIsActive;
  const canPrintUnit = (unitType === "test" || unitType === "survey") && canExportUnit;
  const shouldShowVersionHistoryOption = isInstructor && canViewUnitVersions && !courseIsLocked;

  return [
    ...(canPublish ? [publishOption] : []),
    ...(canUnPublish ? [unPublishOption] : []),
    ...(showOptionsOption ? [optionsOption] : []),
    ...(canEditUnit ? [renameOption] : []),
    ...(canPublishChanges ? [publishChangesOption] : []),
    ...(canCloneUnit ? [cloneOption] : []),
    ...(canPrintUnit ? [printOption] : []),
    ...(shouldShowVersionHistoryOption ? [versionHistoryOption] : []),
    ...(canDeleteUnit ? [deleteOption] : []),
  ];
};

export const getUnitIcon = ({
  showReorderIcon,
  unitType,
}: {
  showReorderIcon: boolean;
  unitType: UnitType | null;
}): JSX.Element | null => {
  if (showReorderIcon) return <GripLinesSVG height={16} className="reorder-icon" />;

  // not return icon for sections
  if (!unitType) return <div className="section-icon-placeholder" />;

  return getUnitIconByType(unitType);
};

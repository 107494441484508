import { Pagination } from "@epignosis_llc/gnosis";
import React, { FC, useEffect } from "react";
import { pagination } from "./styles";
import { PaginationRes } from "types/responses";
import { useApplyTranslations } from "@hooks";

export type RowItem = {
  value: number;
  label: string;
};

export type PaginationWithOptionsProps = PaginationRes & {
  options?: RowItem[];
  listPlacement?: "top" | "bottom";
  onPageChange: (value: number) => void;
  onPageSizeChange: (value: number) => void;
  disabled?: boolean;
};

const FIRST_PAGE = 1;

const PaginationWithOptions: FC<PaginationWithOptionsProps> = ({
  page,
  page_size,
  total_results,
  total_items, // current page results count
  total_pages,
  options = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 40, label: "40" },
    { value: 80, label: "80" },
    { value: 160, label: "160" },
  ].filter((option) => option.value <= total_results * 2),
  onPageChange,
  onPageSizeChange,
  listPlacement = "top",
  disabled = false,
}) => {
  const { t, i18n } = useApplyTranslations();
  const dir = i18n.dir();

  useEffect(() => {
    if (total_items === 0 && page !== FIRST_PAGE) {
      onPageChange(FIRST_PAGE);
    }
  }, [onPageChange, page, page_size, total_items]);

  const paginationTranslations = {
    perPage: t("pagination.perPage"),
    firstPage: t("pagination.firstPage"),
    previousPage: t("pagination.previousPage"),
    nextPage: t("pagination.nextPage"),
    lastPage: t("pagination.lastPage"),
    ofPages: t("pagination.ofPages"),
  };

  return (
    <>
      {total_results > 10 && (
        <div css={pagination} className="pagination-wrapper">
          <Pagination
            page={page}
            pageSize={page_size}
            totalPages={total_pages}
            rowsPerPageOptions={options}
            translations={{ ...paginationTranslations }}
            dir={dir}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            listPlacement={listPlacement}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default PaginationWithOptions;

import React, { FC, memo } from "react";
import { CloseSVG } from "@epignosis_llc/gnosis/icons";
import { PdfThumbnail } from "@components";
import { ActionGhostButton } from "@components/ReusableComponents";
import { templatePreviewStyles } from "./styles";

type TemplatePreviewProps = {
  url: string;
  onClose: () => void;
};

const TemplatePreview: FC<TemplatePreviewProps> = ({ url, onClose }) => {
  return (
    <div css={templatePreviewStyles}>
      <div className="pdf-container">
        <PdfThumbnail fileUrl={url} pageIndex={1} />
      </div>

      <ActionGhostButton onClick={onClose}>
        <CloseSVG height={24} />
      </ActionGhostButton>
    </div>
  );
};

export default memo(TemplatePreview);

import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { useApplyTranslations } from "@hooks";
import { SerializedStyles } from "@emotion/react";

import { brandingLabelStyles } from "./styles";
import { GENERAL_LINK } from "@constants/staticLinks";

const TALENTLMS = "TalentLMS";

const BrandingLabel: FC = () => {
  const { t } = useApplyTranslations();

  return (
    <div css={(theme): SerializedStyles => brandingLabelStyles(theme)}>
      <Text fontSize="xs" as="span">
        {t("general.poweredBy")}
      </Text>{" "}
      <a className="branding-link" href={GENERAL_LINK.TALENT_LMS} target="_blank" rel="noreferrer">
        <Text fontSize="xs" as="span" weight="700">
          {TALENTLMS}
        </Text>
      </a>
    </div>
  );
};
export default BrandingLabel;

import { userHasRequiredPermissions } from "./index";
import useConfigurationStore from "@stores/configuration";
import authService from "@utils/services/AuthService";

// map with all the available permissions
export const discussionsPermissions = {
  read: "discussion.read",
  create: "discussion.create",
  comment: "discussion.comment",
  moderate: "discussion.moderate",
};

const { read, create, comment, moderate } = discussionsPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => discussion.read
   * discussion.read is required in order to have all the other discussion related permissions
   *
   *  Allowed actions:
   *  GET discussions, totalActive, singleDiscussion, replies,
   *  PUT like, unlike
   *
   * @returns boolean
   */
  canAccessDiscussions: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => discussion.create
   *
   * Allowed actions:
   *  GET audience
   *  POST createDiscussion, attachment
   *  PUT edit
   *
   * @returns boolean
   */
  canCreateDiscussion: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [read, create],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => discussion.comment
   *
   * Allowed actions:
   *  POST attachment, comment, commentReply
   *  PUT subscribe, unsubscribe
   *
   * @returns boolean
   */
  canCommentDiscussion: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [read, comment],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => discussion.moderate
   *
   * Allowed actions:
   *  DELETE delete, deleteComment
   *  PUT editCommentReply
   *
   * @returns boolean
   */
  canModerateDiscussion: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [read, moderate],
      getState().userProfileData?.permissions[userRole],
    );
  },
};

import React, { FC } from "react";
import { cardFooterContainer } from "./styles";

type CardFooterProps = {
  children: React.ReactNode;
};

const CardFooter: FC<CardFooterProps> = ({ children }) => {
  return <div css={cardFooterContainer}>{children}</div>;
};

export default CardFooter;

import React, { FC } from "react";
import { Text, Heading } from "@epignosis_llc/gnosis";
import { Course } from "types/entities";
import StarRating from "@components/StarRating/StarRating";
import { useConfigurationStore } from "@stores";

export type CardBodyProps = React.HTMLAttributes<HTMLElement> & {
  course: Course;
  isCatalogView?: boolean;
};

const CardBody: FC<CardBodyProps> = ({ course, isCatalogView = false, ...rest }) => {
  const { domainSettings } = useConfigurationStore();

  return (
    <div {...rest}>
      <Text fontSize="xs" as="div" className="code">
        {course.code}
      </Text>

      <Heading as="h2" size="lg" className="card-title">
        {course.name}
      </Heading>

      {isCatalogView && domainSettings?.course_rating && course.rating?.average && (
        <div className="rating-container">
          <StarRating count={course.rating.average} readonly />
        </div>
      )}
    </div>
  );
};

export default CardBody;

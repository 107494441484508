// Packages or third-party libraries
import React, { FC } from "react";
import { InputError } from "@epignosis_llc/gnosis";
import { Controller, UseFormReturn } from "react-hook-form";

// Components
import { EditableContent } from "@components/ReusableComponents";

// Hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { EditCourseData } from "@views/CourseEdit/api";
import { Course } from "types/entities";
import { toolbarButtonsMinimal } from "@components/ReusableComponents/EditableContent/constants";

type EditDescriptionProps = {
  course: Course;
  form: UseFormReturn<EditCourseData>;
  onSave: () => void;
};

const EditDescription: FC<EditDescriptionProps> = ({ course, form, onSave }) => {
  const { t } = useApplyTranslations();
  const { control } = form;
  const { policies } = course;
  const { can_edit = false } = policies ?? {};

  return (
    <Controller
      name="description"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => (
        <>
          <EditableContent
            course={course}
            activeToolbarButtons={toolbarButtonsMinimal}
            quickInsertEnabled={false}
            id="course-description-editor"
            initialContent={value ?? ""}
            canEdit={can_edit}
            placeholder={t("courseEdit.courseDescriptionPlaceholder")}
            onChange={(content): void => onChange(content || null)}
            onContentSave={onSave}
          />
          {error && <InputError>{error.message}</InputError>}
        </>
      )}
    />
  );
};

export default EditDescription;

export const courseOptionsIds = {
  catalogOptions: "catalog-options",
  accessOptions: "access-options",
  timeOptions: "time-options",
  customFieldsOptions: "custom-fields-options",
  introVideoOptions: "intro-video-options",
  courseCompletionRules: "course-completion-rules",
  learningPathRules: "learning-path-rules",
  certificateOptions: "certificate-options",
};

import { CourseProgress } from "types/entities";

type Color = "primary" | "secondary" | "success" | "danger";

export const getButtonColor = (completion_status: CourseProgress["completion_status"]): Color => {
  switch (completion_status) {
    case "completed":
      return "success";
    case "failed":
      return "secondary";
    default:
      return "primary";
  }
};

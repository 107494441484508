import { isPast, parseISO } from "date-fns";
import { SelectOption } from "types/common";
import { CourseInstructor } from "types/entities/Unit";

export const isSessionLive = (start: string, end: string): boolean => {
  const startDate = parseISO(start);
  const endDate = parseISO(end);
  const hasStartPassed = isPast(startDate);
  const hasEndPassed = isPast(endDate);

  return hasStartPassed && !hasEndPassed;
};

export const mapSessionInstructorsToOptions = (instructors: CourseInstructor[]): SelectOption[] => {
  return instructors.map(({ instructor_id, instructor_name }) => ({
    value: instructor_id.toString(),
    label: instructor_name,
  }));
};

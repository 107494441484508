import { MutationOptions, UseMutationResult, useMutation } from "react-query";
import queryKeys from "@constants/queryKeys";
import { EditCourseData, cloneUnit, editCourse, linkUnit } from "@views/CourseEdit/api";
import { postCourseUnit } from "@api/courses";
import { cloneCourseUnitData } from "./types";

export type usePostCourseFileProps = {
  file: File;
  isLastFile: boolean;
};

export const useEditCourseDetails = ({
  courseId,
  options,
  shouldTrackMarketo,
}: {
  courseId: string;
  shouldTrackMarketo?: boolean;
  options?: MutationOptions<unknown, unknown, EditCourseData, unknown>;
}): UseMutationResult<unknown, unknown, EditCourseData, unknown> => {
  return useMutation(
    [queryKeys.courses.courseEdit, courseId],
    (data: EditCourseData) => editCourse(courseId, data, shouldTrackMarketo),
    options,
  );
};

export const usePostCourseUnit = ({
  courseId,
  options,
}: {
  courseId: string;
  options?: MutationOptions<unknown, unknown, usePostCourseFileProps, unknown>;
}): UseMutationResult<unknown, unknown, usePostCourseFileProps, unknown> => {
  return useMutation(
    [queryKeys.courses.postUnit, courseId, options],
    (data: usePostCourseFileProps) => postCourseUnit({ courseId, file: data.file }),
    options,
  );
};

export const useCloneCourseUnit = ({
  options,
}: {
  options?: MutationOptions<unknown, unknown, cloneCourseUnitData, unknown>;
}): UseMutationResult<unknown, unknown, cloneCourseUnitData, unknown> => {
  return useMutation([queryKeys.courses.unitClone], cloneUnit, options);
};

export const useLinkCourseUnit = ({
  options,
}: {
  options?: MutationOptions<unknown, unknown, cloneCourseUnitData, unknown>;
}): UseMutationResult<unknown, unknown, cloneCourseUnitData, unknown> => {
  return useMutation([queryKeys.courses.unitLink], linkUnit, options);
};

import React, { FC } from "react";
import { Button, Card, Chip, Text } from "@epignosis_llc/gnosis";
import { ShowIconSVG, EditSVG, TrashAltSVG } from "@epignosis_llc/gnosis/icons";
import { SerializedStyles } from "@emotion/react";
import { useResponsive } from "ahooks";
import CardActions, { Actions } from "../components/CardActions";
import { CardBody, CardHeader } from "./styles";
import { Theme } from "types/entities";
import { useConfigurationStore } from "@stores";
import { useApplyTranslations } from "@hooks";

export type ThemeCardProps = {
  theme: Theme;
  onThemePreview: (theme: Theme) => void;
  onThemeEdit: (theme: Theme) => void;
  onThemeDelete: (theme: Theme) => void;
};

type HoverProps = {
  theme: Theme;
  actions: Actions[];
  isDefault: boolean;
  onThemePreview: (theme: Theme) => void;
};

const Hover: FC<HoverProps> = ({ theme, actions, isDefault, onThemePreview }) => {
  return (
    <div>
      {!isDefault && <CardActions actions={actions} />}
      <Button
        rounded
        aria-label="Preview theme"
        className="hover-btn"
        data-testid="action-button"
        onClick={(): void => onThemePreview(theme)}
      >
        <ShowIconSVG height={32} />
      </Button>
    </div>
  );
};

const ThemeCard: FC<ThemeCardProps> = ({ theme, onThemePreview, onThemeEdit, onThemeDelete }) => {
  const { t } = useApplyTranslations();
  const { md } = useResponsive();
  const domainSettings = useConfigurationStore((state) => state.domainSettings);
  const { theme: activeTheme = null } = domainSettings ?? {};
  const { name, active, primary_color, default: isDefault } = theme;

  const actions = [
    {
      label: t("themes.edit"),
      icon: EditSVG,
      onClick: (): void => onThemeEdit(theme),
    },
    ...(activeTheme?.id !== theme.id
      ? [
          {
            label: t("themes.delete"),
            icon: TrashAltSVG,
            onClick: (): void => onThemeDelete(theme),
          },
        ]
      : []),
  ];

  return (
    <Card>
      <Card.Header ratio={[3, 2]}>
        <div css={(): SerializedStyles => CardHeader(primary_color)}>
          {md ? (
            <Card.Hover>
              <Hover
                theme={theme}
                actions={actions}
                isDefault={isDefault}
                onThemePreview={onThemePreview}
              />
            </Card.Hover>
          ) : (
            <Hover
              theme={theme}
              actions={actions}
              isDefault={isDefault}
              onThemePreview={onThemePreview}
            />
          )}
        </div>
      </Card.Header>
      <div css={(theme): SerializedStyles => CardBody(theme, primary_color)}>
        <Card.Body>
          {active && <Chip className="active-chip">{t("general.active").toUpperCase()}</Chip>}
          <Text fontSize="sm">{name}</Text>
        </Card.Body>
      </div>
    </Card>
  );
};

export default ThemeCard;

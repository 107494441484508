import React, { FC, useMemo } from "react";
import {
  ActionsContainer,
  DownloadAction,
  GradeAction,
  OpenedMessageAction,
  ResetAction,
} from "@components";
import { useApplyTranslations } from "@hooks";

type SubmissionsActionsProps = {
  id: string;
  isHovered: boolean;
  canDownload: boolean;
  canGrade: boolean;
  canReset: boolean;
  onDownload: (id: string) => void;
  onGrade: (id: string) => void;
  onSendMessage: (id: string) => void;
  onReset: (id: string) => void;
};

const SubmissionsActions: FC<SubmissionsActionsProps> = ({
  id,
  isHovered,
  canDownload,
  canReset,
  canGrade,
  onDownload,
  onGrade,
  onSendMessage,
  onReset,
}): JSX.Element => {
  const { t } = useApplyTranslations();

  const actions = useMemo(
    () => [
      canDownload && (
        <DownloadAction
          key="download-assignment"
          data-testid="download-assignment"
          onClick={(e): void => {
            e.stopPropagation();
            onDownload(id);
          }}
        />
      ),
      canGrade && (
        <GradeAction
          key="grade-assignment"
          data-testid="grade-assignment"
          onClick={(e): void => {
            e.stopPropagation();
            onGrade(id);
          }}
        />
      ),
      <OpenedMessageAction
        tooltipContent={t("general.message")}
        key="forward-message-assignment"
        data-testid="forward-message-assignment"
        onClick={(e): void => {
          e.stopPropagation();
          onSendMessage(id);
        }}
      />,
      canReset && (
        <ResetAction
          key="reset-assignment"
          data-testid="reset-assignment"
          onClick={(e): void => {
            e.stopPropagation();
            onReset(id);
          }}
        />
      ),
    ],
    [canDownload, canGrade, canReset, id, onDownload, onGrade, onReset, onSendMessage, t],
  );

  return <ActionsContainer actions={actions} isHovered={isHovered} />;
};

export default SubmissionsActions;

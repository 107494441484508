import { GroupPatchSchema } from "@views/Groups/components/GroupSettings/types";
import { ENDPOINTS } from "./endpoints";
import HttpClient from "./HttpClient";
import {
  GroupsRes,
  GroupRes,
  GroupUsersRes,
  GroupFilesRes,
  FileRes,
  CountMassActionResponse,
  MassActionResultResponse,
  GroupsByUsersRes,
  TableCSVExportRes,
} from "types/responses";
import { GroupsMassAction } from "types/entities/Groups";
import { MassActionParam } from "@components/ReusableComponents";
import { marketoQueryParam } from "@constants/queryParameters";
import { Method } from "axios";

export type PatchUserGroupSchema = {
  groupKey: string;
};

const formContentType = {
  "Content-Type": "multipart/form-data",
};

export type EditGroupFileBodyData = Partial<{
  name: string;
  tags: string[];
  shared: boolean;
}>;

/***** GROUP endpoints *****/

export const getGroup = async (groupId: string): Promise<GroupRes> => {
  const res = await HttpClient.get(ENDPOINTS.groups.group(groupId));

  return res.data;
};

export const getGroups = async (queryStr = ""): Promise<GroupsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.groups.groups}${queryStr}`);

  return res.data;
};

export const postGroup = async (data: GroupPatchSchema): Promise<GroupRes> => {
  // By calling this method, a Marketo event will be automatically triggered.
  const res = await HttpClient.post(`${ENDPOINTS.groups.groups}?${marketoQueryParam}`, data);

  return res.data;
};

export const patchGroup = async (data: GroupPatchSchema, groupId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.groups.group(groupId), data);
};

export const deleteGroup = async (groupId: string): Promise<GroupRes> => {
  const res = await HttpClient.delete(ENDPOINTS.groups.group(groupId));

  return res.data;
};

/***** GROUP USER endpoints *****/

export const getGroupUsers = async (queryStr = "", groupId: string): Promise<GroupUsersRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.groups.users(groupId)}${queryStr}`);

  return res.data;
};

export const groupUsersExport = async (
  groupId: string,
  queryStr = "",
): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.groupUsers(groupId)}${queryStr}`);
  return res.data;
};

/***** GROUP FILE endpoints *****/

export const getGroupFiles = async (groupId: string, queryStr = ""): Promise<GroupFilesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.groups.files(groupId)}${queryStr}`);

  return res.data;
};

export const editGroupFile = async ({
  groupId,
  fileId,
  data,
}: {
  groupId: string;
  fileId: string;
  data: EditGroupFileBodyData;
}): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.groups.filesEdit(groupId, fileId), data);
};

export const postGroupFile = async ({
  groupId,
  file,
}: {
  groupId: string;
  file: File;
}): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const endpoint = `${ENDPOINTS.groups.filesUpload(groupId)}`;

  const res = await HttpClient.post(endpoint, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const postGroupFileURL = async ({
  groupId,
  fileURL,
}: {
  groupId: string;
  fileURL: string;
}): Promise<FileRes> => {
  const endpoint = `${ENDPOINTS.groups.filesUploadURL(groupId)}`;
  const body = { url: fileURL };

  const res = await HttpClient.post(endpoint, body);

  return res.data;
};

export const deleteGroupFile = async ({
  groupId,
  fileId,
}: {
  groupId: string;
  fileId: string;
}): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.groups.filesEdit(groupId, fileId));
};

/***** Mass Actions Group endpoints *****/
export const groupsMassActionsCount = async (
  type: GroupsMassAction,
  data: MassActionParam,
): Promise<CountMassActionResponse> => {
  const dataToSend = data?.course_id as number;
  const res = await HttpClient.get(ENDPOINTS.groups.affectedGroups(type, dataToSend));

  return res.data;
};

export const groupsMassActions = async (
  type: GroupsMassAction,
  data: MassActionParam,
): Promise<MassActionResultResponse> => {
  const dataToSend = data?.course_id as number;

  const methods: Record<string, Method> = {
    add: "post",
    remove: "delete",
  };

  const requestType = methods[type];

  const res = await HttpClient.request({
    url: ENDPOINTS.branches.massAction(dataToSend),
    method: requestType,
  });
  return res.data;
};

export const getGroupsByUsers = async (userIds: number[]): Promise<GroupsByUsersRes> => {
  const res = await HttpClient.post(ENDPOINTS.groups.groupsByUsers, { user_ids: userIds });

  return res.data;
};

import React, { FC, useEffect } from "react";
import { FallbackProps } from "react-error-boundary";
import { getItemWithExpiration, setItemWithExpiration } from "@errors";
import localStorageKeys from "@constants/localStorageKeys";
import { InternalServerError } from "@views/Errors";

const CHUNK_FAILED_MESSAGE_REGEX = /Loading chunk [\d]+ failed/;

const ErrorFallbackComponent: FC<FallbackProps> = ({ error }): JSX.Element => {
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    if (error?.message && CHUNK_FAILED_MESSAGE_REGEX.test(error.message)) {
      if (!getItemWithExpiration(localStorageKeys.CHUNK_FAILED)) {
        setItemWithExpiration(localStorageKeys.CHUNK_FAILED, "true", 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return <InternalServerError />;
};

export default ErrorFallbackComponent;

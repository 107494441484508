// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Result, Tabs } from "@epignosis_llc/gnosis";
import { useQuery } from "react-query";

// Styles
import { courseTabStyles, emptyResultsStyles, tabsSectionStyles } from "./styles";

// Components
import { BorderedSection, Skeletons, CourseToC } from "@components";
import { FileList } from "@components/ReusableComponents";
import CompletionText from "./CompletionText";

// Helpers, hooks
import { buildPaginatedSearchQuery, getFlatUnits } from "@utils/helpers";
import { useApplyTranslations, useIsPublicCourse } from "@hooks";
import { useConfigurationStore } from "@stores";
import permissions from "@utils/permissions";
import { getMandatoryUnits } from "@components/CourseOverview/helpers";

// Other imports
import { Course, Section, ValidFileSortingField } from "types/entities";
import { TabObject } from "types/entities/Common";
import { Pagination as IPagination } from "types/responses/index";
import { getCatalogCourseFiles } from "@api/catalog";
import { getCourseFiles } from "@api/courses";
import userRoles from "@constants/userRoles";
import queryKeys from "@constants/queryKeys";
import { TabKey } from "@components/CourseOverview/constants";
import authService from "@utils/services/AuthService";

type CourseTabsProps = {
  course: Course;
  sections: Section[];
  isCatalogCourseView: boolean;
  isAdminOverview?: boolean;
};

const PAGINATION: IPagination = { number: 1, size: 1000 };
const SORTING: ValidFileSortingField[] = ["uploaded_at"];

const CourseTabs: FC<CourseTabsProps> = ({
  course,
  sections,
  isCatalogCourseView,
  isAdminOverview = false,
}) => {
  const { t } = useApplyTranslations();
  const { gamificationSettings, userProfileData } = useConfigurationStore();
  const isPublicCourse = useIsPublicCourse();

  const [activeTab, setActiveTab] = useState(0);

  const { id, role_in_course, availability, level, enrolled, rules } = course;

  // permission related
  const userRole = authService.getDefaultRole();
  const isLearner = userRole === userRoles.LEARNER;

  const { canAccessCourseSharedFiles } = permissions.coursesPermissions;
  const allowAccessCourseSharedFiles = canAccessCourseSharedFiles() || !isLearner;

  const { completion } = rules;
  const units = getFlatUnits(sections);
  const courseId = id.toString();
  const isLevelEnabled = Boolean(gamificationSettings?.levels?.enabled);
  const userLevel = userProfileData?.level ?? 0;
  const courseLevel = level ?? 0;
  const isUserEnrolled = Boolean(enrolled);
  const unlocksOnHigherLevel = isLevelEnabled ? userLevel < courseLevel : false;
  const isCourseAvailable = availability ? availability?.is_available : !unlocksOnHigherLevel;
  const isUserInstructor = role_in_course === userRoles.INSTRUCTOR;
  const isReadonlyLearnerView =
    !isUserEnrolled || !isCourseAvailable || isUserInstructor || isPublicCourse;

  // role or role in course?
  const isAdministrator = authService.getDefaultRole() === userRoles.ADMINISTRATOR;
  const isReadonly = isAdminOverview ? isAdministrator : isReadonlyLearnerView;
  const mandatoryUnits = getMandatoryUnits(completion);

  // files
  const filesSearchQuery = buildPaginatedSearchQuery({
    pagination: {
      number: PAGINATION.number,
      size: PAGINATION.size,
    },
    sorting: SORTING,
  });
  const getFiles = isCatalogCourseView ? getCatalogCourseFiles : getCourseFiles;

  const {
    data: files = [],
    status: filesStatus,
    error: filesError,
  } = useQuery(
    [queryKeys.myFiles, id, filesSearchQuery],
    () => getFiles(courseId, filesSearchQuery),
    {
      enabled: allowAccessCourseSharedFiles,
      select: (res) => res._data,
    },
  );

  const getTabs = (): TabObject[] => {
    const tabs: TabObject[] = [
      {
        title: t("general.content"),
        key: TabKey.Units,
        content: (
          <div css={courseTabStyles}>
            {units.length === 0 ? (
              <Result title={t("myCourses.noUnitsAvailable")} css={emptyResultsStyles} />
            ) : (
              <CourseToC
                course={course}
                sections={sections}
                mandatoryUnits={mandatoryUnits}
                isReadonly={isReadonly}
                isLearnerView={!isAdminOverview}
              />
            )}
          </div>
        ),
      },
    ];

    if (allowAccessCourseSharedFiles && files.length > 0) {
      tabs.push({
        title: t("general.filesUpper"),
        key: TabKey.Files,
        content: (
          <Skeletons.Loader status={filesStatus} error={filesError}>
            <div css={courseTabStyles}>
              <FileList files={files} courseId={courseId} isReadonly={isReadonly} />
            </div>
          </Skeletons.Loader>
        ),
      });
    }

    return tabs;
  };

  const tabsToShow = getTabs();
  const activeTabKey = tabsToShow[activeTab].key;

  return (
    <div css={tabsSectionStyles} className="has-max-width">
      <BorderedSection className="tabs-section">
        {activeTabKey === TabKey.Units && <CompletionText completion={completion} />}
        <Tabs
          className="tabs"
          selectedTab={activeTab}
          tabs={tabsToShow}
          onChangeTab={setActiveTab}
        />
      </BorderedSection>
    </div>
  );
};

export default CourseTabs;

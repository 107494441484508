import { useRef, useState, useEffect } from "react";

type RecordingStatus = "loading" | "success" | "error";
type RecordingType = "video" | "audio";

export enum RecorderErrors {
  AbortError = "media_aborted",
  NotAllowedError = "permission_denied",
  NotFoundError = "no_specified_media_found",
  NotReadableError = "media_in_use",
  OverconstrainedError = "invalid_media_constraints",
  TypeError = "no_constraints",
  NONE = "",
  NO_RECORDER = "recorder_error",
}

type Recording = {
  recorderStatus: RecordingStatus;
  mediaRecorder: MediaRecorder | undefined;
  initRecorder: () => Promise<void>;
};

const useRecording = (type: RecordingType): Recording => {
  const mediaRecorder = useRef<MediaRecorder>();
  const [status, setStatus] = useState<RecordingStatus>("loading");

  // Clear up on component unmount
  useEffect(() => {
    return (): void => {
      mediaRecorder.current?.stream.getTracks().forEach((track) => track.stop());
    };
  }, []);

  const initRecorder = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: type === "video" && true,
        })
        .then((stream) => {
          mediaRecorder.current = new MediaRecorder(stream);
          mediaRecorder.current.onpause = mediaRecorder.current.requestData;
          mediaRecorder.current.onerror = (): void => setStatus("error");
        })
        .then(() => {
          setStatus("success");
          resolve();
        })
        .catch((err) => {
          setStatus("error");
          reject(RecorderErrors[err.name]);
        });
    });
  };

  return {
    recorderStatus: status,
    mediaRecorder: mediaRecorder.current,
    initRecorder,
  };
};

export default useRecording;

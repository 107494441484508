import React from "react";
import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isBefore,
  isToday,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import { Culture, DateLocalizer, DateRange, Formats, View } from "react-big-calendar";
import { utcToZonedTime } from "date-fns-tz";
import { timeZonedDate } from "./date-time";
import { CalendarEvent, CalendarState } from "types/entities";
import { useConfigurationStore, useUIStore } from "@stores";
import permissions from "@utils/permissions";

const { getState: getConfigurationStoreState } = useConfigurationStore;
const { getState: getUIStoreState } = useUIStore;
const { theme } = getUIStoreState();

export const getDatesRange = (
  date: Date,
  view: View,
): { datetimeStart: Date; datetimeEnd: Date } => {
  const startFn = view === "month" ? startOfMonth : view === "week" ? startOfWeek : startOfDay;
  const endFn = view === "month" ? endOfMonth : view === "week" ? endOfWeek : endOfDay;
  const startDatetime = view === "week" ? startFn(date, { weekStartsOn: 0 }) : startFn(date);
  const endDatetime = view === "week" ? endFn(date, { weekStartsOn: 0 }) : endFn(date);

  // add 7 days in month view and 1 in other views
  // to fetch all posible dates that could be shown in calendar view
  return {
    datetimeStart: subDays(startDatetime, view === "month" ? 7 : 1),
    datetimeEnd: addDays(endDatetime, view === "month" ? 7 : 1),
  };
};

export const getStateWithNewDates = (options: {
  currentState: CalendarState;
  date?: Date;
  view?: View;
}): CalendarState => {
  const { currentState, date, view } = options;
  const newState = { ...currentState };
  const newDate = date ?? newState.date;
  const newView = view ?? newState.view;

  const { datetimeStart, datetimeEnd } = getDatesRange(newDate, newView);
  newState.datetimeStart = datetimeStart;
  newState.datetimeEnd = datetimeEnd;

  if (date) newState.date = newDate;
  if (view) newState.view = newView;

  return newState;
};

export const eventPropGetter = (event: CalendarEvent): React.HTMLAttributes<HTMLDivElement> => {
  const { userProfileData } = getConfigurationStoreState();
  const startDate = utcToZonedTime(event.start_datetime, userProfileData?.timezone as string);
  const today = new Date().setHours(0, 0, 0, 0);

  const isPastDate = isBefore(startDate, today);
  const { pastEventColor, eventColor, pastEventTextColor } = theme.calendar;

  return {
    style: {
      background: isPastDate ? pastEventColor : event.color ?? eventColor,
      color: isPastDate ? pastEventTextColor : "#fff",
    },
  };
};

export const dayPropGetter = (date: Date): React.HTMLAttributes<HTMLDivElement> => {
  const { pastDayColor } = theme.calendar;
  const today = new Date().setHours(0, 0, 0, 0);
  const { canCreateEvent } = permissions.calendarPermissions;
  const allowEventCreation = canCreateEvent();
  const isPastDate = isBefore(date, today);

  if (!allowEventCreation) {
    return {
      style: {
        cursor: "default",
      },
    };
  }

  if (isToday(date)) {
    return {
      style: {
        cursor: "pointer",
      },
    };
  }

  return {
    style: {
      backgroundColor: isPastDate ? pastDayColor : "#fff",
      cursor: isPastDate ? "default" : "pointer",
    },
  };
};

export const startAccessor = (event: CalendarEvent): Date => {
  const { userProfileData } = getConfigurationStoreState();
  return timeZonedDate(event.start_datetime, userProfileData?.timezone as string);
};

export const endAccessor = (event: CalendarEvent): Date => {
  const { userProfileData } = getConfigurationStoreState();
  return timeZonedDate(event.end_datetime, userProfileData?.timezone as string);
};

export const getCustomFormats = (lg: boolean): Formats => {
  const { domainSettings } = getConfigurationStoreState();
  const timeFormat = domainSettings?.time_format ?? "HH";

  return {
    dayFormat: `${lg ? "EEE dd/MM" : "EEE"}`,
    weekdayFormat: lg ? "EEEE" : "EEE",
    timeGutterFormat: `${timeFormat}${timeFormat === "hh" ? " a" : ":mm"}`,
    monthHeaderFormat: "LLLL yyyy",
    dayHeaderFormat: "dd MMM, yyyy",
    dayRangeHeaderFormat: (
      range: DateRange,
      culture?: Culture,
      localizer?: DateLocalizer,
    ): string => (localizer ? formatDayRangeHeader(range, culture ?? "", localizer) : ""),
  };
};

// format week view date header
const formatDayRangeHeader = (
  range: DateRange,
  culture: Culture,
  localizer: DateLocalizer,
): string => {
  const startDay = localizer.format(range.start, "dd", culture);
  const startMonth = localizer.format(range.start, "MMM", culture);
  const startYear = localizer.format(range.start, "yyyy", culture);

  const endDay = localizer.format(range.end, "dd", culture);
  const endMonth = localizer.format(range.end, "MMM", culture);
  const endYear = localizer.format(range.end, "yyyy", culture);

  const displayStartMonth = startMonth !== endMonth ? startMonth : "";
  const displayStartYear = startYear !== endYear ? `, ${startYear}` : "";

  const start = `${startDay} ${displayStartMonth}${displayStartYear}`;
  const end = `${endDay} ${endMonth}, ${endYear}`;

  return `${start} - ${end}`;
};

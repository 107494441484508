import Color from "color";
import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["widgets"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    iconColor: protectColorContrast("darker", colors.primary.base),
    descriptionColor: colors.secondary.base,
    completionRate: {
      barBg: colors.primary.base,
      barBgHover: colors.primary.lightest,
    },
    coursePie: {
      iconColor: protectColorContrast("darker", colors.primary.base),
      completed: colors.green.light,
      completedFocused: colors.green.base,
      inProgress: colors.orange.base,
      notStarted: colors.secondary.base,
      notPassed: colors.red.light,
      notPassedFocused: colors.red.base,
      tagColor: colors.secondary.lighter,
    },
    overview: {
      color: colors.primary.base,
      iconBackground: colors.white,
      iconBorder: colors.primary.lightest,
    },
    portalActivity: {
      loginsCountBg: Color(colors.blue.lightest).alpha(0.25).string(),
      completionsCountBg: Color(colors.green.light).alpha(0.15).string(),
      loginsBg: colors.blue.lightest,
      completionsBg: colors.green.light,
      inactive: colors.secondary.base,
    },
    users: {
      color: protectColorContrast("darker", colors.primary.base),
    },
  };
};

import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["courseCard"] => {
  return {
    cardHeader: {
      infoBtn: {
        color: colors.black,
        background: colors.white,
        labelBackground: colors.primary.darkest,
        labelColor: colors.white,
      },
    },
    cardBody: {
      code: colors.secondary.dark,
    },
    cardFooter: {
      borderColor: colors.secondary.lighter,
      color: colors.primary.base,
    },
    progressBar: {
      background: colors.secondary.lighter,
      progressBackground: colors.green.base,
      color: colors.white,
      percentageColor: colors.green.darker,
    },
  };
};

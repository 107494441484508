import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const leaderBoardContainer = css`
  height: 100%;
  overflow-y: hidden;

  .loading-container {
    height: 100%;
  }

  .header-container {
    padding: 1.5rem 2rem;
  }

  .content-container {
    width: 100%;
    height: 100%;
    position: relative;

    .leaderboard-tabs {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      article {
        height: 100%;

        footer {
          display: flex;
          justify-content: center;
          margin-top: 0.5rem;

          ${mq["sm"]} {
            justify-content: flex-end;
            margin-inline-end: 1.5rem;
            margin-top: 0.25rem;
          }
        }
      }
    }
  }

  nav {
    padding: 0 2rem;
  }

  .skeleton-wrapper {
    padding: 2rem;
  }
`;

import { css } from "@emotion/react";

export const creditBreakdownTableStyles = ({ icon }) => css`
  .credits-message {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
      line-height: 0;
    }

    .tooltip-icon {
      color: ${icon.primary};
    }
  }
`;

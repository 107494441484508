import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { tabContainerStyles } from "../styles";
import Divider from "@components/ReusableComponents/Divider/Divider";
import AccessOptions from "./AccessOptions";
import TimeOptions from "./TimeOptions";
import { TabHandle, TabProps } from "../../CourseOptionsDrawer";
import { EditCourseData } from "@views/CourseEdit/api";
import { CourseAvailabilityOptionsSchemas } from "@utils/validation/courses/optionsSchemas";
import { useEditCourseDetails } from "@views/CourseEdit/hooks";
import { CourseAvailabilityFormData } from "@views/CourseEdit/types";
import { handleCourseErrors } from "@errors";
import { useNavigate } from "react-router";
import { URLS } from "@constants/urls";
import { ConfirmationModal } from "@components";
import { useApplyTranslations } from "@hooks";

const formatDataForSubmit = (data: CourseAvailabilityFormData): EditCourseData => {
  const submitData: EditCourseData = { ...data };
  const { time_limit, expires_at } = submitData;
  const canRetainAccess = Boolean(time_limit || expires_at);

  // set retain_access_after_completion false, when neither expires_at nor time_limit have value
  if (!canRetainAccess) {
    submitData.retain_access_after_completion = false;
  }

  return submitData;
};

const AvailabilityTab: ForwardRefRenderFunction<TabHandle, TabProps> = (
  { course, onSuccess, onValidChange, onLoadingChange },
  ref,
) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const {
    id,
    capacity,
    level,
    status,
    time_limit,
    retain_access_after_completion,
    starts_at,
    expires_at,
    policies,
  } = course;
  const { can_update_level = false } = policies ?? {};
  const courseId = id.toString();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const form = useForm<CourseAvailabilityFormData>({
    mode: "onChange",
    resolver: yupResolver(CourseAvailabilityOptionsSchemas),
    defaultValues: {
      // availability tab access options
      capacity,
      level: can_update_level ? level : undefined,
      is_active: Boolean(status === "active"),
      // time options
      time_limit,
      retain_access_after_completion,
      starts_at: starts_at,
      expires_at: expires_at,
    },
  });

  const {
    getValues,
    formState: { isValid },
  } = form;

  const { mutateAsync: editCourseDetailsAsyncMutation, isLoading: isLoadingEditCourseDetails } =
    useEditCourseDetails({
      courseId,
      shouldTrackMarketo: true,
      options: {
        onSuccess: () => {
          onSuccess();
        },
        onError: (err) => {
          const error = err as AxiosError;
          const handleError = (): void => {
            navigate(URLS.courses.courses);
          };

          handleCourseErrors(error, false, handleError);
        },
      },
    });

  const handleSubmit = async (): Promise<void> => {
    if (course.is_prerequisite && !getValues().is_active) {
      setIsModalOpen(true);
    } else {
      await submitData();
    }
  };

  const submitData = async (): Promise<void> => {
    const data = getValues();
    const submitData = formatDataForSubmit(data);
    const isValid = await CourseAvailabilityOptionsSchemas.isValid(data);

    if (isValid) {
      await editCourseDetailsAsyncMutation(submitData);
    }
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  // The component instance will be extended with whatever returned from the callback
  useImperativeHandle(ref, () => ({
    handleApply: handleSubmit,
  }));

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange]);

  useEffect(() => {
    onLoadingChange(isLoadingEditCourseDetails);
  }, [isLoadingEditCourseDetails, onLoadingChange]);

  return (
    <section css={tabContainerStyles}>
      <AccessOptions course={course} form={form} />
      <Divider />
      <TimeOptions course={course} form={form} />
      {isModalOpen && (
        <ConfirmationModal
          id="deactivate-confirmation-modal"
          header={t("courses.courseDeactivation")}
          bodyTitle={
            <div
              dangerouslySetInnerHTML={{
                __html: t("courses.confirmCourseDeactivation", {
                  title: course.name,
                }),
              }}
            />
          }
          footerButton={t("courseEdit.deactivate")}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onConfirm={async (): Promise<void> => await submitData()}
          confirmationButtonColor="primary"
        />
      )}
    </section>
  );
};

export default forwardRef(AvailabilityTab);

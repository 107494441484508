// Packages or third-party libraries
import React, { FC, useLayoutEffect, useRef, useState } from "react";
import { Loader, Result, Tabs } from "@epignosis_llc/gnosis";
import { AddContentSVG } from "@epignosis_llc/gnosis/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

// Styles
import { courseTabStyles, emptyResultsStyles, tabsSectionStyles } from "../styles";

// Components
import { BorderedSection, Skeletons, CourseToC } from "@components";
import { FileList, FileUploadArea } from "@components/ReusableComponents";
import CompletionText from "../CompletionText";

// Helpers, hooks
import {
  buildPaginatedSearchQuery,
  generalNotification,
  getFlatUnits,
  mapTableToSelectSorting,
} from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { usePostCourseUnit } from "@views/CourseEdit/hooks";
import { HandledError } from "@errors";
import { handleUploadFilesErrors } from "@errors/errors";
import { getMandatoryUnits } from "@components/CourseOverview/helpers";

// Other imports
import { Course, Section } from "types/entities";
import { getCourseFiles, postCourseFile } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { sharedFilesDefaultState } from "@views/CourseEdit";
import { URLS } from "@constants/urls";
import { validationTypes } from "@views/CourseEdit/components/Sidebar/components/constants";
import { TabKey } from "@components/CourseOverview/constants";

type CourseEditTabsProps = {
  course: Course;
  sections: Section[];
};

const CourseEditTabs: FC<CourseEditTabsProps> = ({ course, sections }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { id, policies, rules } = course;
  const { completion } = rules;

  const {
    can_view_course_files: canViewCourseFiles,
    can_add_unit: canAddUnit,
    can_edit = false,
    can_view_rules = false,
    can_update_rules = false,
  } = policies ?? {};

  const units = getFlatUnits(sections);
  const activeUnits = units.filter(({ is_active }) => is_active);
  const courseId = id.toString();

  const { pagination, sorting: tableSorting, filters } = sharedFilesDefaultState;
  const sorting = [mapTableToSelectSorting(tableSorting)];
  const filesSearchQuery = buildPaginatedSearchQuery({ pagination, sorting, filters });
  const [activeTab, setActiveTab] = useState(0);
  const unitsListWrapperRef = useRef<HTMLDivElement>(null);
  const fileListWrapperRef = useRef<HTMLDivElement>(null);

  const canEditOptions = can_edit || (can_view_rules && can_update_rules);

  const {
    data: files = [],
    status: filesStatus,
    error: filesError,
  } = useQuery(
    [queryKeys.myFiles, id, filesSearchQuery],
    () => getCourseFiles(courseId, filesSearchQuery),
    {
      enabled: canViewCourseFiles,
      select: (res) => res._data,
    },
  );

  const handleFileDelete = (): void => {
    queryClient.invalidateQueries([queryKeys.myFiles, id, filesSearchQuery]);
  };

  const { mutate: postUnitFileMutation, isLoading: postUnitFileLoading } = usePostCourseUnit({
    courseId,
    options: {
      onSuccess: () => {
        generalNotification("success", <p>{t("courseEdit.unitCreatedSuccessfully")}</p>);
        queryClient.invalidateQueries([queryKeys.units]);
        queryClient.invalidateQueries([queryKeys.courses.images, courseId]);
      },
      onError: (err: unknown) => {
        const error = err as AxiosError;
        generalNotification("error", <p>{error.response?.data._errors[0].title}</p>);
      },
    },
  });

  const { mutate: postCourseFileMutation, isLoading: postCourseFileLoading } = useMutation(
    [queryKeys.courses.postFile, courseId],
    (variables: { file: File; isLastFile: boolean }) =>
      postCourseFile({ courseId, file: variables.file, shared: true }),
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("files.fileUploadSuccessful")}</p>);
        queryClient.invalidateQueries([queryKeys.myFiles, id, filesSearchQuery]);
      },
      onError: (error: AxiosError) => {
        const handleError = (error: HandledError | null): void => {
          if (error?.id === "not_found.course_not_found") {
            navigate(URLS.courses.courses);
          }
        };
        handleUploadFilesErrors(error, false, handleError);
      },
    },
  );

  useLayoutEffect(() => {
    if (postUnitFileLoading) {
      unitsListWrapperRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [postUnitFileLoading]);

  useLayoutEffect(() => {
    if (postCourseFileLoading) {
      fileListWrapperRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [postCourseFileLoading]);

  const mandatoryUnits = getMandatoryUnits(completion);

  const tabs = [
    {
      title: t("general.content"),
      key: TabKey.Units,
      content: (
        <div css={courseTabStyles} ref={unitsListWrapperRef}>
          {activeUnits.length === 0 ? (
            <>
              {canAddUnit ? (
                <FileUploadArea
                  id="upload-course-unit-files"
                  title={t("courseEdit.isEmpty")}
                  subtitle={t("courseEdit.dndOrClickLeft")}
                  icon={AddContentSVG}
                  isLoading={postUnitFileLoading}
                  validationTypes={validationTypes}
                  postFileMutation={postUnitFileMutation}
                  showRules
                  hideBorder
                />
              ) : (
                <Result title={t("courseEdit.isEmpty")} css={emptyResultsStyles} />
              )}
            </>
          ) : (
            <FileUploadArea
              id="upload-course-unit-files"
              isLoading={postUnitFileLoading}
              validationTypes={validationTypes}
              postFileMutation={postUnitFileMutation}
              showRules
              isFileInputReadonly
              hideBorder
            >
              <CourseToC
                course={course}
                sections={sections}
                mandatoryUnits={mandatoryUnits}
                isReadonly={false}
                isLearnerView={false}
              />
              {postUnitFileLoading && <Loader className=" upload-loader" />}
            </FileUploadArea>
          )}
        </div>
      ),
    },
    {
      title: t("general.filesUpper"),
      key: TabKey.Files,
      content: (
        <div css={courseTabStyles} ref={fileListWrapperRef}>
          {files.length === 0 ? (
            <>
              {canViewCourseFiles ? (
                <FileUploadArea
                  id="upload-course-files"
                  title={t("courseEdit.noFiles")}
                  subtitle={t("files.shareFilesWithLearners")}
                  isLoading={postCourseFileLoading}
                  postFileMutation={postCourseFileMutation}
                  showRules
                  hideBorder
                />
              ) : (
                <Result title={t("courseEdit.noFiles")} css={emptyResultsStyles} />
              )}
            </>
          ) : (
            <Skeletons.Loader status={filesStatus} error={filesError}>
              <FileUploadArea
                id="upload-course-files"
                isLoading={postCourseFileLoading}
                postFileMutation={postCourseFileMutation}
                showRules
                isFileInputReadonly
                hideBorder
              >
                <FileList
                  files={files}
                  courseId={courseId}
                  isReadonly={false}
                  onDelete={handleFileDelete}
                />
                {postCourseFileLoading && <Loader className=" upload-loader" />}
              </FileUploadArea>
            </Skeletons.Loader>
          )}
        </div>
      ),
    },
  ];

  const activeTabKey = tabs[activeTab].key;

  return (
    <div id="tabs-section" css={tabsSectionStyles} className="has-max-width">
      <BorderedSection className="tabs-section">
        {activeTabKey === TabKey.Units && (
          <CompletionText completion={completion} canEditOptions={canEditOptions} />
        )}
        <Tabs className="tabs" selectedTab={activeTab} tabs={tabs} onChangeTab={setActiveTab} />
      </BorderedSection>
    </div>
  );
};

export default CourseEditTabs;

import React, { useState } from "react";
import { Text, Loader, Input, Button } from "@epignosis_llc/gnosis";
import { UseMutateFunction } from "react-query";
import { FileRes } from "types/responses";
import { ActionDrawerContainer } from "./styles";
import { SerializedStyles } from "@emotion/react";
import { UploadCloudIconSVG } from "@epignosis_llc/gnosis/icons";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { errorNotification, isValidUrl } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

type FileUploadURLProps = {
  isLoading: boolean;
  postFileMutation: UseMutateFunction<FileRes, unknown, string, unknown>;
};

const dropboxURL = "https://help.talentlms.com/hc/en-us/articles/9651468913692";
const googleURL = "https://help.talentlms.com/hc/en-us/articles/9651517932956";

const FileUploadURL = (props: FileUploadURLProps): JSX.Element => {
  const { t } = useApplyTranslations();
  const { isLoading, postFileMutation } = props;
  const [urlValue, setURLValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setURLValue(value);
  };

  const handleUpload = (): void => {
    if (isValidUrl(urlValue)) {
      postFileMutation(urlValue);
    } else {
      errorNotification(t("files.urlNotValid"));
    }
  };

  return (
    <span css={(theme): SerializedStyles => ActionDrawerContainer(theme)}>
      {!isLoading ? (
        <div className="upload-prompt-area">
          <div className="upload-prompt">
            <div className="upload-prompt-container">
              <Input
                id="id"
                label={t("files.externalURL")}
                placeholder={t("files.pasteURL")}
                value={urlValue}
                onChange={handleInputChange}
                autoComplete="off"
              />
              <Button
                as="button"
                variant="link"
                className="upload-button"
                iconBefore={UploadCloudIconSVG}
                onClick={handleUpload}
                disabled={Boolean(!urlValue)}
              >
                {t("general.upload")}
              </Button>
            </div>

            <Text fontSize="xs" className="subtitle">
              <Trans i18nKey="files.usePublic">
                <Link to={dropboxURL} className="link" target="_blank">
                  Dropbox
                </Link>
                <Link to={googleURL} className="link" target="_blank">
                  Google Drive
                </Link>
              </Trans>
            </Text>
          </div>
        </div>
      ) : (
        <div className="upload-prompt-area">
          <div className="loading-wrapper">
            <Text fontSize="md" weight="700">
              {t("general.uploadingFile", { count: 0 })}
            </Text>
            <Loader />
          </div>
        </div>
      )}
    </span>
  );
};

export default FileUploadURL;

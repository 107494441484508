import React, { FC } from "react";
import { Tooltip, Tag } from "@epignosis_llc/gnosis";
import { useApplyTranslations } from "@hooks";
import { TALENT_LMS_HELP_LINKS } from "@constants/staticLinks";

export type RemainingLicensesProps = {
  licenses: number | null;
};

const RemainingLicenses: FC<RemainingLicensesProps> = ({ licenses }) => {
  const { t } = useApplyTranslations();

  if (typeof licenses !== "number") return null;

  return (
    <Tooltip
      content={
        <div
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
            e.stopPropagation();
          }}
          dangerouslySetInnerHTML={{
            __html: t("courses.remainingLicenses", {
              count: licenses,
              url: TALENT_LMS_HELP_LINKS.REMAINING_LICENSES_TOOLTIP_URL,
            }),
          }}
        />
      }
    >
      <Tag style={{ marginInlineStart: "0.5rem" }}>{licenses}</Tag>
    </Tooltip>
  );
};

export default RemainingLicenses;

import { css } from "@emotion/react";

export const infoPanelContainer = ({ infoPanel }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 1.5rem 0.5rem;
  border-radius: 5px;
  height: 100%;
  background: ${infoPanel.background};
  color: ${infoPanel.color};

  .title,
  .subtitle {
    margin-top: 0.5rem;
    color: ${infoPanel.color};
  }
`;

import React, { ReactElement, useState } from "react";
import { useApplyTranslations } from "@hooks";
import { SSOSettings } from "../types";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {
  Button,
  Input,
  InputError,
  Loader,
  Select,
  Text,
  ToggleSwitch,
} from "@epignosis_llc/gnosis";
import { CheckCircledSVG, SynchronizeSVG } from "@epignosis_llc/gnosis/icons";
import { BorderedSection, ConfirmationModal, CopyToClipboardButton } from "@components";
import { SPMetadata, SSOLoginTypeOptions } from "../constants";
import { Option } from "@components/MainSearch/types";
import LinkText from "./LinkText";
import DrawerInputField from "@views/AccountAndSettings/PortalSettings/components/DrawerInputField";
import { useQuery } from "react-query";
import queryKeys from "@constants/queryKeys";
import { generateSCIMKey } from "../api";
import { formatLabelSelect } from "@views/AccountAndSettings/components/SettingCard/helpers";
import { CustomField } from "types/entities";
import { customUserFieldsIdsToOptions, customUserFieldsToOptions } from "../helpers";
import { SelectOption } from "types/common";
import { optionsToCustomFieldsIds } from "@utils/helpers";

export type SamlFormProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  control: Control<T>;
  watch: UseFormWatch<T>;
  SSOSettings?: SSOSettings;
  customUserFields: CustomField[];
  errors: FieldErrors<T>;
  isDisabled: boolean;
  origin?: "branch" | "portal";
  setValue: UseFormSetValue<T>;
  setIsTestClicked: React.Dispatch<React.SetStateAction<boolean>>;
};

const SamlForm = <T extends FieldValues>({
  SSOSettings,
  customUserFields,
  control,
  watch,
  setValue,
  register,
  errors,
  origin = "portal",
  isDisabled,
  setIsTestClicked,
}: SamlFormProps<T>): JSX.Element => {
  const { t, i18n } = useApplyTranslations();
  const dir = i18n.dir();

  const availableSSOLoginTypeOptions = SSOLoginTypeOptions.map((value) => {
    return { label: formatLabelSelect("login_type", value, []), value: value };
  });

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const idsPrefix = origin === "branch" ? "sso.saml" : "saml";
  const loginTypePrefix = origin === "branch" ? "sso" : "";
  const errorPath = origin === "branch" ? errors?.sso?.["saml"] : errors?.saml;

  const openSAMLCertificateDefaultValue =
    origin === "branch"
      ? watch(`${idsPrefix}.saml_certificate` as Path<T>)
      : Boolean(SSOSettings?.saml?.saml_certificate);
  const openSAMLCertificateAltDefaultValue =
    origin === "branch"
      ? watch(`${idsPrefix}.saml_certificate_alt` as Path<T>)
      : Boolean(SSOSettings?.saml?.saml_certificate_alt);
  const entityIdDefaultValue =
    origin === "branch"
      ? watch("sso.saml.idp_configuration.entity_id" as Path<T>)
      : SSOSettings?.saml?.idp_configuration.entity_id ?? "";
  const acsUrlDefaultValue =
    origin === "branch"
      ? watch("sso.saml.idp_configuration.acs_url" as Path<T>)
      : SSOSettings?.saml?.idp_configuration.acs_url ?? "";
  const singleLogoutUrlDefaultValue =
    origin === "branch"
      ? watch("sso.saml.idp_configuration.single_logout_url" as Path<T>)
      : SSOSettings?.saml?.idp_configuration.single_logout_url ?? "";
  const spMetadataUrlDefaultValue =
    origin === "branch"
      ? watch("sso.saml.idp_configuration.sp_metadata_url" as Path<T>)
      : SSOSettings?.saml?.idp_configuration.sp_metadata_url ?? "";

  const [openSAMLCertificate, setOpenSAMLCertificate] = useState(openSAMLCertificateDefaultValue);
  const [openSAMLCertificateAlt, setOpenSAMLCertificateAlt] = useState(
    openSAMLCertificateAltDefaultValue,
  );

  const isSCIM2Enabled = watch(`${idsPrefix}.enable_scim2_api` as Path<T>);
  const scimKey = watch(`${idsPrefix}.scim2_api_key` as Path<T>);

  const { refetch, isLoading: isSCIMLoading } = useQuery(
    [queryKeys.accountAndSettings.userSettings.generateSCIMKey],
    () => generateSCIMKey(),
    {
      enabled: false,
      select: (SCIMkey) => SCIMkey?._data,
      refetchOnWindowFocus: false,
      onSuccess: (SCIMkey): void =>
        setValue(
          `${idsPrefix}.scim2_api_key` as Path<T>,
          (SCIMkey?.scim_key ?? "") as PathValue<T, Path<T>>,
        ),
    },
  );

  const handleRegenerateKey = (): void => {
    refetch();
  };

  return (
    <>
      <div className="form-wrapper">
        <div className="form-container">
          <div className="single-line-container line-with-toggle">
            <div className="input-error-container">
              <Input
                {...register(`${idsPrefix}.idp` as Path<T>)}
                id="saml.idp"
                placeholder={t("general.typeHere")}
                label={t("accountAndSettings.usersSettings.saml.identityProvider")}
                status={errorPath?.idp ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.idp && <InputError>{errorPath?.idp?.message}</InputError>}
            </div>
            <div className="input-error-container right-container line-with-toggle">
              <Controller
                name={`${idsPrefix}.requires_protocol` as Path<T>}
                control={control}
                render={({ field: { onChange, value } }): JSX.Element => (
                  <div className="toggle-container">
                    <ToggleSwitch
                      labelAfter={`${t("general.type")}: Azure AD`}
                      defaultChecked={value}
                      isDisabled={isDisabled}
                      onChange={(): void => {
                        onChange(!value);
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="single-line-container line-with-toggle">
            <div className="input-error-container">
              <Input
                {...register(`${idsPrefix}.key` as Path<T>)}
                id="saml.key"
                placeholder={t("general.typeHere")}
                label={t("accountAndSettings.usersSettings.saml.certificateFingerprint")}
                status={errorPath?.key ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.key && <InputError>{errorPath?.key?.message}</InputError>}
            </div>
            <div className="input-error-container right-container line-with-toggle">
              <div className="toggle-container">
                <CopyToClipboardButton
                  textToCopy={""}
                  preventDefault
                  onClick={(): void => setOpenSAMLCertificateAlt(!openSAMLCertificateAlt)}
                  clipboardText={t("accountAndSettings.usersSettings.saml.pasteSAML")}
                  disableTooltip
                />
              </div>
            </div>
          </div>

          {openSAMLCertificateAlt && (
            <div className="form-item full-width">
              <DrawerInputField
                name={`${idsPrefix}.saml_certificate_alt` as Path<T>}
                control={control}
                label={t("accountAndSettings.usersSettings.saml.SAMLCertificate")}
                errors={errors}
                isDisabled={isDisabled}
                type={"textarea"}
              />
            </div>
          )}

          <div className="single-line-container line-with-toggle">
            <div className="input-error-container">
              <Input
                {...register(`${idsPrefix}.key_alt` as Path<T>)}
                id="saml.key_alt"
                placeholder={t("general.typeHere")}
                label={t("accountAndSettings.usersSettings.saml.certificateFingerprintAlt")}
                status={errorPath?.key_alt ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.key_alt && <InputError>{errorPath?.key_alt?.message}</InputError>}
            </div>
            <div className="input-error-container right-container line-with-toggle">
              <div className="toggle-container">
                <CopyToClipboardButton
                  textToCopy={""}
                  preventDefault
                  onClick={(): void => setOpenSAMLCertificate(!openSAMLCertificate)}
                  clipboardText={t("accountAndSettings.usersSettings.saml.pasteSAML")}
                  disableTooltip
                />
              </div>
            </div>
          </div>

          {openSAMLCertificate && (
            <div className="form-item full-width">
              <DrawerInputField
                name={`${idsPrefix}.saml_certificate` as Path<T>}
                control={control}
                label={t("accountAndSettings.usersSettings.saml.SAMLCertificate")}
                errors={errors}
                isDisabled={isDisabled}
                type={"textarea"}
              />
            </div>
          )}

          <div className="form-item">
            <Input
              {...register(`${idsPrefix}.sign_in_url` as Path<T>)}
              id="saml.sign_in_url"
              placeholder={t("general.typeHere")}
              label={t("accountAndSettings.usersSettings.saml.signInURL")}
              status={errorPath?.sign_in_url ? "error" : "valid"}
              data-lpignore="true"
              disabled={isDisabled}
            />
            {errorPath?.sign_in_url && <InputError>{errorPath?.sign_in_url.message}</InputError>}
          </div>

          <div className="form-item">
            <Input
              {...register(`${idsPrefix}.sign_out_url` as Path<T>)}
              id="saml.sign_out_url"
              placeholder={t("general.typeHere")}
              label={t("accountAndSettings.usersSettings.saml.signOutURL")}
              status={errorPath?.sign_out_url ? "error" : "valid"}
              data-lpignore="true"
              disabled={isDisabled}
            />
            {errorPath?.sign_out_url && <InputError>{errorPath?.sign_out_url.message}</InputError>}
          </div>

          <div className="form-item">
            <Input
              {...register(`${idsPrefix}.username` as Path<T>)}
              id="saml.username"
              placeholder={t("general.typeHere")}
              label={t("general.username")}
              status={errorPath?.username ? "error" : "valid"}
              data-lpignore="true"
              disabled={isDisabled}
            />
            {errorPath?.username && <InputError>{errorPath?.username.message}</InputError>}
          </div>

          <div className="single-line-container">
            <div className="input-error-container">
              <Input
                {...register(`${idsPrefix}.firstname` as Path<T>)}
                id="saml.firstname"
                placeholder={t("general.typeHere")}
                label={t("accountAndSettings.usersSettings.saml.firstName")}
                status={errorPath?.firstname ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.firstname && <InputError>{errorPath?.firstname?.message}</InputError>}
            </div>
            <div className="input-error-container right-container">
              <Input
                {...register(`${idsPrefix}.lastname` as Path<T>)}
                id="saml.lastname"
                placeholder={t("general.typeHere")}
                label={t("accountAndSettings.usersSettings.saml.lastName")}
                status={errorPath?.lastname ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.lastname && <InputError>{errorPath?.lastname?.message}</InputError>}
            </div>
          </div>

          <div className="form-item">
            <Input
              {...register(`${idsPrefix}.email` as Path<T>)}
              id="saml.email"
              placeholder={t("general.typeHere")}
              label={t("general.email")}
              status={errorPath?.email ? "error" : "valid"}
              data-lpignore="true"
              disabled={isDisabled}
            />
            {errorPath?.email && <InputError>{errorPath?.email.message}</InputError>}
          </div>

          <div className="single-line-container line-with-toggle">
            <div className="input-error-container">
              <Input
                {...register(`${idsPrefix}.group` as Path<T>)}
                id="saml.group"
                placeholder={t("general.typeHere")}
                label={t("general.group")}
                status={errorPath?.group ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.group && <InputError>{errorPath?.group?.message}</InputError>}
            </div>
            <div className="input-error-container right-container line-with-toggle">
              <Controller
                name={`${idsPrefix}.add_group_on_each_login` as Path<T>}
                control={control}
                render={({ field: { onChange, value } }): JSX.Element => (
                  <div className="toggle-container">
                    <ToggleSwitch
                      labelAfter={t("accountAndSettings.usersSettings.oidc.addGroups")}
                      defaultChecked={value}
                      isDisabled={isDisabled}
                      onChange={(): void => {
                        onChange(!value);
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="custom-user-field-container">
            <Controller
              name={`${idsPrefix}.custom_fields.fields` as Path<T>}
              control={control}
              render={({ field: { onChange, value } }): ReactElement => (
                <Select
                  placeholder={t("accountAndSettings.usersSettings.oidc.selectCustomFields")}
                  label={t("accountAndSettings.usersSettings.oidc.customFields")}
                  options={customUserFieldsToOptions(customUserFields)}
                  isMulti
                  isSearchable
                  isClearable
                  value={customUserFieldsIdsToOptions((value ?? []) as number[], customUserFields)}
                  defaultValue={customUserFieldsIdsToOptions(
                    (value ?? []) as number[],
                    customUserFields,
                  )}
                  onChange={(options): void => {
                    const customUserFieldsOptions = optionsToCustomFieldsIds(
                      options as SelectOption[],
                    );
                    onChange(customUserFieldsOptions);
                  }}
                  maxMenuHeight={150}
                />
              )}
            />
          </div>
          <div className="form-item last">
            <Controller
              name={`${idsPrefix}.custom_fields.ignore_namespace` as Path<T>}
              control={control}
              render={({ field: { onChange, value } }): JSX.Element => (
                <div className="toggle-container">
                  <ToggleSwitch
                    labelAfter={t("accountAndSettings.usersSettings.oidc.ignoreNamespace")}
                    defaultChecked={value}
                    isDisabled={isDisabled}
                    onChange={(): void => {
                      onChange(!value);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <div className="form-container">
        <div className="form-item">
          <Controller
            name={`${idsPrefix}.sign_requests` as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => (
              <div className="toggle-container">
                <ToggleSwitch
                  labelAfter={t("accountAndSettings.usersSettings.saml.signRequests")}
                  defaultChecked={value}
                  isDisabled={isDisabled}
                  onChange={(): void => {
                    onChange(!value);
                  }}
                />
              </div>
            )}
          />
        </div>

        <div className="form-item">
          <Controller
            name={`${idsPrefix}.validate_requests` as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => (
              <div className="toggle-container">
                <ToggleSwitch
                  labelAfter={t("accountAndSettings.usersSettings.saml.validateRequests")}
                  defaultChecked={value}
                  isDisabled={isDisabled}
                  onChange={(): void => {
                    onChange(!value);
                  }}
                />
              </div>
            )}
          />
        </div>

        <div className="form-item">
          <Controller
            name={`${idsPrefix}.enable_scim2_api` as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => (
              <div className="toggle-container">
                <ToggleSwitch
                  labelAfter={t("accountAndSettings.usersSettings.saml.enableScim2")}
                  defaultChecked={value}
                  isDisabled={isDisabled}
                  onChange={(): void => {
                    if (!value && !scimKey) {
                      handleRegenerateKey();
                    }
                    onChange(!value);
                  }}
                />
              </div>
            )}
          />
          {isSCIM2Enabled && (
            <div className="scim-input">
              {isSCIMLoading ? (
                <Loader size="md" />
              ) : (
                <>
                  <Input
                    {...register(`${idsPrefix}.scim2_api_key` as Path<T>)}
                    id="saml.scim2_api_key"
                    data-lpignore="true"
                    readOnly
                  />
                  {/* <CopyToClipboardButton textToCopy={watch("saml.scim2_api_key")} /> */}
                  <Button
                    noGutters
                    className="upload-icon"
                    variant="ghost"
                    onClick={(e: React.MouseEvent<HTMLElement>): void => {
                      e.preventDefault();
                      setConfirmationModalOpen(true);
                    }}
                  >
                    <SynchronizeSVG height={32} />
                  </Button>
                </>
              )}
            </div>
          )}
        </div>

        <Button
          variant="outline"
          className="check-configuration-button"
          iconBefore={CheckCircledSVG}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            e.preventDefault();
            setIsTestClicked(true);
          }}
        >
          {t("accountAndSettings.usersSettings.oidc.saveAndCheckConfiguration")}
        </Button>

        <BorderedSection>
          <Text fontSize={"sm"} weight="700">
            {t("accountAndSettings.usersSettings.oidc.identityProviderConfig")}
          </Text>
          <LinkText
            text={t("accountAndSettings.usersSettings.saml.theEntityID")}
            url={entityIdDefaultValue}
          />
          <LinkText
            text={t("accountAndSettings.usersSettings.saml.theAssertionService")}
            url={acsUrlDefaultValue}
          />
          <LinkText
            text={t("accountAndSettings.usersSettings.saml.theSingleLogout")}
            url={singleLogoutUrlDefaultValue}
          />
          <LinkText text={SPMetadata} url={spMetadataUrlDefaultValue} isLink />
        </BorderedSection>

        <div className="sso-login-screen">
          <Controller
            name={`${loginTypePrefix}.login_type` as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): ReactElement => {
              const selectedValue = {
                label: formatLabelSelect("login_type", value),
                value: value ?? "",
              };
              return (
                <Select
                  id="login_type"
                  label={t("accountAndSettings.usersSettings.SSOLoginScreen")}
                  isRtl={dir === "rtl"}
                  options={availableSSOLoginTypeOptions}
                  isDisabled={isDisabled}
                  onChange={(option): void => {
                    onChange((option as Option).value);
                  }}
                  value={selectedValue}
                />
              );
            }}
          />
        </div>
      </div>
      {confirmationModalOpen && (
        <ConfirmationModal
          id={"key"}
          header={t("accountAndSettings.usersSettings.saml.refreshSCIM")}
          hasDontAskAgainCheckbox={true}
          bodyTitle={t("accountAndSettings.usersSettings.saml.refreshSCIMConfirmation")}
          confirmationButtonColor={"danger"}
          footerButton={t("general.refresh")}
          isOpen={confirmationModalOpen}
          onClose={(): void => setConfirmationModalOpen(false)}
          onConfirm={(): void => handleRegenerateKey()}
        />
      )}
    </>
  );
};

export default SamlForm;

import React, { FC, useRef } from "react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { CourseFile, File } from "types/entities";
import { removeFileExtension } from "@utils/helpers";
import { useIsOverflow } from "@hooks";

type FileNameProps = (File | CourseFile) & {
  showExtension?: boolean;
};

const FileName: FC<FileNameProps> = (props) => {
  const { showExtension = false, ...rest } = props;
  const { name } = rest;
  const fileName = showExtension ? name : removeFileExtension(name);

  const ref = useRef<HTMLSpanElement | null>(null);
  const overflowActive = useIsOverflow(ref);

  return (
    <>
      <Tooltip content={fileName} disabled={!overflowActive}>
        <Text fontSize="sm" data-testid="file-text" className="file-name">
          <span ref={ref}>{fileName}</span>
        </Text>
      </Tooltip>
    </>
  );
};

export default FileName;

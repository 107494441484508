import { css } from "@emotion/react";
import { hexToRGBA } from "@styles/utils";

export const ToolbarContainer = ({ pdfPreview }) => css`
  background-color: ${hexToRGBA(pdfPreview.toolbar.background, 0.95)};
  border: 1px solid ${hexToRGBA(pdfPreview.toolbar.background, 0.2)};
  align-items: center;
  border-radius: 3px;
  bottom: 0.5rem;
  display: flex;
  left: 50%;
  padding: 4px;
  position: absolute;
  transform: translate(-50%, 0);
  z-index: 1;
  transition: opacity 0.5s linear;
  opacity: 0;

  .action-btn {
    background-color: transparent;
    svg {
      color: ${hexToRGBA(pdfPreview.toolbar.color, 0.7)};
    }
    &:hover {
      background-color: transparent;
      svg {
        color: ${pdfPreview.toolbar.color};
      }
    }

    .previous-page-btn {
      transform: rotate(-90deg);
    }

    .next-page-btn {
      transform: rotate(90deg);
    }
  }

  .input-wrapper {
    padding: 0.5rem;
    width: 4rem;
  }

  .number-of-pages {
    padding: 0.5rem;
    color: ${pdfPreview.toolbar.color};
  }

  .icon-ascending {
    transform: rotate(180deg) translateY(50%);
    margin-top: 1rem;
  }
`;

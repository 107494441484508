import { PalletThemeColors, getProtectedColor, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["dateInput"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    borderColor: colors.secondary.lighter,
    textColor: colors.black,
    contentActiveColor: protectColorContrast("black", colors.primary.base),
    contentInactiveColor: colors.secondary.base,
    activeBackgroundColor: protectColorContrast(
      "darker",
      hexToRGBA(colors.primary.lightest, 0.25),
      0.25,
    ),
    iconColor: colors.secondary.light,
    iconColorHover: colors.secondary.light,
    headerBackgroundColor: colors.white,
    boxShadowColor: hexToRGBA(colors.black, 0.1),
  };
};

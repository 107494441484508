import { MutableRefObject, useCallback, useEffect, useState } from "react";

const isInViewport = (el: HTMLElement | null, parentEl?: HTMLElement | null): boolean => {
  if (!el) return false;

  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  if (parentEl) {
    const parentRect = parentEl.getBoundingClientRect();
    return elemTop >= 0 && (elemBottom <= parentRect.height || elemTop <= parentRect.height);
  } else {
    return elemTop >= 0 && (elemBottom <= window.innerHeight || elemTop <= window.innerHeight);
  }
};

const useIsScrolledIntoView = (ref: MutableRefObject<null>, parentEl?: HTMLElement): boolean => {
  const [isIntoView, setIsIntoView] = useState(() => isInViewport(ref.current, parentEl));

  const handleOnScroll = useCallback(() => {
    setIsIntoView(isInViewport(ref.current, parentEl));
  }, [parentEl, ref]);

  useEffect(() => {
    const scrollEl = parentEl ?? window;
    scrollEl.addEventListener("scroll", handleOnScroll);
    setIsIntoView(isInViewport(ref.current, parentEl));

    return () => {
      scrollEl.removeEventListener("scroll", handleOnScroll);
    };
  }, [ref, parentEl, handleOnScroll]);

  return isIntoView;
};

export default useIsScrolledIntoView;

import React, { FC, useState } from "react";
import { Button, Drawer, Heading } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { useNavigate } from "react-router-dom";
import { footerButtons } from "./styles";
import { useConfigurationStore, useUIStore } from "@stores";
import Gamification from "@components/Drawers/Gamification/Gamification";
import { URLS } from "@constants/urls";
import { getFormattedUserName } from "@utils/helpers";
import { UserProfile } from "types/entities";
import { useApplyTranslations } from "@hooks";

const GamificationDrawer: FC = () => {
  const { t, i18n } = useApplyTranslations();
  const { lg, sm } = useResponsive();
  const navigate = useNavigate();
  const { userProfileData, gamificationSettings } = useConfigurationStore((state) => state);
  const { name = "", surname = "", login = "" } = (userProfileData as UserProfile) ?? {};
  const { toggleGamificationDrawer, showGamificationDrawer } = useUIStore((state) => state);
  const [leaderboardSelectedTab, setLeaderboardSelectedTab] = useState("");
  const [isGamificationInfoOpen, setisGamificationInfoOpen] = useState(false);
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "left" : "right";

  const handleMyBadgesButtonClicked = (): void => {
    toggleGamificationDrawer();
    navigate(URLS.user.badges);
  };

  return (
    <Drawer
      isOpen={showGamificationDrawer}
      onClose={toggleGamificationDrawer}
      placement={placement}
      width={sm ? "550px" : "100%"}
      id="gamification-drawer"
    >
      <Drawer.Header closable={!lg}>
        <Heading as="h1" size="2xl">
          {getFormattedUserName({ name, surname, login })}
        </Heading>
      </Drawer.Header>
      <Drawer.Body>
        <Gamification
          isGamificationInfoOpen={isGamificationInfoOpen}
          leaderboardSelectedTab={leaderboardSelectedTab}
          setIsGamificationInfoOpen={setisGamificationInfoOpen}
          setLeaderboardSelectedTab={setLeaderboardSelectedTab}
        />
      </Drawer.Body>
      {!isGamificationInfoOpen && (
        <Drawer.Footer>
          {gamificationSettings && (
            <div css={footerButtons}>
              {/* points selected */}
              {leaderboardSelectedTab === "points" && gamificationSettings.points?.enabled && (
                <Button onClick={(): void => setisGamificationInfoOpen(true)}>
                  {t("gamification.collectMorePoints")}
                </Button>
              )}
              {/* levels selected */}
              {leaderboardSelectedTab === "levels" && gamificationSettings.levels?.enabled && (
                <Button onClick={(): void => setisGamificationInfoOpen(true)}>
                  {t("gamification.howToLevelUp")}
                </Button>
              )}
              {/* badges selected */}
              {leaderboardSelectedTab === "badges" && gamificationSettings.badges?.enabled && (
                <Button onClick={(): void => handleMyBadgesButtonClicked()}>
                  {t("dashboard.badgeGallery")}
                </Button>
              )}
            </div>
          )}
        </Drawer.Footer>
      )}
    </Drawer>
  );
};

export default GamificationDrawer;

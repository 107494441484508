import { css } from "@emotion/react";

export const PressedRectangleStyles = ({ pressedRectangle }, { isPressed }) => css`
  width: 100%;
  max-width: 20rem;
  background-color: ${pressedRectangle.backgroundColor};
  border: 1px solid ${isPressed ? pressedRectangle.borderPressedColor : pressedRectangle.border};
  border-radius: 5px;
  padding: 0.5rem;
  text-align: center;
  color: ${pressedRectangle.color};
  cursor: pointer;
  &:hover {
    border: 1px solid ${pressedRectangle.borderHoverColor};
  }
`;

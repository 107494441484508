// Packages or third-party libraries
import React, { FC } from "react";
import { QueryStatus } from "react-query";
import { UseFormReturn } from "react-hook-form";
import { Input, InputError, Loader, Textarea } from "@epignosis_llc/gnosis";

// Styles
import { TabContainer } from "./styles";

// Components
import { Skeletons } from "@components";

// Utils, hooks
import { capitalize } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { GradeFormData } from "types/entities/Assignments";

type GradeTabProps = {
  error: unknown;
  status: QueryStatus;
  form: UseFormReturn<GradeFormData>;
};

const GradeTab: FC<GradeTabProps> = ({ error, status, form }): JSX.Element => {
  const { t } = useApplyTranslations();

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Skeletons.Loader status={status} error={error} loader={<Loader fullScreen />}>
      <div css={TabContainer}>
        <Input
          type="number"
          id="score"
          label={t("assignment.grade")}
          placeholder={t("general.score")}
          status={errors.score ? "error" : "valid"}
          {...register("score", {
            setValueAs: (value) => value || null,
          })}
        />
        {errors.score && <InputError>{errors.score.message}</InputError>}
        <Textarea
          {...register("comments")}
          id="comments"
          label={capitalize(t("general.comments"))}
          placeholder={capitalize(t("general.comments"))}
          status={errors.comments ? "error" : "valid"}
        />
        {errors.comments && <InputError>{errors.comments.message}</InputError>}
      </div>
    </Skeletons.Loader>
  );
};

export default GradeTab;

import { css } from "@emotion/react";

export const UnitWeightsStyles = ({ courseEdit: { weights } }) => css`
  border: 1px solid ${weights.borderColor};
  border-radius: 5px;
  padding: 0.75rem 0;

  .btns-container {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.5rem;
    margin: 0 0.75rem;
  }

  .score-unit-container {
    .weight-percentage {
      background-color: ${weights.percentageBackgroundColor};
      color: ${weights.percentageColor};
      padding: 0.25rem 0.5rem;
    }

    .line {
      width: auto;
      margin: 0.25rem 0.75rem 0.75rem;
    }
  }

  .overall {
    margin: 0 0.75rem;
  }
`;

export const LearningPathRulesStyles = css`
  .learning-path-content-container {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    & > div:first-of-type {
      width: 100%;
    }
  }
`;

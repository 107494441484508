import { css } from "@emotion/react";

export const fileInputContainer = ({ fileInput }, { hideBorder }) => css`
  input[type="file"] {
    display: none;
  }

  .label-container {
    display: flex;

    .tooltip {
      max-width: 15rem;
    }

    .select-files-wrapper {
      width: 100%;
      min-height: 120px;

      &.disabled {
        .select-files-container {
          border: ${hideBorder ? "none" : `1px solid ${fileInput.borderDisabled}`};
          cursor: initial;

          .title,
          svg {
            color: ${fileInput.disabledColor};
          }
        }
      }

      &:not(.disabled):hover {
        .title {
          text-decoration: underline;
        }
      }

      .select-files-container,
      .loading-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: ${hideBorder ? "none" : `1px solid ${fileInput.border}`};
        border-radius: 5px;
        padding: 1rem 4.125rem 1.125rem;
        text-align: center;
      }

      .select-files-container {
        gap: 0.25em;
        cursor: pointer;

        .title,
        svg {
          color: ${fileInput.titleColor};
        }

        .subtitle {
          color: ${fileInput.disabledColor};
        }
      }

      .loading-wrapper {
        gap: 0.5em;
        height: 100%;
      }
    }

    .children-wrapper {
      width: 100%;
    }
  }
`;

export const DragAndDropAreaContainer = ({ fileInput, dragging, showRules }) => css`
  width: 100%;
  position: relative;
  z-index: 4;
  min-height: ${dragging ? "7.5rem" : "0"};

  /* on dragging hide file input select files container */
  .file-input .select-files-container {
    opacity: ${dragging ? "0" : "1"};
  }

  .hidden {
    display: none !important;
  }

  .visually-hidden {
    opacity: 0;
  }

  .drop-files {
    width: 100%;
    height: 100%;
    min-height: ${showRules ? "100%" : "7.5rem"};
    position: absolute;
    top: ${showRules ? "50%" : "0%"};
    left: 50%;
    transform: translateX(-50%) translateY(${showRules ? "-50%" : "0%"});
    background-color: ${fileInput.background};
    border: 1px solid ${fileInput.border};
    border-radius: 5px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    .drop-files-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.25em;
      padding: 1rem 4.125rem 1.125rem;
      text-align: center;

      button {
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
      }

      .title {
        color: ${fileInput.titleColor};
      }

      .subtitle {
        color: ${fileInput.disabledColor};
      }
    }
  }

  .children {
    width: 100%;
  }
`;

// Packages or third-party libraries
import React, { FC } from "react";
import { Button, Dropdown, DropdownItem, Tooltip } from "@epignosis_llc/gnosis";
import { VerticalEllipsisSVG } from "@epignosis_llc/gnosis/icons";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

//Styles
import { iltSessionActionsStyles } from "./styles";

// Components
import { ActionGhostButton } from "@components/ReusableComponents";

// Hooks, utils
import { useApplyTranslations } from "@hooks";
import { handleConferenceErrors } from "@errors";

// Other imports
import { getConferenceURL } from "@api/courses";

type IltSessionActionsProps = {
  showJoinBtn: boolean;
  joinUlr: string | null;
  webinarId: string | null;
  dropdownOptions?: DropdownItem[];
  onListItemSelect?: (item: DropdownItem) => void;
};

const IltSessionActions: FC<IltSessionActionsProps> = ({
  showJoinBtn,
  joinUlr,
  webinarId,
  dropdownOptions = [],
  onListItemSelect,
}) => {
  const { t } = useApplyTranslations();
  const joinBtnTooltipContent = !joinUlr ? t("ilt.youCanJoinSessions") : "";
  const hasOptions = dropdownOptions.length > 0;

  const { mutate: joinUrlMutation, isLoading: joinUrlLoading } = useMutation(getConferenceURL, {
    onSuccess: async (res) => {
      window.open(res._data.join_url);
    },
    onError: (error: AxiosError) => {
      handleConferenceErrors(error);
    },
  });

  const handleJoin = (): void => {
    if (webinarId) {
      joinUrlMutation(webinarId);
    }
  };

  if (!showJoinBtn && !hasOptions) return null;

  return (
    <div css={iltSessionActionsStyles}>
      {showJoinBtn && (
        <Tooltip content={joinBtnTooltipContent} disabled={!joinBtnTooltipContent}>
          <Button
            className="join-btn"
            disabled={!joinUlr}
            isLoading={joinUrlLoading}
            onClick={handleJoin}
          >
            {t("general.join")}
          </Button>
        </Tooltip>
      )}

      {hasOptions && (
        <Dropdown list={dropdownOptions} fixPlacement onListItemSelect={onListItemSelect}>
          <ActionGhostButton data-testid="unit-dropdown" className="unit-dropdown-options">
            <VerticalEllipsisSVG height={16} />
          </ActionGhostButton>
        </Dropdown>
      )}
    </div>
  );
};

export default IltSessionActions;

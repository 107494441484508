import { Course } from "./Courses";
import { UserProfile } from "./User";

export type Branch = {
  id: number;
  name: string;
  url: string;
  description?: string;
  active?: boolean;
  policies: BranchPolicies;
};

export type BranchPolicies = {
  can_clone: boolean;
  can_delete: boolean;
  can_update: boolean;
  can_toggle?: boolean;
};

export type BranchCoursePolicies = {
  can_toggle: boolean;
};

export type BranchCourse = Pick<Course, "id" | "name" | "category" | "code" | "status"> & {
  policies: BranchCoursePolicies;
};

type BranchUserPolicies = {
  can_toggle: boolean;
  can_view: boolean;
};

type BranchUserExtras = {
  type: {
    id: number;
    name: string;
  };
  status: "active" | "inactive";
  last_login: string;
  policies: BranchUserPolicies;
};

export type BranchUser = Pick<
  UserProfile,
  "id" | "name" | "email" | "surname" | "avatar" | "login"
> &
  BranchUserExtras;

export type BranchesMassAction = "add" | "remove";

export type BranchByUsers = Pick<Branch, "id" | "name" | "url">;

export enum EcommerceProcessorEnum {
  STRIPE = "stripe",
  PAYPAL = "paypal",
  NONE = "none",
}

export type BranchEcommerce = {
  processor: EcommerceProcessorEnum | "";
  currency: string;
  paypal_email_address: string;
  subscription: BranchEcommerceSubscription;
  are_credits_enabled: boolean;
};

export enum SubscriptionIntervalEnum {
  MONTHLY = "monthly",
  ANNUALLY = "annually",
}

type BranchEcommerceSubscription = {
  enabled: boolean;
  active_subscriptions?: number;
  fee: number | string;
  interval: SubscriptionIntervalEnum;
  is_first_month_free: boolean;
};

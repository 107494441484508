import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { badgeItemContainer } from "./styles";
import { BadgeFigure } from "@components/ReusableComponents";
import { useApplyTranslations } from "@hooks";
import { BadgeItemProps } from "../types";

const BadgeItem: FC<BadgeItemProps> = (props) => {
  const { t } = useApplyTranslations();
  const { image, name, criteria, awarded, type } = props;

  return (
    <div css={badgeItemContainer}>
      <BadgeFigure type={type} image={image} awarded={awarded} criteria={criteria} />

      <Text fontSize="sm" weight="700" className="name">
        {name}
      </Text>

      <Text fontSize="sm" as="div" className="criteria">
        {t(`badges.criteria.${[type]}`, { count: criteria.count })}
      </Text>
    </div>
  );
};

export default BadgeItem;

import React, { FC } from "react";
import { Button, Dropdown, DropdownItem } from "@epignosis_llc/gnosis";
import { IconChevronUpSVG, IconChevronDownSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "@utils/i18n";
import { CalendarEventType, CalendarExportParams } from "types/entities/Calendar";
import { useCalendarExportMutation } from "@hooks/useCalendarExportMutation";

type EventExportDropdownProps = {
  id: string;
  type: CalendarEventType;
  context: "calendar" | "unit";
  isDropdownOpen?: boolean;
  toggleDropdownOpen?: () => void;
  enrolled: boolean;
  onExport?: () => void;
};

const EventExportDropdown: FC<EventExportDropdownProps> = ({
  id,
  type,
  context,
  isDropdownOpen,
  toggleDropdownOpen,
  enrolled,
  onExport,
}) => {
  const dropdownChevron = isDropdownOpen ? IconChevronDownSVG : IconChevronUpSVG;
  const exportOptions: DropdownItem[] = [
    {
      label: t("calendar.google"),
      value: "google",
    },
    {
      label: t("calendar.iCalendar"),
      value: "ical",
    },
    {
      label: t("calendar.outlook"),
      value: "outlookcom",
    },
    {
      label: t("calendar.yahoo"),
      value: "yahoo",
    },
  ];

  const canExport = type !== "ilt" || (type === "ilt" && enrolled);
  const calendarExportMutation = useCalendarExportMutation();

  const handleExportItemSelected = (item: DropdownItem): void => {
    if (!canExport) {
      return;
    }

    if (onExport) {
      onExport();
    }

    calendarExportMutation({
      exportType: item.value as CalendarExportParams["exportType"],
      id,
      type,
    });
  };

  if (!canExport) {
    return null;
  }

  return (
    <div className="dropdownButton">
      <Dropdown
        list={exportOptions}
        onListItemSelect={handleExportItemSelected}
        onToggleList={toggleDropdownOpen}
      >
        <Button
          variant="ghost"
          iconAfter={context === "calendar" ? dropdownChevron : undefined}
          noGutters
        >
          {context === "calendar" ? t("calendar.addEvent") : t("calendar.addToCalendar")}
        </Button>
      </Dropdown>
    </div>
  );
};

export default EventExportDropdown;

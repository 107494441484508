// Packages or third-party libraries
import React, { FC } from "react";
import { Button, Input, InputError } from "@epignosis_llc/gnosis";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";

// Components
import { ConfirmationModal } from "@components";
import { RenameState } from "./TocContent/components/UnitListItem";

// Utils, stores, hooks
import { isSectionItem } from "@views/CourseEdit/helpers";
import { handleUnitErrors } from "@errors";
import { RenameUnitValidationSchema } from "@views/UnitEdit/validations";
import { useApplyTranslations } from "@hooks";
import queryKeys from "@constants/queryKeys";

// Other imports
import { MyUnit, Section } from "types/entities";
import { UnitEditRouterParams } from "@views/UnitEdit/types";
import { UnitActions } from "../types";
import { UpdateUnitData } from "@views/UnitEdit/api";
import { useUpdateUnit } from "@views/UnitEdit";

type UnitRenameModalProps = RenameState & {
  unit: Section | MyUnit;
  onClose: () => void;
};

const UnitRenameModal: FC<UnitRenameModalProps> = ({ unit, onClose, ...renameState }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const { courseId, unitId: unitIdParam } = useParams() as UnitEditRouterParams;

  const { isOpen, type } = renameState;
  const isSection = isSectionItem(unit);

  const { id, name } = unit;
  const unitType = !isSection ? unit.type : undefined;
  const unitId = id.toString();
  const label =
    type === UnitActions.SectionRename ? t("unitEdit.sectionName") : t("unitEdit.unitName");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateUnitData>({
    defaultValues: { name: name ?? "" },
    resolver: yupResolver(RenameUnitValidationSchema),
    mode: "onChange",
  });

  const onSubmitSuccess = (data: UpdateUnitData): void => {
    patchUnitMutation(data);
  };

  const { mutateAsync: patchUnitMutation, isLoading: patchUnitLoading } = useUpdateUnit({
    unitId,
    options: {
      onSettled: () => onClose(),
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.units, courseId]);

        //invalidate unit data when renaming a unit that is not a section or craft unit
        if (unitIdParam === unitId && type === UnitActions.UnitRename && unitType !== "craft") {
          queryClient.invalidateQueries([queryKeys.unit, unitId]);
        }
      },
      onError: (err): void => {
        const error = err as AxiosError;
        handleUnitErrors(error);
      },
    },
  });

  return (
    <ConfirmationModal
      id={unitId}
      isOpen={isOpen}
      header={t("general.rename")}
      bodyTitle={
        <div className="form-item">
          <Input
            {...register("name")}
            id="unit-name"
            label={label}
            status={errors.name ? "error" : "valid"}
            onKeyDown={(e): void => {
              if (e.key === "Enter") {
                handleSubmit(onSubmitSuccess)();
              }
            }}
          />
          {errors.name && <InputError>{errors.name.message}</InputError>}
        </div>
      }
      footerButton={
        <Button
          disabled={!isValid}
          isLoading={patchUnitLoading}
          onClick={handleSubmit(onSubmitSuccess)}
        >
          {t("general.save")}
        </Button>
      }
      onClose={onClose}
    />
  );
};

export default UnitRenameModal;

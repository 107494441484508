import React, { FC, useRef } from "react";
import { iltInfoItemStyles } from "./styles";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { useIsOverflow } from "@hooks";

type IltInfoItemProp = {
  label: string;
  content: string | JSX.Element;
};

const IltInfoItem: FC<IltInfoItemProp> = ({ label, content }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const overflowActive = useIsOverflow(ref);

  return (
    <div css={iltInfoItemStyles}>
      <Text fontSize="xs" weight="700" className="label">
        {label}
      </Text>
      <Tooltip content={content} disabled={!overflowActive}>
        <div className="content" ref={ref}>
          <Text as="div" fontSize="sm">
            {content}
          </Text>
        </div>
      </Tooltip>
    </div>
  );
};

export default IltInfoItem;

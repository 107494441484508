import React, { FC, useEffect, useState } from "react";
import { DropdownItem } from "@epignosis_llc/gnosis";
import { useApplyTranslations } from "@hooks";
import { DropdownButtonWithArrow } from "@components";
import { SmartTag } from "types/entities/Common";

type SmartTagsSelectorProps = {
  smartTags: SmartTag[];
  setFieldValue: (value: string) => void;
  refs: React.RefObject<HTMLTextAreaElement>;
};

const SmartTagsSelector: FC<SmartTagsSelectorProps> = ({ smartTags, setFieldValue, refs }) => {
  const { t } = useApplyTranslations();
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const handleTagSelect = (tag: DropdownItem): void => {
    const input = refs.current;
    if (!input || !tag.value) return;

    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const textBefore = input.value.substring(0, startPos);
    const textAfter = input.value.substring(endPos);
    const newInputValue = `${textBefore}${tag.value}${textAfter}`;

    setFieldValue(newInputValue);
    setCursorPosition(startPos + tag.value.length);
  };

  // Set cursor position after state update
  useEffect(() => {
    const input = refs.current;
    if (cursorPosition !== null && input) {
      input.selectionStart = input.selectionEnd = cursorPosition;
      input.focus();
    }
  }, [refs, cursorPosition]);

  return (
    <DropdownButtonWithArrow
      options={smartTags}
      handleChange={handleTagSelect}
      label={t("general.smartTags")}
    />
  );
};

export default SmartTagsSelector;

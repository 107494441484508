import { yup } from "./validation";

export const NewMessageFormValidationSchema = yup.object().shape({
  recipients: yup.array().min(1),
  subject: yup.string().trim().required().max(150),
  content: yup.string().trim().max(65000),
});

export const MessageReplyFormValidationSchema = yup.object().shape({
  recipients: yup.array().min(1),
  content: yup.string().trim().max(65000),
});

export const MessageForwardFormValidationSchema = yup.object().shape({
  recipients: yup.array().min(1),
  content: yup.string().trim().max(65000),
});

export const CustomReportNewMessageFormValidationSchema = yup.object().shape({
  recipients: yup.array().min(1),
  body: yup.string().trim().required().max(150),
  content: yup.string().trim().max(65000),
});

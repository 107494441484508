// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Text } from "@epignosis_llc/gnosis";
import {
  MyCoursesSVG,
  CertificateWithViewboxSVG,
  ArrowCircleSVG,
  OpenedMessageSVG,
  LinkedinLogoSVG,
} from "@epignosis_llc/gnosis/icons";
import { useNavigate } from "react-router-dom";

// Styles
import { completionOptionsStyles } from "./styles";

// Components
import { SelectCard } from "@components";
import { SendMessageMassActionDrawer } from "@views/Users";

// Utils, hooks
import { downloadFile, getCertificateLinkedInShareUrl } from "@utils/helpers";
import permissions from "@utils/permissions";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";

// Other imports
import { CourseProgress } from "types/entities";
import { URLS } from "@constants/urls";

type CompletionOptionsProps = {
  courseId: number;
  progress: CourseProgress | null;
};

const CompletionOptions: FC<CompletionOptionsProps> = ({ courseId, progress }) => {
  const navigate = useNavigate();
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();

  const [isSendMessageDrawerOpen, setSendMessageDrawerOpen] = useState(false);

  const { show_course_info_page = false, social_share_certification = false } =
    domainSettings ?? {};
  const { certificate, next_course_id, completion_status } = progress ?? {};
  const { canSendMessageToInstructors } = permissions.messagesPermissions;
  const showSendInstructorMessage = canSendMessageToInstructors() && completion_status === "failed";

  const showShareCertificate = !!certificate && social_share_certification;

  const handleCertificateDownload = (certificate: CourseProgress["certificate"]): void => {
    if (certificate) {
      downloadFile({ fileUrl: certificate.url, fileName: certificate.course_name });
    }
  };

  const handleCourseLinkClick = (): void => {
    navigate(URLS.user.courses);
  };

  const handleShareCertificateClick = (): void => {
    if (showShareCertificate) {
      const { course_name, issued_at, expires_at } = certificate;
      window.open(getCertificateLinkedInShareUrl({ course_name, issued_at, expires_at }));
    }
  };

  const handleGoToNextCourseClick = (): void => {
    if (!next_course_id) return;

    const courseId = next_course_id.toString();

    show_course_info_page
      ? navigate(URLS.user.createCourseLink({ courseId }))
      : navigate(URLS.user.createUnitLink({ courseId, unitId: "continue" }));
  };

  return (
    <>
      <Text fontSize="sm">{t("general.whatToDoNext")}</Text>

      <section css={completionOptionsStyles}>
        {certificate && (
          <SelectCard
            className="option-card"
            id="download-certificate"
            icon={<CertificateWithViewboxSVG height={48} />}
            label={t("myCourses.downloadCertificate")}
            onClick={(): void => handleCertificateDownload(certificate)}
          />
        )}

        <SelectCard
          className="option-card"
          id="course-list-link"
          icon={<MyCoursesSVG height={48} />}
          label={t("myCourses.goToCourseList")}
          onClick={handleCourseLinkClick}
        />

        {showShareCertificate && (
          <SelectCard
            className="option-card linkedin-card"
            id="linkedIn-share-certificate"
            icon={<LinkedinLogoSVG height={48} />}
            label={t("certificates.shareOnLinkedIn")}
            onClick={handleShareCertificateClick}
          />
        )}

        {next_course_id && completion_status === "completed" && (
          <SelectCard
            className="option-card"
            id="next-course-link"
            icon={<ArrowCircleSVG height={48} />}
            label={t("myCourses.goToNextCourse")}
            onClick={handleGoToNextCourseClick}
          />
        )}

        {showSendInstructorMessage && (
          <>
            <SelectCard
              className="option-card"
              id="contact-instructor"
              icon={<OpenedMessageSVG height={48} />}
              label={t("myCourses.contactInstructor")}
              onClick={(): void => setSendMessageDrawerOpen(true)}
            />

            {isSendMessageDrawerOpen && (
              <SendMessageMassActionDrawer
                isDrawerOpen={isSendMessageDrawerOpen}
                onClose={(): void => setSendMessageDrawerOpen(false)}
                courseInstructorId={courseId}
                hideAttachments={true}
                headerText={t("myCourses.contactInstructor")}
              />
            )}
          </>
        )}
      </section>
    </>
  );
};

export default CompletionOptions;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const actionsStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 2.5rem;
  position: relative;

  .top-start {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .top-end {
    margin-inline-end: 3rem;
    display: flex;
    gap: 0.5rem;

    ${mq["lg"]} {
      margin-inline-end: 0;
    }
  }
`;

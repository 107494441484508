import React, { FC, useEffect } from "react";
import { useToggle } from "ahooks";
import { useQuery } from "react-query";
import { Grid } from "@epignosis_llc/gnosis";

import { contactSupportContainer } from "../styles";

import { getServices } from "@components/Drawers/HelpCenter/api";
import { Skeletons } from "@components";
import { SettingArrowButton } from "@views/AccountAndSettings/components/SettingCard";
import {
  SupportTicketDrawer,
  AccountManagerDrawer,
  UpsellDrawer,
} from "@components/Drawers/HelpCenter/Drawers";

import { useApplyTranslations } from "@hooks";
import { closeAndHideZendeskWidget, openAndShowZendeskWidget } from "@hooks/useZendeskService";
import { AccountManagerSVG, LiveChatSVG, PhoneSupportSVG } from "@assets/images";

import { getSupportServices } from "../helpers";
import queryKeys from "@constants/queryKeys";
import { BasicSettings, SupportServices } from "../types";
import { useConfigurationStore, useUIStore } from "@stores";

const ContactSupport: FC = () => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const { phone_support: phoneSupportInPlan } = domainSettings?.features_allowed_in_plan ?? {};
  const { showLiveChat, showPhoneSupport, setLiveChatVisible, setPhoneSupportVisible } =
    useUIStore();
  const supportServices = getSupportServices(showLiveChat, showPhoneSupport, phoneSupportInPlan);

  const {
    data: services = {} as SupportServices,
    status,
    error,
  } = useQuery([queryKeys.help.supportServices], () => getServices(), {
    select: (services) => services._data,
  });

  const { user_type, account_manager, phone_support, are_any_phone_agents_available } = services;
  const canAccessAccountManager = user_type?.id === 1 || account_manager;
  const canAccessPhoneSupport =
    phone_support || are_any_phone_agents_available || !phoneSupportInPlan;

  const [isSupportTicketsDrawer, { toggle: toggleSupportTicketDrawer }] = useToggle(false);
  const [isAccountManagerDrawer, { toggle: toggleAccountManagerDrawer }] = useToggle(false);
  const [isUpsellAccountManagerDrawer, { toggle: toggleIsUpsellAccountManagerDrawer }] =
    useToggle(false);
  const [isUpsellLiveChatDrawer, { toggle: toggleIsUpsellLiveChatDrawer }] = useToggle(false);
  const [isUpsellPhoneSupportDrawer, { toggle: toggleIsUpsellPhoneSupportDrawerDrawer }] =
    useToggle(false);

  useEffect(() => {
    if (showLiveChat) {
      openAndShowZendeskWidget();
    } else {
      closeAndHideZendeskWidget();
    }
  }, [showLiveChat]);

  useEffect(() => {
    if (showPhoneSupport) {
      openAndShowZendeskWidget();
    } else {
      closeAndHideZendeskWidget();
    }
  }, [showPhoneSupport]);

  const handleToggleChat = (): void => {
    setLiveChatVisible(true);
    setPhoneSupportVisible(false);

    if (showLiveChat) {
      setLiveChatVisible(false);
    }
  };

  const handleTogglePhoneSupport = (): void => {
    setPhoneSupportVisible(true);
    setLiveChatVisible(false);

    if (showPhoneSupport) {
      setPhoneSupportVisible(false);
    }
  };

  const drawerToggles: { [key in keyof SupportServices]?: () => void } = {
    submit_ticket: toggleSupportTicketDrawer,
    account_manager: toggleAccountManagerDrawer,
    live_chat: handleToggleChat,
    phone_support: handleTogglePhoneSupport,
  };

  const upsellToggles: { [key in keyof SupportServices]?: () => void } = {
    account_manager: toggleIsUpsellAccountManagerDrawer,
    live_chat: toggleIsUpsellLiveChatDrawer,
    phone_support: toggleIsUpsellPhoneSupportDrawerDrawer,
  };

  const handleOnClick = (name: string): void => {
    if (services[name as keyof SupportServices]) {
      drawerToggles[name]?.();
    } else {
      upsellToggles[name]?.();
    }
  };

  // Define the mapping between service names and permissions
  const servicePermissions = {
    submit_ticket: true,
    live_chat: true,
    phone_support: canAccessPhoneSupport,
    account_manager: canAccessAccountManager,
  };

  // Filter the support services based on the permissions and hide the ones that are not allowed
  const getFilteredSupportServices = (): BasicSettings[] => {
    return supportServices.filter((service) => servicePermissions[service.name]);
  };

  const filteredServices = getFilteredSupportServices();

  const skeletonCount = 4;
  const skeletonNumbers = Array.from({ length: skeletonCount }, (_, index) => index + 1);

  return (
    <>
      <div css={contactSupportContainer}>
        <Skeletons.Loader
          status={status}
          error={error}
          loader={
            <div className="skeleton-wrapper">
              {skeletonNumbers.map((n) => (
                <Skeletons.ListItem key={n} />
              ))}
            </div>
          }
        >
          <Grid templateColumns={1} rowGap={1} key="support-services-grid">
            {filteredServices.map(
              ({ name, title, placeholder, description, tooltipText, tooltipEnabled }) => {
                return (
                  <Grid.Item key={title}>
                    <SettingArrowButton
                      key={name}
                      title={title}
                      buttonText={placeholder}
                      description={description}
                      onClick={(): void => handleOnClick(name)}
                      tooltipText={tooltipText}
                      tooltipEnabled={tooltipEnabled}
                      isDisabled={tooltipEnabled}
                    />
                  </Grid.Item>
                );
              },
            )}
          </Grid>
        </Skeletons.Loader>
      </div>

      {isSupportTicketsDrawer && (
        <SupportTicketDrawer
          isOpen={isSupportTicketsDrawer}
          toggleDrawer={toggleSupportTicketDrawer}
        />
      )}

      {isAccountManagerDrawer && (
        <AccountManagerDrawer
          isOpen={isAccountManagerDrawer}
          toggleDrawer={toggleAccountManagerDrawer}
        />
      )}

      {isUpsellAccountManagerDrawer && (
        <UpsellDrawer
          isOpen={isUpsellAccountManagerDrawer}
          toggleDrawer={toggleIsUpsellAccountManagerDrawer}
          headerTitle={t("helpCenter.accountManager")}
          upsellText={t("helpCenter.accountManageUpsell")}
          icon={<AccountManagerSVG />}
        />
      )}

      {isUpsellLiveChatDrawer && (
        <UpsellDrawer
          isOpen={isUpsellLiveChatDrawer}
          toggleDrawer={toggleIsUpsellLiveChatDrawer}
          headerTitle={t("helpCenter.liveChat")}
          upsellText={t("helpCenter.hideChatUpsell")}
          icon={<LiveChatSVG />}
        />
      )}

      {isUpsellPhoneSupportDrawer && (
        <UpsellDrawer
          isOpen={isUpsellPhoneSupportDrawer}
          toggleDrawer={toggleIsUpsellPhoneSupportDrawerDrawer}
          headerTitle={t("helpCenter.phoneSupport")}
          upsellText={t("helpCenter.phoneSupportUpsell")}
          icon={<PhoneSupportSVG />}
        />
      )}
    </>
  );
};

export default ContactSupport;

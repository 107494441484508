import React, { FC, useEffect, useRef } from "react";
import classNames from "classnames";
import { SerializedStyles } from "@emotion/react";
import { EditableTextStyles } from "./styles";

type EditableTextProps = {
  text: string | null;
  placeholder: string;
  variant?: "heading" | "text";
  canEdit?: boolean;
  autoFocus?: boolean;
  fontSize?: string;
  onBlur?: (newContent: string | null) => void;
  onInput?: (newContent: string | null) => void;
};

const EditableText: FC<EditableTextProps> = ({
  text,
  placeholder,
  variant = "heading",
  canEdit = true,
  autoFocus = false,
  fontSize = "4xl",
  onBlur,
  onInput,
}) => {
  const editableDivRef = useRef<HTMLDivElement>(null);
  const editableClassNames = classNames("editable-text", { [variant]: true });

  const handleOnInput = (e: React.ChangeEvent<HTMLDivElement>): void => {
    if (editableDivRef.current) {
      const newContent = e.target.textContent;
      onInput && onInput(newContent);
    }
  };

  const handleOnBlur = (): void => {
    if (editableDivRef.current) {
      onBlur && onBlur(editableDivRef.current.textContent);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // on enter trigger blur callback
    if (["Enter", "NumpadEnter"].includes(event.code)) {
      event.preventDefault();
      onBlur && onBlur(editableDivRef?.current?.textContent ?? null);
    }
  };

  useEffect(() => {
    if (editableDivRef.current && text !== editableDivRef.current.textContent) {
      editableDivRef.current.textContent = text;
    }
  }, [text, canEdit]);

  useEffect(() => {
    if (editableDivRef.current && autoFocus) {
      editableDivRef.current.focus();
      const textNode = editableDivRef.current.firstChild as Text | null;
      const textLength = textNode?.textContent?.length ?? 0;
      const selection = window.getSelection();

      if (textNode && selection) {
        selection.collapse(textNode, textLength);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={editableClassNames}
      css={(theme): SerializedStyles => EditableTextStyles(theme, { fontSize })}
      onClick={(): void => editableDivRef?.current?.focus()}
    >
      {canEdit ? (
        <div
          className="editable-container"
          contentEditable
          suppressContentEditableWarning
          data-text={placeholder}
          ref={editableDivRef}
          onInput={handleOnInput}
          onBlur={handleOnBlur}
          onKeyDown={handleKeyDown}
          data-testid="editable-text"
          spellCheck={false}
        />
      ) : (
        <div className="editable-container not-editing" data-testid="editable-text">
          {text || <div className="placeholder">{placeholder}</div>}
        </div>
      )}
    </div>
  );
};

export default EditableText;

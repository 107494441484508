import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { useApplyTranslations } from "@hooks";

const HoverActions: FC = () => {
  const { t } = useApplyTranslations();
  return (
    <Button block className="footer-btn">
      {t("courseCatalog.viewCourse")}
    </Button>
  );
};

export default HoverActions;

import { css } from "@emotion/react";

export const UnitDeleteModalStyles = css`
  .subject-text {
    margin-bottom: 1.3rem;
  }

  .unit-delete-modal-footer {
    button {
      padding: 1rem;
      margin-inline-end: 0.5rem;
    }
  }
`;

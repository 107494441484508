import React from "react";
import { SerializedStyles } from "@emotion/react";
import { PressedRectangleStyles } from "./styles";

type PressedRectangleProps<T> = {
  content: string;
  isPressed: boolean;
  type: T;
  onClick: (type: T) => void;
};

const PressedRectangle = <T,>({
  content,
  isPressed,
  type,
  onClick,
  ...rest
}: PressedRectangleProps<T>): JSX.Element => {
  return (
    <div
      onClick={(): void => onClick(type)}
      css={(theme): SerializedStyles => PressedRectangleStyles(theme, { isPressed })}
      {...rest}
    >
      {content}
    </div>
  );
};

export default PressedRectangle;

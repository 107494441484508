import React, { FC, useEffect, useRef } from "react";
import classNames from "classnames";
import { headerStyles } from "./styles";
import { useUnitStore } from "@stores";

export type HeaderProps = {
  isStickyDisabled: boolean;
  children?: React.ReactNode;
};

const Header: FC<HeaderProps> = ({ isStickyDisabled, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isCourseHeaderSticky, setIsCourseHeaderSticky } = useUnitStore();

  const handleScroll = (): void => {
    if (ref.current && !isStickyDisabled) {
      setIsCourseHeaderSticky(window.scrollY > ref.current.offsetHeight);
    }
  };

  const headerClassNames = classNames({
    sticky: isCourseHeaderSticky,
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      // reset value on component unmount
      setIsCourseHeaderSticky(false);
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={headerStyles} ref={ref} id="course-header" className={headerClassNames}>
      <div className="header-container">{children}</div>
    </div>
  );
};

export default Header;

import React, { FC, useRef } from "react";
import { Tooltip, Text } from "@epignosis_llc/gnosis";
import { dateWithTimeTooltipContainer } from "./style";
import { formatUtcDate, getDistanceFromNow } from "@utils/helpers";

type TextWeight = "400" | "700";

type DateWithTimeTooltipProps = {
  date?: string | null;
  weight?: TextWeight;
};

const DateWithTimeTooltip: FC<DateWithTimeTooltipProps> = ({ date, weight = 400 }) => {
  const componentRef = useRef<HTMLSpanElement | null>(null);

  if (!date) return <Text fontSize="sm">{"-"}</Text>;

  return (
    <div css={dateWithTimeTooltipContainer}>
      <div className="column-date">
        <Tooltip content={<Text fontSize="sm">{formatUtcDate(date, "datetime")}</Text>}>
          <span ref={componentRef}>
            <Text fontSize="sm" weight={weight as TextWeight}>
              {getDistanceFromNow(date)}
            </Text>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default DateWithTimeTooltip;

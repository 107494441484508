import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// map with all the available permissions
export const coursesPermissions = {
  read: "course.read",
  create: "course.create",
  update: "course.update",
  delete: "course.delete",
  unenroll: "course.unenroll",
  files_shared_read: "course.files.shared.read",
  file_update: "course.file.update",
  file_upload: "course.file.upload",
  unit_read: "course.unit.read",
  unit_create: "course.unit.create",
  unit_complete: "course.unit.complete",
  unit_update: "course.unit.update",
  users_read: "course.user.read",
  report_courses: "reports.course",
  union_read: "union.course.read",
  course_user_reset: "course.user.reset",
};

const {
  read,
  create,
  update,
  files_shared_read,
  file_upload,
  unit_read,
  unit_complete,
  unit_update,
  unenroll,
  report_courses,
  users_read,
  union_read,
  course_user_reset,
} = coursesPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => course.read
   * course.read is required in order to have all the other course related permissions
   *
   *  Allowed actions:
   *  GET courses.myCourses
   *
   * @returns boolean
   */
  canAccessCourses: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => course.create
   *
   *  Allowed actions:
   *  POST courses.create
   *
   * @returns boolean
   */
  canCreateCourse: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => course.update
   *
   *  Allowed actions:
   *  PUT courses.courseEdit
   *
   * @returns boolean
   */
  canUpdateCourse: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => course.files.shared.read
   *
   *  Allowed actions:
   *  GET courses.courseFiles
   *
   * @returns boolean
   */
  canAccessCourseSharedFiles: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions(
      [read, files_shared_read],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => course.file.upload
   *
   *  Allowed actions:
   *  POST courses.postFile
   *
   * @returns boolean
   */
  canUploadFiles: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions(
      [file_upload],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => course.unit.read
   *
   *  Allowed actions:
   *  GET myCourseUnits, unit, testQuestion, submitTestQuestion, unitResults, unitComplete,
   *  unitEnter, unitExit, unitStart, unitPassword, unitSnapshot, resetTest,
   *  unitAssignment.submit, unitAssignment.fileUpload, /v1/units/{id}/commit (SCORM)
   *
   * @returns boolean
   */
  canAccessCourseUnits: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions(
      [read, unit_read],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => course.unit.complete
   *
   *  Allowed actions:
   *  GET unitComplete
   *
   *  In Talent seems to be applied only in units (hide completion btn)
   *  Test and SCORM units seems to not be affected
   *
   * @returns boolean
   */
  canCompleteCourseUnits: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions(
      [read, unit_complete],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => course.unit.update
   *
   *  Allowed actions:
   *  GET getUnitSmartTags
   *
   * @returns boolean
   */
  canUpdateCourseUnits: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions(
      [unit_update],
      getState().userProfileData?.permissions[userRole],
    );
  },
  /**
   * Permission => course.unenroll
   *
   *  Allowed actions:
   *  PUT putUnenrollFromCourse
   *
   *
   * @returns boolean
   */
  canUnenrollFromCourse: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [unenroll],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => reports.course
   *
   *  Allowed actions:
   *  GET getReportCourses
   *
   *
   * @returns boolean
   */
  canReportsCourse: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [report_courses],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => course.user.read
   *
   *  Allowed actions:
   *  GET getCourseUsers
   *
   *
   * @returns boolean
   */
  // TODO: remove not used
  canAccessCourseUsers: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [users_read],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => union.course.read
   * union.course.read is a combination of admin.course.read and instructor.course.read
   *
   *  Allowed actions:
   *  GET getCourses
   *
   *
   * @returns boolean
   */
  canAccessUnionCourses: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [union_read],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => course.read && course.update
   *
   *  Allowed actions:
   *  POST massActions, POST massActionsCount
   *
   * @returns boolean
   */
  canDoMassActions: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [read, update],
      getState().userProfileData?.permissions[userRole],
    );
  },

  canResetCourseUser: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [course_user_reset],
      getState().userProfileData?.permissions[userRole],
    );
  },
};

import { PalletThemeColors, getProtectedColor, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["editor"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    background: colors.secondary.lighter,
    borderColor: colors.secondary.lighter,
    disabledColor: colors.secondary.base,
    errorColor: colors.red.base,
    focusColor: protectColorContrast("darker", colors.primary.base),
    hoverColor: hexToRGBA(colors.primary.lightest, 0.25),
    moreBackground: colors.white,
    placeholderColor: colors.secondary.base,
  };
};

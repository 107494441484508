import React, { FC, Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { LazyMotion, domAnimation, domMax } from "framer-motion";
import classNames from "classnames";
import { Loader, Drawer } from "@epignosis_llc/gnosis";

import { MainNav } from "@components";
import { MainHeader, GamificationDrawer, MainDrawer } from "@layouts/DashboardLayout/components";
import { main, content } from "@layouts/DashboardLayout/styles";
import { Announcements, ScreenSizeAlert } from "@components/ReusableComponents";
import { URLS, rootURLS } from "@constants/urls";
import { useUIStore } from "@stores";
import { isValidPathname } from "@utils/helpers/url";
import { useScreenSizeAlert } from "@hooks";

type DashboardLayoutProps = {
  noMainContentPadding?: boolean;
};

const DashboardLayout: FC<DashboardLayoutProps> = ({ noMainContentPadding = false }) => {
  const contentContainerClassNames = classNames("main-inner-container", {
    "no-padding": noMainContentPadding,
  });
  const dashboardClassNames = (isDashboard?: boolean): string =>
    classNames("main-page-container", {
      "widgets-dashboard": isDashboard,
    });

  const location = useLocation();
  const pathname = location.pathname;

  const isDashboard = pathname.includes(URLS.dashboard);
  const shouldShowScreenSizeAlert = useScreenSizeAlert(["administrator", "instructor"]);

  const { setActiveTab } = useUIStore();
  const findPath = rootURLS.find((url) => pathname.includes(url));
  const isValid = isValidPathname(pathname, rootURLS);

  useEffect(() => {
    isValid ? setActiveTab(pathname) : findPath ? setActiveTab(findPath) : "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div css={main}>
      <MainHeader />
      <div className={dashboardClassNames(isDashboard)} role="main">
        <LazyMotion features={domMax} strict>
          <MainNav />
        </LazyMotion>
        <LazyMotion features={domAnimation} strict>
          <Drawer.Root />
          <MainDrawer />
          <GamificationDrawer />

          <Suspense fallback={<Loader fullScreen />}>
            <div id="scroll-container" css={content} className={contentContainerClassNames}>
              <Announcements />
              {shouldShowScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
            </div>
          </Suspense>
        </LazyMotion>
      </div>
    </div>
  );
};

export default DashboardLayout;

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Custom hook to scroll to an element identified by the 'scrollToId' URL parameter
 * and remove the parameter from the URL after scrolling.
 *
 * Usage:
 * Call useScrollToId() inside a functional component where you want this behavior.
 */
const useScrollToId = (): void => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollToId = params.get("scrollToId");

    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }

      // Remove scrollToId param from the URL
      params.delete("scrollToId");
      navigate({ search: params.toString() });
    }
  }, [location, navigate]);
};

export default useScrollToId;

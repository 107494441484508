import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const templatePreviewStyles = ({ courseEdit: { certificatePreview } }) => css`
  background: ${certificatePreview.backgroundColor};
  border: 1px solid ${certificatePreview.borderColor};
  border-radius: 5px;
  padding: 0.75rem;
  position: relative;

  ${mq["lg"]} {
    padding: 0.75rem 4rem;
  }

  .pdf-container {
    width: 100%;
    height: 100%;
    min-height: 260px;

    ${mq["lg"]} {
      min-height: 355px;
    }

    .rpv-core__viewer {
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }

  button {
    position: absolute;
    top: 0.75rem;
    inset-inline-end: 0.75rem;
  }
`;

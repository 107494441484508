import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import classNames from "classnames";
import { useConfigurationStore } from "@stores";
import { Course, Bundle } from "types/entities";
import { useApplyTranslations } from "@hooks";

export type AvailableCreditsProps = {
  data: Course | Bundle;
};

const creditsClassNames = (hasEnoughCredits: boolean): string =>
  classNames({
    "credits-num": true,
    "not-enough-credits": !hasEnoughCredits,
  });

const AvailableCredits: FC<AvailableCreditsProps> = ({ data }) => {
  const { t } = useApplyTranslations();
  const { userProfileData } = useConfigurationStore();
  const { price, discounted_price } = data;
  const { credits = 0 } = userProfileData ?? {};
  const hasEnoughCredits = discounted_price
    ? discounted_price.amount <= credits
    : (price?.amount as number) <= credits;

  const translationText =
    credits === 0 ? "general.creditsAvailable" : "general.creditsAvailableStrong";

  return (
    <Text
      fontSize="xs"
      as="div"
      className={creditsClassNames(hasEnoughCredits)}
      dangerouslySetInnerHTML={{
        __html: t(translationText, { count: userProfileData?.credits ?? 0 }),
      }}
    />
  );
};

export default AvailableCredits;

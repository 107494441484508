// Packages or third-party libraries
import React, { FC, memo } from "react";

// Styles
import { listItemActionsWrapperStyles } from "./styles";

// Components
import { StatusTag } from "@components/ReusableComponents";
import { TagColor } from "@components/ReusableComponents/StatusTag/StatusTag";
import LinkedUnitTag from "../../LinkedUnitTag";

// Other imports
import { useApplyTranslations } from "@hooks";
import { MyUnit } from "types/entities";
import { AssignmentSubmissionSVG } from "@epignosis_llc/gnosis/icons";

type UnitListTitleProps = {
  linkedUnits: number;
  sourceUnit?: MyUnit["source_unit"];
  showDraftTag: boolean;
  showUnpublishedTag: boolean;
  pendingSubmissions?: number;
  pendingUsers?: number;
  onPendingSubmissionsClick: () => void;
  toggleLinkedUnitsDrawer: () => void;
  onPendingUsersClick: () => void;
};

const UnitListTitleActions: FC<UnitListTitleProps> = ({
  linkedUnits,
  sourceUnit,
  showDraftTag,
  showUnpublishedTag,
  pendingSubmissions = 0,
  pendingUsers = 0,
  onPendingSubmissionsClick,
  toggleLinkedUnitsDrawer,
  onPendingUsersClick,
}) => {
  const { t } = useApplyTranslations();
  const isParentLinkedUnit = !sourceUnit && linkedUnits > 0;
  const isChildrenLinkedUnit = Boolean(sourceUnit);

  if (
    !isParentLinkedUnit &&
    !isChildrenLinkedUnit &&
    !showDraftTag &&
    !showUnpublishedTag &&
    !pendingUsers &&
    !pendingSubmissions
  ) {
    return null;
  }

  return (
    <div css={listItemActionsWrapperStyles}>
      <LinkedUnitTag
        linkedUnits={linkedUnits}
        sourceUnit={sourceUnit}
        toggleLinkedUnitsDrawer={toggleLinkedUnitsDrawer}
      />
      {showDraftTag && <StatusTag text={t("general.draft")} />}
      {showUnpublishedTag && (
        <StatusTag color={TagColor.Unpublished} text={t("unitEdit.unpublishedChanges")} />
      )}
      {pendingSubmissions > 0 && (
        <StatusTag
          color={TagColor.Pending}
          text={pendingSubmissions?.toString()}
          icon={AssignmentSubmissionSVG}
          onClick={onPendingSubmissionsClick}
        />
      )}

      {pendingUsers > 0 && (
        <StatusTag
          color={TagColor.Pending}
          text={pendingUsers.toString()}
          icon={AssignmentSubmissionSVG}
          onClick={onPendingUsersClick}
        />
      )}
    </div>
  );
};

export default memo(UnitListTitleActions);

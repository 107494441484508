import HttpClient from "./HttpClient";
import {
  IltSessionsRes,
  PrintAttendanceSheetRes,
  SingleIltSessionRes,
  TableCSVExportRes,
  UnenrolledUserRes,
} from "types/responses";
import { ENDPOINTS } from "./endpoints";

export const getIltSessions = async (queryStr = ""): Promise<IltSessionsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.ilts.sessions}${queryStr}`);

  return res.data;
};

export const getIltSession = async (
  unitId: string,
  userId: string,
): Promise<SingleIltSessionRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.ilts.getSingle(unitId, userId)}`);

  return res.data;
};

export const getUnenrolledUsers = async (
  courseId: string,
  queryStr = "",
): Promise<UnenrolledUserRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.ilts.getUnenrolledUsers(courseId)}${queryStr}`);

  return res.data;
};

export const getIltSessionsUsers = async (
  sessionId: string,
  queryStr = "",
  isRoot = false,
): Promise<IltSessionsRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.ilts.sessionsUsers(sessionId)}${queryStr}&is_root=${isRoot}`,
  );

  return res.data;
};

export const postAttendanceSheet = async (
  sessionId: string,
  columns: string[],
  isRoot: boolean,
): Promise<PrintAttendanceSheetRes> => {
  const body = {
    columns,
    is_root: isRoot,
  };

  const res = await HttpClient.post(`${ENDPOINTS.ilts.printAttendance(sessionId)}`, body);

  return res.data;
};

export const iltSessionUsersExport = async (
  queryStr = "",
  sessionId: string,
  isRoot = false,
): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(
    `${ENDPOINTS.tableExports.sessionUsersExport(sessionId)}${queryStr}&is_root=${isRoot}`,
  );

  return res.data;
};

import { Button, Heading, Tooltip } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { tableHeaderContainer } from "./styles";
import { convertToDataTestIdName } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

type TableHeaderProps = {
  title: string;
  backLink: string;
  secondaryActions?: JSX.Element;
  hideBackButton?: boolean;
  tooltipIcon?: {
    icon: JSX.Element;
    tooltipText: string;
  };
  className?: string;
};

const TableHeader: FC<TableHeaderProps> = (props) => {
  const {
    title,
    backLink,
    secondaryActions,
    tooltipIcon,
    hideBackButton = false,
    className,
  } = props;
  const { t } = useApplyTranslations();

  return (
    <div css={tableHeaderContainer} className={className ?? ""}>
      <div className="title-wrapper" data-testid={`${convertToDataTestIdName(title)}-title`}>
        {!hideBackButton && (
          <Button
            color="secondary"
            variant="link"
            as={Link}
            to={backLink}
            aria-label={t("general.back")}
            noGutters
          >
            <ArrowLeftSVG height={28} />
          </Button>
        )}
        <Heading as="h1" size="2xl">
          {title}
        </Heading>
        {tooltipIcon && <Tooltip content={tooltipIcon.tooltipText}>{tooltipIcon.icon}</Tooltip>}
      </div>

      {secondaryActions && <div className="secondary-actions-wrapper">{secondaryActions}</div>}
    </div>
  );
};

export default TableHeader;

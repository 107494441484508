import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";
import React, { FC } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { link } from "./styles";

export type LabelColor = "primary" | "secondary" | "defaultLink";

export type LinkProps = RouterLinkProps & {
  color?: LabelColor;
  readonly?: boolean;
};

const linkContainer = (readonly: boolean): string =>
  classNames({
    readonly: Boolean(readonly),
  });

const Link: FC<LinkProps> = ({ color = "primary", readonly = false, children, ...rest }) => {
  return (
    <RouterLink
      css={(theme): SerializedStyles => link(theme, { color })}
      className={linkContainer(readonly)}
      {...rest}
    >
      {children}
    </RouterLink>
  );
};

export default Link;

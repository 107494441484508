import { css } from "@emotion/react";

export const CardHeader = (primaryColor) => css`
  width: 100%;
  height: 100%;
  background: ${primaryColor};

  .hover-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .action-icon {
    svg {
      height: 14px;
    }
  }
`;

export const CardBody = ({ themeCard }, primaryColor) => css`
  word-break: break-all;

  section {
    padding: 1rem;

    .active-chip {
      background-color: ${primaryColor} !important;
      color: ${themeCard.color};
      margin-inline-end: 0.5rem;
      height: auto;
      font-size: 0.79rem;
    }
  }
`;

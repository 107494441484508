import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";

export const actionButtonContainer = ({ courseCard }) => css`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;

  button {
    height: 2.1rem;
    width: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${courseCard.cardHeader.infoBtn.background};
    border: 0;
    border-radius: 50%;

    svg {
      color: ${courseCard.cardHeader.infoBtn.color};
    }
  }

  .label {
    font-size: ${typeScale["2xs"]};
    background-color: ${courseCard.cardHeader.infoBtn.labelBackground};
    color: ${courseCard.cardHeader.infoBtn.labelColor};
    padding: 0.5rem;
    margin-inline-end: 0.5rem;
    border-radius: 5px;
  }
`;

export const labelOnHoverWrapper = () => css`
  height: 100%;
  width: 100%;
  position: relative;

  .container {
    position: relative;
    top: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  #label {
    font-size: ${typeScale["sm"]};
    white-space: nowrap;
  }
`;

export const cardActionsContainer = (dir) => css`
  position: absolute;
  top: 1.125rem;
  right: ${dir === "rtl" ? "auto" : "1rem"};
  left: ${dir === "rtl" ? "1rem" : "auto"};

  .action {
    margin-bottom: 0.75rem;

    &:last-of-type {
      margin: 0;
    }
  }
`;

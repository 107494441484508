import React, { FC, memo } from "react";
import { Checkbox } from "@epignosis_llc/gnosis";
import { DraggableProvided } from "@hello-pangea/dnd";
import { unitIconWrapperStyles } from "./styles";

type UnitListIconProps = {
  provided: DraggableProvided;
  unitId: string;
  name: string;
  isSelectedSectionUnit: boolean;
  isSectionReorderUnit: boolean;
  unitIcon: JSX.Element | null;
  onClick: () => void;
};

const UnitListIcon: FC<UnitListIconProps> = ({
  provided,
  unitId,
  name,
  isSelectedSectionUnit,
  isSectionReorderUnit,
  unitIcon,
  onClick,
}) => (
  <div css={unitIconWrapperStyles} {...provided.dragHandleProps}>
    {isSelectedSectionUnit ? (
      <Checkbox
        id={unitId}
        name={name ?? ""}
        value={unitId}
        defaultChecked={isSectionReorderUnit}
        containerAttrs={{ className: "unit-reorder-checkbox" }}
        onClick={onClick}
      />
    ) : (
      unitIcon
    )}
  </div>
);

export default memo(UnitListIcon);

import { css } from "@emotion/react";

export const pageContainer = ({ signInPage }) => css`
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  background-color: ${signInPage.background};

  .content-wrapper {
    max-width: 500px;
    margin: auto;

    .form-wrapper {
      background: transparent;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      margin: 1rem;
      border-radius: 30px;
      box-shadow: 0px 0px 20px ${signInPage.boxShadow};
    }

    .announcements-wrapper {
      padding: 1rem 1rem 3rem;
    }
    .alert-container {
      margin: 1rem;
    }

    .lock-alert {
      svg {
        color: #fff;
        height: 32px;
      }
    }
  }
`;

export const loginFormContainer = ({ signInPage }) => css`
  width: 100%;
  max-width: 500px;
  background: ${signInPage.formBackground};
  margin: auto;

  .form-container {
    .container {
      margin-bottom: 1.5rem;

      .line {
        margin: 0 2.5rem;
      }
    }
  }

  .login-links-container {
    padding: 0 2rem 2rem;
  }

  .forgot-pswd {
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .sso-container,
  .login-type-container {
    text-align: center;

    .sign-in-type {
      padding: 0 0 0 1rem;
      width: 100%;
      margin-bottom: 0.5rem;

      span {
        width: 50%;
      }
    }
  }
`;

import { css } from "@emotion/react";

export const passwordTooltipStyles = css`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  .password-content {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

export const passwordIconStyles = ({ icon }, { buttonColor }) => css`
  display: flex;

  svg {
    color: ${icon[buttonColor]};
  }
`;

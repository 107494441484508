import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useConfigurationStore, useUIStore, useUserPreferencesStore } from "@stores";
import authService from "@utils/services/AuthService";
import { logoutZendesk } from "@hooks/useZendeskService";
import { URLS } from "@constants/urls";
import localStorageKeys from "@constants/localStorageKeys";
import { parseAccessToken } from "@utils/helpers";

type UseLogout = {
  handleLogOut: () => void;
};

const useLogout = (): UseLogout => {
  const { setUserProfile } = useConfigurationStore();
  const { hideMainDrawer, setLiveChatVisible } = useUIStore((state) => state);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const useUserStore = useUserPreferencesStore();
  const { resetSettings } = useUserStore() ?? {};

  const handleLogOut = (): void => {
    const token = authService.getAccessToken();
    const decodeToken = token ? parseAccessToken(token) : null;
    const isSSOLogin = decodeToken?.sub.is_sso_login;

    hideMainDrawer();
    document.body.style.setProperty("overflow", "");
    queryClient.clear();
    authService.removeTokens();
    authService.removeRole();
    resetSettings();
    setUserProfile(null); // On logout reset the users profile data
    setLiveChatVisible(false); // Hide live chat on logout
    logoutZendesk(); // Logout from zendesk

    const ssoLogoutUrl = localStorage.getItem(localStorageKeys.SSO_LOGOUT_URL);

    // If user used sso to login
    if (ssoLogoutUrl && isSSOLogin) {
      window.location.replace(ssoLogoutUrl);
    } else {
      navigate(URLS.root);
    }
  };

  return { handleLogOut };
};

export default useLogout;

import { URLS } from "@constants/urls";

type CourseLinkProps = {
  courseId: string;
  isPublic: boolean;
  isAvailableInCatalog: boolean;
};

type CompletionLinkProps = Pick<CourseLinkProps, "courseId" | "isPublic">;

type UnitLinkProps = {
  courseId: string;
  unitId: string;
  isPublic: boolean;
  isPreview?: boolean;
};

export const getCatalogCourseOverviewLink = ({
  courseId,
  isPublic,
  isAvailableInCatalog,
}: CourseLinkProps): string => {
  if (!isPublic) return URLS.catalog.createCourseLink({ courseId });
  if (isAvailableInCatalog) return URLS.externalCatalog.createCourseLink({ courseId });

  return URLS.publicCourse.getPublicCourseLink({ courseId });
};

export const getCourseOverviewLink = ({
  courseId,
  isAvailableInCatalog,
  isPublic,
}: CourseLinkProps): string => {
  if (!isPublic) return URLS.user.createCourseLink({ courseId });
  if (isAvailableInCatalog) return URLS.externalCatalog.createCourseLink({ courseId });

  return URLS.publicCourse.getPublicCourseLink({ courseId });
};

export const getCourseCompletionLink = ({ courseId, isPublic }: CompletionLinkProps): string => {
  return isPublic
    ? URLS.publicCourse.createCourseResultsLink({ courseId })
    : URLS.user.createCourseResultsLink({ courseId });
};

/* Course unit helpers  */

export const getUnitLink = ({ courseId, unitId, isPublic, isPreview }: UnitLinkProps): string => {
  if (isPreview) {
    return URLS.user.createPreviewUnitLink({ courseId, unitId });
  }

  return isPublic
    ? URLS.publicCourse.createPublicUnitLink({ courseId, unitId })
    : URLS.user.createUnitLink({ courseId, unitId });
};

export const getSampleUnitLink = ({ courseId, unitId, isPublic }: UnitLinkProps): string => {
  return isPublic
    ? URLS.publicCourse.createSampleUnitLink({ courseId, unitId })
    : URLS.catalog.createSampleUnitLink({ courseId, unitId });
};

import React, { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LazyMotion, domAnimation } from "framer-motion";
import { Loader } from "@epignosis_llc/gnosis";

const BasicLayout: FC = () => (
  <LazyMotion features={domAnimation} strict>
    <Suspense fallback={<Loader fullScreen />}>
      <Outlet />
    </Suspense>
  </LazyMotion>
);

export default BasicLayout;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { useEffect } from "react";
import { useConfigurationStore } from "@stores";
import { useAuth } from "@hooks/index";
import { useZendeskConfigData } from "@hooks/useZendeskConfigData";
import { ZendeskTokenRes } from "types/responses";

// extend window interface
declare const window: {
  zE: any;
  zESettings: any;
} & Window;

const ZENDESK_SCRIPT_ID = "ze-snippet";
const ZENDESK_SCRIPT_URL = "https://static.zdassets.com/ekr/snippet.js?key=";

const canUseDOM = (): boolean => {
  return (
    typeof window !== "undefined" &&
    typeof window.document !== "undefined" &&
    typeof window.document.createElement !== "undefined"
  );
};

export const ZendeskAPI = (...args: any[]): void => {
  if (canUseDOM() && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn("Zendesk is not initialized yet");
  }
};

export const logoutZendesk = (): void => {
  ZendeskAPI("webWidget", "logout");
  closeAndHideZendeskWidget();

  // Remove Zendesk script
  const script = document.getElementById(ZENDESK_SCRIPT_ID);
  if (script) {
    script.remove();
  }
};

export const closeAndHideZendeskWidget = (): void => {
  ZendeskAPI("webWidget", "close");
  ZendeskAPI("webWidget", "hide");
};

export const openAndShowZendeskWidget = (): void => {
  ZendeskAPI("webWidget", "open");
  ZendeskAPI("webWidget", "show");
};

type ZendeskServiceProps = {
  zendeskKey: string;
  shouldInitializeZendesk?: boolean;
  defer?: boolean;
  onLoaded?: () => void;
  showLiveChat?: boolean;
  showPhoneSupport?: boolean;
  [key: string]: any; // for other possible settings
};

const useZendeskService = ({
  zendeskKey,
  shouldInitializeZendesk,
  defer,
  onLoaded,
  showLiveChat,
  showPhoneSupport,
}: ZendeskServiceProps): void => {
  const { isAuthenticated } = useAuth();
  const { domainSettings } = useConfigurationStore();
  const { chat_support: liveChatInPlan, phone_support: phoneSupportInPlan } =
    domainSettings?.features_allowed_in_plan ?? {};
  const { zendeskConfigDataMutation } = useZendeskConfigData();

  const zendeskSettings = {
    webWidget: {
      authenticate: {
        chat: {
          jwtFn: async (jwtFnCallback: (jwt: string) => void): Promise<void> => {
            if (!isAuthenticated && (!liveChatInPlan || !phoneSupportInPlan)) return;
            zendeskConfigDataMutation("chat", {
              onSuccess: (data: ZendeskTokenRes) => {
                jwtFnCallback(data._data.jwt);
              },
            });
          },
        },
      },
      chat: {
        suppress: false,
      },
      talk: {
        nickname: "TalkWidget",
        suppress: false,
        title: {
          "*": "Talk with us!",
        },
      },
    },
  };

  if (showLiveChat && liveChatInPlan) {
    ZendeskAPI("webWidget", "updateSettings", {
      chat: {
        suppress: !showLiveChat,
      },
      talk: {
        suppress: true,
      },
    });
  } else if (showPhoneSupport && phoneSupportInPlan) {
    ZendeskAPI("webWidget", "updateSettings", {
      chat: {
        suppress: true,
      },
      talk: {
        suppress: !showPhoneSupport,
      },
    });
  }

  useEffect(() => {
    if (isAuthenticated && liveChatInPlan) {
      ZendeskAPI("webWidget", "chat:reauthenticate");
    } else {
      ZendeskAPI("webWidget", "logout");
    }
  }, [isAuthenticated, liveChatInPlan]);

  useEffect(() => {
    const onScriptLoaded = (): void => {
      if (typeof onLoaded === "function") {
        onLoaded();
      }
    };

    const insertScript = (zendeskKey: string, defer?: boolean): void => {
      if (!document.getElementById(ZENDESK_SCRIPT_ID)) {
        const script = document.createElement("script");

        script.id = ZENDESK_SCRIPT_ID;
        script.src = `${ZENDESK_SCRIPT_URL}${zendeskKey}`;
        script.defer = !!defer;
        script.async = !defer;

        script.addEventListener("load", () => {
          window.zESettings = zendeskSettings;
          onScriptLoaded();
        });
        document.body.appendChild(script);
      } else {
        if (window.zE) {
          window.zESettings = { ...zendeskSettings };
        } else {
          console.warn("Zendesk script already loaded but zE is not available.");
        }
      }
    };

    if (canUseDOM() && shouldInitializeZendesk) {
      insertScript(zendeskKey, defer);
    }

    return () => {
      const script = document.getElementById(ZENDESK_SCRIPT_ID);
      if (script) {
        script.removeEventListener("load", onScriptLoaded);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zendeskKey, defer, onLoaded, showLiveChat, showPhoneSupport]);
};

export default useZendeskService;

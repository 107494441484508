import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import { ArticlesRes, createTicketPostData, SupportServicesRes, TicketRes } from "./types";
import { FileRes } from "types/responses";
import { marketoQueryParam } from "@constants/queryParameters";

const formContentType = {
  "Content-Type": "multipart/form-data",
};

export const getArticles = async (queryStr = ""): Promise<ArticlesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.help.articles}${queryStr}`);

  return res.data;
};

export const getServices = async (): Promise<SupportServicesRes> => {
  const res = await HttpClient.get(ENDPOINTS.help.supportServices);

  return res.data;
};

export const createTicket = async (
  ticket: createTicketPostData,
  shouldTrackMarketo = false,
): Promise<TicketRes> => {
  const url = shouldTrackMarketo
    ? `${ENDPOINTS.help.ticket.create}?${marketoQueryParam}`
    : ENDPOINTS.help.ticket.create;

  const res = await HttpClient.post(url, ticket);
  return res.data;
};

export const postTicketAttachmentFile = async (file: File): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const res = await HttpClient.post(ENDPOINTS.help.ticket.attachment, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const createAccountManager = async (ticket: createTicketPostData): Promise<TicketRes> => {
  const res = await HttpClient.post(ENDPOINTS.help.accountManager.create, ticket);
  return res.data;
};

export const postAccountManagerAttachmentFile = async (file: File): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const res = await HttpClient.post(ENDPOINTS.help.accountManager.attachment, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

import React, { FC } from "react";
import { Tag, Text } from "@epignosis_llc/gnosis";
import { MultiSessionSVG } from "@epignosis_llc/gnosis/icons";
import { SerializedStyles } from "@emotion/react";
import { iltTitleStyles } from "./styles";
import { hasDatePassed } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

type IltTitleProps = {
  name: string;
  showMultiSession: boolean;
  isLive: boolean;
  endDatetime: string;
};

export const IltTitle: FC<IltTitleProps> = ({ name, showMultiSession, isLive, endDatetime }) => {
  const { t } = useApplyTranslations();
  const isPast = !showMultiSession && hasDatePassed(endDatetime);

  return (
    <div css={(theme): SerializedStyles => iltTitleStyles(theme, { isPast })}>
      {showMultiSession && <MultiSessionSVG className="icon" height={32} />}
      <Text fontSize="md" weight="700" className="text">
        {name}
      </Text>
      {isLive && (
        <Tag className="has-started">
          <span className="has-started-icon" />
          <span className="has-started-text">{t("general.live").toUpperCase()}</span>
        </Tag>
      )}
    </div>
  );
};

export default IltTitle;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from "react";
import {
  MyProfileSVG,
  MyFilesSVG,
  MyGroupsSVG,
  MyPaymentsSVG,
  MyProgressSVG,
  OpenedMessageSVG,
  MyBadgesSVG,
  ChatCloudsSVG,
  LeavePortalSVG,
  LogoutSVG,
  BranchesDropdownSVG,
  UserExitSVG,
  AdminIconSVG,
  InstructorSVG,
  LearnerIconSVG,
  ReferralIconSVG,
  CirclePlaySVG,
} from "@epignosis_llc/gnosis/icons";
import { URLS } from "@constants/urls";
import { WATCH_DEMO_URL } from "../WatchDemoButton/constants";

const userRoleIcon = {
  administrator: <AdminIconSVG height={32} />,
  instructor: <InstructorSVG height={32} />,
  learner: <LearnerIconSVG height={32} />,
};

export type UserPagesType = {
  key: string;
  title: string;
  url?: string;
  icon: ReactElement;
};

export const userPages = [
  {
    key: "myProfile",
    title: "myProfile.myProfile",
    url: URLS.user.profile,
    icon: <MyProfileSVG height={32} />,
  },
  {
    key: "myBadges",
    title: "myProfile.myBadges",
    url: URLS.user.badges,
    icon: <MyBadgesSVG height={32} />,
  },
  {
    key: "myProgress",
    title: "myProfile.myProgress",
    url: URLS.user.progress,
    icon: <MyProgressSVG height={32} />,
  },
  {
    key: "myGroups",
    title: "myProfile.myGroups",
    url: URLS.user.groups,
    icon: <MyGroupsSVG height={32} />,
  },
  {
    key: "myFiles",
    title: "myProfile.myFiles",
    url: URLS.user.files,
    icon: <MyFilesSVG height={32} />,
  },
  {
    key: "myPayments",
    title: "myProfile.myPayments",
    url: URLS.user.payments,
    icon: <MyPaymentsSVG height={32} />,
  },
];

// Messages and Discussions should be shown on the menu (mobile version only).
export const messagesAndDiscussions = [
  {
    key: "myMessages",
    title: "mainMenu.messages",
    url: URLS.user.messages,
    icon: <OpenedMessageSVG height={32} />,
  },
  {
    key: "myDiscussions",
    title: "mainMenu.discussions",
    url: URLS.user.discussions,
    icon: <ChatCloudsSVG height={32} />,
  },
];

// Branches menu (mobile version only).
export const branches = [
  {
    key: "myBranches",
    title: "mainMenu.switchBranch",
    icon: <BranchesDropdownSVG height={32} />,
  },
];

// Legacy UI
export const returnToLegacy = [
  {
    key: "legacy",
    title: "mainMenu.legacyUI",
    icon: <LeavePortalSVG height={32} />,
  },
];

//Consensus
export const watchDemo = [
  {
    key: "watchDemo",
    title: "consensus.watchDemo",
    url: WATCH_DEMO_URL,
    icon: <CirclePlaySVG height={32} />,
  },
];

// Referral
export const referral = [
  {
    key: "referral",
    title: "referral.referral",
    url: URLS.referral.referral,
    icon: <ReferralIconSVG height={32} />,
  },
];

// Logout button
export const logout = [
  {
    key: "logout",
    title: "general.logOut",
    icon: <LogoutSVG height="32" />,
  },
];

// Impersonation button
export const impersonation = [
  {
    key: "impersonation",
    title: "general.backToAdmin",
    icon: <UserExitSVG height={32} />,
  },
];

// User Role
export const userRole = (role: string): UserPagesType[] => [
  {
    key: "userRole",
    title: `general.${role}`,
    icon: userRoleIcon[role],
  },
];

import { Loader } from "@epignosis_llc/gnosis";
import React, { FC } from "react";
import { uploadLoaderStyles } from "./styles";

const UploadLoader: FC = () => (
  <div css={uploadLoaderStyles}>
    <Loader />
  </div>
);

export default UploadLoader;

import React, { FC, ReactNode } from "react";
import { Result } from "@epignosis_llc/gnosis";
import { centeredPanel } from "./styles";

type CenteredPanel = {
  title: string;
  description?: string;
  footer?: ReactNode;
  children?: ReactNode;
};

const CenteredPanel: FC<CenteredPanel> = ({ title, description, children, footer }) => {
  return (
    <section css={centeredPanel}>
      <Result title={title} info={description} footer={footer} />
      {children}
    </section>
  );
};

export default CenteredPanel;

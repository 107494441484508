import { PalletThemeColors, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["discussions"] => {
  return {
    color: colors.secondary.base,
    hover: hexToRGBA(colors.primary.lightest.toString(), 0.4),
    linkColor: colors.black,
    border: colors.secondary.lighter,
    textColor: colors.black,
    listBackgroundColor: colors.secondary.lighter,
    boxShadowColor: colors.secondary.light,
    filledIcon: colors.orange.base,
    hoverIcon: colors.orange.light,
  };
};

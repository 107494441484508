// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";

// Styles
import { FileListStyle } from "./styles";

// Components
import { FileModal } from "@components";
import FileName from "./Components/FileName";
import FileActions from "./Components/FileActions";

// Utils, hooks
import { readableBytes } from "@utils/helpers";

// Other imports
import { CourseFile } from "types/entities";

const fileClassNames = (isReadonly: boolean, canPreview: boolean): string =>
  classNames("file", {
    readonly: isReadonly,
    previewable: canPreview,
  });

type FileListProps = {
  files: CourseFile[];
  title?: string;
  courseId?: string;
  isLearnerView?: boolean;
  isReadonly?: boolean;
  onDelete?: () => void;
};

const FileList: FC<FileListProps> = ({
  files,
  title,
  courseId,
  isLearnerView = true,
  isReadonly = false,
  onDelete,
}) => {
  const [hoveredRow, setHoveredRow] = useState<CourseFile | null>(null);
  const [selectedFile, setSelectedFile] = useState<CourseFile | null>(null);

  // learner fetches only shared files but has no 'shared' property in those files
  const shownFiles = files?.filter((file) => file.shared || isLearnerView);

  const closeModal = (): void => {
    setSelectedFile(null);
  };

  const handleFileClick = (
    e: React.MouseEvent<HTMLDivElement>,
    file: CourseFile,
    canPreview: boolean,
  ): void => {
    const { className } = e.target as HTMLDivElement;

    // set file for preview, when can preview and has file class name
    if (!canPreview || typeof className !== "string" || !className.includes("file")) return;

    setSelectedFile(file);
  };

  return (
    <div css={(theme): SerializedStyles => FileListStyle(theme)} className="files-container">
      {title && (
        <Text fontSize="md" weight="700" className="files-header" as="div">
          {title}
        </Text>
      )}

      <div className="files-container">
        {shownFiles.map((file) => {
          const isRowHovered = hoveredRow?.id === file.id;
          const { id, type, size_in_bytes, preview_url, url } = file;
          const size = readableBytes(size_in_bytes);
          const isPreviewable = Boolean(preview_url);
          const canPreview = Boolean(url && !isReadonly && isPreviewable);

          return (
            <div
              className={fileClassNames(isReadonly, canPreview)}
              key={file.id}
              onMouseEnter={(): void => setHoveredRow(file)}
              onMouseLeave={(): void => setHoveredRow(null)}
              onClick={(e): void => handleFileClick(e, file, canPreview)}
            >
              <Text fontSize="sm" className="file-name-container">
                <FileName key={id} {...file} />
                <span className="type">{type}</span>
                <Text fontSize="sm" className="upper-dot"></Text>
                <span className="size">{size}</span>
              </Text>

              {!isReadonly && (
                <FileActions
                  courseId={courseId}
                  key={id}
                  file={file}
                  isRowHovered={isRowHovered}
                  isPreviewable={isPreviewable}
                  onPreview={setSelectedFile}
                  onDelete={onDelete}
                />
              )}
            </div>
          );
        })}
      </div>
      {/* Preview */}
      {selectedFile && (
        <FileModal {...selectedFile} isOpen={Boolean(selectedFile)} onClose={closeModal} />
      )}
    </div>
  );
};

export default FileList;

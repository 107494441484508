import { t } from "@utils/i18n";
import { isWithinTimeIntervalOnWeekdays } from "@utils/helpers/date-time";
import { ArticleFilters, BasicSettings, SearchQueryOptionsWithFilters } from "./types";

export const buildAPIHelpArticlesFilters = (filters?: ArticleFilters): string => {
  if (!filters) return "";

  const { section_id: sectionId } = filters;

  const sectionFilter = sectionId ? `&filter[section_id][eq]=${sectionId}` : "";

  return `${sectionFilter}`;
};

export const buildHelpArticlesSearchQuery = ({
  filters,
  search,
}: SearchQueryOptionsWithFilters): string => {
  let query = `?${buildAPIHelpArticlesFilters(filters)}`;

  // add search to query
  if (search) {
    query += `&filter[keyword][like]=${encodeURIComponent(search)}`;
  }

  return query;
};

export const fullTrim = (snippet: string): string => {
  // Remove all occurrences of &nbsp;
  snippet = snippet.replace(/&nbsp;/g, "");

  // Remove leading/trailing whitespace from each line
  snippet = snippet.replace(/(^\s+|\s+$|\n\s+|\s+\n)/g, "");

  // Replace multiple spaces with a single space
  snippet = snippet.replace(/\s+/g, " ");

  // Replace all occurrences of <em> and </em> with <strong> and </strong>
  snippet = snippet.replace(/<em>/g, "<strong>").replace(/<\/em>/g, "</strong>");

  return snippet;
};

export const convertPatternToBreaks = (body: string): string => {
  // Replace specific pattern of </p>\n<p> with </p><br><p
  body = body.replace(/<\/p>\n<p/g, "</p><br><p");

  return body;
};

export const getPathSegments = (path: string): string => {
  // Split the path into parts and filter out numeric segments
  const parts = path.split("/").filter((part) => part && isNaN(Number(part)));

  // Join the remaining parts and return them as a path
  return `/${parts.join("/")}`;
};

const dateToCheck = new Date();
const isWithinPhoneSupportHours = isWithinTimeIntervalOnWeekdays(dateToCheck, 14, 22);
const isWithinLiveChatHours = isWithinTimeIntervalOnWeekdays(dateToCheck, 5, 22);

export const getSupportServices = (
  isLiveChatVisible: boolean,
  isPhoneSupportVisible: boolean,
  phoneSupportInPlan?: boolean,
): BasicSettings[] => {
  return [
    {
      title: t("helpCenter.openTicket"),
      description: t("helpCenter.openTicketInfo"),
      placeholder: t("helpCenter.openTicketPlaceholder"),
      name: "submit_ticket",
    },
    {
      title: t("helpCenter.liveChat"),
      description: t("helpCenter.liveChatInfo"),
      placeholder: isLiveChatVisible
        ? t("helpCenter.hideChat")
        : t("helpCenter.liveChatPlaceholder"),
      tooltipText: t("helpCenter.liveChatTooltip"),
      tooltipEnabled: !isWithinLiveChatHours,
      name: "live_chat",
    },
    {
      title: t("helpCenter.phoneSupport"),
      description: t("helpCenter.phoneSupportInfo"),
      placeholder: isPhoneSupportVisible
        ? t("helpCenter.hideCall")
        : t("helpCenter.phoneSupportPlaceholder"),
      tooltipText: t("helpCenter.phoneSupportTooltip"),
      tooltipEnabled: !isWithinPhoneSupportHours && phoneSupportInPlan,
      name: "phone_support",
    },
    {
      title: t("helpCenter.accountManager"),
      description: t("helpCenter.accountManagerInfo"),
      placeholder: t("helpCenter.accountManagerPlaceholder"),
      name: "account_manager",
    },
  ];
};

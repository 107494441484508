import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const tabContainerStyles = ({ button, rangeInput }) => css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0;

  .grid-container {
    padding: 1rem 2rem;

    &.align-items-end {
      align-items: flex-end;
    }

    .toggle-option {
      padding-top: 1rem;
    }

    ${mq["lg"]} {
      .half-column {
        max-width: 50%;
      }
    }
  }

  .is-pressed {
    background: ${button.primary.ghost.background};
  }

  .range-input-container {
    border: 1px solid ${rangeInput.borderColor};
    border-radius: 5px;
    padding: 0.75rem 0 0;
  }
`;

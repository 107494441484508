import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { ListSVG_duplicate, GridSVG_duplicate } from "@epignosis_llc/gnosis/icons";
import classNames from "classnames";

import { toggleButtonsViewContainer } from "./styles";
import { useApplyTranslations } from "@hooks";

type ToggleButtonsViewProps = {
  showTable: boolean;
  handleTableShow: () => void;
};

const toggleButtonClassName = (isSelected: boolean): string =>
  classNames({
    item: true,
    "selected-item": isSelected,
  });

const ToggleButtonsView: FC<ToggleButtonsViewProps> = ({ showTable, handleTableShow }) => {
  const { t } = useApplyTranslations();

  return (
    <div css={toggleButtonsViewContainer}>
      <Button
        aria-label={t("a11y.general.showListView")}
        onClick={handleTableShow}
        noGutters
        className={toggleButtonClassName(showTable)}
        data-testid="show-list-view-button"
      >
        <ListSVG_duplicate height={32} />
      </Button>
      <Button
        aria-label={t("a11y.general.showGridView")}
        onClick={handleTableShow}
        noGutters
        className={toggleButtonClassName(!showTable)}
        data-testid="show-grid-view-button"
      >
        <GridSVG_duplicate height={32} />
      </Button>
    </div>
  );
};

export default ToggleButtonsView;

import React, { FC, ReactElement, useMemo, useState } from "react";
import { CSSObject, SerializedStyles, useTheme } from "@emotion/react";
import { Label, Tag, Tooltip } from "@epignosis_llc/gnosis";
import Select, {
  components,
  ControlProps,
  createFilter,
  GroupBase,
  InputActionMeta,
  MenuListProps,
  MultiValue,
  MultiValueGenericProps,
  OptionProps,
  SingleValue,
} from "react-select";
import {
  AutocompleteContainer,
  MenuListStyles,
  MultiValueLabelStyles,
  MultiValueRemoveStyles,
  MultiValueStyles,
  SelectContainerStyles,
  ValueContainerStyles,
  SingleValueStyles,
} from "./styles";
import CustomDropdownIndicator from "@components/CustomReactSelect/CustomDropdownIndicator";
import {
  ClearIndicatorStyles,
  DropdownIndicatorStyles,
  ControlStyles,
  PlaceholderStyles,
  OptionStyles,
  GroupHeadingStyles,
  GroupStyles,
} from "@components/CustomReactSelect/styles";
import CustomInput from "@components/CustomReactSelect/CustomInput";
import { CustomMultiValueRemove } from "@components";
import { useApplyTranslations } from "@hooks";

export type Option = {
  value: string;
  label: string;
  group?: string;
  type?: string;
};

export type GroupOption = {
  label: string;
  options: Option[];
};

type MenuListCustomProps = {
  totalItemsLength: number;
};

export type AutocompleteInputProps = {
  optionsArray: GroupOption[] | Option[];
  label?: string;
  placeholder?: string;
  defaultValue?: Option | Option[] | null;
  value?: Option | Option[] | null;
  status?: string;
  dropdownIcon?: boolean;
  isMulti?: true | undefined;
  showGroup?: boolean;
  disableFilterOptions?: boolean;
  disableFilterValueOption?: boolean;
  required?: boolean;
  noOptionsMessage?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  maxMenuHeight?: number;
  testId?: string;
  menuListProps?: MenuListCustomProps;
  isMultiValueLabelTransparent?: boolean;
  hideSelectedOptions?: boolean;
  formatOptionLabel?: (option: Option) => JSX.Element;
  onInputChange?: (inputValue: string) => void;
  onChange?: (selectedOptions: SingleValue<Option> | MultiValue<Option>) => void;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
};

const AutocompleteInput: FC<AutocompleteInputProps> = ({
  label,
  optionsArray,
  defaultValue = null,
  value = undefined,
  placeholder,
  dropdownIcon = true,
  status,
  required = false,
  isMulti = undefined,
  showGroup = false,
  disableFilterOptions = false,
  disableFilterValueOption = false,
  noOptionsMessage = "",
  isDisabled = false,
  isLoading = false,
  maxMenuHeight = 300,
  testId = "autocomplete-input",
  menuListProps,
  isMultiValueLabelTransparent = false,
  hideSelectedOptions = true,
  formatOptionLabel,
  onChange,
  onInputChange,
  onMenuClose,
  onMenuOpen,
}) => {
  const { autocompleteInput } = useTheme();
  const { t } = useApplyTranslations();
  const [inputValue, setInputValue] = useState("");

  // Custom styles for the components
  const customStyles = {
    clearIndicator: (base: CSSObject): CSSObject =>
      ClearIndicatorStyles({ autocompleteInput, base }),
    dropdownIndicator: (base: CSSObject): CSSObject => DropdownIndicatorStyles({ base }),
    control: (base: CSSObject, state: ControlProps<Option, true, GroupBase<Option>>): CSSObject =>
      ControlStyles({ autocompleteInput, base, state, status }),
    placeholder: (base: CSSObject): CSSObject => PlaceholderStyles({ autocompleteInput, base }),
    valueContainer: (base: CSSObject): CSSObject => ValueContainerStyles({ base }),
    singleValue: (base: CSSObject): CSSObject => SingleValueStyles({ base }),
    multiValue: (base: CSSObject): CSSObject => MultiValueStyles({ autocompleteInput, base }),
    multiValueLabel: (base: CSSObject): CSSObject => MultiValueLabelStyles({ base }),
    multiValueRemove: (base: CSSObject): CSSObject =>
      MultiValueRemoveStyles({ autocompleteInput, base }),
    container: (base: CSSObject): CSSObject => SelectContainerStyles({ base }),
    menuList: (base: CSSObject): CSSObject => MenuListStyles({ base }),
    option: (base: CSSObject, state: OptionProps<Option, true, GroupBase<Option>>): CSSObject =>
      OptionStyles({ autocompleteInput, base, state }),
    group: (base: CSSObject): CSSObject => GroupStyles({ autocompleteInput, base }),
    groupHeading: (base: CSSObject): CSSObject => GroupHeadingStyles({ base }),
  };

  // Multiselect label component
  const MultiValueLabel = (props: MultiValueGenericProps<Option>): ReactElement => {
    let displayLabel = props.data.label;
    const isLabelLong = displayLabel?.length > 50;
    if (isLabelLong) {
      displayLabel = `${displayLabel.substring(0, 50)}...`;
    }
    const label = !isLabelLong ? (
      displayLabel
    ) : (
      <Tooltip content={props.data.label}>{displayLabel}</Tooltip>
    );
    return (
      <components.MultiValueLabel {...props}>
        <Tag
          className="multi-value-label"
          style={{
            backgroundColor: isMultiValueLabelTransparent
              ? "transparent"
              : autocompleteInput.tagColor,
            color: autocompleteInput.textColor,
          }}
        >
          {showGroup && props.data.group && `${props.data.group}:`} {label}
        </Tag>
      </components.MultiValueLabel>
    );
  };

  const MenuList = (props: MenuListProps<Option, true>): ReactElement => {
    const { children, options } = props;

    // Flatten options in case they are grouped and count them
    const unselectedOptionsCount = useMemo(
      () =>
        options.reduce((acc: number, option) => {
          if ("options" in option) {
            return acc + option.options.length;
          }
          return acc + 1;
        }, 0),
      [options],
    );

    return (
      <components.MenuList {...props} className="autocomplete-input-menu-list">
        {children}
        {menuListProps && (
          <div className="menu-list-footer">
            {t("reports.customReports.showingFilteredItems", {
              count: unselectedOptionsCount,
              total: menuListProps.totalItemsLength,
            })}
          </div>
        )}
      </components.MenuList>
    );
  };

  const defaultFilterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    // TODO: check if can be fixed
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stringify: (option: any) => `${option.label} ${!disableFilterValueOption && option.value}`,
    /* Some option arrays use the value property of an object to store the id so we need 
    to disable it in filtering with this flag */
    trim: true,
    matchFrom: "any" as unknown as "any",
  };

  const handleInputChange = (newValue: string, action: InputActionMeta): void => {
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      setInputValue(newValue);
      if (onInputChange) onInputChange(newValue);
    }
  };

  return (
    <div
      css={(): SerializedStyles => AutocompleteContainer(autocompleteInput, { required })}
      data-testid={testId}
    >
      {label && <Label>{label}</Label>}
      <Select
        aria-label={label}
        className="autocomplete-input"
        defaultValue={defaultValue}
        value={value}
        inputValue={inputValue}
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        options={optionsArray}
        isClearable={false}
        styles={customStyles}
        placeholder={placeholder}
        isDisabled={isDisabled}
        filterOption={disableFilterOptions ? null : createFilter(defaultFilterConfig)}
        noOptionsMessage={(): string => noOptionsMessage}
        isLoading={isLoading}
        maxMenuHeight={maxMenuHeight}
        components={{
          DropdownIndicator: dropdownIcon ? CustomDropdownIndicator : null,
          IndicatorSeparator: null,
          MultiValueLabel,
          MultiValueRemove: CustomMultiValueRemove,
          MenuList,
          Input: CustomInput,
        }}
        hideSelectedOptions={hideSelectedOptions}
        formatOptionLabel={formatOptionLabel}
        onInputChange={handleInputChange}
        onChange={onChange}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
      />
    </div>
  );
};

export default AutocompleteInput;

import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { CopyIconSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type CloneActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  disabled?: boolean;
  onClick: (e: MouseEvent) => void;
};

const CloneAction: FC<CloneActionProps> = ({
  tooltipContent,
  disabled = false,
  icon: Icon = CopyIconSVG,
  onClick,
  ...rest
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      disabled={disabled}
      tooltipContent={tooltipContent ?? t("general.actions.clone")}
      onClick={onClick}
      data-testid="clone-action"
      {...rest}
    >
      <Icon height={30} />
    </ActionButton>
  );
};

export default CloneAction;

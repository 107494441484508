import { PalletThemeColors, getProtectedColor, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["trainingMatrix"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    border: colors.secondary.lighter,
    completed: colors.green.base,
    failed: colors.red.base,
    incomplete: colors.orange.base,
    notAttempted: colors.secondary.base,
    svgColor: colors.white,
    hover: protectColorContrast("darker", hexToRGBA(colors.primary.lightest, 0.25), 0.25),
    titleColor: colors.secondary.base,
    linkColor: colors.black,
  };
};

// Packages or third-party libraries
import React, { FC, Fragment } from "react";
import { QueryStatus } from "react-query";
import { Loader } from "@epignosis_llc/gnosis";

// Styles
import { TabContainer } from "./styles";

// Components
import { Skeletons } from "@components";
import { Divider } from "@components/ReusableComponents";
import { IltSession, IltWrapper } from "@views/Unit";

// Other imports
import { SingleIltSession } from "types/entities/Ilts";

type SessionTabProps = {
  session: SingleIltSession;
  error: unknown;
  status: QueryStatus;
};

const SessionTab: FC<SessionTabProps> = ({ session, error, status }): JSX.Element => {
  const { session: singleSession } = session ?? {};
  const { sessions = [] } = singleSession ?? {};
  const sessionItem = sessions[0];

  return (
    <Skeletons.Loader status={status} error={error} loader={<Loader fullScreen />}>
      <div css={TabContainer}>
        <IltWrapper sessionColor={sessionItem.color}>
          {sessions.map((sessionItem, index) => {
            const isLastItem = index === sessions.length - 1;

            return (
              <Fragment key={sessionItem.id}>
                <IltSession
                  sessionItem={sessionItem}
                  session={singleSession}
                  showTitle
                  maxCols={4}
                  shouldDisableGrayOutDate
                  isAdmin
                  hideActions
                />
                {!isLastItem && <Divider />}
              </Fragment>
            );
          })}
        </IltWrapper>
      </div>
    </Skeletons.Loader>
  );
};

export default SessionTab;

import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["onboardingIntro"] => {
  return {
    backButton: colors.secondary.base,
    badgeText: colors.secondary.lighter,
    border: colors.secondary.lighter,
    createWithAI: {
      inputBackground: colors.white,
      pillBackground: Color(colors.primary.lightest).alpha(0.25).string(),
    },
    descriptionLight: colors.secondary.base,
    introBackground: colors.secondary.lighter,
    nextStepsOptions: {
      background: colors.secondary.lighter,
      backgroundHover: colors.primary.light,
      backgroundSecondary: colors.white,
      colorHover: colors.white,
      iconColor: colors.secondary.base,
      disabledColor: colors.secondary.dark,
    },
    option: {
      borderSelected: colors.primary.base,
      backgroundSelected: Color(colors.primary.lightest).alpha(0.25).string(),
      borderDeactivated: colors.secondary.light,
    },
    stepBackgroundActive: colors.primary.lightest,
    stepBackgroundDefault: colors.secondary.light,
    stepBackgroundPrevious: colors.primary.base,
  };
};

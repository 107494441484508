type recordingTypeOption = {
  blobType: string;
  fileExtension: string;
};

export const MIN_VIDEO_DURATION = 5; // in seconds
export const MAX_VIDEO_DURATION = 3600; // in seconds

export const RECORDING_TYPE_OPTIONS: Record<string, recordingTypeOption> = {
  video: {
    blobType: "video/mp4",
    fileExtension: "mp4",
  },
  screen: {
    blobType: "video/webm",
    fileExtension: "webm",
  },
  audio: {
    blobType: "audio/mpeg",
    fileExtension: "mp3",
  },
};

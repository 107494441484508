import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["dropdown"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    boxShadowColor: colors.secondary.light,
    backgroundColor: colors.white,
    emptyStateColor: colors.secondary.base,
    hoverBackgroundColor: colors.secondary.lighter,
    borderBottomColor: colors.secondary.lighter,
    borderOverviewColor: protectColorContrast("darker", colors.primary.base),
  };
};

import React, { ReactElement, useState } from "react";
import { ActionDrawer, TextWithIcon } from "@components";
import { useApplyTranslations } from "@hooks";
import { Controller, FieldValues, Path } from "react-hook-form";
import { BranchDrawersProps, BranchPropertiesNames } from "@views/Branches/types";
import { Select, Input, Text, InputError } from "@epignosis_llc/gnosis";
import { SelectOption } from "types/common";
import {
  ecommerceProcessorLabelMapping,
  ecommerceProcessorOptions,
} from "@views/Branches/constants";
import { useConfigurationStore } from "@stores";
import { formatLabelSelect } from "@views/AccountAndSettings/components/SettingCard/helpers";
import { ecommerceContainer } from "@views/Branches/styles";
import { SerializedStyles } from "@emotion/react";
import { EcommerceProcessorEnum } from "types/entities/Branches";
import { CheckCircledSVG } from "@epignosis_llc/gnosis/icons";

const EcommerceProcessor = <T extends FieldValues>({
  control,
  isDisabled,
  watch,
  toggleDrawer,
  resetField,
  errors,
  isButtonDisabled,
  isOpen,
}: BranchDrawersProps<T> & { isButtonDisabled: boolean }): JSX.Element => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const { currencies, is_stripe_connected } = domainSettings ?? {};

  const processor = watch?.(BranchPropertiesNames.BRANCH_ECOMMERCE_PROCESSOR as Path<T>);
  const currency = watch?.(BranchPropertiesNames.BRANCH_ECOMMERCE_CURRENCY as Path<T>);
  const paypalEmail = watch?.(BranchPropertiesNames.BRANCH_ECOMMERCE_PAYPAL_EMAIL as Path<T>);
  const tempValues = {
    processor,
    currency,
    paypalEmail,
  };
  const [tempValuesState, setTempValues] = useState(tempValues);

  const headerTitle = t("branches.properties.ecommerceProcessor.title");
  const handleClose = (): void => {
    const resetProcessorValue = tempValuesState.processor
      ? { defaultValue: tempValuesState.processor }
      : undefined;
    const resetCurrencyValue = tempValuesState.currency
      ? { defaultValue: tempValuesState.currency }
      : undefined;
    const resetPaypalEmailValue = tempValuesState.paypalEmail
      ? { defaultValue: tempValuesState.paypalEmail }
      : undefined;

    resetField?.(BranchPropertiesNames.BRANCH_ECOMMERCE_PROCESSOR as Path<T>, resetProcessorValue);
    resetField?.(BranchPropertiesNames.BRANCH_ECOMMERCE_CURRENCY as Path<T>, resetCurrencyValue);
    resetField?.(
      BranchPropertiesNames.BRANCH_ECOMMERCE_PAYPAL_EMAIL as Path<T>,
      resetPaypalEmailValue,
    );

    toggleDrawer();
  };
  const actionButton = {
    text: t("general.back"),
    isDisabled: isDisabled || isButtonDisabled,
  };
  const onApply = (): void => {
    setTempValues(tempValues);
    toggleDrawer();
  };

  const filteredEcommerceProcessorOptions = ecommerceProcessorOptions.filter((value) => {
    if (!is_stripe_connected) {
      return value !== EcommerceProcessorEnum.STRIPE;
    }
    return true;
  });

  const ecommerceProcessorSelectOptions = filteredEcommerceProcessorOptions.map((value) => ({
    label: value !== "none" ? ecommerceProcessorLabelMapping[value] : t("general.none"),
    value: value,
  }));

  const ecommerceCurrenciesSelectOptions = currencies?.map((value: string) => ({
    label: formatLabelSelect(BranchPropertiesNames.BRANCH_ECOMMERCE_CURRENCY, value, []),
    value: value,
  }));

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={handleClose}
      showFooter={true}
      size="md"
      headerTitle={headerTitle}
      actionButton={actionButton}
      onApply={onApply}
    >
      <div css={(theme): SerializedStyles => ecommerceContainer(theme)}>
        <Controller
          name={BranchPropertiesNames.BRANCH_ECOMMERCE_PROCESSOR as Path<T>}
          control={control}
          render={({ field: { onChange, value } }): ReactElement => {
            const defaultValue = value
              ? ecommerceProcessorSelectOptions.find((option) => option.value === value)
              : null;

            return (
              <Select
                id="ecommerce-processor"
                options={ecommerceProcessorSelectOptions}
                isDisabled={isDisabled}
                onChange={(option): void => {
                  const { value } = option as SelectOption;
                  onChange(value);
                }}
                label={t("branches.properties.ecommerceProcessor.title")}
                defaultValue={defaultValue}
              />
            );
          }}
        />

        {processor === EcommerceProcessorEnum.PAYPAL && (
          <>
            <Controller
              name={BranchPropertiesNames.BRANCH_ECOMMERCE_CURRENCY as Path<T>}
              control={control}
              render={({ field: { onChange, value } }): ReactElement => {
                const defaultValue = value
                  ? ecommerceCurrenciesSelectOptions &&
                    ecommerceCurrenciesSelectOptions.find(
                      (option: SelectOption) => option.value === value,
                    )
                  : null;

                return (
                  <Select
                    id="ecommerce-processor"
                    options={ecommerceCurrenciesSelectOptions}
                    isDisabled={isDisabled}
                    onChange={(option): void => {
                      const { value } = option as SelectOption;
                      onChange(value);
                    }}
                    label={t("accountAndSettings.portalSettings.currency")}
                    defaultValue={defaultValue}
                  />
                );
              }}
            />
            <div>
              <Controller
                name={BranchPropertiesNames.BRANCH_ECOMMERCE_PAYPAL_EMAIL as Path<T>}
                control={control}
                render={({ field: { onChange, value } }): ReactElement => {
                  return (
                    <>
                      <Input
                        label={t("branches.properties.ecommerceProcessor.paypalEmail")}
                        id={BranchPropertiesNames.BRANCH_ECOMMERCE_PAYPAL_EMAIL}
                        status={errors?.ecommerce?.paypal_email_address ? "error" : "valid"}
                        disabled={isDisabled}
                        value={value ?? ""}
                        onChange={(text): void => {
                          onChange(text);
                        }}
                      />
                      {errors && errors?.ecommerce?.paypal_email_address && (
                        <InputError>{errors?.ecommerce?.paypal_email_address?.message}</InputError>
                      )}
                    </>
                  );
                }}
              />
              <div className="description-container">
                <Text as="div" fontSize="sm" className="description">
                  {t("branches.properties.ecommerceProcessor.paypalDescription")}
                </Text>
              </div>
            </div>
          </>
        )}
        {processor === EcommerceProcessorEnum.STRIPE && (
          <div>
            <TextWithIcon
              icon={<CheckCircledSVG height={16} />}
              hasDescription
              iconColor="green"
              descriptionSize="sm"
              description={t("branches.properties.stripeAccountConnected")}
              descriptionSuccess={true}
            />
          </div>
        )}
      </div>
    </ActionDrawer>
  );
};

export default EcommerceProcessor;

import React from "react";
import { DropdownItem } from "@epignosis_llc/gnosis";
import {
  AssignmentPassedAnswer,
  AssignmentPendingAnswer,
  AssignmentNotPassedAnswer,
} from "@epignosis_llc/gnosis/icons";
import { MassActionEnum } from "@components/ReusableComponents/MassActions/types";
import { t } from "@utils/i18n";
import { IltSession } from "types/entities";
import { SubmissionListing, SubmissionStatus } from "types/entities/Assignments";

export const isSubmission = (
  submission: SubmissionListing | IltSession,
): submission is SubmissionListing => {
  return (submission as SubmissionListing).submission_date !== undefined;
};

export const extractDate = (submission: SubmissionListing | IltSession): string => {
  if (isSubmission(submission)) {
    return submission.submission_date;
  }
  return submission.session_date;
};

export const getIconFromStatus = (status: SubmissionStatus, height = 20): JSX.Element | null => {
  switch (status) {
    case "passed":
      return <AssignmentPassedAnswer height={height} />;
    case "not-passed":
      return <AssignmentNotPassedAnswer height={height} />;
    case "pending":
      return <AssignmentPendingAnswer height={height} />;
    default:
      return null;
  }
};

export const getTranslationFromStatus = (status: SubmissionStatus): string => {
  switch (status) {
    case "passed":
      return "general.progress.passed";
    case "not-passed":
      return "general.progress.notPassed";
    case "pending":
    default:
      return "general.progress.pending";
  }
};

export const getSubmissionsMassActionsOptions = (): DropdownItem[] => [
  {
    label: t("general.actions.grade"),
    value: MassActionEnum.grade,
  },

  {
    label: t("general.message"),
    value: MassActionEnum.send_message,
  },
  {
    label: t("general.reset"),
    value: MassActionEnum.reset,
  },
];

export const getIltSessionsMassActionsOptions = (): DropdownItem[] => [
  {
    label: t("general.actions.grade"),
    value: MassActionEnum.grade,
  },

  {
    label: t("general.message"),
    value: MassActionEnum.send_message,
  },
  {
    label: t("general.reset"),
    value: MassActionEnum.reset,
  },
  {
    label: t("ilt.removeFromSession"),
    value: MassActionEnum.remove_from_session,
  },
];

import { css } from "@emotion/react";

export const pageContainer = ({ signInPage }) => css`
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  background-color: ${signInPage.background};

  .content-wrapper {
    max-width: 500px;
    margin: auto;

    .form-wrapper {
      background: transparent;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      padding: 1rem;
    }

    .announcements-wrapper {
      padding: 1rem 1rem 3rem;
    }
  }
`;

export const signUpFormContainer = ({ signInPage }) => css`
  width: 100%;
  max-width: 500px;
  background: ${signInPage.formBackground};
  border-radius: 30px;

  box-shadow: 0px 0px 20px ${signInPage.boxShadow};

  .form-container {
    .container {
      margin-bottom: 1.5rem;

      .line {
        margin: 0 2.5rem;
      }
    }
  }

  .signUp-link-container {
    padding: 0 2rem 2rem;
  }
`;

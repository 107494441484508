import React, { FC, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useAuth } from "@hooks";
import { URLS } from "@constants/urls";
import SignInModal from "@components/SignIn/SignInModal";
import { useConfigurationStore, useUIStore } from "@stores";
import TermsModal from "@views/Terms/TermsModal";
import localStorageKeys from "@constants/localStorageKeys";
import queryParameters from "@constants/queryParameters";

const PrivateRoute: FC = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { boot, hardShutdown } = useIntercom();
  const { showSessionExpirationModal, setShowSessionExpirationModal, setShowTermsModal } =
    useUIStore();
  const { domainTerms, userIntegrations, userProfileData, domainSettings } =
    useConfigurationStore();
  const { intercom } = domainSettings?.javascript_integrations ?? {};
  const { is_active: intercomIsActive = false } = intercom ?? {};
  const { intercom_settings = null } = userIntegrations ?? {};
  const isPortalOwner = Boolean(userProfileData?.is_owner);

  // on initial render close sign in modal
  useEffect(() => {
    setShowSessionExpirationModal(false);
    localStorage.removeItem(localStorageKeys.PUBLIC_COURSE); // When the user logins or signs up, and is authenticated remove the public token
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Only boot Intercom if the user is the portal owner, valid settings exist, and Intercom is not already active.
  // Shut down Intercom if conditions are not met to prevent unnecessary activation.
  useEffect(() => {
    const shouldBootIntercom =
      isPortalOwner && intercom_settings?.user_id && intercom_settings?.user_hash;

    if (shouldBootIntercom) {
      boot({
        userId: intercom_settings.user_id,
        userHash: intercom_settings.user_hash,
      });
    } else {
      hardShutdown();
    }

    return () => {
      // Ensure Intercom is cleaned up when the component unmounts.
      hardShutdown();
    };
  }, [boot, hardShutdown, intercom_settings, isPortalOwner, intercomIsActive]);

  useEffect(() => {
    setShowTermsModal(isAuthenticated && domainTerms?.terms !== null);
  }, [domainTerms, setShowTermsModal, isAuthenticated]);

  return (
    <>
      <SignInModal />
      {isAuthenticated && <TermsModal />}

      {/* user authenticated or login modal is visible */}
      {isAuthenticated || showSessionExpirationModal ? (
        <Outlet />
      ) : (
        <Navigate
          to={`${URLS.login}?${queryParameters.redirect}=${encodeURIComponent(
            location.pathname + location.search,
          )}`}
        />
      )}
    </>
  );
};

export default PrivateRoute;

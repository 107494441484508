// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Select } from "@epignosis_llc/gnosis";
import { useInfiniteQuery } from "react-query";
import { Props } from "react-select";
import { useDebounceFn } from "ahooks";
import { AxiosError } from "axios";

// Constants
import { baseSearchQuery } from "./constants";

// Types
import { GenericFilterRes, SelectOption } from "types/common";

// Utils
import { branchesToOptions, buildPaginatedSearchQuery } from "@utils/helpers";
import { fetchData, getQueryKey, handleError, updateSearchQuery } from "./helpers";

type FilterProps = Props<SelectOption> & {
  filterType: string;
  minWidth?: string;
  maxWidth?: string;
  label?: string;
};

const InfiniteAsyncFilterSelect: FC<FilterProps> = ({
  filterType,
  onChange,
  ...rest
}): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>("");
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
  const searchQuery = buildPaginatedSearchQuery(baseSearchQuery);
  const queryKey = getQueryKey(filterType);

  const { fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } = useInfiniteQuery(
    [queryKey, searchQuery, inputValue],
    ({ pageParam }): Promise<GenericFilterRes> => {
      // updates page number in search query
      const updatedQueryString = updateSearchQuery(searchQuery, inputValue, pageParam);
      return fetchData(filterType, updatedQueryString);
    },
    {
      getNextPageParam: (res: GenericFilterRes) => {
        const currentPage = res._meta.pagination?.page ?? 0;
        const totalPages = res._meta.pagination?.total_pages ?? 0;

        if (currentPage < totalPages) {
          return currentPage + 1;
        }

        return undefined;
      },
      onSettled: (res) => {
        const allItems = res?.pages.flatMap((page) => page._data) ?? [];
        const selectOptions = branchesToOptions(allItems);
        setSelectOptions(selectOptions);
      },
      onError: (error: AxiosError) => {
        handleError(filterType, error);
      },
      keepPreviousData: true,
    },
  );

  const { run } = useDebounceFn((newValue: string) => setInputValue(newValue), { wait: 500 });

  const handleMenuScrollToBottom = (): void => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const isLoading = isFetchingNextPage || isFetching;

  return (
    <Select
      isSearchable
      defaultOptions
      cacheOptions
      options={selectOptions}
      onInputChange={run}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      isLoading={isLoading}
      onChange={(options, action): void => {
        onChange?.(options, action);
        const selectedOptions = options as SelectOption[];

        // edge case when all options are selected, we need to fetch the next page
        if (selectOptions.length <= selectedOptions?.length) {
          fetchNextPage();
        }
      }}
      {...rest}
    />
  );
};

export default InfiniteAsyncFilterSelect;

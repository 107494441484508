import { NestedSelectOption } from "types/common";
import { CourseCategory } from "types/entities";

export type reformTreeType = {
  category: CourseCategory;
  result?: NestedSelectOption[];
  level?: number;
};

export const createCategoriesWithLevels = (categories: CourseCategory[]): NestedSelectOption[] => {
  const res = categories.map((category) => createOptionsWithLevelsRecursion({ category }));
  const categoryOptions = res?.sort((a, b) => a[0].label.localeCompare(b[0].label));
  return categoryOptions.flat();
};

const createOptionsWithLevelsRecursion = ({
  category,
  result = [],
  level = 0,
}: reformTreeType): NestedSelectOption[] => {
  result.push({ label: category.name, value: category.id.toString(), level });

  if (category.children) {
    level++;
    category.children.forEach((category) => {
      createOptionsWithLevelsRecursion({ category, result, level });
    });
  }

  return result;
};

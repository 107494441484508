import React, { useState } from "react";
import { ActionDrawer } from "@components";
import { useApplyTranslations } from "@hooks";
import { Controller, FieldValues, Path } from "react-hook-form";
import { BranchDrawersProps, BranchPropertiesNames } from "@views/Branches/types";
import { ToggleSwitch, Text, Input, InputError } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { toggleSwitchDescription } from "@views/Branches/styles";

const RestrictedDomainsDrawer = <T extends FieldValues>({
  control,
  isDisabled,
  watch,
  resetField,
  toggleDrawer,
  register,
  errors,
  isOpen,
}: BranchDrawersProps<T>): JSX.Element => {
  const { t } = useApplyTranslations();

  const isEnabled = watch?.(BranchPropertiesNames.BRANCH_ENABLED_RESTRICT_REGISTRATION as Path<T>);
  const restrictedDomains = watch?.(BranchPropertiesNames.BRANCH_RESTRICT_REGISTRATION as Path<T>);

  const tempValues = {
    isEnabled,
    restrictedDomains,
  };

  const [tempValuesState, setTempValues] = useState(tempValues);

  const headerTitle = t("accountAndSettings.security.restrictRegistrationToSpecificDomains");
  const handleClose = (): void => {
    const resetEnabledValue = tempValuesState.isEnabled
      ? { defaultValue: tempValuesState.isEnabled }
      : undefined;
    const resetRestrictedDomainsValue = tempValuesState.restrictedDomains
      ? { defaultValue: tempValuesState.restrictedDomains }
      : undefined;

    resetField?.(
      BranchPropertiesNames.BRANCH_ENABLED_RESTRICT_REGISTRATION as Path<T>,
      resetEnabledValue,
    );
    resetField?.(
      BranchPropertiesNames.BRANCH_RESTRICT_REGISTRATION as Path<T>,
      resetRestrictedDomainsValue,
    );

    toggleDrawer();
  };
  const actionButton = {
    text: t("general.back"),
    isDisabled: isDisabled,
  };
  const onApply = (): void => {
    setTempValues(tempValues);
    toggleDrawer();
  };

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={handleClose}
      showFooter={true}
      size="md"
      headerTitle={headerTitle}
      actionButton={actionButton}
      onApply={onApply}
    >
      <div css={(theme): SerializedStyles => toggleSwitchDescription(theme)}>
        <Controller
          name={BranchPropertiesNames.BRANCH_ENABLED_RESTRICT_REGISTRATION as Path<T>}
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => (
            <div>
              <ToggleSwitch
                labelAfter={t("general.enabled")}
                defaultChecked={Boolean(value)}
                isDisabled={isDisabled}
                onChange={(): void => {
                  onChange(!value);
                }}
              />
              <Text
                as="div"
                fontSize="sm"
                className="description"
                dangerouslySetInnerHTML={{
                  __html: t("branches.properties.restrictRegistration.description"),
                }}
              />
              {/* }>
                {t("branches.properties.restrictRegistration.description")}
              </Text> */}
            </div>
          )}
        />
        <div className="restricted-domains-input">
          <>
            <Input
              {...register?.(BranchPropertiesNames.BRANCH_RESTRICT_REGISTRATION as Path<T>)}
              id={BranchPropertiesNames.BRANCH_RESTRICT_REGISTRATION}
              label={t("accountAndSettings.security.restrictRegistrationToSpecificDomains")}
              status={errors?.restrict_user_registration_domains ? "error" : "valid"}
              data-lpignore="true"
              disabled={!isEnabled || isDisabled}
              data-testid={BranchPropertiesNames.BRANCH_RESTRICT_REGISTRATION}
            />
            {errors?.restrict_user_registration_domains && (
              <InputError style={{ margin: 0 }}>
                {errors.restrict_user_registration_domains?.message}
              </InputError>
            )}
          </>
        </div>
      </div>
    </ActionDrawer>
  );
};

export default RestrictedDomainsDrawer;

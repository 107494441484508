import React, { FC, useEffect, useState } from "react";
import { Tabs } from "@epignosis_llc/gnosis";
import { Course, Section } from "types/entities";
import { ActionDrawer } from "@components";
import DetailsTab from "./Tabs/DetailsTab/DetailsTab";
import { useQueryClient } from "react-query";
import queryKeys from "@constants/queryKeys";
import { generalNotification } from "@utils/helpers";
import AvailabilityTab from "./Tabs/AvailabilityTab/AvailabilityTab";
import RulesAndPathsTab from "./Tabs/RulesAndPathsTab/RulesAndPathsTab";
import { CustomField, TabObject } from "types/entities/Common";
import CertificateTab from "./Tabs/CertificateTab/CertificateTab";
import { useApplyTranslations } from "@hooks";
import { useUIStore } from "@stores";

export type TabHandle = {
  handleApply: () => void;
  triggerValidation?: () => void;
};

export type TabProps = {
  course: Course;
  sections?: Section[];
  customFields?: CustomField[];
  onSuccess: () => void;
  onValidChange: (isValid: boolean) => void;
  onLoadingChange: (isLoading: boolean) => void;
};

type CourseOptionsDrawerProps = {
  course: Course;
  sections: Section[];
  customFields?: CustomField[];
  isOptionsDrawerOpen: boolean;
  shouldTriggerValidation: boolean;
  onOptionsDrawerClose: () => void;
};

export type VideoType = "youtube" | "file";

export enum TabKey {
  Details = "detailsTab",
  Availability = "availabilityTab",
  RulesAndPaths = "rulesAndPathsTab",
  Certificate = "certificateTab",
}

const CourseOptionsDrawer: FC<CourseOptionsDrawerProps> = ({
  course,
  sections,
  customFields,
  isOptionsDrawerOpen,
  shouldTriggerValidation,
  onOptionsDrawerClose,
}) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const { courseOptionsState } = useUIStore();

  const { id, policies } = course;
  const { can_edit = false, can_view_rules = false, can_update_rules = false } = policies ?? {};
  const courseId = id.toString();

  const { activeTab: courseOptionsActiveTab, scrollId } = courseOptionsState;

  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const detailsTabRef = React.useRef<TabHandle>(null);
  const availabilityTabRef = React.useRef<TabHandle>(null);
  const rulesAndPathsTabRef = React.useRef<TabHandle>(null);
  const certificateTabRef = React.useRef<TabHandle>(null);

  const mapTabKeysToRefs = {
    [TabKey.Details]: detailsTabRef,
    [TabKey.Availability]: availabilityTabRef,
    [TabKey.RulesAndPaths]: rulesAndPathsTabRef,
    [TabKey.Certificate]: certificateTabRef,
  };

  const handleApply = (): void => {
    if (activeTabKey) {
      const activeTabRef = mapTabKeysToRefs[activeTabKey];
      activeTabRef.current?.handleApply();
    }
  };

  const handleSuccess = (): void => {
    queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
    // invalidate the users/me endpoint to refetch the can_create_course policies, in order to show/disable/hide the "Add course" button
    queryClient.invalidateQueries([queryKeys.userProfile]);
    generalNotification("success", <p>{t("courseEdit.courseUpdatedSuccessfully")}</p>);
    onOptionsDrawerClose();
  };

  const getTabsToShow = (): TabObject[] => {
    const tabs = [];

    // required policy to render details and availability tabs
    if (can_edit) {
      tabs.push(
        {
          title: t("courseEdit.courseDetails"),
          key: TabKey.Details,
          id: "details-tab",
          content: (
            <DetailsTab
              customFields={customFields}
              course={course}
              ref={detailsTabRef}
              onValidChange={(isValid: boolean): void => {
                if (activeTabKey === TabKey.Details) setIsValid(isValid);
              }}
              onLoadingChange={(isLoading: boolean): void => {
                if (activeTabKey === TabKey.Details) setIsLoading(isLoading);
              }}
              onSuccess={handleSuccess}
            />
          ),
        },
        {
          title: t("general.availability"),
          key: TabKey.Availability,
          id: "availability-tab",
          content: (
            <AvailabilityTab
              course={course}
              ref={availabilityTabRef}
              onValidChange={(isValid: boolean): void => {
                if (activeTabKey === TabKey.Availability) setIsValid(isValid);
              }}
              onLoadingChange={(isLoading: boolean): void => {
                if (activeTabKey === TabKey.Availability) setIsLoading(isLoading);
              }}
              onSuccess={handleSuccess}
            />
          ),
        },
      );
    }

    // required policy to render rules tab
    if (can_view_rules && can_update_rules) {
      tabs.push({
        title: t("courseEdit.rulesAndPaths"),
        key: TabKey.RulesAndPaths,
        id: "rules-and-paths-tab",
        content: (
          <RulesAndPathsTab
            course={course}
            sections={sections}
            ref={rulesAndPathsTabRef}
            onValidChange={(isValid: boolean): void => {
              if (activeTabKey === TabKey.RulesAndPaths) setIsValid(isValid);
            }}
            onLoadingChange={(isLoading: boolean): void => {
              if (activeTabKey === TabKey.RulesAndPaths) setIsLoading(isLoading);
            }}
            onSuccess={handleSuccess}
          />
        ),
      });
    }

    // required policy to render details and availability tabs
    if (can_edit) {
      tabs.push({
        title: t("certificates.certificate"),
        key: TabKey.Certificate,
        id: "certificate-tab",
        content: (
          <CertificateTab
            course={course}
            ref={certificateTabRef}
            onValidChange={(isValid: boolean): void => {
              if (activeTabKey === TabKey.Certificate) setIsValid(isValid);
            }}
            onLoadingChange={(isLoading: boolean): void => {
              if (activeTabKey === TabKey.Certificate) setIsLoading(isLoading);
            }}
            onSuccess={handleSuccess}
          />
        ),
      });
    }

    return tabs;
  };
  const tabsToShow = getTabsToShow();
  const startingTabIndex = tabsToShow.findIndex((tab) => tab.key === courseOptionsActiveTab);
  const [activeTab, setActiveTab] = useState(Math.max(0, startingTabIndex));
  const activeTabKey = tabsToShow[activeTab].key;

  useEffect(() => {
    setIsValid(true);
    setIsLoading(false);
  }, [activeTab]);

  useEffect(() => {
    if (shouldTriggerValidation) {
      detailsTabRef.current?.triggerValidation && detailsTabRef.current.triggerValidation();
    }
  }, [shouldTriggerValidation]);

  useEffect(() => {
    if (!scrollId) return;

    const element = document.getElementById(scrollId);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [isOptionsDrawerOpen, scrollId]);

  return (
    <ActionDrawer
      onApply={handleApply}
      onClose={onOptionsDrawerClose}
      headerTitle={t("courseEdit.courseOptions")}
      isOpen={isOptionsDrawerOpen}
      size="md"
      actionButton={{
        text: t("general.save"),
        isDisabled: !isValid,
        isLoading: isLoading,
      }}
    >
      {tabsToShow.length > 0 && (
        <Tabs
          tabs={tabsToShow}
          selectedTab={activeTab}
          onChangeTab={(index): void => setActiveTab(index)}
          data-testid="course-options-drawer-body"
        />
      )}
    </ActionDrawer>
  );
};

export default CourseOptionsDrawer;

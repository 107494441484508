import { PalletThemeColors, hexToRGBA, getProtectedColor } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["statusTag"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    inactive: {
      backgroundColor: colors.secondary.base,
      textColor: colors.white,
    },
    info: {
      backgroundColor: hexToRGBA(colors.primary.lightest, 0.25),
      textColor: protectColorContrast("darker", colors.primary.base),
    },
    failed: {
      backgroundColor: hexToRGBA(colors.red.lightest, 0.25),
      textColor: colors.red.base,
    },
    processing: {
      background: hexToRGBA(colors.primary.lightest, 0.25),
      color: colors.primary.base,
    },
  };
};

import FroalaEditor from "froala-editor";

// define custom icons
FroalaEditor.DefineIconTemplate("my_fa", `<i class="fa-kit fa-[NAME]"></i>`);
FroalaEditor.DefineIcon("bold", { NAME: "bold", template: "my_fa" });
FroalaEditor.DefineIcon("italic", { NAME: "italic", template: "my_fa" });
FroalaEditor.DefineIcon("underline", { NAME: "underline", template: "my_fa" });
FroalaEditor.DefineIcon("fontFamily", { NAME: "font", template: "my_fa" });
FroalaEditor.DefineIcon("textColor", { NAME: "palette", template: "my_fa" });
FroalaEditor.DefineIcon("backgroundColor", { NAME: "paintbrush", template: "my_fa" });
FroalaEditor.DefineIcon("moreRich", { NAME: "ellipsis-vertical", template: "my_fa" });
FroalaEditor.DefineIcon("insertTable", { NAME: "table", template: "my_fa" });
FroalaEditor.DefineIcon("insertImage", { NAME: "image", template: "my_fa" });
FroalaEditor.DefineIcon("insertLink", { NAME: "link", template: "my_fa" });
FroalaEditor.DefineIcon("insertHR", { NAME: "minus", template: "my_fa" });
FroalaEditor.DefineIcon("html", { NAME: "code", template: "my_fa" });
FroalaEditor.DefineIcon("remove", { NAME: "arrow-rotate-left", template: "my_fa" });
FroalaEditor.DefineIcon("linkBack", { NAME: "arrow-left", template: "my_fa" });
FroalaEditor.DefineIcon("lineHeight", { NAME: "line-height", template: "my_fa" });
FroalaEditor.DefineIcon("quote", { NAME: "block-quote", template: "my_fa" });

// Link edit icons
FroalaEditor.DefineIcon("linkOpen", { NAME: "arrow-up-right-from-square", template: "my_fa" });
FroalaEditor.DefineIcon("linkEdit", { NAME: "pen-to-square", template: "my_fa" });
FroalaEditor.DefineIcon("linkRemove", { NAME: "link-slash", template: "my_fa" });

// Image edit icons
FroalaEditor.DefineIcon("imageCaption", { NAME: "image-polaroid", template: "my_fa" });
FroalaEditor.DefineIcon("imageRemove", { NAME: "eraser", template: "my_fa" });
FroalaEditor.DefineIcon("imageDisplay", { NAME: "chart-tree-map", template: "my_fa" });
FroalaEditor.DefineIcon("imageAlt", { NAME: "message-text", template: "my_fa" });

// Table edit icons
FroalaEditor.DefineIcon("tableRemove", { NAME: "eraser", template: "my_fa" });
FroalaEditor.DefineIcon("tableRows", { NAME: "table-rows", template: "my_fa" });
FroalaEditor.DefineIcon("tableColumns", { NAME: "table-columns", template: "my_fa" });
FroalaEditor.DefineIcon("tableCellBackground", { NAME: "fill", template: "my_fa" });

// custom button used as vertical divider
FroalaEditor.DefineIconTemplate("custom_element", `<span class="custom-[NAME]"></span>`);
FroalaEditor.DefineIcon("pipe", { NAME: "pipe", template: "custom_element" });
FroalaEditor.RegisterCommand("horizontalDivider", {
  title: "",
  icon: "pipe",
  class: "horizontal-divider",
  focus: false,
  showOnMobile: true,
});

export default FroalaEditor;

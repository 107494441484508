import React, { FC } from "react";
import { completionScreenContainer } from "./styles";
import { Course } from "types/entities";
import CompletionHeader from "./components/CompletionHeader";
import CompletionRewards from "./components/CompletionRewards";
import CompletionOptions from "./components/CompletionOptions";
import PublicCourseSignUp from "./components/PublicCourseSignUp";
import { useApplyTranslations } from "@hooks";
import { Link } from "@components/ReusableComponents";
import { URLS } from "@constants/urls";

type CompletionScreenProps = {
  course: Course;
  isPublic?: boolean;
};

const CompletionScreen: FC<CompletionScreenProps> = ({ course, isPublic = false }) => {
  const { t } = useApplyTranslations();
  const { progress, id } = course;
  const { score, completion_status = "failed" } = progress ?? {};
  const isCompleted = completion_status === "completed";
  const courseId = id.toString();
  const backLink = isPublic
    ? URLS.externalCatalog.createCourseLink({ courseId })
    : URLS.user.createCourseLink({ courseId });

  return (
    <article className="unit-wrapper">
      <div className="unit-container" css={completionScreenContainer}>
        <CompletionHeader isCompleted={isCompleted} />
        <CompletionRewards isCompleted={isCompleted} score={score ?? null} />

        {!isPublic ? (
          <CompletionOptions courseId={course.id} progress={progress} />
        ) : (
          <PublicCourseSignUp course={course} />
        )}

        {!isPublic && <Link to={backLink}>{t("general.backToStart")}</Link>}
      </div>
    </article>
  );
};

export default CompletionScreen;

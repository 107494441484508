import { useEffect, useState } from "react";

const useUrlFromContent = (url: string | null, content: string | null): string => {
  const [src, setSrc] = useState("");

  useEffect(() => {
    if (url) {
      setSrc(url);
    } else {
      const $el = document.createElement("div");
      $el.innerHTML = content ?? "";
      const $iframe = $el.querySelector("iframe");
      const src = $iframe?.getAttribute("src") as string;
      setSrc(src);
    }
  }, [url, content]);

  return src;
};

export default useUrlFromContent;

import React, { FC, HTMLAttributes } from "react";
import { Tag } from "@epignosis_llc/gnosis";
import { infoTagStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";

type InfoTagProps = HTMLAttributes<HTMLSpanElement> & {
  onClick?: () => void;
  canEditOptions?: boolean;
};

const InfoTag: FC<InfoTagProps> = ({ onClick, canEditOptions = false, children, ...rest }) => {
  const shouldShowEdit = Boolean(onClick) && canEditOptions;

  return (
    <Tag
      onClick={onClick}
      css={(theme): SerializedStyles => infoTagStyles(theme, { shouldShowEdit })}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default InfoTag;

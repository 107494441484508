import { useEffect } from "react";

const useScrollToTop = (depArr: unknown[]): void => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...depArr]);
};

export default useScrollToTop;

import Color from "color";
import { PalletThemeColors, getProtectedColor } from "@styles/utils";
import { GnosisTheme } from "@epignosis_llc/gnosis";

export default (colors: PalletThemeColors): GnosisTheme["formElements"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    generic: {
      required: colors.red.base,
      black: colors.black,
    },
    errors: {
      errorBorderColor: colors.red.base,
    },
    input: {
      placeholderColor: colors.black,
      borderHoverColor: protectColorContrast("darker", colors.primary.light),
      borderFocusColor: protectColorContrast("darkest", colors.primary.base),
      inputBorderColor: colors.secondary.lighter,
      background: colors.secondary.lighter,
      backgroundFocus: colors.white,
      iconColor: colors.black,
      iconHoverColor: colors.secondary.base,
      hoverColor: colors.secondary.lighter,
      disabledColor: colors.secondary.base,
      disabledBackground: colors.secondary.lighter,
      disabledBorder: colors.secondary.base,
      disabledIcon: colors.secondary.base,
      borderFocus: protectColorContrast("darker", colors.primary.base),
      infoTooltipColor: protectColorContrast("darker", colors.primary.base),
      verticalLineColor: colors.secondary.base,
      textColor: colors.black,
      textColorFocused: colors.white,
    },
    radioButtonGroup: {
      normalBackground: colors.secondary.lighter,
      normalFontColor: colors.black,
      selectedBackground: colors.primary.base,
      selectedFontColor: colors.white,
      borderColor: Color(colors.secondary.light).alpha(0.5).string(),
    },
    multiSelect: {
      background: Color(colors.primary.lightest).alpha(0.25).string(),
      hoverClose: Color(colors.primary.lighter).alpha(0.5).string(),
      hover: protectColorContrast("darker", colors.secondary.lighter),
    },
    checkbox: {
      partiallySelected: colors.white,
      input: {
        background: protectColorContrast("darker", colors.primary.base),
        borderColor: colors.black,
        shadowColor: protectColorContrast(
          "darker",
          Color(colors.primary.lightest).alpha(0.25).string(),
          0.25,
        ),
      },
      disabled: {
        borderColor: colors.secondary.base,
      },
    },
    generalError: {
      borderColor: colors.red.base,
      background: Color(colors.red.light).alpha(0.15).string(),
    },
    toggleSwitch: {
      hoverDisabledColor: Color(colors.primary.lightest).alpha(0.5).string(),
      backgroundEnabled: colors.primary.base,
      backgroundInlineEnabled: colors.green.base,
      backgroundDisabled: colors.secondary.base,
      backgroundInlineDisabled: Color(colors.primary.lightest).alpha(0.5).string(),
      disabledBackground: colors.secondary.light,
      textColor: colors.white,
      requiredAsteriskColor: colors.red.base,
      subtitleDescription: colors.secondary.darker,
    },
  };
};

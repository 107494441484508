import { yup } from "./validation";
import { t } from "i18next";

export const TicketFormValidationSchema = yup.object().shape({
  subject: yup
    .string()
    .trim()
    .required()
    .max(255, () => t("validationMessages.maxCharacters", { number: 255 })),
  body: yup
    .string()
    .trim()
    .required()
    .max(10000, () => t("validationMessages.maxCharacters", { number: 10000 })),
});

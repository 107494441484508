import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router";
import { AnyObjectSchema } from "yup";
import { detailsTabContainer } from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CatalogOptions from "./Options/CatalogOptions";
import IntroVideoOptions from "./Options/IntroVideoOptions";
import Divider from "@components/ReusableComponents/Divider/Divider";
import { TabHandle, TabProps } from "../../CourseOptionsDrawer";
import CustomFieldsOptions from "./Options/CustomFieldsOptions";
import { EditCourseData } from "@views/CourseEdit/api";
import { CoursesOptionsSchemas } from "@utils/validation/courses/optionsSchemas";
import { useEditCourseDetails } from "@views/CourseEdit/hooks";
import queryKeys from "@constants/queryKeys";
import { getCategories } from "@api";
import { deleteCourseIntroVideo, postCourseIntroVideo } from "@api/courses";
import { URLS } from "@constants/urls";
import { HandledError, handleCourseErrors } from "@errors";
import { CustomField, FormCustomFields } from "types/entities/Common";
import { getCustomFieldSchema, mapFormDataToSubmitData } from "@views/CourseEdit/helpers";
import { handleUploadFilesErrors } from "@errors/errors";
import { createCategoriesWithLevels } from "@views/Categories/helpers";

export type CourseCustomFieldWithValue = CustomField & {
  value: string;
};

const DetailsTab: ForwardRefRenderFunction<TabHandle, TabProps> = (
  { course, onSuccess, onValidChange, onLoadingChange, customFields },
  ref,
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { id, code, category, price, is_hidden_from_catalog, intro_video, policies } = course;
  const { can_update_price = false } = policies ?? {};
  const courseId = id.toString();
  // should not sent price when the policy is false
  const priceAmount = can_update_price ? (price?.amount ? price?.amount : null) : undefined;

  const [videoPreviewFile, setVideoPreviewFile] = useState<File | null>(null);
  const [shouldDeleteVideo, setShouldDeleteVideo] = useState<boolean>(false);

  const customFieldsWithValues: CourseCustomFieldWithValue[] | undefined = customFields?.map(
    (customField) => {
      const value = course?.custom_fields?.find(({ id }) => id === customField.id)?.value ?? "";
      return { ...customField, value };
    },
  );

  const defaultCustomFields = course?.custom_fields?.reduce<FormCustomFields>(
    (object, { name, value }: CourseCustomFieldWithValue) => ({ ...object, [name]: value }),
    {},
  );

  const resolveCustomFieldValidation = (
    customFields: CourseCustomFieldWithValue[] | undefined,
  ): AnyObjectSchema => {
    if (!customFields) return CoursesOptionsSchemas;

    const customFieldsSchema = getCustomFieldSchema(customFields);

    if (customFieldsSchema) {
      return CoursesOptionsSchemas.concat(customFieldsSchema);
    }

    return CoursesOptionsSchemas;
  };

  const customFieldValidation = resolveCustomFieldValidation(customFieldsWithValues);

  const defaultValues = useMemo(() => {
    return {
      code: code ?? null,
      price: priceAmount,
      is_hidden_from_catalog: Boolean(is_hidden_from_catalog),
      intro_video_url: intro_video?.type === "youtube" ? intro_video?.url : null,
      custom_fields: defaultCustomFields,
      category: category
        ? {
            name: category.name,
            id: category.id,
          }
        : null,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const form = useForm<EditCourseData>({
    mode: "onChange",
    resolver: yupResolver(customFieldValidation),
    defaultValues,
  });

  const {
    getValues,
    formState: { isValid },
    reset,
    trigger,
  } = form;

  const getCustomFieldsValues = useCallback(
    (customFields: CustomField[]): FormCustomFields => {
      return customFields?.reduce((acc, item) => {
        const field = course?.custom_fields?.find((customField) => customField.id === item.id);
        const fieldValue =
          field?.type === "dropdown"
            ? item.dropdown_items?.some((item) => item.item === field.value)
              ? field.value
              : ""
            : field?.value;

        const value = item.type !== "checkbox" ? fieldValue ?? "" : fieldValue ?? "off";

        return { ...acc, [item.id]: value };
      }, {});
    },
    [course?.custom_fields],
  );

  const formReset = useCallback(
    (customFields: CustomField[]) => {
      reset({
        ...defaultValues,
        custom_fields: { ...getCustomFieldsValues(customFields) },
      });
    },
    [defaultValues, getCustomFieldsValues, reset],
  );

  useEffect(() => {
    formReset(customFields ?? []);
  }, [formReset, customFields]);

  const { data: categoriesTree } = useQuery(
    [queryKeys.categories.categories],
    () => getCategories(false, ""),
    {
      select: (categories) => ({
        data: categories._data,
        pagination: categories._meta?.pagination,
      }),
    },
  );

  const categoriesWithLevels =
    categoriesTree && categoriesTree.data && createCategoriesWithLevels(categoriesTree.data);

  const { mutateAsync: editCourseDetailsAsyncMutation, isLoading: isLoadingEditCourseDetails } =
    useEditCourseDetails({
      courseId,
      shouldTrackMarketo: true,
      options: {
        onSuccess: () => {
          if (!videoPreviewFile) {
            onSuccess();
          }
        },
        onError: (err) => {
          const error = err as AxiosError;
          const handleError = (): void => {
            navigate(URLS.courses.courses);
          };

          handleCourseErrors(error, false, handleError);
        },
      },
    });

  const { mutateAsync: postIntroVideoMutation, isLoading: isLoadingIntroVideo } = useMutation(
    [queryKeys.courses.postFile, courseId],
    (file: File) => postCourseIntroVideo({ courseId, file }),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error: AxiosError) => {
        const handleError = (error: HandledError | null): void => {
          if (error?.id === "not_found.course_not_found") {
            navigate(URLS.courses.courses);
          }
        };
        handleUploadFilesErrors(error, false, handleError);
      },
    },
  );

  const { mutateAsync: deleteIntroVideoMutation } = useMutation(
    [queryKeys.courses.deleteIntroVideo, courseId],
    () => deleteCourseIntroVideo({ courseId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
      },
      onError: (error: AxiosError) => {
        const handleError = (error: HandledError | null): void => {
          if (error?.id === "not_found.course_not_found") {
            navigate(URLS.courses.courses);
          }
        };
        handleUploadFilesErrors(error, false, handleError);
      },
    },
  );

  const handleSubmit = async (): Promise<void> => {
    const data = mapFormDataToSubmitData(getValues(), customFields);

    if (videoPreviewFile) {
      await postIntroVideoMutation(videoPreviewFile);
    }

    if (shouldDeleteVideo) {
      await deleteIntroVideoMutation();
    }

    await editCourseDetailsAsyncMutation(data);
  };

  // The component instance will be extended with whatever returned from the callback
  useImperativeHandle(ref, () => ({
    handleApply: handleSubmit,
    triggerValidation: trigger,
  }));

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange, customFieldValidation]);

  useEffect(() => {
    onLoadingChange(isLoadingEditCourseDetails || isLoadingIntroVideo);
  }, [isLoadingEditCourseDetails, isLoadingIntroVideo, onLoadingChange]);

  return (
    course && (
      <section css={detailsTabContainer}>
        <CatalogOptions course={course} form={form} categories={categoriesWithLevels} />
        {customFields && customFields.length > 0 && (
          <>
            <Divider />
            <CustomFieldsOptions customFields={customFieldsWithValues} form={form} />
          </>
        )}

        <Divider />
        <IntroVideoOptions
          videoPreviewFile={videoPreviewFile}
          course={course}
          form={form}
          handleFileChanged={setVideoPreviewFile}
          setShouldDeleteVideo={setShouldDeleteVideo}
        />
      </section>
    )
  );
};

export default forwardRef(DetailsTab);

const noScriptSnippet = `
      <iframe src="//www.googletagmanager.com/ns.html?id=GTM-KNT4S7"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

const scriptSnippet = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KNT4S7');`;

type TagManager = {
  initialize: () => void;
};

const useTagManager = (): TagManager => {
  const noScript = (): HTMLElement => {
    const noscript = document.createElement("noscript");
    noscript.innerHTML = noScriptSnippet;
    return noscript;
  };

  const script = (): HTMLElement => {
    const script = document.createElement("script");
    script.innerHTML = scriptSnippet;
    return script;
  };

  const initialize = (): void => {
    document.head.insertBefore(script(), document.head.childNodes[0]);
    document.body.insertBefore(noScript(), document.body.childNodes[0]);
  };

  return { initialize };
};

export default useTagManager;

import { yup } from "./validation";

export const CustomUserFieldSchema = yup.object().shape({
  type: yup.string(),
  dropdown_items: yup
    .array()
    .nullable()
    .when("type", {
      is: "dropdown",
      then: yup
        .array()
        .of(
          yup.object().shape({
            item: yup.string().max(150),
            default: yup.boolean(),
          }),
        )
        .min(1)
        .max(5000),
    }),
  max_length: yup.number().nullable(),
  name: yup.string().required().trim().max(80),
});

import React, { FC, useRef, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import ReactFroalaEditor from "froala-editor";
import { Label } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { useClickAway } from "ahooks";
import PubSub from "pubsub-js";

// Plugins
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/save.min.js";
import "froala-editor/js/plugins/quick_insert.min.js";

// CSS
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Styles
import { EditorStyles } from "./styles";

// Utils
import { insertImageBadLInkMessage } from "./helpers";
import { useLoadScript } from "@hooks";
import { i18n } from "@utils/i18n";

// Other imports
import {
  DEFAULT_CONFIGURATION,
  DEFAULT_IMAGE_INSERT_BUTTONS,
  FONTAWESOME_KIT_URL,
  INSERT_IMAGES_DROPDOWN,
} from "./constants";
import { InsertCustomOptions, ToolbarButton } from "./types";
import "./config";

export type EditorProps = {
  toolbarButtons: string[] | ToolbarButton;
  id: string;
  model: string;
  placeholderText?: string;
  minHeight?: number;
  heightMax?: number;
  label?: string;
  status?: "valid" | "error";
  autofocus?: boolean;
  toolbarInline?: boolean;
  saveInterval?: number;
  required?: boolean;
  insertImagesOptions?: InsertCustomOptions;
  uploadImageSubscriber?: string;
  uploadImageCloseSubscriber?: string;
  smartTagInsertSubscriber?: string;
  smartTagCloseSubscriber?: string;
  quickInsertEnabled?: boolean;
  onChange?: (html: string) => void;
  onBlur?: () => void;
  onSave?: (html: string) => void;
  onCodeViewToggle?: (isActive: boolean) => void;
  onFocus?: (isFocused: boolean) => void;
  onUploadImageButtonClick?: () => void;
  onInsertSmartTagButtonClick?: () => void;
};

const Editor: FC<EditorProps> = ({
  toolbarButtons,
  id,
  placeholderText = "",
  minHeight = 150,
  heightMax,
  label,
  required = false,
  status = "valid",
  model = "",
  autofocus = false,
  toolbarInline = false,
  // Note: It is NOT recommended to use values lower than 2000ms in order to prevent overload
  saveInterval = 0,
  insertImagesOptions,
  uploadImageSubscriber,
  uploadImageCloseSubscriber,
  smartTagInsertSubscriber,
  smartTagCloseSubscriber,
  quickInsertEnabled = false,
  onChange,
  onBlur,
  onSave,
  onCodeViewToggle,
  onFocus,
  onUploadImageButtonClick,
  onInsertSmartTagButtonClick,
}): JSX.Element => {
  const { isLoaded: isFontAwesomeKitLoaded } = useLoadScript(FONTAWESOME_KIT_URL);
  const [isFocused, setIsFocused] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const editorSnapshotRef = useRef<string | null>(null);

  const [isUploadImageOpen, setIsUploadImageOpen] = useState(false);
  const [isInsertSmartTagOpen, setIsInsertSmartTagOpen] = useState(false);

  const hasError = status === "error";
  const hasImageOptions = insertImagesOptions && Object.keys(insertImagesOptions).length > 0;

  // Dynamically show/hide INSERT_IMAGES_DROPDOWN option from insert images buttons
  const imageInsertButtons = !hasImageOptions
    ? DEFAULT_IMAGE_INSERT_BUTTONS
    : [...DEFAULT_IMAGE_INSERT_BUTTONS, INSERT_IMAGES_DROPDOWN];

  if (hasImageOptions) {
    ReactFroalaEditor.DefineIcon("insertImagesDropdownIcon", {
      NAME: "folder-image",
      template: "my_fa",
    });
    ReactFroalaEditor.RegisterCommand(INSERT_IMAGES_DROPDOWN, {
      title: "Insert images dropdown",
      type: "dropdown",
      icon: "insertImagesDropdownIcon",
      options: insertImagesOptions,
      undo: true,
      focus: true,
      refreshAfterCallback: true,
      callback: function (_: string, val: string) {
        this.image.insert(val, false, {}, null);
      },
    });
  }

  if (uploadImageSubscriber) {
    const uploadImageButtonCallback = function (editor: ReactFroalaEditor): void {
      if (!onUploadImageButtonClick) return;

      // trigger save selection in order to apply the proper selection markers in editor's html
      editor.selection.save();

      // take an editor's snapshot, that includes editor's selection info
      editorSnapshotRef.current = editor.snapshot.get();

      onUploadImageButtonClick();
      setIsUploadImageOpen(true);

      // Define what happens once the image is uploaded
      const handleImageUpload = (token: string, imageUrl: string): void => {
        // restore the previous saved snapshot
        editor.snapshot.restore(editorSnapshotRef.current);

        // insert the new uploaded image
        editor.image.insert(imageUrl, false, {}, null);

        // reset ref
        editorSnapshotRef.current = null;
        setIsUploadImageOpen(false);

        // Unsubscribe to ensure this callback isn't called multiple times
        PubSub.unsubscribe(token);
      };

      // Subscribe to all events of the given uploadImageSubscriber
      PubSub.subscribe(uploadImageSubscriber, handleImageUpload);

      if (uploadImageCloseSubscriber) {
        // Define what happens on image upload modal close
        PubSub.subscribe(uploadImageCloseSubscriber, () => setIsUploadImageOpen(false));
      }
    };

    ReactFroalaEditor.DefineIcon("uploadImageButtonIcon", {
      NAME: "cloud-arrow-up",
      template: "my_fa",
    });
    ReactFroalaEditor.RegisterCommand("uploadImageButton", {
      title: "Upload image",
      type: "button",
      icon: "uploadImageButtonIcon",
      undo: true,
      focus: true,
      refreshAfterCallback: true,
      callback: function () {
        uploadImageButtonCallback(this);
      },
    });

    if (quickInsertEnabled) {
      ReactFroalaEditor.RegisterQuickInsertButton("uploadImageButton", {
        title: "Upload image",
        icon: "uploadImageButtonIcon",
        callback: function () {
          const editor = this as ReactFroalaEditor;
          uploadImageButtonCallback(editor);
        },
      });
    }
  }

  if (smartTagInsertSubscriber) {
    const insertSmartTagsButtonCallback = (editor: ReactFroalaEditor): void => {
      if (!onInsertSmartTagButtonClick) return;

      // trigger save selection in order to apply the proper selection markers in editor's html
      editor.selection.save();

      // take an editor's snapshot, that includes editor's selection info
      editorSnapshotRef.current = editor.snapshot.get();

      onInsertSmartTagButtonClick();
      setIsInsertSmartTagOpen(true);

      // Define what happens on smart tag selection
      const handleSmartTagInsert = (token: string, smartTag: string): void => {
        // restore the previous saved snapshot
        editor.snapshot.restore(editorSnapshotRef.current);

        // insert the smart tag
        editor.html.insert(smartTag);

        // reset ref
        editorSnapshotRef.current = null;
        setIsInsertSmartTagOpen(false);

        // Unsubscribe to ensure this callback isn't called multiple times
        PubSub.unsubscribe(token);
      };

      // Subscribe to all events of the given handleSmartTagInsert
      PubSub.subscribe(smartTagInsertSubscriber, handleSmartTagInsert);

      if (smartTagCloseSubscriber) {
        // Define what happens on smart tag modal close
        PubSub.subscribe(smartTagCloseSubscriber, () => setIsInsertSmartTagOpen(false));
      }
    };

    ReactFroalaEditor.DefineIcon("insertSmartTagIcon", {
      NAME: "tag",
      template: "my_fa",
    });
    ReactFroalaEditor.RegisterCommand("insertSmartTagButton", {
      title: "Smart tags",
      type: "button",
      icon: "insertSmartTagIcon",
      undo: true,
      focus: true,
      refreshAfterCallback: true,
      callback: function () {
        insertSmartTagsButtonCallback(this);
      },
    });

    if (quickInsertEnabled) {
      ReactFroalaEditor.RegisterQuickInsertButton("insertSmartTagButton", {
        title: "Smart tags",
        icon: "insertSmartTagIcon",
        callback: function () {
          const editor = this as ReactFroalaEditor;
          insertSmartTagsButtonCallback(editor);
        },
      });
    }
  }

  // On click outside fire blur callback
  // Used instead of Froala'a blur event and the reason behind this approach is
  // that image caption is firing blur event as well (probably Froala's bug).
  useClickAway((e) => {
    // Not fire blur when editor is not focused
    if (!isFocused) return;

    const target = e.target as HTMLElement;

    // check if the target is a froala element because,
    // there some editor's pop-ups elements that shouldn't trigger blur
    // eg. insert image and image pop-up
    const isFroalaElement = Array.from(target.classList).some((className) =>
      className.startsWith("fr"),
    );

    // not trigger blur when upload image or insert smart tag modal is opened
    if (isUploadImageOpen || isInsertSmartTagOpen) return;

    if (!isFroalaElement) {
      setIsFocused(false);
      onFocus && onFocus(false);
      onBlur && onBlur();
    }
  }, editorRef);

  // editor handled events
  const events = {
    focus: (): void => {
      setIsFocused(true);
      onFocus && onFocus(true);
    },
    "image.error": (error: { code: number }): void => {
      // bad link error
      if (error.code === 1) {
        const editor = editorRef.current;
        const errorHeading = editor?.querySelector(".fr-image-progress-bar-layer h3");

        if (errorHeading) {
          errorHeading.innerHTML = insertImageBadLInkMessage();
        }
      }
    },
    // Will trigger only if saveInterval value is set > 0
    "save.before": function (html: string): void {
      onSave && onSave(html ?? "");
    },
    "commands.after": function (cmd: string): void {
      if (cmd === "html") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isActive = (this as any)?.codeView?.isActive();
        onCodeViewToggle && onCodeViewToggle(isActive);
      }
    },
    "image.inserted": function (): void {
      // hide loading image popup on image insertion
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any)?.popups?.hideAll();
    },
  };

  // final editor's configuration
  const config = {
    ...DEFAULT_CONFIGURATION,
    imageInsertButtons,
    toolbarButtons: toolbarButtons,
    placeholderText: placeholderText,
    heightMin: minHeight,
    heightMax: heightMax,
    toolbarInline: toolbarInline,
    direction: i18n.dir(),
    autofocus: autofocus,
    saveInterval: saveInterval,
    quickInsertEnabled: quickInsertEnabled,
    events,
  };

  const handleModelChange = (object: string): void => {
    onChange && onChange(object);
  };

  return (
    <div
      id={id}
      ref={editorRef}
      css={(theme): SerializedStyles => EditorStyles(theme, { required, isFocused, hasError })}
    >
      {label && <Label>{label}</Label>}

      {isFontAwesomeKitLoaded && (
        <FroalaEditor
          tag="textarea"
          config={config}
          model={model}
          onModelChange={handleModelChange}
        />
      )}
    </div>
  );
};

export default React.memo(Editor);

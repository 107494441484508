export type Balance = {
  ai_credits: number;
  total_ai_credits: number | null;
  subscription_interval: 1 | 12 | null; //the subscription interval in months
  next_billing_date: string | null;
  ai_credits_type: AiCreditsType;
};

export enum AiCreditsType {
  OnceOff = "talentcraft_ai_once_off",
  Replenishing = "talentcraft_ai_replenishing",
}

export type CreditCatalog = {
  [key: string]: AICatalogItem;
};

export type AICatalogItem = {
  credits: number;
  credits_display: string;
  message: string;
  key: string;
};

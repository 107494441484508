// Packages or third-party libraries
import React, { FC, useRef, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";

// Styles
import { descriptionStyles } from "./styles";

// Hooks
import { useApplyTranslations, useIsOverflow } from "@hooks";

// Other imports
import { RichText } from "types/entities";

type DescriptionProps = {
  description: RichText | null;
};

export const MAX_NUMBER_OF_CHARS = 643;

const Description: FC<DescriptionProps> = ({ description }) => {
  const { t } = useApplyTranslations();

  const { html = "" } = description ?? {};

  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(ref);
  const [showMore, setShowMore] = useState(false);
  const showMoreBtn = isOverflow || showMore;

  if (!html) return null;

  return (
    <div css={(theme): SerializedStyles => descriptionStyles(theme, { showMore })}>
      <div ref={ref} className="description">
        <Text fontSize="md" dangerouslySetInnerHTML={{ __html: html }} />
      </div>

      {showMoreBtn && (
        <Button variant="link" noGutters className="" onClick={(): void => setShowMore(!showMore)}>
          {showMore ? t("general.viewLess") : t("general.viewMore")}
        </Button>
      )}
    </div>
  );
};

export default Description;

import React, { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LazyMotion, domAnimation } from "framer-motion";
import { Loader, Drawer } from "@epignosis_llc/gnosis";
import { main } from "./styles";
import { useIsExternalCatalog, useIsPublicCourse, useScreenSizeAlert } from "@hooks";
import { ScreenSizeAlert } from "@components/ReusableComponents";
import { ExternalHeader } from "@layouts";

const CourseOverviewLayout: FC = () => {
  const isExternalCatalogView = useIsExternalCatalog();
  const isPublicCourse = useIsPublicCourse();

  const shouldShowScreenSizeAlert = useScreenSizeAlert(["administrator", "instructor"]);

  return (
    <div css={main}>
      <div className="main-content-container">
        {(isExternalCatalogView || isPublicCourse) && <ExternalHeader />}
        <LazyMotion features={domAnimation} strict>
          <Drawer.Root />
          <Suspense fallback={<Loader fullScreen />}>
            <main className="main-content-wrapper">
              {shouldShowScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
            </main>
          </Suspense>
        </LazyMotion>
      </div>
    </div>
  );
};

export default CourseOverviewLayout;

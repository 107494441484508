import React, { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card } from "@epignosis_llc/gnosis";
import { ShowIconSVG, PlayUnitSVG } from "@epignosis_llc/gnosis/icons";
import { bodyWrapper } from "../styles";
import { CardHeader, CardBody, CardFooter } from "../components";
import CatalogCourseCardFooter from "./components/CatalogCourseCardFooter";
import { Course } from "types/entities";
import { useIsExternalCatalog } from "@hooks";
import CourseStoreCardFooter from "./components/CourseStoreCardFooter";
import { getCourseLink } from "./helpers";

export type CatalogCardProps = {
  course: Course;
  link?: string;
  isStore?: boolean;
  isCustomHomePage?: boolean;
  isDisabled?: boolean;
};

const CatalogCard: FC<CatalogCardProps> = ({
  isCustomHomePage = false,
  course,
  isStore = false,
  link,
  isDisabled = false,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const isExternalCatalogView = useIsExternalCatalog();
  const { pathname } = useLocation();

  const { Header, Body } = Card;
  const { id: courseId, mapped_course_id } = course;
  const isCourseAcquired = Boolean(mapped_course_id);
  // When availability is null, means that the user is not enrolled to the course
  const isUserEnrolled = Boolean(course.availability);
  const courseLink = getCourseLink({
    link,
    isExternalView: isExternalCatalogView,
    courseId: courseId.toString(),
  });

  // Users can be redirected to the course overview page from various places.
  // We need to store the link they came from, using router state params.
  const routeState = {
    isCustomHomePage: isCustomHomePage,
    pathname: pathname ?? "",
  };

  return (
    <Card
      onMouseEnter={(): void => setIsHovering(true)}
      onMouseLeave={(): void => setIsHovering(false)}
    >
      <Header ratio={[3, 2]}>
        <CardHeader
          routeState={routeState}
          course={course}
          isDisabled={isDisabled}
          actions={[]}
          isCustomHomePage={isCustomHomePage}
          courseLink={courseLink}
          icon={isUserEnrolled ? PlayUnitSVG : ShowIconSVG}
        />
      </Header>

      <Link
        to={isDisabled ? "#" : courseLink}
        state={routeState}
        css={bodyWrapper}
        data-testid="card-body"
      >
        <Body>
          <CardBody course={course} isCatalogView className="body-container" />
        </Body>

        <CardFooter>
          {isStore ? (
            <CourseStoreCardFooter isCourseAcquired={isCourseAcquired} />
          ) : (
            <CatalogCourseCardFooter course={course} isHovering={isHovering} />
          )}
        </CardFooter>
      </Link>
    </Card>
  );
};

export default CatalogCard;

export const toolbarButtons = {
  moreText: {
    buttons: [
      "bold",
      "italic",
      "underline",
      "fontFamily",
      "fontSize",
      "textColor",
      "backgroundColor",
      "formatOL",
      "formatUL",
      "align",
      "horizontalDivider",
    ],
    buttonsVisible: 12,
  },
  moreRich: {
    buttons: [
      "paragraphFormat",
      "lineHeight",
      "outdent",
      "indent",
      "quote",
      "insertTable",
      "insertImage",
      "insertLink",
      "insertHR",
      "insertSmartTagButton",
      "html",
    ],
    buttonsVisible: 0,
  },
};

export const toolbarButtonsMessage = [
  "bold",
  "italic",
  "underline",
  "formatOL",
  "formatUL",
  "align",
  "indent",
  "outdent",
  "insertLink",
  "insertImage",
];

export const toolbarButtonsMinimal = ["bold", "italic", "underline", "textColor"];

export const COURSE_FILES_DEFAULT_STATE = {
  pagination: { number: 1, size: 10000 },
  sorting: ["name"],
  filters: [{ key: "[use_case]", value: "course.image" }],
};

export const DEFAULT_SAVE_INTERVAL = 30000;
export const COURSE_IMAGE_UPLOAD = "course-image-upload";
export const COURSE_IMAGE_UPLOAD_CLOSE = "course-image-upload-close";
export const SMART_TAG_INSERT = "smart-tag-insert";
export const SMART_TAG_CLOSE = "smart-tag-close";

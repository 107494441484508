import React, { FC } from "react";
import { t } from "i18next";
import ItemSelect from "./ItemSelect";
import { GroupedOption, SelectOption } from "types/common";
import { MassActionEnum } from "../types";

type ModalBodyProps = {
  total: number;
  type: MassActionEnum;
  confirmationBodyTitle: string;
  isCountLoading: boolean;
  shouldTriggerCount: boolean;
  handleItemSelect: (selectedItems: SelectOption[]) => void;
  calculateSelectOptions: () => SelectOption[] | GroupedOption[];
};

const ModalBody: FC<ModalBodyProps> = ({
  total,
  type,
  confirmationBodyTitle,
  isCountLoading,
  shouldTriggerCount,
  handleItemSelect,
  calculateSelectOptions,
}) => {
  const shouldShowConfirmationBodyTitle =
    total > 0 ||
    type === MassActionEnum.add_users_to_group_courses ||
    type === MassActionEnum.remove_users_from_group_courses ||
    type === MassActionEnum.custom_reports_delete_users;
  if (shouldTriggerCount) {
    if (isCountLoading) {
      return <></>;
    }
    return shouldShowConfirmationBodyTitle ? (
      <div dangerouslySetInnerHTML={{ __html: t(confirmationBodyTitle, { count: total }) }} />
    ) : (
      <></>
    );
  }

  return (
    <ItemSelect
      type={type}
      selectOptions={calculateSelectOptions()}
      handleSelect={handleItemSelect}
    />
  );
};

export default ModalBody;

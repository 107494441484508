import { css } from "@emotion/react";

export const StatusTagStyles = ({ courseToc }, color, hasIcon) => css`
  border-radius: 5px;
  padding: ${hasIcon ? "1px 4px" : "2px 4px"};
  text-transform: uppercase;
  color: ${courseToc.edit[color].color} !important;
  background-color: ${courseToc.edit[color].background} !important;
  white-space: nowrap;
  display: flex;
  gap: 0.5rem;
`;

import { DropdownItem, TableProps } from "@epignosis_llc/gnosis";
import { t } from "@utils/i18n";
import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

export const MIN_DURATION_IN_MINUTES = 30;
export const MAX_DURATION_IN_MINUTES = 240;
export const MIN_DURATION_IN_HOURS = 4;
export const MAX_DURATION_IN_HOURS = 24;

export const CONFERENCE_MIN_DURATION_IN_MINUTES = 15;
export const CONFERENCE_INTEGRATED_MAX_DURATION_IN_MINUTES = 240;
export const CONFERENCE_MAX_DURATION_IN_MINUTES = 480;

const SortingValue = {
  SessionDate: "session_date",
  Name: "name",
  Instructor: "instructor",
  RegisteredUsers: "registered_users",
};

const PAGINATION: IPagination = { number: 1, size: 10000 };
const SORTING: TableProps["sorting"] = { column: SortingValue.SessionDate, isDescending: false };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export const ILTS_DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
};

export const getSortingOptions = (): DropdownItem[] => [
  { value: SortingValue.SessionDate, label: t("gradingHub.sessionDate") },
  { value: SortingValue.Name, label: t("general.name") },
  { value: SortingValue.Instructor, label: t("general.instructor") },
  { value: SortingValue.RegisteredUsers, label: t("ilt.registeredUsers") },
];

export const Root = "root";

import { PalletThemeColors, getContrastColor, getProtectedColor, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["sidebarExtended"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    selectedColor: protectColorContrast(
      "darker",
      hexToRGBA(colors.primary.lightest.toString(), 0.4),
      0.4,
    ),
    dynamicTextColor: getContrastColor(colors.primary.base),
    dynamicTextColorOnHover: getContrastColor(colors.primary.base),
    dynamicTextColorOnActive: getContrastColor(colors.primary.darker),
    textColor: colors.black,
    white: colors.white,
    black: colors.black,
    backgroundHover: colors.secondary.lighter,
    backgroundActive: protectColorContrast("darker", colors.primary.darker),
    secondaryBase: colors.secondary.base,
    leaveBetaMenuItemColor: colors.orange.light,
    boxShadow: "rgba(0, 0, 0, 0.16)",
    helpBorderColor: protectColorContrast(
      "darker",
      hexToRGBA(colors.primary.lightest.toString(), 0.25),
      0.25,
    ),
    helpIconBackground: protectColorContrast(
      "darker",
      hexToRGBA(colors.primary.lightest.toString(), 0.25),
      0.25,
    ),
  };
};

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { UNIT_PAGE_LAYOUT_CONTENT_BODY_PADDING } from "./constants.ts";

export const unitPageLayout = css`
  display: flex;
  height: 100%;
  width: 100%;

  @media print {
    /* Enable background graphics for page print */
    * {
      -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 - 15.3, Edge */
      color-adjust: exact !important; /* Firefox 48 - 96 */
      print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
    }
  }
`;

export const unitPageLayoutContent = () => css`
  width: 100%;
  overflow: auto;
  scrollbar-width: none;

  /* 100vh has not constant behavior in mobile browser, not working when units sidebar is sticky */
  position: fixed;
  height: 100%;

  ${mq["lg"]} {
    height: 100vh;
    position: initial;
  }

  /* Styles for TEST RESULTS PRINT */
  @media print {
    height: 100% !important;

    @page :first {
      margin-top: 0;
    }

    @page {
      margin-top: 2rem;
    }
  }
`;

export const unitPageLayoutContentSidebar = () => css`
  min-width: fit-content;

  /* Hide from TEST RESULTS PRINT */
  @media print {
    display: none !important;
  }
`;

export const unitPageLayoutContentHeader = () => css`
  height: 3.5rem;
  min-height: 3.5rem;

  /* Hide from TEST RESULTS PRINT */
  @media print {
    display: none !important;
  }
`;

export const unitPageLayoutContentBody = (noPadding) => css`
  min-height: 100vh;
  width: 100%;
  padding: ${noPadding ? "0" : `${UNIT_PAGE_LAYOUT_CONTENT_BODY_PADDING}`};
  position: relative;
`;

export const unitPageLayoutContentFooter = () => css`
  height: 3.5rem;
  min-height: 3.5rem;

  /* Hide from TEST RESULTS PRINT */
  @media print {
    display: none !important;
  }
`;

// Packages or third-party libraries
import React, { FC } from "react";
import { Dropdown, DropdownItem, Text } from "@epignosis_llc/gnosis";
import { LockIconSVG } from "@epignosis_llc/gnosis/icons";
import classNames from "classnames";
import { SerializedStyles } from "@emotion/react";

// Styles
import { restrictionsStyles } from "./styles";

// Utils, hooks
import { getDropdownOptions } from "./helpers";
import { useApplyTranslations } from "@hooks";
import { useUIStore } from "@stores";

// Other imports
import { CoursePrerequisite } from "types/entities";
import { Restriction } from "./types";
import { courseOptionsIds } from "@views/CourseEdit/components/CourseOptions";
import { TabKey } from "@views/CourseEdit/components/CourseOptions/CourseOptionsDrawer";

type RestrictionsProps = {
  level: number;
  unlocksOnHigherLevel: boolean;
  prerequisites: CoursePrerequisite[];
  startsAt: string | null;
  userAccessExpiresAt: string | null;
  canEditOptions?: boolean;
  isEdit?: boolean;
};

const shouldShowStartRestriction = (startsAt: string | null, isEdit: boolean): boolean => {
  // on course edit show start restriction if there is set an start date
  if (isEdit) return Boolean(startsAt);

  const today = Date.parse(new Date().toISOString());
  return startsAt ? Date.parse(startsAt) >= today : false;
};

const shouldShowExpiredRestriction = (
  userAccessExpiresAt: string | null,
  isEdit: boolean,
): boolean => {
  // on course edit user access expires is not available
  if (isEdit) return false;

  const today = Date.parse(new Date().toISOString());
  return userAccessExpiresAt ? Date.parse(userAccessExpiresAt) < today : false;
};

const Restrictions: FC<RestrictionsProps> = ({
  level,
  unlocksOnHigherLevel,
  prerequisites,
  startsAt,
  userAccessExpiresAt,
  canEditOptions = false,
  isEdit = false,
}) => {
  const { t } = useApplyTranslations();
  const { setCourseOptionsState } = useUIStore();
  const showStartRestriction = shouldShowStartRestriction(startsAt, isEdit);
  const showExpiredRestriction = shouldShowExpiredRestriction(userAccessExpiresAt, isEdit);

  const handleItemSelect = ({ value }: DropdownItem): void => {
    switch (value) {
      case Restriction.Prerequisites: {
        const element = document.getElementById("course-prerequisites");
        element?.scrollIntoView({ behavior: "smooth" });
        return;
      }
      case Restriction.Level:
        if (!canEditOptions) return;

        setCourseOptionsState({
          isOpen: true,
          activeTab: TabKey.Availability,
          scrollId: courseOptionsIds.accessOptions,
        });
        return;
      case Restriction.ExpiresAt:
      case Restriction.StartsAt:
      default: {
        if (!canEditOptions) return;

        setCourseOptionsState({
          isOpen: true,
          activeTab: TabKey.Availability,
          scrollId: courseOptionsIds.timeOptions,
        });
        return;
      }
    }
  };

  const dropdownOptions = getDropdownOptions({
    unlocksOnHigherLevel,
    level,
    hasPrerequisites: prerequisites.length > 0,
    showStartRestriction,
    startsAt,
    showExpiredRestriction,
    userAccessExpiresAt,
    canEditOptions,
  });

  const hasNoRestrictions = dropdownOptions.length === 0;
  const hasOneRestriction = dropdownOptions.length === 1;

  if (hasNoRestrictions) return null;

  if (hasOneRestriction) {
    const isPrerequisitesItem = dropdownOptions[0].value === Restriction.Prerequisites;
    const itemClassNames = classNames("restriction-item", {
      "clickable-item": isPrerequisitesItem || canEditOptions,
    });

    return (
      <div css={(theme): SerializedStyles => restrictionsStyles(theme, { hasOneRestriction })}>
        <div className={itemClassNames} onClick={(): void => handleItemSelect(dropdownOptions[0])}>
          <LockIconSVG height={32} className="restriction-item-icon" />
          <Text fontSize="sm" as="div">
            {dropdownOptions[0].label}
          </Text>
        </div>
        <div />
      </div>
    );
  }

  return (
    <div css={(theme): SerializedStyles => restrictionsStyles(theme, { hasOneRestriction })}>
      <Dropdown list={dropdownOptions} onListItemSelect={handleItemSelect}>
        <div className="restriction-item clickable-item">
          <LockIconSVG height={32} className="restriction-item-icon" />
          <Text fontSize="sm">{t("general.restricted")}</Text>
        </div>
      </Dropdown>
    </div>
  );
};

export default Restrictions;

// Packages or third-party libraries
import React, { FC, useEffect, useRef } from "react";
import { SerializedStyles } from "@emotion/react";
import { Button } from "@epignosis_llc/gnosis";
import { TrashAltSVG, UploadCloudIconSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { CourseImageStyles } from "./styles";

// Components
import { ConfirmationModal, ImageModal } from "@components";

// Utils, hooks
import { buildImageFile, convertBytesToSizeUnit, generalNotification } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { useEditImage } from "./hooks";
import { useConfigurationStore } from "@stores";
import CoursePermissions from "@utils/permissions/courses";

// Other imports
import { images } from "@constants/assets";
import { MimeType } from "types/entities";
import { COURSE_IMAGE_CROP_ASPECT_RATIO } from "@views/CourseEdit/constants";

type CourseImageProps = {
  courseId: string;
  image: string;
};

const CourseImage: FC<CourseImageProps> = ({ courseId, image }) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();

  const [courseImageFile, setCourseImageFile] = React.useState<File | null>(null);
  const [courseImageUrl, setCourseImageUrl] = React.useState("");
  const [isCropModalOpen, setIsCropModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [courseImage, setCourseImage] = React.useState(image);
  const { postImage, deleteImage } = useEditImage(courseId);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { canUpdateCourse } = CoursePermissions;
  const allowUpdateCourse = canUpdateCourse();
  const disabled = !allowUpdateCourse;

  const { mime_types: mimeType, size_limit: sizeLimit } =
    domainSettings?.upload_limits["course.image"] || {};

  const acceptedImageTypes = mimeType ?? ["image/gif", "image/jpeg", "image/png"];
  const maxImageSize = sizeLimit ? convertBytesToSizeUnit(sizeLimit, "MB") : 10;

  useEffect(() => {
    setCourseImage(image);
  }, [image]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const imageFile = e.target.files?.[0];

    if (!imageFile) {
      return;
    }

    if (!acceptedImageTypes.includes(imageFile.type as MimeType)) {
      generalNotification("error", <p>{t("notifications.fileTypeNotSupported")}</p>);
      return;
    }

    const imageSizeInMB = convertBytesToSizeUnit(imageFile.size, "MB");
    if (imageSizeInMB > maxImageSize) {
      generalNotification("error", <p>File size exceeds the “{maxImageSize} MB” limit</p>);
      return;
    }

    setCourseImageFile(imageFile);
    setCourseImageUrl(URL.createObjectURL(imageFile));
    setIsCropModalOpen(true);

    e.target.value = "";
  };

  const handleOnSave = (croppedCanvas: HTMLCanvasElement): void => {
    // Build image based on the cropped canvas size.
    const imageFile = buildImageFile(croppedCanvas, courseImageFile);

    if (imageFile) postImage(imageFile);
    setIsCropModalOpen(false);
  };

  const handleCloseModal = (): void => {
    setCourseImageUrl(image);
    setIsCropModalOpen(false);
  };

  return (
    <>
      <div css={(theme): SerializedStyles => CourseImageStyles(theme, disabled)}>
        <div className="upload-overlay">
          <Button
            rounded
            className="upload-icon"
            onClick={(): void => fileInputRef.current?.click()}
          >
            <UploadCloudIconSVG height={32} />
          </Button>
          {courseImage && (
            <Button
              rounded
              className="delete-icon"
              onClick={(): void => {
                setIsDeleteModalOpen(true);
              }}
            >
              <TrashAltSVG height={16} />
            </Button>
          )}
        </div>
        <img src={courseImage || images.cover} alt={t("courseEdit.courseImageText")} />
        <input
          ref={fileInputRef}
          type="file"
          id="course"
          className="input-field"
          onChange={handleOnChange}
        />
      </div>

      <ImageModal
        isOpen={isCropModalOpen}
        imageSrc={courseImageUrl}
        cropAspectRatio={COURSE_IMAGE_CROP_ASPECT_RATIO}
        imageSelectorTitle={"courseEdit.courseImageText"}
        onClose={handleCloseModal}
        onSave={handleOnSave}
      />

      <ConfirmationModal
        id="delete-image"
        header={t("courseEdit.modal.deleteImageHeader")}
        bodyTitle={t("courseEdit.modal.deleteImageText")}
        bodyText={t("general.actionCantBeUndone")}
        footerButton={t("general.delete")}
        isOpen={isDeleteModalOpen}
        onClose={(): void => setIsDeleteModalOpen(false)}
        onConfirm={(): void => deleteImage()}
      />
    </>
  );
};

export default CourseImage;

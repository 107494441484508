// Packages or third-party libraries
import React, { FC, useCallback, useMemo, useState } from "react";
import { Row, Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";

// Styles
import { assignmentStatusStyles } from "./styles";

// Components
import { CustomTable, TextWithStatus, DateWithTimeTooltip } from "@components";
import SubmissionsActions from "./SubmissionsActions";

// Utils, hooks
import { getFormattedUserName, getIconFromStatus, getTranslationFromStatus } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";

// Other imports
import { SubmissionsTableProps } from "../types";
import { NAME_COL_MAX_WIDTH, actionsColumn, getAssignmentsColumns } from "../constants";

const SubmissionsTable: FC<SubmissionsTableProps> = ({
  data,
  state,
  emptyState,
  tableStatus,
  paginationRes,
  hideColumns = false,
  onRowSelect,
  onSortingChanged,
  onPageChange,
  onPageSizeChange,
  onDownload,
  onGrade,
  onMessage,
  onReset,
  tableRef,
}) => {
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  const { domainSettings } = useConfigurationStore();
  const { sm } = useResponsive();
  const { t } = useApplyTranslations();

  const columns = getAssignmentsColumns(hideColumns);
  const { sorting } = state;
  const showActionsColumn = data.some((item) => Object.values(item.policies ?? []).includes(true));
  const userFormat = domainSettings?.user_format as string;

  const newColumns = useMemo(
    () => (showActionsColumn ? [...columns, actionsColumn] : columns),
    [showActionsColumn, columns],
  );

  const onHoveredRowChange = useCallback((row: Row | null): void => {
    setHoveredRowId(row ? row.id.toString() : null);
  }, []);

  const onRowClick = useCallback(
    (row: Row): void => {
      onGrade(row.id.toString(), true);
    },
    [onGrade],
  );

  const handleDownload = useCallback(
    (assignmentId: string): void => onDownload(assignmentId),
    [onDownload],
  );
  const handleGrade = useCallback((id: string): void => onGrade(id), [onGrade]);
  const handleMessage = useCallback((id: string): void => onMessage(id), [onMessage]);
  const handleReset = useCallback((id: string): void => onReset(id), [onReset]);

  const rows = data.map((entry) => {
    const {
      id,
      user,
      course,
      unit,
      submission_date,
      grade,
      grade_date,
      status,
      policies,
      submission_file,
    } = entry ?? {};

    const { name, surname, login } = user;
    const { name: courseName } = course;
    const { name: unitName } = unit;
    const { can_grade, can_reset_unit_status } = policies;
    const isHovered = hoveredRowId === id.toString() && sm;
    const canDownload = Boolean(submission_file?.url);
    const assignmentId = id.toString();
    const canReset = can_reset_unit_status && status != "pending";

    const userName = getFormattedUserName({
      name,
      surname,
      login,
      userFormat,
    });

    return {
      id,
      user: (): JSX.Element => (
        <TextWithStatus
          content={
            <Text
              fontSize="sm"
              as="div"
              className="overflow-text"
              style={{ maxWidth: NAME_COL_MAX_WIDTH }}
            >
              {userName}
            </Text>
          }
        />
      ),
      course: (): JSX.Element => (
        <TextWithStatus
          content={
            <Text
              fontSize="sm"
              as="div"
              className="overflow-text"
              style={{ maxWidth: NAME_COL_MAX_WIDTH }}
            >
              {courseName}
            </Text>
          }
        />
      ),
      unit: (): JSX.Element => (
        <TextWithStatus
          content={
            <Text
              fontSize="sm"
              as="div"
              className="overflow-text"
              style={{ maxWidth: NAME_COL_MAX_WIDTH }}
            >
              {unitName}
            </Text>
          }
        />
      ),
      submission_date: (): JSX.Element => <DateWithTimeTooltip date={submission_date} />,
      grade: (): JSX.Element => {
        return <span>{grade ?? "-"}</span>;
      },
      grade_date: (): JSX.Element => (
        <div style={{ whiteSpace: "nowrap" }}>
          <DateWithTimeTooltip date={grade_date} />
        </div>
      ),
      status: (): JSX.Element => {
        return (
          <div css={assignmentStatusStyles}>
            {getIconFromStatus(status)}
            <div className="status">{t(getTranslationFromStatus(status))}</div>
          </div>
        );
      },
      actions: (): JSX.Element => (
        <SubmissionsActions
          id={assignmentId}
          isHovered={isHovered}
          canDownload={canDownload}
          canGrade={can_grade}
          canReset={canReset}
          onDownload={handleDownload}
          onGrade={handleGrade}
          onReset={handleReset}
          onSendMessage={handleMessage}
        />
      ),
    };
  });

  return (
    <CustomTable
      tableProps={{
        columns: newColumns,
        rows,
        emptyState,
        sorting,
        selectable: true,
        autohide: true,
        onSortingChanged,
        onHoveredRowChange,
        onRowClick,
        onRowSelect,
      }}
      paginationProps={{
        paginationRes,
        onPageChange,
        onPageSizeChange,
      }}
      tableStatus={tableStatus}
      tableRef={tableRef}
    />
  );
};

export default SubmissionsTable;

// Gnosis theme components
import alert from "./alert";
import badge from "./badge";
import button from "./button";
import card from "./card";
import chip from "./chip";
import drawer from "./drawer";
import dropdown from "./dropdown";
import formElements from "./formElements";
import loader from "./loader";
import modal from "./modal";
import progressBar from "./progressBar";
import result from "./result";
import sidebar from "./sidebar";
import tabs from "./tabs";
import table from "./table";
import tag from "./tag";
import tooltip from "./tooltip";
import pagination from "./pagination";
import scrollbar from "./scrollbar";

import { ColorPaletteTypes } from "@styles/types";
import { GnosisTheme } from "@epignosis_llc/gnosis";

// Initialize the gnosis theme object
export const getGnosisTheme = (
  colors: ColorPaletteTypes,
): Omit<GnosisTheme, "body" | "link" | "typeScaleSizes"> => ({
  alert: alert(colors),
  badge: badge(colors),
  button: button(colors),
  card: card(colors),
  chip: chip(colors),
  drawer: drawer(colors),
  dropdown: dropdown(colors),
  formElements: formElements(colors),
  loader: loader(colors),
  modal: modal(colors),
  progressBar: progressBar(colors),
  result: result(colors),
  sidebar: sidebar(colors),
  tabs: tabs(colors),
  table: table(colors),
  tag: tag(colors),
  tooltip: tooltip(colors),
  pagination: pagination(colors),
  scrollbar: scrollbar(colors),
});

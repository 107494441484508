import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { GlobalStyleVariables } from "@styles";

const { unitsMaxWidth } = GlobalStyleVariables;

export const CourseEditLayoutStyles = css`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
`;

export const MainStyles = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const FooterStyles = ({ courseEdit }) => css`
  border-top: 1px solid ${courseEdit.grayBase};
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 62px;

  ${mq["sm"]} {
    height: 62px;
  }
`;

export const ContentStyles = css`
  padding: 2rem;
  overflow: auto;
  flex: 1;

  &.craft {
    padding: 0;
    overflow: hidden;

    .content-wrapper {
      height: 100%;
    }
  }

  // This should be the first element in the content
  .content-wrapper {
    margin: auto;
    max-width: ${unitsMaxWidth};

    &.full-width {
      max-width: 100%;
    }
  }

  .complete-with-question-container {
    .line {
      margin: 2rem 0;
    }
  }
`;

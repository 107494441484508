import React, { FC } from "react";
import { Heading } from "@epignosis_llc/gnosis";
import { errorContainer } from "./styles";

const WidgetErrorMsg: FC = () => {
  return (
    <div css={errorContainer}>
      <Heading>Loading failed</Heading>
      <div className="text" data-testid="details">
        <p>Something went wrong and we were unable to fetch this data</p>
        <p>Refresh your browser to try again</p>
      </div>
    </div>
  );
};

export default WidgetErrorMsg;

import React, { FC } from "react";
import { iltSessionsContainerStyles } from "./styles";

type IltSessionContainerProps = {
  children?: React.ReactNode;
};

const IltSessionContainer: FC<IltSessionContainerProps> = ({ children }) => (
  <div css={iltSessionsContainerStyles}>{children}</div>
);

export default IltSessionContainer;

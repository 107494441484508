import { ReorderActions } from "./constants";
import { ReorderActionType, SectionReorderState } from "./types";
import { MyUnit, Section } from "types/entities";

// create an array with section and their units in the same level
export const flatsUnitsReducer = (
  array: Array<Section | MyUnit>,
  section: Section,
): Array<Section | MyUnit> => {
  return array
    .concat(section)
    .concat(section.units ?? [])
    .filter((unit) => unit.id);
};

// reducer for full reordering of sections
export const sectionReorderReducer = (
  state: SectionReorderState,
  action: ReorderActionType,
): SectionReorderState => {
  const { type, payload } = action;

  switch (type) {
    case ReorderActions.setSection: {
      return payload;
    }
    case ReorderActions.unsetSection: {
      return { selectedSection: null, selectedUnits: [] };
    }
    case ReorderActions.removeUnit: {
      const newUnits = [...state.selectedUnits.filter((unitId) => unitId !== payload.unitId)];
      return { ...state, selectedUnits: newUnits };
    }
    case ReorderActions.addUnit: {
      const sectionUnits = [...(state.selectedSection?.units?.map((unit) => unit.id) ?? [])];
      const newUnits = [...state.selectedUnits.concat([payload.unitId])];

      // sort unit to keep the same order with the original section's units
      newUnits.sort(function (a, b) {
        return sectionUnits.indexOf(a) - sectionUnits.indexOf(b);
      });

      return { ...state, selectedUnits: newUnits };
    }
    default: {
      return state;
    }
  }
};

import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { Link } from "react-router-dom";

export type LinkTextProps = {
  text: string;
  url: string;
  isLink?: boolean;
};

const LinkText: FC<LinkTextProps> = ({ text, url, isLink = false }) => {
  return (
    <div className="url-container">
      <Text fontSize={"sm"} weight="700">
        {`${text}:`}
      </Text>
      {isLink ? (
        <Link to={url} className="url-wrapper">
          <Text fontSize={"sm"}>{url}</Text>
        </Link>
      ) : (
        <Text className="url-wrapper" fontSize={"sm"}>
          {url}
        </Text>
      )}
    </div>
  );
};

export default LinkText;

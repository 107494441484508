import React, { FC, useMemo } from "react";
import {
  ActionsContainer,
  GradeAction,
  OpenedMessageAction,
  ResetAction,
  UnenrollAction,
} from "@components";
import { useApplyTranslations } from "@hooks";

type IltSessionsActionsProps = {
  id: string;
  isHovered: boolean;
  canGrade: boolean;
  canReset: boolean;
  canUnregister: boolean;
  onGrade: (id: string) => void;
  onSendMessage: (id: string) => void;
  onReset: (id: string) => void;
  onUnregister: () => void;
};

const IltSessionsActions: FC<IltSessionsActionsProps> = ({
  id,
  isHovered,
  canGrade,
  canReset,
  canUnregister,
  onGrade,
  onSendMessage,
  onReset,
  onUnregister,
}): JSX.Element => {
  const { t } = useApplyTranslations();

  const actions = useMemo(
    () => [
      canGrade && (
        <GradeAction
          key="grade-ilt"
          data-testid="grade-ilt"
          onClick={(e): void => {
            e.stopPropagation();
            onGrade(id);
          }}
        />
      ),
      <OpenedMessageAction
        tooltipContent={t("general.message")}
        key="forward-message-ilt"
        data-testid="forward-message-ilt"
        onClick={(e): void => {
          e.stopPropagation();
          onSendMessage(id);
        }}
      />,
      canReset && (
        <ResetAction
          key="reset-session"
          data-testid="reset-session"
          onClick={(e): void => {
            e.stopPropagation();
            onReset(id);
          }}
        />
      ),
      canUnregister && (
        <UnenrollAction
          key="unregister-from-session"
          data-testid="unregister-from-session"
          tooltipContent={t("ilt.removeFromSession")}
          onClick={(e): void => {
            e.stopPropagation();
            onUnregister();
          }}
        />
      ),
    ],
    [canGrade, canReset, canUnregister, id, onGrade, onReset, onSendMessage, t, onUnregister],
  );

  return <ActionsContainer actions={actions} isHovered={isHovered} />;
};

export default IltSessionsActions;

// Packages or third-party libraries
import React, { FC } from "react";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Components
import { ActionDrawer } from "@components";
import { MassActionType } from "@components/ReusableComponents";
import MassActionGradingFooter from "./MassActionGradingFooter";
import GradeTab from "@components/Submissions/components/Tabs/GradeTab";

// Utils, hooks
import { generalNotification } from "@utils/helpers";
import { gradeValidationSchema } from "@utils/validation/gradeSchema";
import { useApplyTranslations } from "@hooks";
import { handleAssignmentErrors } from "@errors/assignmentErrors";

// Other imports
import { MassActionEnum, MassActionParam } from "@components/ReusableComponents/MassActions/types";
import { CountMassActionResponse, MassActionResultResponse } from "types/responses";
import { GradeFormData, SubmissionStatus } from "types/entities/Assignments";
import { DrawerSize } from "types/common";
import queryKeys from "@constants/queryKeys";

type MassActionGradingDrawerProps = {
  isDrawerOpen: boolean;
  title?: string;
  size?: DrawerSize;
  hasBackButton?: boolean;
  countRequest: (type: MassActionType, data: MassActionParam) => Promise<CountMassActionResponse>;
  massActionRequest: (
    type: MassActionType,
    data: MassActionParam,
  ) => Promise<MassActionResultResponse>;
  onCloseDrawer: () => void;
  invalidateSubmissionTable: () => void;
  cleanState: () => void;
};

const MassActionGradingDrawer: FC<MassActionGradingDrawerProps> = ({
  isDrawerOpen,
  title,
  size = "md",
  hasBackButton = false,
  countRequest,
  massActionRequest,
  onCloseDrawer,
  invalidateSubmissionTable,
  cleanState,
}) => {
  const { t } = useApplyTranslations();

  const { mutate: massActionCountMutation, isLoading: isGradeCountLoading } = useMutation(
    [queryKeys.courseUnenroll],
    (data: MassActionParam) => countRequest(MassActionEnum.grade, data),
    {
      onSuccess: async (res, data): Promise<void> => {
        const { chunks, total } = res._data;

        for (let i = 0; i < chunks; i++) {
          await massActionRequest(MassActionEnum.grade, data);
        }

        handleMassActionSuccess(total);
      },
      onError: (error: AxiosError) => {
        handleAssignmentErrors(error);
      },
    },
  );

  const form = useForm<GradeFormData>({
    mode: "onChange",
    resolver: yupResolver(gradeValidationSchema),
    defaultValues: {
      comments: null,
      status: undefined,
    },
  });

  const { handleSubmit, setValue } = form;

  const handleGrade = async (status: SubmissionStatus): Promise<void> => {
    setValue("status", status);
    const handleClick = handleSubmit((data) => {
      const newData = { ...data, status, comments: data.comments ?? undefined };
      massActionCountMutation(newData);
    });
    await handleClick();
  };

  const handleMassActionSuccess = (total: number): void => {
    generalNotification(
      "success",
      <p dangerouslySetInnerHTML={{ __html: t("gradingHub.messages.grade.success", { total }) }} />,
    );
    invalidateSubmissionTable();
    cleanState();
    onCloseDrawer();
  };

  return (
    <ActionDrawer
      isOpen={isDrawerOpen}
      headerTitle={title ?? t("assignment.gradeUser")}
      onClose={onCloseDrawer}
      size={size}
      hasBackButton={hasBackButton}
      customFooter={
        <MassActionGradingFooter
          isGradeLoading={isGradeCountLoading}
          handleGrade={handleGrade}
          closeDrawer={onCloseDrawer}
        />
      }
    >
      <GradeTab form={form} isGradeEnabled />
    </ActionDrawer>
  );
};

export default MassActionGradingDrawer;

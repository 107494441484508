import create from "zustand";

type UnitState = {
  isMenuOpen: boolean;
  toggleIsMenuOpen: (show?: boolean) => void;
  canAutoSaveUnit: boolean;
  setCanAutoSaveUnit: (canSave: boolean) => void;
  shouldAllowUpdate: boolean;
  setShouldAllowUpdate: (shouldAllowUpdate: boolean) => void;
  isCourseCompletionModalOpen: boolean;
  setIsCourseCompletionModalOpen: (show: boolean) => void;
  isCourseHeaderSticky: boolean;
  setIsCourseHeaderSticky: (isSticky: boolean) => void;
};

const useUnitStore = create<UnitState>((set, get) => ({
  isMenuOpen: false,
  toggleIsMenuOpen: (show): void =>
    set((state) => ({
      ...state,
      isMenuOpen: show !== undefined ? show : !get().isMenuOpen,
    })),
  canAutoSaveUnit: true,
  setCanAutoSaveUnit: (canSave: boolean): void =>
    set((state) => ({ ...state, canAutoSaveUnit: canSave })),
  shouldAllowUpdate: true,
  setShouldAllowUpdate: (shouldAllowUpdate: boolean): void =>
    set((state) => ({ ...state, shouldAllowUpdate })),
  isCourseCompletionModalOpen: false,
  setIsCourseCompletionModalOpen: (show): void => {
    set((state) => ({ ...state, isCourseCompletionModalOpen: show }));
  },
  isCourseHeaderSticky: false,
  setIsCourseHeaderSticky: (isSticky): void => {
    set((state) => ({ ...state, isCourseHeaderSticky: isSticky }));
  },
}));

export default useUnitStore;

import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";

type UnitTitleProps = {
  name: string;
};

const UnitTitle: FC<UnitTitleProps> = ({ name }) => {
  return (
    <Text fontSize="4xl" as="div" weight="700">
      {name}
    </Text>
  );
};

export default UnitTitle;

import { useUnitStore } from "@stores";
import React, { FC } from "react";
import { Course } from "types/entities";
import { images } from "@constants/assets";

type CoverImageProps = {
  coverImage: Course["cover_image"];
};

const CoverImage: FC<CoverImageProps> = ({ coverImage }) => {
  const { isCourseHeaderSticky } = useUnitStore();
  const imageSrc = coverImage?.default ?? images.cover;

  if (isCourseHeaderSticky) return null;

  return <img src={imageSrc} alt={`${name} cover image.`} className="thumbnail-image" />;
};

export default CoverImage;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const publicHeaderContainer = ({ signInPage }) => css`
  display: flex;
  justify-content: center;
  background-color: ${signInPage.headerBackground};
  width: 100%;
  align-items: center;
  height: 100%;
  max-height: 4.5rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  box-shadow: 0 0 10px ${signInPage.boxShadow};

  &.transparent {
    background-color: transparent;
    margin-bottom: 1.5rem;
    box-shadow: unset;
  }

  &.sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
    margin-bottom: 0;
    transition: background-color 0.3s ease;

    &.white {
      background-color: white;
      box-shadow: 0 0 10px ${signInPage.boxShadow};
    }
  }

  &.show-catalog {
    justify-content: space-between;
  }

  .logo {
    cursor: pointer;

    ${mq["md"]} {
      margin-inline-end: 2rem;
    }

    ${mq["lg"]} {
      margin-inline-start: 1rem;
    }

    img {
      max-height: 38px;

      ${mq["md"]} {
        max-height: 46px;
      }

      ${mq["sm"]} {
        max-width: fit-content;
      }
    }
  }

  ${mq["lg"]} {
    justify-content: flex-start;
  }
`;

// Packages or third-party libraries
import React, { FC, useEffect, useState } from "react";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { useQuery } from "react-query";

// Components
import { Editor } from "@components";
import CourseFileUploadModal from "./CourseFileUploadModal";
import SmartTagsModal from "./SmartTagsModal";

// Utils, hooks
import { buildPaginatedSearchQuery } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { InsertCustomOptions, ToolbarButton } from "@components/FormElements/Editor/types";
import { Course, CourseFile } from "types/entities";
import { SelectOption } from "types/common";
import { getCourseFiles } from "@api/courses";
import { getUnitSmartTags } from "@views/CourseEdit/api";
import queryKeys from "@constants/queryKeys";
import {
  COURSE_FILES_DEFAULT_STATE,
  DEFAULT_SAVE_INTERVAL,
  COURSE_IMAGE_UPLOAD,
  COURSE_IMAGE_UPLOAD_CLOSE,
  SMART_TAG_INSERT,
  SMART_TAG_CLOSE,
  toolbarButtons,
} from "./constants";

type EditableContentProps = {
  course: Course;
  id: string;
  initialContent: string;
  placeholder?: string;
  canEdit: boolean;
  saveInterval?: number;
  activeToolbarButtons?: string[] | ToolbarButton;
  quickInsertEnabled?: boolean;
  onChange?: (newContent: string) => void;
  onContentSave?: (newContent: string) => void;
};

type courseFileMapping = {
  [key: string]: string;
};

// Return previewable version of provided content
// Find all file ids in content and replace them with the file's url
const builtContentForPreview = (content: string, courseFiles: CourseFile[]): string => {
  if (courseFiles.length === 0) return content;
  if (!content) return "";

  const regex = /\[File:#([0-9]{1,})#\]/gi;
  // Find all occurrences of files in the given
  const filesOccurrences: string[] = content.match(regex) ?? [];

  // Array with all files found in content
  const filesMappingObj = filesOccurrences.reduce((object, contentFile) => {
    const fileId = contentFile.match(/#(.*?)#/)?.[1];
    const courseFile = courseFiles.find(({ id }) => id === Number(fileId));

    if (!courseFile) return object;

    const { id, url } = courseFile;
    return { ...object, [id]: url };
  }, {} as courseFileMapping);

  // replace all files occurrences in content with their url
  const newContent = content
    .split(regex)
    .map((str) => {
      const fileId = Number(str);

      // This part of content is not a file id, return the string
      if (!fileId) return str;

      const fileUrl = filesMappingObj[fileId];

      // File id not found in course files, return string with file id
      if (!fileUrl) return `[File:#${fileId}#]`;

      // return the file's url
      return fileUrl;
    })
    .join("");

  return newContent;
};

// Return savable version of provided content
// Find all file urls in content and replace them with the file's id
const builtContentForSave = (content: string, courseFiles: CourseFile[]): string => {
  if (courseFiles.length === 0) return content;
  if (!content) return "";

  // create element and append current content
  const newDiv = document.createElement("div");
  newDiv.innerHTML = content;

  // find all images in the created element
  const images = newDiv.querySelectorAll("img");

  // replace course images url with file id
  images.forEach((image) => {
    const imageUrl = image.src;
    const courseFile = courseFiles.find(({ url }) => url === imageUrl);

    if (courseFile) {
      image.src = `[File:#${courseFile.id}#]`;
    }
  });

  const newContent = newDiv.innerHTML;
  newDiv.remove();

  return newContent;
};

const EditableContent: FC<EditableContentProps> = ({
  course,
  id,
  initialContent,
  placeholder,
  canEdit,
  saveInterval = DEFAULT_SAVE_INTERVAL,
  activeToolbarButtons = toolbarButtons,
  quickInsertEnabled = true,
  onChange,
  onContentSave,
}) => {
  const { t } = useApplyTranslations();
  const { policies } = course;
  const { can_view_course_files = false, can_update_content = false } = policies ?? {};
  const courseId = course.id.toString();
  const searchQuery = buildPaginatedSearchQuery(COURSE_FILES_DEFAULT_STATE);
  const editorPlaceholder = placeholder ?? t("unitEdit.addContent");

  const [isCourseFileUploadModalOpen, setCourseFileUploadModalOpen] = useState(false);
  const [isSmartTagsModalOpen, setSmartTagsModalOpen] = useState(false);

  const { data: filesRes } = useQuery(
    [queryKeys.courses.images, courseId],
    () => getCourseFiles(courseId, searchQuery),
    { enabled: can_view_course_files },
  );

  const courseImages = filesRes?._data ?? [];
  const insertImagesOptions: InsertCustomOptions = courseImages.reduce(
    (object, { url, name }) => ({ ...object, [url]: name }),
    {},
  );

  const { data: smartTags = [] } = useQuery([queryKeys.courses.unitSmartTags], getUnitSmartTags, {
    select: (smartTagsRes) => smartTagsRes._data ?? [],
    enabled: can_update_content,
  });

  const smartTagsSelectOptions: SelectOption[] = smartTags.map(({ value, label }) => ({
    value,
    label,
  }));
  const hasSmartTags = smartTagsSelectOptions.length > 0;

  const [previewContent, setPreviewContent] = useState<string>(() =>
    builtContentForPreview(initialContent, courseImages),
  );
  const [saveContent, setSaveContent] = useState<string>(initialContent);
  const [isCodeViewActive, setIsCodeViewActive] = useState(false);

  const handleContentSave = (): void => {
    onContentSave && onContentSave(saveContent);
  };

  const handleCodeViewChange = (content: string): void => {
    const newPreviewContent = builtContentForPreview(content, courseImages);
    setPreviewContent(newPreviewContent);
    setSaveContent(content);
    onChange && onChange(content);
  };

  const handleTextViewChange = (content: string): void => {
    setPreviewContent(content);
    const newSaveContent = builtContentForSave(content, courseImages);
    setSaveContent(newSaveContent);
    onChange && onChange(newSaveContent);
  };

  const handleImageUploadClick = (): void => {
    setCourseFileUploadModalOpen(true);
  };

  const handleInsertSmartTagClick = (): void => {
    setSmartTagsModalOpen(true);
  };

  // set preview content on image change
  useEffect(() => {
    if (courseImages.length > 0) {
      setPreviewContent(builtContentForPreview(initialContent, courseImages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseImages]);

  // Do not render empty content when cannot edit
  if (!canEdit && !previewContent) return null;

  return (
    <section className="editable-content">
      {!canEdit ? (
        <FroalaEditorView model={previewContent} />
      ) : (
        <>
          <Editor
            toolbarButtons={activeToolbarButtons}
            id={id}
            model={isCodeViewActive ? saveContent : previewContent}
            placeholderText={editorPlaceholder}
            toolbarInline
            minHeight={0}
            saveInterval={saveInterval}
            insertImagesOptions={insertImagesOptions}
            uploadImageSubscriber={COURSE_IMAGE_UPLOAD}
            uploadImageCloseSubscriber={COURSE_IMAGE_UPLOAD_CLOSE}
            smartTagInsertSubscriber={SMART_TAG_INSERT}
            smartTagCloseSubscriber={SMART_TAG_CLOSE}
            quickInsertEnabled={quickInsertEnabled}
            onChange={isCodeViewActive ? handleCodeViewChange : handleTextViewChange}
            onBlur={handleContentSave}
            onSave={handleContentSave}
            onCodeViewToggle={setIsCodeViewActive}
            onUploadImageButtonClick={handleImageUploadClick}
            onInsertSmartTagButtonClick={handleInsertSmartTagClick}
          />

          <CourseFileUploadModal
            isOpen={isCourseFileUploadModalOpen}
            courseId={courseId}
            onClose={(): void => setCourseFileUploadModalOpen(false)}
          />

          {hasSmartTags && isSmartTagsModalOpen && (
            <SmartTagsModal
              isOpen={isSmartTagsModalOpen}
              options={smartTagsSelectOptions}
              onClose={(): void => setSmartTagsModalOpen(false)}
            />
          )}
        </>
      )}
    </section>
  );
};

export default React.memo(EditableContent);

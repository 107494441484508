// Packages or third-party libraries
import React, { FC } from "react";
import { Tag, Text } from "@epignosis_llc/gnosis";
import { UnitCompleteSVG, UnitFailedSVG } from "@epignosis_llc/gnosis/icons";
import { useTheme } from "@emotion/react";

// Styles
import { completionRewardsStyles } from "./styles";

// Hooks
import { useApplyTranslations } from "@hooks";

type CompletionRewardsProps = {
  isCompleted: boolean;
  score: number | null;
};

const CompletionRewards: FC<CompletionRewardsProps> = ({ isCompleted, score }) => {
  const {
    units: {
      completionScreen: { success, fail },
    },
  } = useTheme();
  const { t } = useApplyTranslations();
  const Icon = isCompleted ? UnitCompleteSVG : UnitFailedSVG;
  const color = isCompleted ? success.textColor : fail.textColor;
  const backgroundColor = isCompleted ? success.backgroundColor : fail.backgroundColor;
  const hasScore = score !== null; // null means that we should not show score

  return (
    <section css={completionRewardsStyles}>
      {hasScore && (
        <div className="rewards-container">
          <Tag className="reward-tag" style={{ color, backgroundColor }}>
            <Icon height={32} className="reward-icon" />
            <Text
              fontSize="sm"
              dangerouslySetInnerHTML={{ __html: t("myCourses.yourScoreIs", { score }) }}
            />
          </Tag>
        </div>
      )}
    </section>
  );
};

export default CompletionRewards;

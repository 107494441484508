import React, { Modal } from "@epignosis_llc/gnosis";
import { FC } from "react";
import SignUpFormContainer from "@views/SignUp/SignUpFormContainer";
import { signUpModalBodyContainer } from "@layouts/ExternalLayout/components/styles";

type GuestSignupModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const GuestSignupModal: FC<GuestSignupModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Body css={signUpModalBodyContainer}>
        <SignUpFormContainer isPublic />
      </Modal.Body>
    </Modal>
  );
};

export default GuestSignupModal;

import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";

export const RangeInputStyles = ({ rangeInput }, { disabled }) => css`
  padding: 0.5rem;
  white-space: nowrap;

  .range-container {
    position: relative;
    padding: 0.5rem 0;

    .range-value {
      position: absolute;
      inset-inline-start: 0%;
      top: -12px;
      color: ${disabled ? rangeInput.disabled : "initial"};
    }
  }

  .label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      font-size: ${typeScale["xs"]};
      font-weight: normal;
      font-style: italic;
      margin: 0;
      color: ${disabled ? rangeInput.disabled : "initial"};
    }
  }

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: ${disabled ? "not-allowed" : "pointer"};
    outline: none;
    border-radius: 5px;
    height: 10px;
    background: ${rangeInput.backgroundColor};

    &:focus {
      outline: none;
    }

    /***** Chrome, Safari, Opera and Edge Chromium styles *****/

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 1rem;
      width: 1rem;
      background: ${disabled ? rangeInput.disabled : rangeInput.color};
      border-radius: 50%;
      cursor: ${disabled ? "not-allowed" : "pointer"};
    }

    /******** Firefox styles ********/

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 1rem;
      width: 1rem;
      background: ${disabled ? rangeInput.disabled : rangeInput.color};
      border-radius: 50%;
      cursor: ${disabled ? "not-allowed" : "pointer"};
    }
  }
`;

import React, { FC, MouseEvent } from "react";
import { DownloadIconSVG } from "@epignosis_llc/gnosis/icons";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { useApplyTranslations } from "@hooks";

export type DownloadActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => Promise<void> | void;
};

const DownloadAction: FC<DownloadActionProps> = ({
  tooltipContent,
  icon: Icon = DownloadIconSVG,
  iconHeight = 32,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.actions.download")}
      onClick={onClick}
      data-testid="download-action"
      aria-label={t("general.actions.download")}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default DownloadAction;

import { currencyMapping } from "@utils/helpers/currency";
import { dateFormatsMapping, timeFormatsMapping } from "@utils/helpers/date-time";
import {
  userFormatsMapping,
  signUpOptionsMapping,
  verificationOptionsMapping,
  getFormattedOptionByName,
  getFormattedLocale,
  getFormattedTimezoneOffset,
  integrationTypesOptionsMapping,
  SSOLoginTypeOptionsMapping,
} from "@utils/helpers/view";
import { t } from "@utils/i18n";
import { Timezone, Group, Theme, BadgeSet } from "types/entities";
import { Language } from "types/entities/Domain";
import { AvailableUserType } from "types/entities/User";
import { OptionalParameterType } from "./types";

export const formatLabelSelect = (
  type: string,
  value: string,
  options?: OptionalParameterType,
): string => {
  switch (type) {
    case "currency":
    case "ecommerce.currency":
      return t(currencyMapping[value]);
    case "date_format":
      return dateFormatsMapping[value]?.toUpperCase();
    case "time_format":
      return t(timeFormatsMapping[value]);
    case "user_format":
      return t(userFormatsMapping[value]);
    case "signup":
      return t(signUpOptionsMapping[value]);
    case "user_verification":
      return t(verificationOptionsMapping[value]);
    case "default_user_type":
      return getFormattedOptionByName(value, (options as AvailableUserType[]) ?? []);
    case "default_group":
      return getFormattedOptionByName(value, (options as Group[]) ?? []);
    case "default_locale":
      return getFormattedLocale(value, (options as Language[]) ?? []);
    case "default_timezone":
      return getFormattedTimezoneOffset(value, (options as Timezone[]) ?? []);
    case "theme":
    case "theme_id":
      return getFormattedOptionByName(value, (options as Theme[]) ?? []);
    case "badge_set":
    case "badge_set_id":
      return getFormattedOptionByName(value, (options as BadgeSet[]) ?? []);
    case "integration_type":
      return value === "none"
        ? t(integrationTypesOptionsMapping[value])
        : integrationTypesOptionsMapping[value];
    case "login_type":
      return t(SSOLoginTypeOptionsMapping[value]);
    default:
      return value;
  }
};

// Packages or third-party libraries
import React, { FC, useEffect, useState } from "react";
import { Tooltip, Button, ToggleSwitch } from "@epignosis_llc/gnosis";
import {
  GearIconSVG,
  CourseExitSVG,
  AllUsersSVG,
  FolderSVG,
  PreviewIconSVG,
} from "@epignosis_llc/gnosis/icons";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

// Styles
import { actionsStyles } from "./styles";

// Components
import { ToC } from "@components";
import { CourseOptionsDrawer } from "@views/CourseEdit/components/CourseOptions";
import AICreditsDrawer from "../AICreditsDrawer/AICreditsDrawer";
import AIButton from "./AIButton/AIButton";

// Hooks, stores
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore, useUIStore } from "@stores";

// Constants
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";
import userRoles from "@constants/userRoles";

// Utils
import { getFlatUnits, getUnitIdToContinue, getUnitLink } from "@utils/helpers";
import { handleUserCoursesErrors } from "@errors/errors";

// Other imports
import { Course, Section } from "types/entities";
import { getCourseCustomFields, getMyCourseUnits, resetProgress } from "@api/courses";
import { CourseActiveTab } from "@views/Course/types";
import { UnitEditRouterParams } from "@views/UnitEdit/types";

type TocTopProps = {
  course: Course;
  sections: Section[];
  isOpen: boolean;
};

type LocationState = {
  openCourseOptionsDrawer: boolean;
};

const ToCTop: FC<TocTopProps> = ({ course, sections, isOpen }) => {
  const { unitId: unitIdParam } = useParams() as UnitEditRouterParams;
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const { state } = useLocation();
  const locationState = state as LocationState;

  const {
    courseOptionsState,
    setCourseOptionsState,
    isAICreditsDrawerOpen,
    setIsAICreditsDrawerOpen,
  } = useUIStore();

  const { isOpen: isCourseOptionsDrawerOpen } = courseOptionsState;

  const [shouldTriggerValidation, setShouldTriggerValidation] = useState(false);
  const { policies, id, role_in_course, status } = course;

  // permissions
  const isCourseActive = status === "active";
  const isInstructorInCourse = role_in_course === userRoles.INSTRUCTOR;

  const units = getFlatUnits(sections);

  const selectedUnit = units.find((unit) => unit.id.toString() === unitIdParam);
  const isCraftunit = selectedUnit?.type === "craft";

  const hasAtLeastOneActiveUnit = units.some((unit) => unit.is_active);
  const shouldShowPreview = isInstructorInCourse && isCourseActive && hasAtLeastOneActiveUnit;

  const {
    can_edit = false,
    can_view_rules = false,
    can_update_rules = false,
    can_view_users = false,
    can_view_course_files = false,
  } = policies ?? {};
  const canViewOptions = can_edit || (can_view_rules && can_update_rules);
  const courseId = id.toString();

  const { data: customFields } = useQuery(
    [queryKeys.courses.customFields],
    () => getCourseCustomFields(),
    {
      select: (course) => course._data,
    },
  );

  const handleBackBtnClick = (): void => {
    navigate(URLS.courses.courses);
  };

  const handleUsersBtnClick = (): void => {
    navigate(URLS.courses.getSingleCourse({ courseId, activeTab: CourseActiveTab.Users }));
  };

  const handleFilesBtnClick = (): void => {
    navigate(URLS.courses.getSingleCourse({ courseId, activeTab: CourseActiveTab.Files }));
  };

  const handleCloseOptionsDrawer = (): void => {
    setCourseOptionsState({ isOpen: false });
    setShouldTriggerValidation(false);
  };

  const handleCloseAIDrawer = (): void => {
    setIsAICreditsDrawerOpen(false);
  };

  // When navigating state has the "openCourseOptionsDrawer" true, open the options drawer
  // to save mandatory course fields
  useEffect(() => {
    if (locationState?.openCourseOptionsDrawer) {
      window.history.replaceState({}, "");
      setCourseOptionsState({ isOpen: true });
      setShouldTriggerValidation(true);
    }
  }, [locationState, setCourseOptionsState]);

  const { userProfileData } = useConfigurationStore();

  const { mutate: resetProgressMutation, isLoading: isResetLoading } = useMutation(
    () => resetProgress(courseId, userProfileData?.id.toString() ?? ""),
    {
      onError: (error: AxiosError) => {
        handleUserCoursesErrors(error);
      },
      onSuccess: async () => {
        const units = await getMyCourseUnits(courseId, true);
        const flatUnits = getFlatUnits(units._data);
        const hasUnits = Boolean(flatUnits.length);
        const continueUnitId = hasUnits ? getUnitIdToContinue(flatUnits) : null;

        const link = getUnitLink({
          courseId,
          unitId: continueUnitId?.toString() ?? "",
          isPublic: false,
          isPreview: true,
        });

        navigate(link);
      },
    },
  );

  const handlePreviewClick = (): void => {
    resetProgressMutation();
  };

  return (
    <>
      <ToC.Top isOpen={isOpen}>
        <div css={actionsStyles}>
          <div className="top-start">
            <Tooltip content={t("general.back")}>
              <Button
                noGutters
                color="primaryLight"
                data-testid="course-back-button"
                onClick={handleBackBtnClick}
              >
                <CourseExitSVG height={32} />
              </Button>
            </Tooltip>

            {isOpen && (
              <>
                {can_view_users && (
                  <Tooltip content={t("general.users")}>
                    <Button
                      noGutters
                      color="primaryLight"
                      data-testid="course-users-button"
                      onClick={handleUsersBtnClick}
                    >
                      <AllUsersSVG height={32} />
                    </Button>
                  </Tooltip>
                )}

                {can_view_course_files && (
                  <Tooltip content={t("general.filesUpper")}>
                    <Button
                      noGutters
                      color="primaryLight"
                      data-testid="course-files-button"
                      onClick={handleFilesBtnClick}
                    >
                      <FolderSVG height={32} />
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
          </div>

          {isOpen && (
            <div className="top-end">
              {isCraftunit && (
                <AIButton text="AI" onClick={(): void => setIsAICreditsDrawerOpen(true)} />
              )}
              {canViewOptions && (
                <Tooltip content={t("courseEdit.courseOptions")}>
                  <Button
                    noGutters
                    color="primaryLight"
                    data-testid="course-options-button"
                    onClick={(): void => setCourseOptionsState({ isOpen: true })}
                  >
                    <GearIconSVG height={32} />
                  </Button>
                </Tooltip>
              )}
              {shouldShowPreview && (
                <Tooltip content={t("general.preview")}>
                  <ToggleSwitch
                    defaultChecked={false}
                    size="md"
                    variant="success"
                    onChange={handlePreviewClick}
                    InternalIcon={<PreviewIconSVG height={32} />}
                    isDisabled={isResetLoading}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </ToC.Top>

      {isCourseOptionsDrawerOpen && (
        <CourseOptionsDrawer
          customFields={customFields}
          course={course}
          sections={sections}
          isOptionsDrawerOpen={isCourseOptionsDrawerOpen}
          shouldTriggerValidation={shouldTriggerValidation}
          onOptionsDrawerClose={handleCloseOptionsDrawer}
        />
      )}

      {isAICreditsDrawerOpen && (
        <AICreditsDrawer isOpen={isAICreditsDrawerOpen} onClose={handleCloseAIDrawer} />
      )}
    </>
  );
};

export default ToCTop;

import { Course, MyUnit, Section, UnitType } from "types/entities";

export enum UnitActions {
  SectionReorder = "section-reorder",
  UnitUnPublish = "unit-unpublish",
  Delete = "delete",
  SectionRename = "section-rename",
  UnitRename = "unit-rename",
  UnitOptions = "unit-options",
  UnitPublish = "unit-publish",
  UnitClone = "unit-clone",
  UnitPrint = "unit-print",
  UnitVersionHistory = "unit-version-history",
  Delay = "section-delay",
}

export type SectionDropdownOptions = {
  coursePolicies: Course["policies"];
  sectionPolicies: Section["policies"];
  isReorderingSection: boolean;
};

export type UnitDropdownOptions = {
  unitPolicies: MyUnit["policies"];
  courseIsActive: boolean;
  unitIsActive: boolean;
  unitType: UnitType | null;
  hasUnpublishedChanges: boolean;
  shouldAllowUpdate: boolean;
  isSelected: boolean;
  courseIsLocked: boolean;
  roleInCourse?: "instructor" | "learner";
};

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const modalFooter = css`
  text-align: start;

  .cancel-btn {
    margin-inline-start: 1rem;
  }
`;

export const imageEditorContainer = ({ imageEditor }) => css`
  .edit-options {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    ${mq["md"]} {
      flex-direction: row;
    }

    .zoom-options {
      display: flex;
      align-items: center;

      input {
        width: 12rem;
      }
    }

    .rotate-options {
      display: flex;

      .vertical-icon {
        padding: 0 1rem;

        svg {
          transform: rotate(90deg);
        }
      }

      .horizontal-icon {
        padding: 0 1rem;
      }
    }

    .slider {
      -webkit-appearance: none;
      width: 100%;
      border-radius: 10px;
      height: 12px;
      background: ${imageEditor.background};
      outline: none;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: ${imageEditor.thumb};
      cursor: pointer;
    }

    .slider::-moz-range-thumb {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: ${imageEditor.thumb};
      cursor: pointer;
    }
  }
`;

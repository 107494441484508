import React, { FC } from "react";
import { unitContentStyles } from "./styles";

type UnitTitleProps = {
  content: string;
};

const UnitContent: FC<UnitTitleProps> = ({ content }) => {
  return (
    <div
      className="fr-view"
      css={unitContentStyles}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default UnitContent;

import { FunctionComponent, SVGProps } from "react";
import {
  AustralianDollarSVG,
  BrazilianRealRSVG,
  CanadianDollarSVG,
  ChineseRenmenbiSVG,
  CzechKorunaSVG,
  DollarSVG,
  EuroSVG,
  HongKongDollarSVG,
  HungarianForintSVG,
  IsraeliShekelSVG,
  JapaneseYenSVG,
  KroneSVG,
  MalaysianRinggitSVG,
  MexicanPesoSVG,
  NewTaiwanDollarSVG,
  PhilippinePesoSVG,
  PolishZlotySVG,
  PoundSterlingSVG,
  SingaporeDollarSVG,
  SwissFrancSVG,
  ThaiBahtSVG,
} from "@epignosis_llc/gnosis/icons";
import { t } from "@utils/i18n";
import { SelectOption } from "types/common";

export const getCurrencySymbol = (
  currency: string | null,
): FunctionComponent<SVGProps<SVGSVGElement>> => {
  switch (currency) {
    case "AUD":
      return AustralianDollarSVG;
    case "BRL":
      return BrazilianRealRSVG;
    case "CAD":
      return CanadianDollarSVG;
    case "CNY":
      return ChineseRenmenbiSVG;
    case "CZK":
      return CzechKorunaSVG;
    case "EUR":
      return EuroSVG;
    case "HKD":
      return HongKongDollarSVG;
    case "HUF":
      return HungarianForintSVG;
    case "ILS":
      return IsraeliShekelSVG;
    case "JPY":
      return JapaneseYenSVG;
    case "MYR":
      return MalaysianRinggitSVG;
    case "MXN":
      return MexicanPesoSVG;
    case "TWD":
      return NewTaiwanDollarSVG;
    case "PHP":
      return PhilippinePesoSVG;
    case "PLN":
      return PolishZlotySVG;
    case "GBP":
      return PoundSterlingSVG;
    case "SGD":
      return SingaporeDollarSVG;
    case "CHF":
      return SwissFrancSVG;
    case "THB":
      return ThaiBahtSVG;
    case "SEK":
    case "NOK":
    case "DKK":
      return KroneSVG;
    case "USD":
    case "NZD":
    default:
      return DollarSVG;
  }
};

export const currencyMapping = {
  AUD: "general.currencies.australianDollar",
  BRL: "general.currencies.brazilianReal",
  CAD: "general.currencies.canadianDollar",
  CZK: "general.currencies.czechKoruna",
  EUR: "general.currencies.euro",
  HKD: "general.currencies.hongKongDollar",
  HUF: "general.currencies.hungarianForint",
  ILS: "general.currencies.israeliShekel",
  JPY: "general.currencies.japaneseYen",
  MYR: "general.currencies.malaysianRinggit",
  MXN: "general.currencies.mexicanPeso",
  TWD: "general.currencies.newTaiwanDollar",
  PHP: "general.currencies.philippinePeso",
  PLN: "general.currencies.polishZloty",
  GBP: "general.currencies.poundSterling",
  SGD: "general.currencies.singaporeDollar",
  CHF: "general.currencies.swissFranc",
  THB: "general.currencies.thaiBaht",
  SEK: "general.currencies.swedishKrona",
  NOK: "general.currencies.norwegianKrone",
  DKK: "general.currencies.danishKrone",
  RUB: "general.currencies.russianRuble",
  TRY: "general.currencies.turkishLira",
  INR: "general.currencies.indianRupee",
  USD: "general.currencies.usDollar",
  NZD: "general.currencies.newZealandDollar",
};

export const currenciesToSelectOptions = (currencyMapping: {
  [key: string]: string;
}): SelectOption[] => {
  return Object.entries(currencyMapping).map(([value, label]) => ({
    label: t(label),
    value,
  }));
};

import { css } from "@emotion/react";

export const dropdownButtonWithArrowContainer = ({ button }, isDropdownOpen, variant) => {
  return css`
    .multiple-option-button {
      white-space: nowrap;
      padding: 4px 4px 4px 16px !important;

      :hover {
        svg {
          fill: ${button.primary.default.color};
        }

        .mass-action-count {
          background: ${button.primary.default.color};
          color: ${button.primary.default.background};
        }
      }

      svg {
        fill: ${variant === "solid"
          ? button.primary.default.color
          : isDropdownOpen
          ? button.primary.default.color
          : button.primary.default.background};
      }

      .btn-text {
        align-items: center;
        gap: 4px;
      }
    }

    .single-option-button {
      white-space: nowrap;
    }
  `;
};

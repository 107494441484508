import { css } from "@emotion/react";

export const tableHeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-wrapper {
    display: flex;
    align-items: center;
    word-break: break-all;
  }

  .secondary-actions-wrapper {
    margin-inline-start: 1rem;
  }
`;

import { AxiosError } from "axios";
import { HandledError, handledApiError } from "./errors";

// Error messages

export const questionErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.invalid_gaps",
    errorMsg: "errors.questions.invalidText",
  },
  {
    status: 400,
    id: "bad_request.invalid_question_pool",
    errorMsg: "errors.questions.invalidQuestionPool",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.unit_not_found",
    errorMsg: "errors.units.notFound",
  },
  {
    status: 404,
    id: "not_found.question_not_found",
    errorMsg: "errors.questions.notFound",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
];

// Error handlers

export const handleQuestionErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, questionErrors, hideNotification, "", handleError);
};

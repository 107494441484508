import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

const containerBorders = (widget) => ({
  primary: `1px solid ${widget.borderColor}`,
  secondary: 0,
});

export const container = ({ widget }, { background }, { showBorder }) => {
  const border = containerBorders(widget);

  return css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: ${showBorder && border[background]};
    border-radius: 5px;
    background: ${widget.background[background]};
    color: ${widget.color};
  `;
};

export const title = ({ widget }) => css`
  h2 {
    margin: 0;
    padding: 1rem;
    color: ${widget.color};

    ${mq["sm"]} {
      padding: 1rem 2rem;
    }
  }
`;

export const loaderContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 3rem;
`;

export const errorContainer = css`
  .text {
    line-height: 1rem;

    p {
      margin-bottom: 12px;
    }
  }
`;

export const contentContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { MessageUnreadSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type MarkAsReadActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const MarkAsReadAction: FC<MarkAsReadActionProps> = ({
  tooltipContent,
  icon: Icon = MessageUnreadSVG,
  iconHeight = 28,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("messages.markAsRead")}
      onClick={onClick}
      data-testid="mark-as-read-action"
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default MarkAsReadAction;

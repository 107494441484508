import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const reverseTrialModalCustomStyles = css`
  background-color: rgba(0, 0, 0, 0.9);
`;

export const trialContainerStyle = ({ reverseTrial }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-bottom: 1.5rem;

  max-height: 60vh;
  overflow: auto;

  ${mq["sm"]} {
    max-height: 65vh;
  }

  ${mq["md"]} {
    max-height: 70vh;
  }

  .reverse-trial-modal {
    &-icon {
      display: flex;
      justify-content: center;
    }

    &-unlock-description {
      align-self: stretch;
    }

    &-rows {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      align-self: stretch;
    }

    &-features-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      flex: 1 0 0;
      margin-inline-start: 0.3rem;
    }

    &-feature {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.625rem;

      &-tick-color {
        color: ${reverseTrial.trialModal.tickColor};
        width: 1.25rem;
        height: 1.25rem;
        flex-shrink: 0;
      }
    }
  }
`;

import { css } from "@emotion/react";

export const sidebarIconsStyles = css`
  display: flex;
  justify-content: end;
  gap: 1rem;

  .shared-icon {
    cursor: pointer;
  }
`;

export const sidebarIconStyles = ({ courseToc }) => css`
  .icon-container {
    height: 2rem;
    width: 2rem;
    border-radius: 5px;
    background-color: ${courseToc.icon.backgroundColor};

    svg {
      color: ${courseToc.icon.color};
    }
  }
`;

import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { useApplyTranslations } from "@hooks";
import { CheckSolidSVG } from "@epignosis_llc/gnosis/icons";

const OwnedAlreadyActions: FC = () => {
  const { t } = useApplyTranslations();

  return (
    <Button block className="footer-btn" color="success">
      <div className="with-icon">
        <CheckSolidSVG height={16} />
        {t("courseCatalog.youHaveCourse")}
      </div>
    </Button>
  );
};

export default OwnedAlreadyActions;

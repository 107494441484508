import React, { FC, useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Grid, Input, InputError, ToggleSwitch } from "@epignosis_llc/gnosis";
import { AccessSVG } from "@epignosis_llc/gnosis/icons";
import { DateInput, TextWithIcon, TimeInput } from "@components";
import { Course } from "types/entities";
import { PressedRectangle } from "@components/ReusableComponents";
import { useConfigurationStore } from "@stores";
import {
  formatSelectedDate,
  getDomainDateFormat,
  getDomainTimeFormat,
  timeZonedDate,
} from "@utils/helpers";
import { add, startOfDay } from "date-fns";
import { CourseAvailabilityFormData } from "@views/CourseEdit/types";
import { useApplyTranslations } from "@hooks";
import { courseOptionsIds } from "../constants";

type TimeOptionsProps = {
  course: Course;
  form: UseFormReturn<CourseAvailabilityFormData>;
};

enum TimeOption {
  Duration = "duration",
  TimeLimit = "timeLimit",
}
const todayStartOfDay = startOfDay(new Date());
const startsAtMinDate = add(todayStartOfDay, { days: 1 });

const TimeOptions: FC<TimeOptionsProps> = ({ course, form }) => {
  const { t } = useApplyTranslations();
  const { userProfileData } = useConfigurationStore();
  const timezone = userProfileData?.timezone ?? "UTC";
  const dateFormat = getDomainDateFormat();
  const timeFormat = getDomainTimeFormat();

  const [timeOptionsType, setTimeOptionsType] = useState<TimeOption | null>(() => {
    const { starts_at, time_limit } = course;
    return time_limit && !starts_at ? TimeOption.TimeLimit : TimeOption.Duration;
  });
  const isDurationSelected = timeOptionsType === TimeOption.Duration;
  const isTimeLimitSelected = timeOptionsType === TimeOption.TimeLimit;

  const { control, watch, setValue, trigger } = form;
  const startsAtDateWatch = watch("starts_at");
  const startsAtDate = startsAtDateWatch ? timeZonedDate(startsAtDateWatch, timezone) : null;
  const expiresAtDateWatch = watch("expires_at");
  const expiresAtDate = expiresAtDateWatch ? timeZonedDate(expiresAtDateWatch, timezone) : null;
  const startsAtMaxDate = expiresAtDate ?? undefined;
  // expires_at minimum date is the date after starts_at at midnight (eg. if starts_at is 2021-10-10 23:30:00, expires_at minimum date is 2021-10-11 00:00:00)
  const expiresAtMinDate = startsAtDate
    ? new Date(startsAtDate.setHours(24, 0, 0, 0))
    : startsAtMinDate;

  const handleTimeOptionSelect = (type: TimeOption): void => {
    if (type === timeOptionsType) return;
    setTimeOptionsType(type);
  };

  // trigger validation when date and time fields change
  useEffect(() => {
    trigger();
  }, [startsAtDateWatch, expiresAtDateWatch, trigger]);

  return (
    <div id={courseOptionsIds.timeOptions}>
      <TextWithIcon icon={<AccessSVG height={32} />} label={t("courseEdit.timeOptions")} />

      <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} className="grid-container">
        <Grid.Item colSpan={1}>
          <PressedRectangle
            data-testid="duration"
            content={t("general.duration")}
            isPressed={isDurationSelected}
            type={TimeOption.Duration}
            onClick={handleTimeOptionSelect}
          />
        </Grid.Item>
        <Grid.Item colSpan={1}>
          <PressedRectangle
            data-testid="time-limit"
            content={t("general.timeLimit")}
            isPressed={isTimeLimitSelected}
            type={TimeOption.TimeLimit}
            onClick={handleTimeOptionSelect}
          />
        </Grid.Item>

        {isDurationSelected && (
          <>
            <Controller
              name="starts_at"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => {
                const startsAt = value ? timeZonedDate(value, timezone) : null;
                return (
                  <>
                    <Grid.Item colSpan={1}>
                      <DateInput
                        id="starts-at-date"
                        value={startsAt}
                        dateFormat={dateFormat}
                        label={t("courseEdit.startDate")}
                        tooltipContent={t("courseEdit.startDateTooltip")}
                        minDate={startsAtMinDate}
                        maxDate={startsAtMaxDate}
                        status={error ? "error" : "valid"}
                        onChange={(selectedDate: Date | null): void =>
                          onChange(formatSelectedDate(selectedDate, timezone))
                        }
                      />
                      {error && <InputError>{error.message}</InputError>}
                    </Grid.Item>
                    <Grid.Item colSpan={1}>
                      <TimeInput
                        id="starts-at-time"
                        value={startsAt}
                        timeFormat={timeFormat}
                        label={t("courseEdit.startTime")}
                        status={error ? "error" : "valid"}
                        onChange={(selectedTime: Date | null): void =>
                          onChange(formatSelectedDate(selectedTime, timezone))
                        }
                      />
                    </Grid.Item>
                  </>
                );
              }}
            />

            <Controller
              name="expires_at"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => {
                const expiresAt = value ? timeZonedDate(value, timezone) : null;
                return (
                  <>
                    <Grid.Item colSpan={1}>
                      <DateInput
                        id="expires-at-date"
                        value={expiresAt}
                        dateFormat={dateFormat}
                        label={t("courseEdit.endDate")}
                        tooltipContent={t("courseEdit.endDateTooltip")}
                        minDate={expiresAtMinDate}
                        status={error ? "error" : "valid"}
                        onChange={(selectedDate: Date | null): void => {
                          onChange(
                            selectedDate ? formatSelectedDate(selectedDate, timezone) : null,
                          );

                          setValue("time_limit", null);
                        }}
                      />
                      {error && <InputError>{error.message}</InputError>}
                    </Grid.Item>
                    <Grid.Item colSpan={1}>
                      <TimeInput
                        id="expired-at-time"
                        value={expiresAt}
                        timeFormat={timeFormat}
                        label={t("courseEdit.endTime")}
                        status={error ? "error" : "valid"}
                        onChange={(selectedTime: Date | null): void =>
                          onChange(formatSelectedDate(selectedTime, timezone))
                        }
                      />
                    </Grid.Item>
                  </>
                );
              }}
            />
          </>
        )}

        {isTimeLimitSelected && (
          <Grid.Item colSpan={1}>
            <Controller
              name="time_limit"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => (
                <>
                  <Input
                    id="time-limit"
                    type="number"
                    value={value ?? ""}
                    min={1}
                    step={1}
                    label={t("courseEdit.numberOfDays")}
                    placeholder={t("courseEdit.numberOfDays")}
                    tooltipContent={t("courseEdit.numberOfDaysInfo")}
                    status={error ? "error" : "valid"}
                    onChange={(e): void => {
                      const timeLimit = e.target.value;
                      onChange(timeLimit ? timeLimit : null);

                      setValue("expires_at", null);
                    }}
                  />
                  {error && <InputError>{error.message}</InputError>}
                </>
              )}
            />
          </Grid.Item>
        )}

        {(isDurationSelected || isTimeLimitSelected) && (
          <Grid.Item colSpan={[1, 1, 1, 2]} className="toggle-option">
            <Controller
              name="retain_access_after_completion"
              control={control}
              render={({ field: { onChange, value } }): JSX.Element => {
                return (
                  <ToggleSwitch
                    data-testid="retain-access"
                    labelAfter={t("courseEdit.retainAccess")}
                    defaultChecked={value}
                    onChange={(): void => onChange(!value)}
                  />
                );
              }}
            />
          </Grid.Item>
        )}
      </Grid>
    </div>
  );
};

export default TimeOptions;

import { css } from "@emotion/react";

export const completionHeaderStyles = ({ units: { completionScreen } }) => css`
  .title-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

    .subtitle {
      color: ${completionScreen.subtitleColor};
    }
  }
`;

export const completionRewardsStyles = css`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .reward-tag {
    padding-block: 0;
    padding-inline-start: 0;

    .reward-icon {
      line-height: 0;
    }
  }
`;

export const completionOptionsStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: 1rem 0;

  .option-card {
    padding-inline: 3.25rem;

    &.linkedin-card {
      padding-inline: 2.25rem;
    }

    .select-card-icon {
      line-height: 0;
    }
  }
`;

export const publicCourseContainer = css`
  text-align: center;
  margin-top: 2rem;

  .sign-up-btn {
    margin: 1rem 0;
  }

  .social-buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;

    .social-link {
      line-height: 0;
    }
  }
`;

// Packages or third-party libraries
import React, { FC, useCallback, useMemo, useState } from "react";
import { Row, Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";

// Styles
import { IltsStatusStyles, UnitSecondaryText } from "../styles";

// Components
import { CustomTable, TextWithStatus, DateWithTimeTooltip } from "@components";
import IltSessionsActions from "./IltSessionsActions";

// Utils, hooks
import { getFormattedUserName, getIconFromStatus, getTranslationFromStatus } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";

// Other imports
import { IltSessionsTableProps } from "../types";
import { NAME_COL_MAX_WIDTH, actionsColumn, getIltSessionsColumns } from "../constants";

const IltSessionsTable: FC<IltSessionsTableProps> = ({
  data,
  state,
  emptyState,
  tableStatus,
  paginationRes,
  tableType,
  isSelectedSessionLinked = false,
  onRowSelect,
  onSortingChanged,
  onPageChange,
  onPageSizeChange,
  onGrade,
  onMessage,
  onReset,
  onUnregister,
  handleExportToCSV,
  tableRef,
}) => {
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  const { domainSettings } = useConfigurationStore();
  const { sm } = useResponsive();
  const { t } = useApplyTranslations();

  const columns = getIltSessionsColumns(tableType, isSelectedSessionLinked);
  const { sorting } = state;
  const showActionsColumn = data.some((item) => Object.values(item.policies ?? []).includes(true));
  const userFormat = domainSettings?.user_format as string;
  const isUsersTable = tableType === "iltSessionsUsers";

  const newColumns = useMemo(
    () => (showActionsColumn ? [...columns, actionsColumn] : columns),
    [showActionsColumn, columns],
  );

  const onHoveredRowChange = useCallback((row: Row | null): void => {
    setHoveredRowId(row ? row.id.toString() : null);
  }, []);

  const onRowClick = useCallback(
    (row: Row): void => {
      onGrade(row.id.toString(), true);
    },
    [onGrade],
  );

  const handleGrade = useCallback((id: string): void => onGrade(id), [onGrade]);
  const handleMessage = useCallback((id: string): void => onMessage(id), [onMessage]);
  const handleReset = useCallback((id: string): void => onReset(id), [onReset]);
  const handleUnregister = useCallback(
    (userId: string): void => onUnregister(userId),
    [onUnregister],
  );

  const rows = data.map((entry) => {
    const {
      id,
      user,
      course,
      unit,
      grade,
      grade_date,
      session_date,
      status,
      policies,
      session_name,
      attendance,
    } = entry ?? {};
    const { can_grade, can_reset_unit_status, can_unregister } = policies ?? {};
    const isHovered = hoveredRowId === id.toString() && sm;
    const canReset = can_reset_unit_status && status != "pending";
    const canUnregister = can_unregister && isUsersTable;

    const { name, surname, login, id: userId } = user;
    const { name: courseName } = course;
    const { name: unitName } = unit;
    const sessionId = id.toString();

    const userName = getFormattedUserName({
      name,
      surname,
      login,
      userFormat,
    });

    return {
      id,
      user: (): JSX.Element => (
        <TextWithStatus
          content={
            <Text fontSize="sm" as="div" className="overflow-text">
              {userName}
            </Text>
          }
        />
      ),
      course: (): JSX.Element => (
        <TextWithStatus
          content={
            <Text
              fontSize="sm"
              as="div"
              className="overflow-text"
              style={{ maxWidth: NAME_COL_MAX_WIDTH }}
            >
              {courseName}
            </Text>
          }
        />
      ),
      unit: (): JSX.Element => (
        <>
          <TextWithStatus
            content={
              <Text
                fontSize="sm"
                as="div"
                className="overflow-text"
                style={{ maxWidth: NAME_COL_MAX_WIDTH }}
              >
                {unitName}
              </Text>
            }
          />
          <TextWithStatus
            content={
              <Text
                style={{ maxWidth: NAME_COL_MAX_WIDTH }}
                className="overflow-text"
                weight="400"
                fontSize="xs"
                as="div"
                css={UnitSecondaryText}
              >
                {session_name}
              </Text>
            }
          />
        </>
      ),
      session_date: (): JSX.Element => <DateWithTimeTooltip date={session_date} />,
      grade_date: (): JSX.Element => (
        <div style={{ whiteSpace: "nowrap" }}>
          <DateWithTimeTooltip date={grade_date} />
        </div>
      ),
      grade: (): JSX.Element => {
        return <span>{grade ?? "-"}</span>;
      },
      status: (): JSX.Element => {
        return (
          <div css={IltsStatusStyles}>
            {getIconFromStatus(status)}
            <div style={{ whiteSpace: "nowrap" }}>{t(getTranslationFromStatus(status))}</div>
          </div>
        );
      },
      attendance: (): JSX.Element => {
        if (!attendance) return <span>-</span>;

        // attended_sessions has min value 0, total_sessions has min value 1
        const { attended_sessions, total_sessions, attended } = attendance;

        if (isSelectedSessionLinked) return <span>{attended ? t("general.yes") : "-"}</span>;

        // single session
        if (total_sessions <= 1) {
          return <span>{attended_sessions ? t("general.yes") : "-"}</span>;
        }

        // multiple sessions
        return <span>{`${attended_sessions}/${total_sessions}`}</span>;
      },
      actions: !isSelectedSessionLinked
        ? (): JSX.Element => (
            <IltSessionsActions
              id={sessionId}
              isHovered={isHovered}
              canGrade={can_grade}
              canReset={canReset}
              canUnregister={canUnregister}
              onSendMessage={handleMessage}
              onGrade={handleGrade}
              onReset={handleReset}
              onUnregister={(): void => handleUnregister(userId.toString())}
            />
          )
        : null,
    };
  });

  return (
    <>
      <CustomTable
        id="ilt-sessions-table"
        key="ilt-sessions-table"
        tableProps={{
          columns: newColumns,
          rows,
          emptyState,
          sorting,
          selectable: !isSelectedSessionLinked,
          autohide: true,
          onSortingChanged,
          onHoveredRowChange,
          onRowClick,
          onRowSelect,
        }}
        paginationProps={{
          paginationRes,
          onPageChange,
          onPageSizeChange,
        }}
        tableStatus={tableStatus}
        handleExportToCSV={handleExportToCSV}
        tableRef={tableRef}
      />
    </>
  );
};

export default IltSessionsTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableStatus } from "@components/CustomTable/types";
import { PaginatedState } from "@hooks/usePaginatedStateReducer";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {
  BadgeSet,
  Branch,
  BranchEcommerce,
  RichText,
  SignUpEnum,
  Theme,
  Image,
  SSOSettings,
} from "types/entities";
import { PaginationRes } from "types/responses";

export type BranchActionFn = (branchId: string) => void;

export type BranchesTableProps = {
  data: Branch[];
  paginationRes: PaginationRes | undefined;
  state: PaginatedState;
  tableStatus: TableStatus;
  handleRestoreDefault: () => void;
  onSortingChanged: (sorting: PaginatedState["sorting"]) => void;
  onPageChange: (value: number) => void;
  onPageSizeChange: (value: number) => void;
  handleBranchDelete: BranchActionFn;
  handleBranchClone: BranchActionFn;
  handleExportToCSV: () => void;
};

export type BranchesFilters = {
  active: "active" | "inactive";
};

export type BasicSettings = {
  title: string;
  name: string;
  hint?: string;
  description?: string;
  placeholder?: string;
  options?: string[];
  control: Control<BranchSettings, any>;
  testId?: string;
};

type CMSPage = {
  active: boolean;
  id: number;
  show_after_login: boolean;
  show_before_login: boolean;
  title: string;
  type: string;
  url: string;
};

type CMSType = {
  id: number;
  legacy_has_cms: boolean;
  pages: CMSPage[];
  published: boolean;
};

export type BranchSettings = {
  id?: number;
  url?: string;
  name: string;
  title: string;
  description: string;
  avatar?: Image | File | string;
  logo?: Image | File | string;
  favicon_uploader?: Image | string | File;
  favicon?: Image | File | string;
  theme?: Theme | string;
  theme_id: string;
  active: boolean;
  default_locale: string;
  default_timezone: string;
  announcement_internal: RichText | string;
  announcement_external: RichText | string;
  default_user_type: DefaultUserType | number;
  default_group: DefaultGroup | string | number;
  signup: SignUpEnum;
  user_verification: UserVerification | "";
  restrict_user_registration_domains: string;
  enabled_restrict_user_registration_domains?: boolean;
  restrict_total_registered_users: number | string;
  enabled_restrict_total_registered_users?: boolean;
  disallow_access_to_main_domain: boolean;
  terms_of_service: RichText | string;
  enforce_terms_of_service?: boolean;
  sso?: SSOSettings;
  cms?: CMSType;
  badge_set?: BadgeSet;
  badge_set_id: number;
  ecommerce?: BranchEcommerce;
  policies?: BranchSettingsPolicies;
};

export type BranchSettingsPolicies = {
  can_delete: boolean;
};

export enum UserVerification {
  NONE = "none",
  CAPTCHA = "captcha",
  CAPTCHA_EMAIL = "captcha_email",
  CAPTCHA_ADMIN = "captcha_admin",
  EMAIL = "email",
  ADMIN = "admin",
}

export type DefaultUserType = {
  id: number;
  name: string;
  is_default: boolean;
};

export type DefaultGroup = {
  id: number;
  name: string;
  description: string;
};

export enum BranchPropertiesNames {
  BRANCH_NAME = "name",
  BRANCH_TITLE = "title",
  BRANCH_DESCRIPTION = "description",
  BRANCH_THEME = "theme",
  BRANCH_AVATAR = "avatar",
  BRANCH_FAVICON_UPLOADER = "favicon_uploader",
  BRANCH_ACTIVE = "active",
  BRANCH_DEFAULT_LOCALE = "default_locale",
  BRANCH_DEFAULT_TIMEZONE = "default_timezone",
  BRANCH_INTERNAL_ANNOUNCEMENT = "announcement_internal",
  BRANCH_EXTERNAL_ANNOUNCEMENT = "announcement_external",
  BRANCH_DEFAULT_USER_TYPE = "default_user_type",
  BRANCH_DEFAULT_GROUP = "default_group",
  BRANCH_SIGNUP = "signup",
  BRANCH_VERIFICATION = "user_verification",
  BRANCH_ENABLED_RESTRICT_REGISTRATION = "enabled_restrict_user_registration_domains",
  BRANCH_RESTRICT_REGISTRATION = "restrict_user_registration_domains",
  BRANCH_ENABLED_RESTRICT_REGISTERED_USERS = "enabled_restrict_total_registered_users",
  BRANCH_RESTRICT_REGISTERED_USERS = "restrict_total_registered_users",
  BRANCH_DISALLOW_ACCESS_TO_MAIN_DOMAIN = "disallow_access_to_main_domain",
  BRANCH_TERMS_OF_SERVICE = "terms_of_service",
  BRANCH_ENFORCE_TERMS_OF_SERVICE = "enforce_terms_of_service",
  BRANCH_ECOMMERCE_CURRENCY = "ecommerce.currency",
  BRANCH_ECOMMERCE_PROCESSOR = "ecommerce.processor",
  BRANCH_CREDITS_ENABLED = "ecommerce.are_credits_enabled",
  BRANCH_ECOMMERCE_PAYPAL_EMAIL = "ecommerce.paypal_email_address",
  BRANCH_SUBSCRIPTION = "ecommerce.subscription",
  BRANCH_SUBSCRIPTION_ENABLED = "ecommerce.subscription.enabled",
  BRANCH_SUBSCRIPTION_HAS_ACTIVE = "ecommerce.subscription.active_subscriptions",
  BRANCH_SUBSCRIPTION_FEE = "ecommerce.subscription.fee",
  BRANCH_SUBSCRIPTION_CURRENCY = "ecommerce.subscription.currency",
  BRANCH_SUBSCRIPTION_INTERVAL = "ecommerce.subscription.interval",
  BRANCH_SUBSCRIPTION_FREE_MONTH = "ecommerce.subscription.free_month",
  BRANCH_BADGE_SET = "badge_set",
  BRANCH_SSO_INTEGRATION_TYPE = "sso.integration_type",
  BRANCH_HOMEPAGE = "cms",
}

export type BranchPropertiesFormProps = {
  isEdit?: boolean;
  setActiveTab?: (activeTab: number) => void;
  branchPropertiesData?: BranchSettings;
  forceRerender?: () => void;
};

export type BranchDrawersProps<T extends FieldValues> = {
  control: Control<T>;
  register?: UseFormRegister<T>;
  errors?: FieldErrors<BranchSettings>;
  watch?: UseFormWatch<T>;
  setValue?: UseFormSetValue<T>;
  getValues?: UseFormGetValues<BranchSettings>;
  resetField?: UseFormResetField<T>;
  isOpen: boolean;
  isDisabled: boolean;
  toggleDrawer: () => void;
};

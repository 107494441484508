// Packages or third-party libraries
import React, { FC } from "react";

// Styles
import { courseContentStyles } from "./styles";

// Components
import CustomFields from "./CustomFields";
import Description from "./Description";
import Certificate from "./Certificate";
import CourseTabs from "./CourseTabs";
import Prerequisites from "./Prerequisites";
import Footer from "./Footer";

// Other imports
import { Course, Section } from "types/entities";

type CourseContentProps = {
  course: Course;
  sections: Section[];
  isCatalogCourseView?: boolean;
  isCourseStore?: boolean;
  isAdminOverview?: boolean;
};

const CourseContent: FC<CourseContentProps> = ({
  course,
  sections,
  isCatalogCourseView = false,
  isCourseStore = false,
  isAdminOverview = false,
}) => {
  const { custom_fields = [], description, certificate, progress, rules } = course;
  const { prerequisites } = rules;
  const { certificate: progressCertificate = null } = progress ?? {};
  const hasCertificate = Boolean(certificate) && !isCourseStore;
  const hasPrerequisites = prerequisites.length > 0 && !isCourseStore;

  return (
    <div css={courseContentStyles}>
      <div className="content-wrapper">
        <div className="description-container has-max-width">
          <CustomFields customFields={custom_fields} />
          <Description description={description} />
        </div>
        {hasCertificate && <Certificate acquiredCertificate={progressCertificate} />}
        <CourseTabs
          course={course}
          sections={sections}
          isCatalogCourseView={isCatalogCourseView}
          isAdminOverview={isAdminOverview}
        />
        {hasPrerequisites && <Prerequisites prerequisites={prerequisites} />}
      </div>
      <Footer course={course} isCatalogCourseView={isCatalogCourseView} />
    </div>
  );
};

export default CourseContent;

import React, { FC, MouseEvent } from "react";
import { GradebookSVG } from "@epignosis_llc/gnosis/icons";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { useApplyTranslations } from "@hooks";

export type GradeActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => Promise<void> | void;
};

const GradeAction: FC<GradeActionProps> = ({
  tooltipContent,
  icon: Icon = GradebookSVG,
  iconHeight = 32,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton tooltipContent={tooltipContent ?? t("general.actions.grade")} onClick={onClick}>
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default GradeAction;

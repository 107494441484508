// Packages or third-party libraries
import React, { FC } from "react";
import { AddContentSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { sidebarEmptyStateStyles } from "./styles";

// Components
import { FileInput } from "@components";
import UploadLoader from "./UploadLoader";

// Utils, hooks
import { errorNotification } from "@utils/helpers";
import { getExtraValidExtensions, getFilesValidation } from "@components/ReusableComponents";
import { useApplyTranslations } from "@hooks";

// Other imports
import { MAX_ALLOWED_FILES, validationTypes } from "../../constants";

type SidebarEmptyStateProps = {
  postFileLoading: boolean;
  canAddUnit: boolean;
  droppedAttachments: FileList | null;
  showUnitSidebar: boolean;
  handleFilesChanged: (files: File[]) => void;
};

const SidebarEmptyState: FC<SidebarEmptyStateProps> = ({
  postFileLoading,
  canAddUnit,
  droppedAttachments,
  showUnitSidebar,
  handleFilesChanged,
}) => {
  const { t } = useApplyTranslations();
  const extraValidExtensions = getExtraValidExtensions(validationTypes);
  const mimeTypeAndFilesizeValidations = getFilesValidation(validationTypes);

  const handleFileError = (error: string): void => {
    errorNotification(`${error}`);
  };

  if (!canAddUnit || !showUnitSidebar) return null;

  return (
    <div css={sidebarEmptyStateStyles}>
      {postFileLoading ? (
        <div className="empty-state-loader">
          <UploadLoader />
        </div>
      ) : (
        <div className="empty-state-container">
          <FileInput
            id="sidebar-upload-course-unit-files"
            name="files"
            maxFiles={MAX_ALLOWED_FILES}
            mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
            acceptedFileExtensions={extraValidExtensions}
            addedFiles={droppedAttachments}
            icon={AddContentSVG}
            title={t("courseEdit.addContent")}
            subtitle={t("courseEdit.dndOrClickBelow")}
            hideBorder
            onFileError={handleFileError}
            onFilesChange={handleFilesChanged}
          />
        </div>
      )}
    </div>
  );
};

export default SidebarEmptyState;

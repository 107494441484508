// Packages or third-party libraries
import React, { FC } from "react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { UnitPendingSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { prerequisitesStyles } from "./styles";

// Components
import { Link, PathItem, PathList } from "@components/ReusableComponents";

// Utils, hooks
import { getIconByCompletion, groupBy } from "@utils/helpers";
import { useApplyTranslations, useIsExternalCatalog } from "@hooks";

// Other imports
import { CoursePrerequisite } from "types/entities";
import { URLS } from "@constants/urls";
import { useUIStore } from "@stores";
import { courseOptionsIds } from "@views/CourseEdit/components/CourseOptions";
import { SerializedStyles } from "@emotion/react";
import { TabKey } from "@views/CourseEdit/components/CourseOptions/CourseOptionsDrawer";

export type PrerequisitesProps = {
  prerequisites: CoursePrerequisite[];
  canEditOptions?: boolean;
  isEdit?: boolean;
};

export const getCatalogLink = ({
  courseId,
  isExternalView,
  isAvailableInCatalog,
  isEdit,
}: {
  courseId: string;
  isExternalView: boolean;
  isAvailableInCatalog: boolean;
  isEdit: boolean;
}): string => {
  if (!isAvailableInCatalog || isEdit) return "#";

  return isExternalView
    ? URLS.externalCatalog.createCourseLink({ courseId })
    : URLS.catalog.createCourseLink({ courseId });
};

const Prerequisites: FC<PrerequisitesProps> = ({
  prerequisites,
  isEdit = false,
  canEditOptions = false,
}) => {
  const { t } = useApplyTranslations();
  const isExternalCatalogView = useIsExternalCatalog();
  const { setCourseOptionsState } = useUIStore();

  const ruleSets = groupBy(prerequisites, ({ rule_set }) => rule_set);
  const ruleSetsKeys = Object.keys(ruleSets);
  const hasManyRuleSets = ruleSetsKeys.length > 1;

  const handlePrerequisiteClick = (): void => {
    if (!canEditOptions) return;

    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.RulesAndPaths,
      scrollId: courseOptionsIds.learningPathRules,
    });
  };

  return (
    <div
      css={(theme): SerializedStyles => prerequisitesStyles(theme, { canEditOptions })}
      id="course-prerequisites"
    >
      <div className="prerequisites-container">
        <div className="title-container">
          <Text
            fontSize="md"
            weight="700"
            className="section-title"
            onClick={handlePrerequisiteClick}
          >
            {t("myCourses.prerequisites")}
          </Text>
          {hasManyRuleSets && (
            <Text fontSize="sm" className="section-subtitle">
              {t("myCourses.completePath")}
            </Text>
          )}
        </div>

        <div className="rule-sets-container">
          {ruleSetsKeys.map((key, index) => {
            const ruleSetPrerequisites = ruleSets[key];
            const isLastItem = index + 1 >= ruleSetsKeys.length;

            return (
              <div key={`rule-set-${key}`}>
                <PathList>
                  {ruleSetPrerequisites.map(
                    ({ id, name, completion_status, available_in_catalog }, index) => {
                      const hideLine = index + 1 >= ruleSetPrerequisites.length;
                      const isCompleted = completion_status === "completed";
                      const isEmpty = !isCompleted || isEdit;
                      const isReadonly = !available_in_catalog || isEdit;
                      const isDisabled = available_in_catalog || isEdit;

                      const courseLink = getCatalogLink({
                        courseId: id.toString(),
                        isExternalView: isExternalCatalogView,
                        isAvailableInCatalog: available_in_catalog,
                        isEdit,
                      });

                      return (
                        <PathItem key={`prerequisite-${id}`}>
                          <PathItem.Separator hideLine={hideLine} isEmpty={isEmpty} />
                          <PathItem.Content className="prerequisite-path-item">
                            <Link to={courseLink} color="secondary" readonly={isReadonly}>
                              <div className="prerequisite-content">
                                <Tooltip
                                  content={t("general.currentlyUnavailable")}
                                  disabled={isDisabled}
                                >
                                  <Text fontSize="md" weight="700">
                                    {name}
                                  </Text>
                                </Tooltip>

                                {!isEdit && (
                                  <div className="icon">
                                    {getIconByCompletion(
                                      completion_status ?? "not_attempted",
                                      32,
                                      <UnitPendingSVG height={32} />,
                                    )}
                                  </div>
                                )}
                              </div>
                            </Link>
                          </PathItem.Content>
                        </PathItem>
                      );
                    },
                  )}

                  {!isLastItem && (
                    <Text fontSize="sm" weight="700" className="divider" data-testid="divider">
                      {t("general.or")}
                    </Text>
                  )}
                </PathList>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Prerequisites;

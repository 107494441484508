import { endpointPrefixV1 } from "@config";

export const ENDPOINTS = {
  myInvoices: `${endpointPrefixV1}/users/me/invoices`,
  invoice: (id: string): string => `${endpointPrefixV1}/invoices/${id}`,
  invoiceDownload: (id: string): string => `${endpointPrefixV1}/invoices/${id}/download`,
  cancelSubscription: (id: string): string => `${endpointPrefixV1}/catalog/subscriptions/${id}`,
  updateCreditCard: (id: string): string =>
    `${endpointPrefixV1}/catalog/subscriptions/${id}/payment-method`,
};

import { PalletThemeColors, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["achievementsStats"] => {
  return {
    scrollbarBackgroundColor: colors.secondary.base,
    iconBackground: colors.secondary.lighter,
    certificateHoverBackground: colors.white,
    dateColor: colors.black,
    iconColor: colors.black,
    buttonBackgroundColor: hexToRGBA(colors.primary.lightest, 0.25),
    dateExpiredColor: colors.red.base,
    icon: {
      color: colors.black,
      hover: colors.primary.base,
    },
  };
};

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const externalHeaderContainer = ({ mainHeader }) => css`
  position: sticky;
  top: 0;
  width: 100%;
  max-height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px ${mainHeader.boxShadowColor};
  padding: 1rem;
  z-index: 3;
  background-color: ${mainHeader.background};

  .logo {
    ${mq["md"]} {
      margin-inline-end: 2rem;
    }

    ${mq["lg"]} {
      margin-inline-start: 1rem;
    }

    img {
      max-height: 38px;

      ${mq["md"]} {
        max-height: 46px;
      }

      ${mq["sm"]} {
        max-width: fit-content;
      }
    }
  }

  .login-btn {
    svg {
      margin-inline-start: 0 !important;
    }
  }
`;

export const modalBodyContainer = css`
  padding: 0;
`;

export const signUpModalBodyContainer = css`
  padding: 0;
  max-height: calc(100vh - 6rem); // here the 6rem represents the elements height offset
  overflow: auto;
  border-radius: 30px;

  .signup-form-container {
    border-radius: 0;
    box-shadow: initial;
  }
`;

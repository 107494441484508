import React, { FC, useCallback, useEffect, useState } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { CloseModalSVG } from "@epignosis_llc/gnosis/icons";

import { dialogBoxStyles, overlayStyles } from "./DialogBox.styles";

import { TextWithIcon } from "@components";
import { IconType } from "types/common";

type WithDialogBoxProps = {
  title: string;
  show: boolean | undefined;
  icon?: IconType;
  children: React.ReactNode;
  onClose?: () => void;
};

const DialogBox: FC<WithDialogBoxProps> = ({
  show = false,
  onClose,
  title,
  icon: Icon,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const handleOnClose = useCallback((): void => {
    setIsOpen(false);
    onClose && onClose();
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <>
      <div
        css={overlayStyles}
        className="overlay"
        onClick={handleOnClose}
        data-testid="dialog-box-overlay"
      />
      <div
        css={dialogBoxStyles}
        className="dialog-box-container"
        data-testid="dialog-box-container"
      >
        <div className="dialog-box-header">
          <TextWithIcon
            className="dialog-box-title"
            label={
              <Text as="p" weight="700" fontSize="md">
                {title}
              </Text>
            }
            icon={Icon && <Icon height={32} />}
          />

          <span className="close-btn" data-testid="dialog-box-close-btn" onClick={handleOnClose}>
            <CloseModalSVG height="32" />
          </span>
        </div>

        <div className="dialog-box-body" data-testid="dialog-box-body">
          {children}
        </div>
      </div>
    </>
  );
};

export default DialogBox;

import { css } from "@emotion/react";

export const brandingLabelStyles = ({ brandingLabel }) => css`
  padding-top: 1rem;
  padding-bottom: 2rem;
  text-align: center;

  .branding-link {
    color: ${brandingLabel.link};
  }
`;

import React, { useState } from "react";
import { useApplyTranslations } from "@hooks";
import { ActionDrawer } from "@components";
import { BranchPropertiesNames, BranchDrawersProps } from "@views/Branches/types";
import { Label, Select } from "@epignosis_llc/gnosis";
import { SelectOption } from "types/common";
import { Controller, FieldValues, Path } from "react-hook-form";
import { signUpOptions } from "../constants";
import { formatLabelSelect } from "@views/AccountAndSettings/components/SettingCard/helpers";
import { verificationOptions } from "@views/Branches/constants";
import { signUpContainer } from "@views/Branches/styles";
import { SignUpEnum } from "types/entities/User";

const SignupDrawer = <T extends FieldValues>({
  control,
  isDisabled,
  resetField,
  watch,
  toggleDrawer,
  isOpen,
}: BranchDrawersProps<T>): JSX.Element => {
  const { t, i18n } = useApplyTranslations();
  const dir = i18n.dir();
  const signUpValue = watch?.(BranchPropertiesNames.BRANCH_SIGNUP as Path<T>);
  const userVerificationValue = watch?.(BranchPropertiesNames.BRANCH_VERIFICATION as Path<T>);
  const tempValues = {
    signUp: signUpValue,
    userVerification: userVerificationValue,
  };
  const [tempValuesState, setTempValues] = useState(tempValues);

  const headerTitle = t("signUp.signUp");
  const handleClose = (): void => {
    const resetSignUpValue = tempValuesState.signUp
      ? { defaultValue: tempValuesState.signUp }
      : undefined;
    const resetUserVerificationValue = tempValuesState.userVerification
      ? { defaultValue: tempValuesState.userVerification }
      : undefined;

    resetField?.(BranchPropertiesNames.BRANCH_SIGNUP as Path<T>, resetSignUpValue);
    resetField?.(BranchPropertiesNames.BRANCH_VERIFICATION as Path<T>, resetUserVerificationValue);

    toggleDrawer();
  };
  const actionButton = {
    text: t("general.back"),
    isDisabled: isDisabled,
  };
  const onApply = (): void => {
    setTempValues(tempValues);
    toggleDrawer();
  };

  const signUpSelectOptions = signUpOptions?.map((value) => ({
    label: formatLabelSelect(BranchPropertiesNames.BRANCH_SIGNUP, value, []),
    value: value,
  }));

  const userVerificationSelectOptions = verificationOptions?.map((value) => ({
    label: formatLabelSelect(BranchPropertiesNames.BRANCH_VERIFICATION, value, []),
    value: value,
  }));

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={handleClose}
      showFooter={true}
      size="md"
      headerTitle={headerTitle}
      actionButton={actionButton}
      onApply={onApply}
    >
      <div css={signUpContainer}>
        <Controller
          name={BranchPropertiesNames.BRANCH_SIGNUP as Path<T>}
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => {
            const defaultValue = {
              label: formatLabelSelect(
                BranchPropertiesNames.BRANCH_SIGNUP,
                value?.id?.toString(),
                [],
              ),
              value: value?.id?.toString(),
            };

            const selectedValue = {
              label: formatLabelSelect(BranchPropertiesNames.BRANCH_SIGNUP, value, []),
              value: value,
            };

            const defaultValueOrSelected = defaultValue?.value ? defaultValue : selectedValue;

            return (
              <div>
                <Label>{t("signUp.signUp")}</Label>
                <Select
                  id={BranchPropertiesNames.BRANCH_SIGNUP}
                  className="select-input"
                  isRtl={dir === "rtl"}
                  options={signUpSelectOptions}
                  isDisabled={isDisabled}
                  onChange={(option): void => {
                    onChange((option as SelectOption).value);
                  }}
                  value={defaultValueOrSelected}
                />
              </div>
            );
          }}
        />

        {signUpValue !== SignUpEnum.MANUAL && (
          <Controller
            name={BranchPropertiesNames.BRANCH_VERIFICATION as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => {
              const defaultValue = {
                label: formatLabelSelect(
                  BranchPropertiesNames.BRANCH_VERIFICATION,
                  value?.id?.toString(),
                  [],
                ),
                value: value?.id?.toString(),
              };

              const selectedValue = {
                label: formatLabelSelect(BranchPropertiesNames.BRANCH_VERIFICATION, value, []),
                value: value,
              };

              const defaultValueOrSelected = defaultValue?.value ? defaultValue : selectedValue;

              return (
                <div>
                  <Label>{t("accountAndSettings.usersSettings.userVerification")}</Label>
                  <Select
                    id={BranchPropertiesNames.BRANCH_VERIFICATION}
                    className="select-input"
                    isRtl={dir === "rtl"}
                    options={userVerificationSelectOptions}
                    isDisabled={isDisabled}
                    onChange={(option): void => {
                      onChange((option as SelectOption).value);
                    }}
                    value={defaultValueOrSelected}
                  />
                </div>
              );
            }}
          />
        )}
      </div>
    </ActionDrawer>
  );
};

export default SignupDrawer;

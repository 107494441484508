// Packages or third-party libraries
import React, { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { Input, InputError, Textarea } from "@epignosis_llc/gnosis";

// Styles
import { TabContainer } from "../styles";

// Utils, hooks
import { capitalize } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { GradeFormData } from "types/entities/Assignments";

type GradeTabProps = {
  form: UseFormReturn<GradeFormData>;
  isGradeEnabled: boolean;
};

const GradeTab: FC<GradeTabProps> = ({ form, isGradeEnabled }): JSX.Element => {
  const { t } = useApplyTranslations();

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div css={TabContainer}>
      <Input
        type="number"
        id="score"
        label={t("assignment.grade")}
        placeholder={t("general.score")}
        status={errors.score ? "error" : "valid"}
        disabled={!isGradeEnabled}
        {...register("score", {
          setValueAs: (value) => value || null,
        })}
      />
      {errors.score && <InputError>{errors.score.message}</InputError>}
      <Textarea
        {...register("comments")}
        id="comments"
        label={capitalize(t("general.comments"))}
        placeholder={capitalize(t("general.comments"))}
        status={errors.comments ? "error" : "valid"}
        disabled={!isGradeEnabled}
      />
      {errors.comments && <InputError>{errors.comments.message}</InputError>}
    </div>
  );
};

export default GradeTab;

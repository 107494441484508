import React, { FC, useRef, useState } from "react";
import { useClickAway } from "ahooks";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import {
  IconChevronUpSVG,
  QuoteLeftSVG,
  CopySolidSVG,
  LinkedUnitSVG,
} from "@epignosis_llc/gnosis/icons";
import { Button } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { DropdownStyles, TalentCraftDisclaimer } from "./styles";
import { createSection, createUnit } from "../../api";
import { handleNoPermissionError } from "@errors";
import { useConfigurationStore, useUIStore } from "@stores";
import queryKeys from "@constants/queryKeys";
import { generalNotification, getActiveThemePrimaryColor, getUnitIconByType } from "@utils/helpers";
import { t } from "@utils/i18n";
import { CreateUnitActions, CreateUnitMenu } from "../../constants";
import { URLS } from "@constants/urls";
import { UnitType, Course } from "types/entities";
import { UnitMenu } from "./types";
import { putCraftDisclaimer } from "@api/courses";
import { useApplyTranslations } from "@hooks";
import { ConfirmationModal } from "@components";
import UnitsDropdown from "./UnitsDropdown";
import CloneModal from "../CloneModal/CloneModal";
import LinkModal from "../Link/LinkModal";

const {
  CREATE_SECTION,
  CREATE_CONTENT_UNIT,
  CREATE_WEB_CONTENT_UNIT,
  CREATE_VIDEO_UNIT,
  CREATE_AUDIO_UNIT,
  CREATE_DOCUMENT_UNIT,
  CREATE_SCORM_UNIT,
  CREATE_IFRAME_UNIT,
  CREATE_TALENT_CRAFT_UNIT,
  CREATE_TEST_UNIT,
  CREATE_ASSIGNMENT_UNIT,
  CREATE_SURVEY_UNIT,
  CLONE_FROM_COURSE,
  LINK_FROM_COURSE,
  CREATE_ILT_UNIT,
} = CreateUnitActions;

const { CREATE_CONTENT_MENU, CREATE_LEARNING_MENU, CREATE_MORE_MENU } = CreateUnitMenu;

type UnitCreateDropdownProps = {
  course: Course;
};

const unitsMenu = (): UnitMenu[] => {
  const options: UnitMenu[] = [
    // Talent craft Unit
    {
      label: t("general.talentCraft"),
      value: CREATE_TALENT_CRAFT_UNIT,
      description: t("units.descriptions.talentCraft"),
      icon: getUnitIconByType(CREATE_TALENT_CRAFT_UNIT),
      isFeatured: true,
    },
    // Standard content units
    {
      label: t("general.standardContent"),
      description: t("units.descriptions.standardContent"),
      icon: getUnitIconByType(CREATE_CONTENT_MENU),
      subMenu: [
        {
          label: t("myCourses.content"),
          value: CREATE_CONTENT_UNIT,
          icon: getUnitIconByType(CREATE_CONTENT_UNIT),
        },
        {
          label: t("general.webContent"),
          value: CREATE_WEB_CONTENT_UNIT,
          icon: getUnitIconByType(CREATE_WEB_CONTENT_UNIT),
        },
        {
          label: t("general.video"),
          value: CREATE_VIDEO_UNIT,
          icon: getUnitIconByType(CREATE_VIDEO_UNIT),
        },
        {
          label: t("general.audio"),
          value: CREATE_AUDIO_UNIT,
          icon: getUnitIconByType(CREATE_AUDIO_UNIT),
        },
        {
          label: `${t("general.presentation")} | ${t("general.document")}`,
          value: CREATE_DOCUMENT_UNIT,
          icon: getUnitIconByType(CREATE_DOCUMENT_UNIT),
        },
        {
          label: t("general.iframe"),
          value: CREATE_IFRAME_UNIT,
          icon: getUnitIconByType(CREATE_IFRAME_UNIT),
          subMenu: [],
        },
      ],
    },
    // Learning activities Units
    {
      label: t("general.learningActivities"),
      description: t("units.descriptions.learningActivities"),
      icon: getUnitIconByType(CREATE_LEARNING_MENU),
      subMenu: [
        {
          label: t("general.test"),
          value: CREATE_TEST_UNIT,
          icon: getUnitIconByType(CREATE_TEST_UNIT),
        },
        {
          label: t("general.survey"),
          value: CREATE_SURVEY_UNIT,
          icon: getUnitIconByType(CREATE_SURVEY_UNIT),
        },
        {
          label: t("general.assignment"),
          value: CREATE_ASSIGNMENT_UNIT,
          icon: getUnitIconByType(CREATE_ASSIGNMENT_UNIT),
        },
        {
          label: t("general.instructorLedTraining"),
          value: CREATE_ILT_UNIT,
          icon: getUnitIconByType(CREATE_ILT_UNIT),
        },
        {
          label: `${t("general.scorm")} | ${t("general.xapi")} | ${t("general.cmi5")}`,
          value: CREATE_SCORM_UNIT,
          icon: getUnitIconByType(CREATE_SCORM_UNIT),
        },
      ],
    },
    // More options
    {
      label: t("general.more"),
      description: t("units.descriptions.more"),
      icon: getUnitIconByType(CREATE_MORE_MENU),
      subMenu: [
        {
          label: t("general.section"),
          value: CREATE_SECTION,
          icon: <QuoteLeftSVG height={32} />,
        },
        {
          label: t("units.cloneFromCourse"),
          value: CLONE_FROM_COURSE,
          icon: <CopySolidSVG height={32} />,
        },
        {
          label: t("units.linkFromCourse"),
          value: LINK_FROM_COURSE,
          icon: <LinkedUnitSVG height={32} />,
        },
      ],
    },
  ];

  return options;
};

const UnitCreateDropdown: FC<UnitCreateDropdownProps> = ({ course }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { showUnitSidebar, toggleUnitSidebar } = useUIStore();
  const { userProfileData } = useConfigurationStore();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowTalentCraftDisclaimerModal, setTalentCraftDisclaimerModal] = useState(false);
  const { domainSettings } = useConfigurationStore();
  const { theme } = domainSettings ?? {};
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

  const { id } = course;
  const { has_accepted_craft_disclaimer } = userProfileData ?? {};
  const courseId = id.toString();

  useClickAway(() => {
    setIsExpanded(false);
  }, dropdownRef);

  const handleCreateUnitDropdownItemClick = async (value: string | undefined): Promise<void> => {
    setIsExpanded(false);
    if (!value) return;

    switch (value) {
      case CREATE_SECTION: {
        createSectionMutation(id);
        return;
      }
      case CREATE_TALENT_CRAFT_UNIT: {
        if (has_accepted_craft_disclaimer) {
          await createUnitMutation({
            course_id: id,
            type: value as UnitType,
            primary_color: theme ? getActiveThemePrimaryColor(theme) : undefined,
          });
          showUnitSidebar && toggleUnitSidebar();
        } else {
          setTalentCraftDisclaimerModal(true);
        }
        return;
      }
      case CLONE_FROM_COURSE: {
        setIsCloneModalOpen(true);
        return;
      }
      case LINK_FROM_COURSE: {
        setIsLinkModalOpen(true);
        return;
      }
      default:
        break;
    }

    // handle other unit creation
    await createUnitMutation({ course_id: id, type: value as UnitType });
  };

  const { mutateAsync: createUnitMutation } = useMutation(
    [queryKeys.courses.unitCreate],
    createUnit,
    {
      onSuccess: (res) => {
        const unit = res._data;
        const unitId = unit.id.toString();
        queryClient.invalidateQueries([queryKeys.units, courseId]);
        navigate(URLS.units.getUnitEditLink({ courseId, unitId }));
      },
      onError: (error: AxiosError) => {
        handleNoPermissionError(error);
      },
    },
  );

  const { mutate: createSectionMutation } = useMutation(
    [queryKeys.courses.sectionCreate],
    createSection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.units, courseId]);
        generalNotification("success", <p>{t("courseEdit.sectionCreatedSuccessfully")}</p>);
      },
      onError: (error: AxiosError) => {
        handleNoPermissionError(error);
      },
    },
  );

  const { mutate: putDisclaimerMutation } = useMutation(
    [queryKeys.courses.sectionCreate],
    putCraftDisclaimer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.userProfile]);
      },
    },
  );

  const handleConfirm = async (): Promise<void> => {
    setTalentCraftDisclaimerModal(false);
    putDisclaimerMutation();
    await createUnitMutation({ course_id: id, type: "craft" as UnitType });
    showUnitSidebar && toggleUnitSidebar();
  };

  const handleClose = (): void => {
    setTalentCraftDisclaimerModal(false);
  };

  return (
    <div css={(theme): SerializedStyles => DropdownStyles(theme)} ref={dropdownRef}>
      <UnitsDropdown
        handleCreateUnitFn={handleCreateUnitDropdownItemClick}
        unitsMenu={unitsMenu}
        isExpanded={isExpanded}
      />
      <Button
        id="add-unit-btn"
        size="lg"
        iconAfter={IconChevronUpSVG}
        onClick={(): void => setIsExpanded(!isExpanded)}
        noGutters
        className="add-unit-btn"
      >
        {t("general.add")}
      </Button>

      <ConfirmationModal
        id="talent-craft-disclaimer"
        header={t("terms.talentcraft.termsOfUse")}
        bodyTitle={
          <div css={TalentCraftDisclaimer}>
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph2") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph3") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph4") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph5") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph6") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph6half") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph7") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.date") }} />
          </div>
        }
        footerButton={t("general.agreeAndContinue")}
        restrictedBody={true}
        confirmationButtonColor="primary"
        isOpen={shouldShowTalentCraftDisclaimerModal}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />

      {isCloneModalOpen && (
        <CloneModal
          isOpen={isCloneModalOpen}
          targetCourseId={courseId}
          onClose={(): void => setIsCloneModalOpen(false)}
        />
      )}
      {isLinkModalOpen && (
        <LinkModal
          isOpen={isLinkModalOpen}
          targetCourseId={courseId}
          onClose={(): void => setIsLinkModalOpen(false)}
        />
      )}
    </div>
  );
};

export default UnitCreateDropdown;

import { css } from "@emotion/react";

export const EditorStyles = ({ editor }, { required, isFocused, hasError }) => {
  const { background, borderColor, focusColor, errorColor, placeholderColor, hoverColor } = editor;
  const border = hasError ? errorColor : isFocused ? focusColor : borderColor;
  const borderStyle = `${isFocused ? 2 : 1}px solid ${border} !important`;

  return css`
    label {
      margin-inline-start: 0.5rem;

      ${required && `&::after { content: " *"; color: ${editor.errorColor}}`}
    }

    /* Override default styles */

    /* toolbar styles */
    .fr-toolbar.fr-top {
      background: ${background};
      border: ${borderStyle};
      border-bottom: none !important;
      border-radius: 5px 5px 0 0;
    }

    /* view styles */
    .fr-box.fr-basic {
      .fr-wrapper {
        border: ${borderStyle};
        border-top: none !important;
        border-bottom: none !important;

        &.show-placeholder .fr-placeholder {
          color: ${placeholderColor};
        }
      }

      .fr-second-toolbar {
        border: ${borderStyle};
        border-top: none !important;
        border-radius: 0 0 5px 5px;
      }
    }

    .fr-box.fr-inline {
      border: 1px solid transparent;
      border-radius: 5px;
      .fr-wrapper {
        &.show-placeholder .fr-placeholder {
          color: ${placeholderColor};
        }
      }

      &.fr-code-view {
        padding: 1.75rem 0.75rem 0.75rem;
        border-color: ${focusColor};

        .fr-command.html-switch {
          padding: 12px 8px;
          top: 0.5rem;
          inset-inline-end: 0.5rem;
          border-radius: 5px;

          &:hover {
            background: ${hoverColor};
          }

          i {
            font-size: 1rem;
          }
        }
      }
    }
  `;
};

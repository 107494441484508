import React, { FC } from "react";
import { ChevronArrowRightSVG, ChevronArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { Tag, Text } from "@epignosis_llc/gnosis";

import { useResponsive } from "ahooks";
import { UnitMenu } from "./types";
import classNames from "classnames";
import { i18n } from "@utils/i18n";
import { t } from "i18next";

type UnitsDropdownDesktopProps = {
  isExpanded: boolean;
  unitsMenu: () => UnitMenu[];
  handleCreateUnitFn: (unitType: string) => Promise<void>;
};

const DropdownClassnames = (isMedium: boolean): string =>
  classNames("create-unit-dropdown", {
    mobile: !isMedium,
  });

const ActiveClassnames = (index: number, activeMenu: number | null): string =>
  classNames("main-item", {
    active: activeMenu === index,
  });

const UnitsDropdown: FC<UnitsDropdownDesktopProps> = ({
  isExpanded,
  unitsMenu,
  handleCreateUnitFn: handleCreateUnitDropdownItemClick,
}) => {
  const [activeMenu, setActiveMenu] = React.useState<number | null>(null);
  const { lg: isMedium } = useResponsive();
  const isRtl = i18n.dir() === "rtl";

  React.useEffect(() => {
    if (!isExpanded) setActiveMenu(null);
  }, [isExpanded]);

  return (
    <>
      {isExpanded && (
        <div className={DropdownClassnames(isMedium)}>
          <ul className="main-menu">
            {unitsMenu().map((item, index) => {
              const hasSubMenu = typeof item.subMenu !== "undefined" && item.subMenu.length > 0;
              const isFeatured = item.isFeatured!;

              return (
                <li
                  key={index}
                  className={ActiveClassnames(index, activeMenu)}
                  onClick={(): void => setActiveMenu(index)}
                  onMouseEnter={(): void | null => (isMedium ? setActiveMenu(index) : null)}
                  onMouseLeave={(): void | null => (isMedium ? setActiveMenu(null) : null)}
                  onBlur={(): void | null => (isMedium ? setActiveMenu(null) : null)}
                >
                  {item.icon}

                  <div
                    className="menu-item"
                    onClick={(): void => {
                      item.value && handleCreateUnitDropdownItemClick(item.value);
                    }}
                  >
                    <Text fontSize="sm" weight="700" as="span" className="menu-title">
                      {item.label}
                    </Text>
                    <Text fontSize="sm" weight="400" as="p" className="menu-description">
                      {item.description}
                    </Text>
                  </div>
                  {isFeatured && <Tag className="new-tag">{t("general.new").toUpperCase()}</Tag>}
                  {hasSubMenu && (
                    <>
                      <ul className="sub-menu">
                        <div className="inner-container">
                          {!isMedium && (
                            <div className="back-container">
                              <button
                                onClick={(e): void => {
                                  e.stopPropagation();
                                  setActiveMenu(null);
                                }}
                              >
                                {isRtl ? <ChevronArrowRightSVG /> : <ChevronArrowLeftSVG />}
                                {t("general.back")}
                              </button>
                            </div>
                          )}
                          {typeof item.subMenu !== "undefined" &&
                            item.subMenu.map((subItem, subIndex) => (
                              <li
                                key={subIndex}
                                className="sub-item"
                                onClick={(): void => {
                                  setActiveMenu(null);
                                  subItem.value && handleCreateUnitDropdownItemClick(subItem.value);
                                }}
                              >
                                <div className="menu-item">
                                  <Text fontSize="sm" weight="400" as="span" className="menu-title">
                                    {subItem.icon}
                                    {subItem.label}
                                  </Text>
                                </div>
                              </li>
                            ))}
                        </div>
                      </ul>
                      {isRtl ? <ChevronArrowLeftSVG /> : <ChevronArrowRightSVG />}
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default UnitsDropdown;

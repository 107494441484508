import { PalletThemeColors, getProtectedColor, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["autocompleteInput"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);
  return {
    background: colors.secondary.lighter,
    backgroundWhite: colors.white,
    backgroundHover: colors.secondary.lighter,
    border: colors.secondary.lighter,
    blueColor: colors.blue.base,
    primaryColor: protectColorContrast("darker", colors.primary.base),
    tagColor: hexToRGBA(colors.primary.lightest, 0.25),
    textColor: colors.black,
    borderColor: protectColorContrast("darker", colors.secondary.lighter),
    disabledBorder: colors.secondary.base,
    errorBorderColor: colors.red.base,
  };
};

import React, { FC } from "react";
import { pathListStyles } from "./styles";

type PathListProps = {
  children?: React.ReactNode;
};

const PathList: FC<PathListProps> = ({ children }) => <ul css={pathListStyles}>{children}</ul>;

export default PathList;

import React, { FC } from "react";
import ContentLoader from "react-content-loader";

export type SkeletonIltsProps = {
  items?: number;
};

const SkeletonIlts: FC<SkeletonIltsProps> = ({ items = 5 }) => (
  <>
    {Array.from({ length: items }, (_, index) => (
      <ContentLoader
        key={`ilt-item-${index}`}
        height={161.58}
        speed={2}
        viewBox="0 0 1000 161.58"
        style={{
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px 0px",
          borderRadius: "5px",
        }}
      >
        {/* title */}
        <rect x="24" y="24" rx="5" ry="5" width="952" height="25.14" />

        {/* date */}
        <rect x="24" y="64.14" rx="5" ry="5" width="50.61" height="70.44" />

        {/* date divider */}
        <rect x="90.61" y="64.14" rx="5" ry="5" width="1" height="70.44" />

        {/* details */}
        <rect x="106.61" y="78.26" rx="5" ry="5" width="869.39" height="42.2" />
      </ContentLoader>
    ))}
  </>
);

export default SkeletonIlts;

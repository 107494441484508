import React, { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { URLS } from "@constants/urls";
import { useConfigurationStore } from "@stores";
import { userHasRequiredPermissions } from "@utils/permissions";
import authService from "@utils/services/AuthService";
import userRoles from "@constants/userRoles";
import { featureFlags } from "@config";

type PermissionsRouteProps = {
  permissions: string[];
  roles?: string[];
  requireAll?: boolean;
  canAccess?: boolean;
};
type UserRole = "administrator" | "instructor" | "learner";

const PermissionsRoute: FC<PermissionsRouteProps> = ({
  roles = ["administrator", "instructor", "learner"],
  permissions,
  requireAll = true,
  canAccess = true,
}) => {
  const location = useLocation();
  let userRole: UserRole = authService.getDefaultRole() as UserRole;
  // Temporarily we give administrator permissions to Theming page so learners can access it
  if (location.pathname === "/my/profile/customize-theming" && !featureFlags.roleSwitching) {
    userRole = userRoles.ADMINISTRATOR as UserRole;
  }

  const userProfileData = useConfigurationStore((state) => state.userProfileData);
  const userPermissions = userProfileData?.permissions[userRole] || [];
  const hasRolePermissions = roles?.includes(userRole);

  return hasRolePermissions &&
    canAccess &&
    userHasRequiredPermissions(permissions, userPermissions, requireAll) ? (
    <Outlet />
  ) : (
    <Navigate to={URLS.dashboard} state={{ from: location }} replace />
  );
};

export default PermissionsRoute;

import userRoles from "@constants/userRoles";
import { t } from "@utils/i18n";
import { Course, CourseProgress } from "types/entities";

type ButtonDisabledProps = {
  isSubscriptionEnabled: boolean;
  isCatalogCourseView: boolean;
  unlocksOnHigherLevel: boolean;
  availability: Course["availability"];
  roleInCourse: string | undefined;
};

type ButtonVisibleProps = {
  isUserEnrolled: boolean;
  continueUnitId: number | null;
  availability: Course["availability"];
};

export const isCourseButtonDisabled = ({
  isSubscriptionEnabled,
  isCatalogCourseView,
  unlocksOnHigherLevel,
  availability,
  roleInCourse,
}: ButtonDisabledProps): boolean => {
  if (isCatalogCourseView && roleInCourse === userRoles.INSTRUCTOR) return true;
  if (unlocksOnHigherLevel) return true;

  // if we are enrolled check availability, else the button is disabled only for level restrictions
  const isCourseAvailable = availability ? availability?.is_available : !unlocksOnHigherLevel;

  // If we are on catalog view and subscriptions are active and the course is not available
  // due to subscription cancellation, allow the user to subscribe
  if (
    isCatalogCourseView &&
    isSubscriptionEnabled &&
    !isCourseAvailable &&
    availability?.reason === "cancelled"
  )
    return false;

  return !isCourseAvailable;
};

export const isCourseButtonVisible = ({
  isUserEnrolled,
  continueUnitId,
  availability,
}: ButtonVisibleProps): boolean => {
  const { is_available = false, reason } = availability ?? {};

  // show button not in catalog view or user not enrolled
  if (!isUserEnrolled) return true;

  // hide button the course is not available due to subscription cancellation,
  // or there is not unit to continue
  return (!is_available && reason === "cancelled") || Boolean(continueUnitId);
};

export const getCourseButtonText = (
  completionStatus: CourseProgress["completion_status"] | undefined,
): string => {
  switch (completionStatus) {
    case "completed":
    case "failed":
      return t("myCourses.mainButtonText.revisitCourse");
    case "incomplete":
      return t("myCourses.mainButtonText.incomplete");
    case "not_attempted":
      return t("myCourses.mainButtonText.notAttempted");
    default:
      return t("myCourses.mainButtonText.notAttempted");
  }
};

import yup from "./yup-extended";

export const UsersSettingsSchema = yup.object().shape({
  terms_of_service: yup.lazy((value) => {
    switch (typeof value) {
      case "string":
        return yup.string().max(35000); // schema for string
      default:
        return yup.mixed(); // here you can decide what is the default
    }
  }),
});

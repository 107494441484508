import React, { FC, memo } from "react";
import { Button, Dropdown, DropdownItem, Tooltip } from "@epignosis_llc/gnosis";
import { VerticalEllipsisSVG, CloseSVG } from "@epignosis_llc/gnosis/icons";
import { ActionGhostButton } from "@components/ReusableComponents";
import { useApplyTranslations } from "@hooks";

type UnitListActionsProps = {
  isReorderingSection: boolean;
  dropdownOptions: DropdownItem[];
  isDropdownDisabled: boolean;
  onListItemSelect: (item: DropdownItem) => void;
  onClose: () => void;
};

const UnitListActions: FC<UnitListActionsProps> = ({
  isReorderingSection,
  dropdownOptions,
  isDropdownDisabled,
  onClose,
  onListItemSelect,
}) => {
  const { t } = useApplyTranslations();

  return (
    <>
      {/* Close full section reordering */}
      {isReorderingSection ? (
        <Tooltip content={t("courseEdit.cancelFullSectionReorder")}>
          <Button variant="ghost" noGutters as="div" onClick={onClose}>
            <CloseSVG height={16} />
          </Button>
        </Tooltip>
      ) : (
        <>
          {dropdownOptions.length > 0 && (
            <Dropdown
              list={dropdownOptions}
              placement="bottom-end"
              fixPlacement
              onListItemSelect={onListItemSelect}
            >
              <ActionGhostButton
                data-testid="unit-dropdown"
                className="unit-dropdown-options"
                disabled={isDropdownDisabled}
              >
                <VerticalEllipsisSVG height={16} />
              </ActionGhostButton>
            </Dropdown>
          )}
        </>
      )}
    </>
  );
};

export default memo(UnitListActions);

import { TableProps } from "@epignosis_llc/gnosis";
import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

const SORTING: TableProps["sorting"] = { column: "uploaded_at", isDescending: true };
const PAGINATION: IPagination = { number: 1, size: 1000 };
const DEFAULT_FILTERS: QueryFilter[] = [{ key: "[shared]", value: "1" }];

export const sharedFilesDefaultState = {
  pagination: {
    number: PAGINATION.number,
    size: PAGINATION.size,
  },
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
  search: null,
};

export const cloneUnitCoursesDefaultState = {
  pagination: { number: 1, size: 1000 },
  sorting: { column: "name", isDescending: false },
  filters: [{ key: "[use_case]", value: "unit.clone" }],
};

export const linkUnitCoursesDefaultState = {
  pagination: { number: 1, size: 1000 },
  sorting: { column: "name", isDescending: false },
  filters: [{ key: "[use_case]", value: "unit.link" }],
};

export enum ReorderActions {
  setSection = "SET_SECTION",
  unsetSection = "UNSET_SECTION",
  addUnit = "ADD_UNIT",
  removeUnit = "REMOVE_UNIT",
}

export enum CourseActions {
  Lock = "LOCK",
  Share = "SHARE",
  Unlock = "UNLOCK",
}

export enum CreateUnitActions {
  CREATE_SECTION = "section",
  CREATE_CONTENT_UNIT = "unit",
  CREATE_WEB_CONTENT_UNIT = "webpage",
  CREATE_VIDEO_UNIT = "video-youtube",
  CREATE_AUDIO_UNIT = "audio",
  CREATE_DOCUMENT_UNIT = "document-slideshare",
  CREATE_SCORM_UNIT = "scorm",
  CREATE_IFRAME_UNIT = "iframe",
  CREATE_TALENT_CRAFT_UNIT = "craft",
  CREATE_TEST_UNIT = "test",
  CREATE_ASSIGNMENT_UNIT = "assignment",
  CREATE_SURVEY_UNIT = "survey",
  CLONE_FROM_COURSE = "clone",
  LINK_FROM_COURSE = "link",
  CREATE_ILT_UNIT = "ilt",
}

export enum CreateUnitMenu {
  CREATE_CONTENT_MENU = "content",
  CREATE_LEARNING_MENU = "learning",
  CREATE_MORE_MENU = "more",
}

export const COURSE_IMAGE_CROP_ASPECT_RATIO = 3 / 2;

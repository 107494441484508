// Packages or third-party libraries
import React, { FC } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Tabs } from "@epignosis_llc/gnosis";

// Components
import { ActionDrawer, GradeCustomFooter, Skeletons, SubmissionInfo } from "@components";
import SessionTab from "./Tabs/SessionTab";
import GradeTab from "./Tabs/GradeTab";

// Utils, hooks
import { generalNotification } from "@utils/helpers";
import { gradeValidationSchema } from "@utils/validation/gradeSchema";
import { useApplyTranslations } from "@hooks";
import { handleAssignmentErrors } from "@errors/assignmentErrors";

// Other imports
import { getIltSession } from "@api/ilts";
import { gradeSubmission } from "@api/gradingHub";
import { IltSession } from "types/entities";
import { SingleIltSession } from "types/entities/Ilts";
import { GradeFormData } from "types/entities/Assignments";
import { DrawerSize } from "types/common";
import queryKeys from "@constants/queryKeys";

type GradingDrawerProps = {
  tabIndex: number;
  ILTSession: IltSession;
  isDrawerOpen: boolean;
  size?: DrawerSize;
  hasBackButton?: boolean;
  onCloseDrawer: () => void;
  onSuccess: () => void;
};

const GradingDrawer: FC<GradingDrawerProps> = ({
  tabIndex,
  ILTSession,
  isDrawerOpen,
  size = "md",
  hasBackButton = false,
  onCloseDrawer,
  onSuccess,
}) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();

  const { unit, user, id, grade } = ILTSession;

  const unitId = unit.id.toString();
  const userId = user.id.toString();

  const {
    data: singleSession,
    status: fetchingStatus,
    error,
  } = useQuery([queryKeys.ilts.session, id, userId, unitId], () => getIltSession(unitId, userId), {
    select: (assignment) => ({
      data: assignment._data,
    }),
    onSuccess: (res) => {
      setValue("comments", res.data.comments);
      setValue("status", res.data.status);
      setValue("score", res.data.grade);
    },

    onError: (error: AxiosError) => {
      handleAssignmentErrors(error);
    },
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const ILTStatus = singleSession?.data.status;
  const isGraded = ILTStatus === "passed" || ILTStatus === "not-passed";

  const form = useForm<GradeFormData>({
    mode: "onChange",
    resolver: yupResolver(gradeValidationSchema),
    defaultValues: {
      score: grade ?? null,
      comments: null,
      status: ILTStatus,
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  const { mutate: gradeMutation, isLoading: isGradeLoading } = useMutation(
    [queryKeys.gradingHub.gradeSubmission, unitId, userId],
    (data: GradeFormData) => {
      const { shouldShowNotification: _shouldShowNotification, ...rest } = data;

      return gradeSubmission(unitId, userId, rest);
    },
    {
      onSuccess: (_res, data: GradeFormData) => {
        const { shouldShowNotification } = data;

        shouldShowNotification &&
          generalNotification("success", <p>{t("gradingHub.messages.grade.update")}</p>);
        onSuccess();
        queryClient.invalidateQueries([queryKeys.gradingHub.pendingCount]);
      },
      onError: (error: AxiosError) => {
        handleAssignmentErrors(error);
      },
    },
  );

  const onGrade = (data: GradeFormData, shouldShowNotification?: boolean): void => {
    gradeMutation({ ...data, shouldShowNotification });
  };

  const availableTabs = [
    {
      title: t("gradingHub.session"),
      content: (
        <SessionTab
          session={singleSession?.data as SingleIltSession}
          error={error}
          status={fetchingStatus}
        />
      ),
    },
    {
      title: t("general.actions.grade"),
      content: <GradeTab form={form} error={error} status={fetchingStatus} />,
    },
  ];

  return (
    <ActionDrawer
      isOpen={isDrawerOpen}
      headerTitle={t("assignment.gradeUser")}
      onClose={onCloseDrawer}
      size={size}
      hasBackButton={hasBackButton}
      customFooter={
        <GradeCustomFooter
          shouldDisabledActions={Boolean(!(Object.keys(errors).length === 0))}
          shouldShowFooterButtons={Boolean(singleSession)}
          status={ILTStatus}
          isGradeLoading={isGradeLoading}
          isGraded={isGraded}
          handleSubmit={handleSubmit}
          onGrade={onGrade}
          onCloseDrawer={onCloseDrawer}
        />
      }
    >
      <Skeletons.Loader error={error} status={fetchingStatus} fullScreen>
        <SubmissionInfo submission={ILTSession} singleSubmission={singleSession?.data} />
        <Tabs selectedTab={tabIndex} tabs={availableTabs} />
      </Skeletons.Loader>
    </ActionDrawer>
  );
};

export default GradingDrawer;

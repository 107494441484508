import { css } from "@emotion/react";

export const emptyStateContainer = ({ icon, widgets }) => css`
  position: relative;

  .empty-state-container {
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    .empty-state {
      &-icon {
        display: flex;
        justify-content: center;
        color: ${icon.primary};
      }

      &-body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      &-description {
        color: ${widgets.descriptionColor};
      }

      &-footer {
        margin-top: 1rem;
      }
    }
  }
`;

import { Select } from "@epignosis_llc/gnosis";
import React, { FC } from "react";
import { GroupedOption, SelectOption } from "types/common";
import { resolveLabel } from "../helpers";
import { MassActionType, MassActionEnum } from "../types";

type ItemSelectType = {
  type: MassActionType;
  selectOptions: SelectOption[] | GroupedOption[];
  handleSelect: (options: SelectOption[]) => void;
};

const hasNotMultiSelect = [
  MassActionEnum.addCourseToAllBranches,
  MassActionEnum.addCourseToAllGroups,
  MassActionEnum.removeCourseFromAllBranches,
  MassActionEnum.removeCourseFromAllGroups,
  MassActionEnum.add_to_category,
  MassActionEnum.add_to_branch,
  MassActionEnum.remove_from_branch,
  MassActionEnum.add_to_group,
  MassActionEnum.remove_from_group,
  MassActionEnum.add_to_course,
  MassActionEnum.remove_from_course,
  MassActionEnum.custom_reports_reset_progress,
];

const ItemSelect: FC<ItemSelectType> = ({ type, handleSelect, selectOptions }) => {
  const label = resolveLabel(type);
  const isMulti = !hasNotMultiSelect.includes(type);

  return (
    <Select
      label={label}
      options={selectOptions}
      isMulti={isMulti}
      onChange={(options): void => {
        handleSelect(options as SelectOption[]);
      }}
      closeMenuOnSelect={true}
    />
  );
};

export default ItemSelect;

import { UseMutateFunction, useMutation } from "react-query";
import queryKeys from "@constants/queryKeys";
import { postZendeskConfigData } from "@api";
import { ZendeskTokenRes } from "types/responses";

type ZendeskConfigDataMutation = {
  zendeskConfigDataMutation: UseMutateFunction<ZendeskTokenRes, unknown, string, unknown>;
};

export const useZendeskConfigData = (): ZendeskConfigDataMutation => {
  const { mutate: zendeskConfigDataMutation } = useMutation<ZendeskTokenRes, unknown, string>(
    [queryKeys.zendesk.token],
    (mode: string) => postZendeskConfigData(mode),
  );

  return { zendeskConfigDataMutation };
};

export default useZendeskConfigData;

import { css } from "@emotion/react";

export const textWithIconContainer = (theme, iconColor) => css`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .prefix-icon {
    display: flex;
    align-items: center;

    svg {
      color: ${iconColor === "green" ? theme.textWithIcon.descriptionGreen : undefined};
    }
  }

  .text-with-icon-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .heading {
      &-black {
        color: ${theme.textWithIcon.headingBlack};
      }

      &-grey {
        color: ${theme.textWithIcon.headingGrey};
      }
    }

    .description-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .description-success {
        color: ${theme.textWithIcon.descriptionGreen};
      }

      .tooltip-content {
        display: flex;
        color: ${theme.icon.primary};
      }
    }
  }
`;

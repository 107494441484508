/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback } from "react";
import classNames from "classnames";
import { SerializedStyles } from "@emotion/react";
import { IconChevronDownSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";

// Styles
import { profileMenuStyles } from "./ProfileMenu.styles";

// Components
import { UserDetails } from "./components/UserDetails";
import { ProfileSubMenu } from "./components/ProfileSubMenu";

// Stores
import { useUIStore } from "@stores";

//Types etc.
import { ReverseTrialStats } from "types/entities/ReverseTrial";

const getButtonStyles = (isActive: boolean): string => classNames({ "is-active": isActive });

type ProfileMenuProps = {
  isImpersonated: boolean | undefined;
  toggleTrialModal: () => void;
  reverseTrialStats?: ReverseTrialStats;
};

const ProfileMenu: FC<ProfileMenuProps> = ({
  isImpersonated,
  toggleTrialModal,
  reverseTrialStats,
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const { hideMainDrawer, mainDrawer } = useUIStore((state) => state);
  const { md } = useResponsive();

  // Mouse callbacks
  const handleMouseOver = useCallback(() => md && setIsActive(true), [md]);
  const handleMouseLeave = useCallback(() => md && setIsActive(false), [md]);
  const handleResponsiveClick = useCallback(() => {
    if (!md) {
      mainDrawer.show && hideMainDrawer(); // If the main drawer (sidebar) is open, close it.
      setIsActive((active) => !active);
    }
  }, [hideMainDrawer, mainDrawer.show, md]);

  return (
    <div
      css={(theme): SerializedStyles => profileMenuStyles(theme)}
      id="profile-menu"
      data-testid="profile-menu"
      className={getButtonStyles(isActive)}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div
        data-testid="profile-menu-button"
        className="profile-menu-button"
        onClick={handleResponsiveClick}
      >
        <UserDetails />

        {md && (
          <span className="arrow-down">
            <IconChevronDownSVG height="24" />
          </span>
        )}
      </div>

      {isActive && (
        <ProfileSubMenu
          isImpersonated={isImpersonated}
          setIsActive={setIsActive}
          toggleTrialModal={toggleTrialModal}
          reverseTrialStats={reverseTrialStats}
        />
      )}
    </div>
  );
};

export default ProfileMenu;

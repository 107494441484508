import React from "react";
import { Text } from "@epignosis_llc/gnosis";
import { CheckRegularSVG } from "@epignosis_llc/gnosis/icons";
import { capitalize } from "@utils/helpers";
import { t } from "@utils/i18n";
import { Discount, Invoice } from "types/entities";

export const convertBinaryToPDF = (binary: string, name = "invoice"): void => {
  const blob = new Blob([binary], { type: "application/pdf" });
  const blobUrl = window.URL.createObjectURL(blob);

  // To download the PDF file:
  const downloadLink = document.createElement("a");
  downloadLink.href = blobUrl;
  downloadLink.download = `${name}.pdf`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const getDiscountTypeTitle = (discount: Discount): string => {
  const { type, code } = discount;
  switch (type) {
    case "global":
    case "rewards":
      return t("payments.discountType", { type: capitalize(type) });
    default:
      return t("payments.couponCode", { code: code });
  }
};

export const getDiscountInfo = (discount: Discount): string => {
  const { percent_off, amount_off } = discount;

  if (percent_off) return `(${percent_off}%)`;

  return `(${amount_off}%)`;
};

export const getPaymentStatus = (
  status: Invoice["status"],
  isCreditsProcessor: boolean,
): string | JSX.Element => {
  switch (status) {
    case "pending":
      return t("payments.paymentStatus.pending");
    case "refunded":
      return t("payments.paymentStatus.refunded");
    case "unpaid":
      return t("payments.paymentStatus.unpaid");
    default: {
      return (
        <div className="paid-container">
          <Text fontSize="sm">
            {t(isCreditsProcessor ? "payments.paidWithCredits" : "payments.paymentStatus.paid")}
          </Text>

          <CheckRegularSVG height={16} />
        </div>
      );
    }
  }
};

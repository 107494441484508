import { css } from "@emotion/react";

export const toggleButtonsViewContainer = (theme) => css`
  background-color: ${theme.toggleButtonsView.borderBottomColor};
  display: flex;
  padding: 4px;
  gap: 4px;
  border-radius: 5px;
  align-items: center;
  color: ${theme.toggleButtonsView.textColor};

  .item {
    background-color: transparent;
    border: none;
    width: 32px;
    height: 32px;
    min-width: auto;
    color: ${theme.toggleButtonsView.textColor} !important;

    &:hover {
      background-color: transparent;
    }

    &.selected-item {
      background-color: ${theme.toggleButtonsView.backgroundColor} !important;
    }
  }
`;

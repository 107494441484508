import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import { CourseRes, UnitsRes } from "types/responses";

export const getCourse = async (courseId: string): Promise<CourseRes> => {
  const res = await HttpClient.get(ENDPOINTS.courseStore.course(courseId), {});

  return res.data;
};

export const getCourseUnits = async (courseId: string): Promise<UnitsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courseStore.units(courseId), {});

  return res.data;
};

export const acquireCourse = async (courseId: string): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courseStore.acquire(courseId));
};

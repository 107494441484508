import { Input, InputError } from "@epignosis_llc/gnosis";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { HexColorPicker } from "react-colorful";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";
import { colorPickerInput } from "../styles";
import { useClickOutside } from "@hooks";
import { PopoverPlacement } from "types/common";

const BLACK_COLOR = "#1E1E1E";
const DEFAULT_WHITE_SHADE = "#CCCCCC";
const whiteShadeThreshold = 210;
const blackShadeThreshold = 20;

type ColorPickerInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  label: string;
  color: string;
  error?: FieldError;
  placement?: PopoverPlacement;
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
  onChange: (newColor: string) => void;
};

const ColorPickerInput: FC<ColorPickerInputProps> = ({
  id,
  label,
  color,
  error,
  placement = PopoverPlacement.Bottom,
  onChange,
  disabled,
  ...rest
}) => {
  const popover = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useState(false);
  const [currentColor, setCurrentColor] = useState(color ? color : BLACK_COLOR);

  const popoverClassnames = classNames("popover", {
    top: placement === PopoverPlacement.Top,
    bottom: placement === PopoverPlacement.Bottom,
  });

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const handleSwatchClick = (): void => {
    if (!disabled) {
      toggle(true);
    }
  };

  const handleColorChange = (newColor: string): void => {
    if (!disabled) {
      setCurrentColor(newColor);
      onChange(newColor);
    }
  };

  const limitColorShades = React.useMemo(() => {
    return (color: string): string => {
      // Convert the hex color to RGB
      const red = parseInt(color.substring(1, 3), 16);
      const green = parseInt(color.substring(3, 5), 16);
      const blue = parseInt(color.substring(5, 7), 16);

      const isBlackShade =
        red <= blackShadeThreshold && green <= blackShadeThreshold && blue <= blackShadeThreshold;
      const isWhiteShade =
        red >= whiteShadeThreshold && green >= whiteShadeThreshold && blue >= whiteShadeThreshold;

      if (isBlackShade) {
        return BLACK_COLOR;
      } else if (isWhiteShade) {
        return DEFAULT_WHITE_SHADE;
      } else {
        return color;
      }
    };
  }, []);

  useEffect(() => {
    if (color !== currentColor && color) {
      setCurrentColor(color);
    }
  }, [color, currentColor]);

  return (
    <div
      css={(theme): SerializedStyles => colorPickerInput(theme, { color: currentColor, disabled })}
    >
      <Input
        id={id}
        label={label}
        status={error ? "error" : "valid"}
        value={limitColorShades(currentColor)}
        disabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          handleColorChange(limitColorShades(e.target.value))
        }
        {...rest}
      />
      {error && <InputError>{error.message}</InputError>}

      <div className="swatch" onClick={handleSwatchClick}>
        {isOpen && (
          <div className={popoverClassnames} ref={popover}>
            <HexColorPicker
              color={currentColor}
              onChange={(color: string): void => {
                handleColorChange(limitColorShades(color));
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPickerInput;

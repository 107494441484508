import { css } from "@emotion/react";

export const cardWrapper = (maxWidth) => css`
  max-width: ${maxWidth}rem;

  .card-container {
    justify-content: start;

    & > section {
      flex: 1;
      display: flex;
    }
  }
`;

export const cardHeader = ({ fileCard }, { isReadonly }) => css`
  width: 100%;
  height: 100%;
  background-color: ${fileCard.fileCover.background};
  position: relative;
  cursor: ${isReadonly ? "default" : "pointer"};
`;

export const hoverActionsWrapper = ({ fileCard }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .hover-btn {
    width: 4rem;
    height: 4rem;
  }

  .hover-description {
    color: ${fileCard.fileCover.textColor};
    cursor: default;
  }
`;

import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Grid, InputError, Label, Select } from "@epignosis_llc/gnosis";
import { CertificateSmSVG, PreviewIconSVG } from "@epignosis_llc/gnosis/icons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { getDate, getMonth, set } from "date-fns";
import { tabContainerStyles } from "../styles";
import queryKeys from "@constants/queryKeys";
import { getCertificateTemplatePreview, getCertificateTemplates } from "@api/certificates";
import { SelectOption } from "types/common";
import { EditCourseData } from "@views/CourseEdit/api";
import { CourseCertificateSchemas } from "@utils/validation/courses/optionsSchemas";
import { CertificateDuration } from "types/entities";
import { useEditCourseDetails } from "@views/CourseEdit/hooks";
import { URLS } from "@constants/urls";
import { handleCourseErrors, handlePreviewCertificateTemplateErrors } from "@errors";
import { capitalize, getDomainDateFormatWithoutYears } from "@utils/helpers";
import { DateInput, RangeInput, TextWithIcon } from "@components";
import { TabHandle, TabProps } from "../../CourseOptionsDrawer";
import TemplatePreview from "./TemplatePreview";
import DurationTypeSelect from "./DurationTypeSelect";
import ReassignWhenSelect from "./ReassignWhenSelect";
import { useApplyTranslations } from "@hooks";
import { courseOptionsIds } from "../constants";

const CertificateTab: ForwardRefRenderFunction<TabHandle, TabProps> = (
  { course, onValidChange, onLoadingChange, onSuccess },
  ref,
) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const { id, certificate } = course;
  const courseId = id.toString();
  const {
    duration = null,
    duration_type,
    expiration_month,
    expiration_day,
    reassign_when = null,
  } = certificate ?? {};

  // duration is in seconds, covert it to days
  const durationDays =
    duration_type === CertificateDuration.Custom && duration ? duration / (3600 * 24) : undefined;

  const form = useForm<EditCourseData>({
    mode: "onChange",
    resolver: yupResolver(CourseCertificateSchemas),
    defaultValues: {
      certificate: certificate
        ? {
            id: certificate.id,
            duration_type,
            duration_days: durationDays,
            expiration_day: expiration_day ?? undefined,
            expiration_month: expiration_month ?? undefined,
            reassign_when,
          }
        : null,
    },
  });

  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { isValid, errors },
    trigger,
  } = form;

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const { data: certificateTemplates = [] } = useQuery(
    [queryKeys.certificates.templates],
    getCertificateTemplates,
    { select: (res) => res._data },
  );

  const certificateTemplateOptions: SelectOption[] = certificateTemplates.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }));

  const certificateWatch = watch("certificate");
  const {
    id: certificateId,
    duration_type: durationType,
    expiration_day: expirationDay,
    expiration_month: expirationMonth,
  } = certificateWatch ?? {};

  const durationDaysLabel = capitalize(t("general.day", { count: 2 }));
  const hasSelectedType = Boolean(certificateId);
  const hasSelectedDuration = Boolean(durationType);
  const hasForeverDuration = hasSelectedDuration && durationType === CertificateDuration.Forever;
  const hasCustomDuration = hasSelectedDuration && durationType === CertificateDuration.Custom;
  const hasDateDuration = hasSelectedDuration && durationType === CertificateDuration.Date;
  const showReassignWhen = hasSelectedType && hasSelectedDuration && !hasForeverDuration;
  const hasExpirationDate = expirationDay && expirationMonth;
  const expirationDate = hasExpirationDate
    ? set(new Date(), { month: expirationMonth - 1, date: expirationDay })
    : null;

  const handleTypeChange = (typeId: number): void => {
    setValue("certificate.id", typeId);

    // set duration to one month if duration_type has not a value
    if (!certificateWatch?.duration_type) {
      setValue("certificate.duration_type", CertificateDuration.Forever);
    }

    // should always send reassign_when
    if (!certificateWatch?.reassign_when) {
      setValue("certificate.reassign_when", null);
    }
  };

  const handleExpirationDateChange = (date: Date | null): void => {
    setValue("certificate.expiration_day", date ? getDate(date) : undefined);
    setValue("certificate.expiration_month", date ? getMonth(date) + 1 : undefined);

    // trigger validation manually because there aren't controlled field for these properties
    trigger(["certificate.expiration_day", "certificate.expiration_month"]);
  };

  const handlePreviewClick = (): void => {
    if (certificateId) {
      certificateTemplatePreviewMutation(certificateId.toString());
    }
  };

  const {
    mutate: certificateTemplatePreviewMutation,
    isLoading: isLoadingCertificateTemplatePreview,
  } = useMutation([queryKeys.certificates.templatePreview], getCertificateTemplatePreview, {
    onSuccess: (res) => {
      setPreviewUrl(res._data.preview_url);
    },
    onError: (err) => {
      const error = err as AxiosError;
      handlePreviewCertificateTemplateErrors(error);
    },
  });

  const { mutateAsync: editCourseCertificateMutation, isLoading: isLoadingEditCourseCertificate } =
    useEditCourseDetails({
      courseId,
      shouldTrackMarketo: true,
      options: {
        onSuccess: () => {
          onSuccess();
        },
        onError: (err) => {
          const error = err as AxiosError;
          const handleError = (): void => {
            navigate(URLS.courses.courses);
          };

          handleCourseErrors(error, false, handleError);
        },
      },
    });

  const handleSubmit = async (): Promise<void> => {
    const data = getValues();
    const isValid = await CourseCertificateSchemas.isValid(data);

    if (isValid) {
      await editCourseCertificateMutation(data);
    }
  };

  // The component instance will be extended with whatever returned from the callback
  useImperativeHandle(ref, () => ({
    handleApply: handleSubmit,
  }));

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange]);

  useEffect(() => {
    onLoadingChange(isLoadingEditCourseCertificate);
  }, [isLoadingEditCourseCertificate, onLoadingChange]);

  return (
    <section css={tabContainerStyles} id={courseOptionsIds.certificateOptions}>
      <div className="catalog-options-container">
        <TextWithIcon icon={<CertificateSmSVG height={32} />} label={t("certificates.info")} />
        <Grid
          templateColumns={[1, 1, 1, 2]}
          rowGap={1}
          columnGap={1}
          className="grid-container align-items-end"
        >
          <Grid.Item colSpan={1}>
            <Controller
              name="certificate"
              control={control}
              render={({ field: { value, onChange } }): JSX.Element => {
                const selectedTemplate = certificateTemplateOptions.find(
                  (template) => template.value === value?.id?.toString(),
                );

                return (
                  <Select
                    label={t("general.type")}
                    placeholder={t("certificates.selectType")}
                    options={certificateTemplateOptions}
                    value={selectedTemplate}
                    isClearable
                    onChange={(option): void => {
                      const { value } = (option as SelectOption) ?? {};

                      // on clear selection reset the whole certificate object
                      !value ? onChange(null) : handleTypeChange(Number(value));

                      // reset preview on change
                      setPreviewUrl(null);
                    }}
                  />
                );
              }}
            />
          </Grid.Item>

          <Grid.Item colSpan={1}>
            {hasSelectedType && (
              <Button
                variant="ghost"
                iconBefore={PreviewIconSVG}
                noGutters
                isLoading={isLoadingCertificateTemplatePreview}
                onClick={handlePreviewClick}
              >
                {t("general.preview")}
              </Button>
            )}
          </Grid.Item>

          {previewUrl && (
            <Grid.Item colSpan={[1, 1, 1, 2]} className="certificate-preview">
              <TemplatePreview url={previewUrl} onClose={(): void => setPreviewUrl(null)} />
            </Grid.Item>
          )}

          {hasSelectedType && (
            <Grid.Item colSpan={[1, 1, 1, 2]}>
              <Grid templateColumns={[1, 1, 1, 2]} gap={1}>
                <Grid.Item colSpan={1}>
                  <DurationTypeSelect form={form} />
                </Grid.Item>

                {hasDateDuration && (
                  <Grid.Item colSpan={1}>
                    <DateInput
                      label={t("general.expirationDate")}
                      dateFormat={getDomainDateFormatWithoutYears()}
                      value={expirationDate}
                      hideYears
                      tooltipContent={t("certificates.expirationTooltip")}
                      onChange={(selectedDate: Date | null): void => {
                        handleExpirationDateChange(selectedDate);
                      }}
                    />
                    {errors?.certificate?.expiration_month && (
                      <InputError>{errors?.certificate?.expiration_month.message}</InputError>
                    )}
                  </Grid.Item>
                )}
              </Grid>
            </Grid.Item>
          )}

          {hasCustomDuration && (
            <Grid.Item colSpan={[1, 1, 1, 2]}>
              <Label>{t("courseEdit.setDuration")}</Label>
              <div className="range-input-container">
                <Controller
                  name="certificate.duration_days"
                  control={control}
                  render={({ field: { value, onChange } }): JSX.Element => (
                    <RangeInput
                      id="custom-duration-range-input"
                      name="custom-duration-range-input"
                      min={1}
                      max={4015}
                      value={value ?? 1}
                      label={durationDaysLabel}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </Grid.Item>
          )}

          {showReassignWhen && <ReassignWhenSelect form={form} />}
        </Grid>
      </div>
    </section>
  );
};

export default forwardRef(CertificateTab);

import { css } from "@emotion/react";

export const textWithStatusContainer = ({ statusTag }, { variant }) => css`
  .column-description {
    display: flex;
    align-items: center;
    margin-inline-end: 1rem;

    .overflow-text {
      word-break: break-word;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .status-tag {
      background: ${statusTag[variant]?.backgroundColor} !important;
      color: ${statusTag[variant]?.textColor} !important;
      padding: 0.2rem 0.5rem;
      white-space: nowrap;
      border-radius: 2.5px;
      -webkit-margin-start: 0.5rem;
      margin-inline-start: 0.5rem;
    }

    .instructor-icon-container {
      line-height: 0;
      padding-inline-start: 0.5rem;
    }
  }
`;

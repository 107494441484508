import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const overviewContainer = ({ achievementsStats }, { showCollectPointsInfo }) => css`
  height: 100%;

  .info-container {
    display: flex;
    justify-content: end;
    padding-top: 1rem;

    .info-button {
      min-width: 32px;
      height: 32px;
      margin-bottom: 0.5rem;

      &:hover,
      &:active,
      &:focus {
        background-color: ${achievementsStats.buttonBackgroundColor} !important;
      }
    }
  }

  .overview-grid-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
    justify-content: ${!showCollectPointsInfo ? "center" : "flex-start"};
    padding-bottom: 3rem;

    .overview-container {
      max-height: 250px;
      overflow-y: auto;
      margin: 0 0.5rem 0rem 1rem;
      padding: 0 1rem 0.5rem 1rem;

      .title {
        font-size: 2.125rem;
        font-weight: 700;
        color: ${achievementsStats.iconColor};
      }
      .subtitle {
        font-size: 1.4rem;
      }

      span {
        background: ${achievementsStats.certificateHoverBackground};
      }

      svg {
        fill: ${achievementsStats.iconColor};
        color: ${achievementsStats.iconColor};
        max-height: 40px;
      }
    }

    footer {
      text-align: center;
      margin-top: 2rem;
    }
  }
`;

export const achievementsCertificatesContainer = ({ isDashboard }) => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0.5rem;

  .certificates-container {
    height: ${isDashboard ? "220px" : "300px"};
    overflow-y: auto;
    margin: 1.5rem 0.5rem 0rem 1rem;
    padding: 0 1rem 0.5rem 1rem;
  }

  ${mq["sm"]} {
    padding: 0;
  }

  footer {
    text-align: center;
    margin: 1rem 0;

    ${mq["sm"]} {
      margin: 1rem 2rem;
      text-align: right;
    }
  }
`;

export const certificateItemContainer = ({ achievementsStats }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0 0 1.5rem;

  &:last-child {
    margin: 0;
  }

  .list-item-info {
    display: flex;
    align-items: center;
    padding-inline-end: 1rem;

    figure {
      background-color: ${achievementsStats.iconBackground};
      border-radius: 5px;
      padding: 1rem;
      margin-inline: 0 1rem;
      margin-block: 0;

      svg {
        display: block;
      }
    }

    h4 {
      line-height: 1;
    }

    .expiration-date {
      color: ${achievementsStats.dateColor};

      &.expired {
        color: ${achievementsStats.dateExpiredColor};
      }
    }
  }

  .list-item-icons {
    display: flex;
    align-items: center;

    button {
      display: inline-flex;
      color: ${achievementsStats.icon.color};

      &:hover {
        color: ${achievementsStats.icon.hover};
      }
    }
  }
`;

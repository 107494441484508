import { css } from "@emotion/react";

export const imageSelectorContainer = ({ imageSelector }) => css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  min-height: 48px;

  &:hover {
    background-color: ${imageSelector.wrapperHoverColor};
  }

  img {
    max-height: 32px;
  }

  .input-wrapper {
    .input-field {
      display: none;
    }
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;

    button {
      color: ${imageSelector.color};
    }
  }
`;

export const announcementsContainer = ({ announcementsDrawer }) => css`
  .form-container {
    margin-bottom: 0.5rem;

    .domain-name-container {
      display: flex;
      align-items: center;

      .drawer-input-field {
        max-width: 345px;
        width: 100%;

        &.is-textarea {
          max-width: 100%;
        }
      }

      .text-container {
        margin-top: 30px;
        margin-left: 0.5rem;
        color: ${announcementsDrawer.fontColor};
        font-style: italic;
      }
    }

    .announcementTextarea {
      min-height: 300px;
    }
  }

  .subtitle {
    color: ${announcementsDrawer.fontColor};
  }
`;

export const customDomainContainer = () => css`
  position: relative;
  display: flex;
  flex-direction: column;

  header {
    margin-bottom: 1.5rem;

    .title-wrapper {
      a {
        min-width: auto;
        padding: 0;
        margin-right: 0.5rem;
      }
    }
  }

  .custom-domain-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: auto;
    max-width: 62.5rem;

    .form-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin-bottom: 2rem;

      .domain-name-input {
        align-self: start;
        max-width: 320px;
        width: 100%;
      }

      .important-note {
        font-style: italic;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const emailNotificationsDrawerContainer = ({ accordion }) => css`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .important-note {
    font-style: italic;
    display: flex;
    flex-direction: column;
  }

  .text-icon {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .record-status {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .single-status-record {
      display: flex;
      gap: 8px;
      align-items: center;
      color: ${accordion.statusColor.invalid};

      span {
        color: ${accordion.textColor} !important;
      }

      &.is-valid {
        color: ${accordion.statusColor.valid};
      }
    }
  }

  .spf-accordion {
    margin-top: 0.5rem;

    .accordion-content.is-expanded {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .divider {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: ${accordion.dividerColor};
        }
      }
    }

    .accordion-header .accordion-header-content .accordion-title {
      font-size: 14px;
    }
  }
`;

export const generateCSRKeyContainer = () => css`
  .form-item {
    margin-bottom: 1.5rem;
    max-width: 345px;
  }

  .single-line-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;

    .input-error-container {
      width: 100%;

      &.right-container {
        margin-left: 1rem;
      }
    }
  }
`;

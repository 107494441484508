import React, { FC } from "react";
import { SingleSubmission } from "types/entities";
import { TabContainer } from "../styles";
import { HTMLContent } from "@views";

type AssignmentTabProps = {
  submission: SingleSubmission;
};

const AssignmentTab: FC<AssignmentTabProps> = ({ submission }): JSX.Element => {
  const { assignment_description } = submission ?? {};

  return (
    <div css={TabContainer}>
      {assignment_description && <HTMLContent content={assignment_description} />}
    </div>
  );
};

export default AssignmentTab;

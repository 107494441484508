import { css } from "@emotion/react";

export const reorderColumns = ({ reorderColumns }) => css`
  .draggable-item {
    padding: 0 0.75rem;
    background: #ffffff;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    /* override dnd calculation */
    left: 2rem !important;

    &:hover {
      border-radius: 5px;
      box-shadow: 0px 3px 6px ${reorderColumns.boxShadowColor};
    }

    &:active {
      border-radius: 5px;
      box-shadow: 0px 3px 6px ${reorderColumns.boxShadowColor};
      border: 1px solid ${reorderColumns.iconColor};

      svg {
        color: ${reorderColumns.iconColor};
      }
    }

    .grip-icon {
      margin-inline-end: 1.5rem;
    }
  }
`;

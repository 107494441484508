import React, { FC, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { publicCourseContainer } from "./styles";
import { useConfigurationStore } from "@stores";
import { URLS } from "@constants/urls";
import { FacebookSVG, TwitterSVG } from "@epignosis_llc/gnosis/icons";
import { Course } from "types/entities";
import { getSocialMediaShareURL } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { Link } from "@components/ReusableComponents";
import GuestSignupModal from "./GuestSignupModal";

type PublicCourseSignUpProps = {
  course: Course;
};

const PublicCourseSignUp: FC<PublicCourseSignUpProps> = ({ course }) => {
  const { t } = useApplyTranslations();
  const { catalogSettings, domainSettings } = useConfigurationStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id, progress } = course;
  const isSignUpEnabled = Boolean(domainSettings?.signup?.enabled);
  const canShareOnSocialMedia = Boolean(catalogSettings?.social_catalog);

  const catalogUrl = URLS.externalCatalog.createCourseLink({
    courseId: id.toString(),
  });

  return (
    <div css={publicCourseContainer}>
      {isSignUpEnabled && (
        <>
          <Text fontSize="sm" as="div">
            {progress?.certificate
              ? t("externalCatalog.publicCourse.youMaySignUpCertificate")
              : t("externalCatalog.publicCourse.youMaySignUp")}
          </Text>

          <Button className="sign-up-btn" onClick={(): void => setIsModalOpen(true)}>
            {t("signUp.signUp")}
          </Button>
        </>
      )}

      {canShareOnSocialMedia && (
        <div className="social-buttons-wrapper">
          <Text fontSize="sm">{t("myCourses.share")}</Text>
          <Link
            className="social-link"
            to={getSocialMediaShareURL("facebook", catalogUrl, course)}
            target="_blank"
          >
            <FacebookSVG height={32} />
          </Link>
          <Link
            className="social-link"
            to={getSocialMediaShareURL("twitter", catalogUrl, course)}
            target="_blank"
          >
            <TwitterSVG height={32} />
          </Link>
        </div>
      )}

      {isSignUpEnabled && (
        <GuestSignupModal isOpen={isModalOpen} onClose={(): void => setIsModalOpen(false)} />
      )}
    </div>
  );
};

export default PublicCourseSignUp;

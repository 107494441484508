import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors, hexToRGBA } from "@styles/utils";

export default (colors: PalletThemeColors): ThemePlusTypes["referral"] => {
  return {
    reward: {
      figureBackground: colors.secondary.lighter,
      pillBackgroundPending: hexToRGBA(colors.orange.base, 0.1),
      pillTextPending: colors.orange.base,
      pillBackgroundIneligible: hexToRGBA(colors.blue.base, 0.1),
      pillTextIneligible: colors.blue.base,
      pillBackgroundClaimed: hexToRGBA(colors.green.base, 0.1),
      pillTextClaimed: colors.green.base,
      itemHeading: colors.black,
      contentColor: colors.secondary.base,
    },
    rewardContainer: {
      background: colors.white,
      borderColor: colors.secondary.light,
    },
  };
};

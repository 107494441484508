import React, { FC, useRef, useState } from "react";
import { Text, Heading, Tooltip } from "@epignosis_llc/gnosis";
import { FirstPlaceSVG, SecondPlaceSVG, ThirdPlaceSVG } from "@assets/images";
import { getFormattedUserName } from "@utils/helpers";
import { LeaderboardUser } from "types/entities";
import { UserAvatar } from "@components/ReusableComponents";
import UserBadgesModal from "./UserBadgesModal";
import { PreviewAction } from "@components";
import { useResponsive } from "ahooks";
import { useIsOverflow } from "@hooks";

type UserItemProps = {
  leaderboardUser: LeaderboardUser;
  index: number;
  title?: string;
};

const InfoContainer = (name: string, surname: string, login: string): JSX.Element => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const overflowActive = useIsOverflow(ref);
  const formattedName = getFormattedUserName({ name, surname, login });

  return (
    <div className="info">
      <Tooltip content={formattedName} disabled={!overflowActive}>
        <Text as="div" fontSize="sm" weight="700">
          <span ref={ref}>{formattedName}</span>
        </Text>
      </Tooltip>
    </div>
  );
};

const tooltipContainer = (name?: string, surname?: string): JSX.Element => (
  <div className="tooltip">
    {name} {surname}
  </div>
);

const UserItem: FC<UserItemProps> = ({ leaderboardUser, title, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { name, score, avatar, surname, login, id } = leaderboardUser;
  const [activeUserId, setActiveUserId] = useState<string | null>(null);
  const { sm } = useResponsive();
  const isBadgesTab = title === "badges";

  const handleShowClick = (id: string): void => {
    setActiveUserId(id);
    setIsOpen(true);
  };

  const handleClickClose = (): void => {
    setActiveUserId(null);
    setIsOpen(false);
  };

  return (
    <>
      <div
        className="container"
        onClick={(): void | null => (!sm && isBadgesTab ? handleShowClick(id.toString()) : null)}
      >
        <div className="image-container">
          <span className="ranking-number">
            {index === 0 && <FirstPlaceSVG />}
            {index === 1 && <SecondPlaceSVG />}
            {index === 2 && <ThirdPlaceSVG />}
            {index > 2 && (
              <Text fontSize="md" weight="700">
                {index + 1}
              </Text>
            )}
          </span>

          <span className="avatar-container">
            <Tooltip content={tooltipContainer(name, surname)}>
              <UserAvatar
                avatar={avatar}
                imageSize="sm"
                avatarSize="md"
                name={name}
                alt={`${name} ${surname}`}
              />
            </Tooltip>
          </span>
        </div>

        <div className="info-value-container">
          {InfoContainer(name, surname, login)}
          <Heading size="lg" className="stats">
            {score}
            {isBadgesTab && sm && (
              <PreviewAction onClick={(): void => handleShowClick(id.toString())} />
            )}
          </Heading>
        </div>
      </div>

      {activeUserId && (
        <UserBadgesModal
          userId={activeUserId.toString()}
          leaderboardUser={leaderboardUser}
          index={index}
          isOpen={isOpen}
          onClose={handleClickClose}
        />
      )}
    </>
  );
};

export default UserItem;

// Packages or third-party libraries
import React, { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Modal, Text } from "@epignosis_llc/gnosis";
import { ReverseTrialStartSVG, CheckRegularSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { trialContainerStyle, reverseTrialModalCustomStyles } from "./ReverseTrial.styles";

// Hooks etc.
import { useApplyTranslations } from "@hooks";
import { generalNotification } from "@utils/helpers";
import { enableReverseTrial } from "@api/reverseTrial";

//Constants
import queryKeys from "@constants/queryKeys";

type ReverseTrialModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
};

export const StartReverseTrialModal: FC<ReverseTrialModalProps> = ({ isOpen, toggleModal }) => {
  const { t } = useApplyTranslations();

  const firstColumnItems = [
    t("reverseTrial.aiCourseGenerator"),
    t("reverseTrial.talentLibraryCourses"),
    t("reverseTrial.branches", { number: 5 }),
    t("reverseTrial.prioritySupport"),
  ];

  const secondColumnItems = [
    t("general.automations"),
    t("systemOptions.customReports"),
    t("accountAndSettings.portalSettings.customDomain.customDomain"),
    t("reverseTrial.sso"),
  ];

  const renderColumn = (items: string[]): JSX.Element[] =>
    items.map((item, index) => (
      <div key={index} className="reverse-trial-modal-feature">
        <CheckRegularSVG height={20} className="reverse-trial-modal-feature-tick-color" />
        <Text fontSize="md">{item}</Text>
      </div>
    ));

  const queryClient = useQueryClient();

  const invalidateQueries = (): void => {
    queryClient.invalidateQueries([queryKeys.reverseTrial.enable]);
    queryClient.invalidateQueries([queryKeys.accountAndSettings.subscription.plans]);
    queryClient.invalidateQueries([queryKeys.accountAndSettings.subscription.currentPlan]);
    queryClient.invalidateQueries([queryKeys.domainSettings]);
    queryClient.invalidateQueries([queryKeys.userProfile]);
  };

  const { mutate: startTrialMutation, isLoading: enableReverseTrialLoading } = useMutation(
    () => enableReverseTrial(),
    {
      onSuccess: () => {
        invalidateQueries();
        generalNotification("success", <p>{t("reverseTrial.activatedSuccessfully")}</p>);
        toggleModal();
      },
      onError: () => {
        toggleModal();
        generalNotification("error", <p>{t("reverseTrial.activationFailed")}</p>);
      },
    },
  );

  const handleStartTrial = (): void => {
    startTrialMutation();
  };

  return (
    <Modal
      data-testid="reverse-trial-modal"
      style={reverseTrialModalCustomStyles}
      isOpen={isOpen}
      onClose={toggleModal}
      closeOnOutsideClick={true}
    >
      <Modal.Header>
        <Text fontSize="xl" weight="700">
          {t("reverseTrial.startOfTrialHeader")}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <div css={trialContainerStyle}>
          <ReverseTrialStartSVG height={200} className="reverse-trial-modal-icon" />
          <Text fontSize="md" className="reverse-trial-modal-unlock-description">
            {t("reverseTrial.startOfTrialBody")}
          </Text>
          <div className="reverse-trial-modal-rows">
            <div className="reverse-trial-modal-features-column">
              {renderColumn(firstColumnItems)}
            </div>
            <div className="reverse-trial-modal-features-column">
              {renderColumn(secondColumnItems)}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="reverse-trial-modal-yes-button"
          variant="solid"
          onClick={handleStartTrial}
          isLoading={enableReverseTrialLoading}
        >
          {t("reverseTrial.start14DayTrial")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

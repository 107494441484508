import React, { FC } from "react";
import { ProgressBar, Text } from "@epignosis_llc/gnosis";
import { formatUtcDate, getIconByCompletion } from "@utils/helpers";
import { Course, CourseProgress as CourseProgressType } from "types/entities";
import { useApplyTranslations } from "@hooks";
import InfoTag from "./InfoTag";

type ProgressProps = {
  isUserEnrolled: boolean;
  progress: CourseProgressType | null;
  availability: Course["availability"];
};

const Progress: FC<ProgressProps> = ({ isUserEnrolled, progress, availability }) => {
  const { t } = useApplyTranslations();

  const { completion_status, completion_date = null, completion_percentage = 0 } = progress ?? {};
  const isCompleted = isUserEnrolled && completion_status === "completed";
  const isFailed = isUserEnrolled && completion_status === "failed";
  const showCompletedInfo = isCompleted && !!completion_date;
  const isAvailable = availability && availability.is_available;

  if (!progress || !isUserEnrolled || !isAvailable) return null;

  if (showCompletedInfo) {
    return (
      <InfoTag>
        {getIconByCompletion(completion_status)}
        <Text fontSize="sm">
          {t("myCourses.courseCompletedOn", {
            date: formatUtcDate(completion_date),
          })}
        </Text>
      </InfoTag>
    );
  }

  if (isFailed) {
    return (
      <InfoTag>
        {getIconByCompletion(completion_status)}
        <Text fontSize="sm">{t("general.progress.notPassed")}</Text>
      </InfoTag>
    );
  }

  return (
    <ProgressBar
      percent={completion_percentage}
      size="xs"
      color="white"
      labelAfter={t("general.completedPercentage", { percentage: completion_percentage })}
    />
  );
};

export default Progress;

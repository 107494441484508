import { SelectOption } from "types/common";
import { CustomField } from "types/entities/Common";

export const customUserFieldsIdsToOptions = (
  ids: number[] | CustomField[],
  customUserFields: CustomField[],
): SelectOption[] => {
  if (ids.length) {
    if (typeof ids[0] === "number") {
      const values = ids
        .map((id) => {
          const customUserField = customUserFields.find((b) => b.id.toString() === id.toString());
          return customUserField
            ? { label: customUserField.name, value: customUserField.id.toString() }
            : { label: "", value: id.toString() };
        })
        .filter((option) => option.label);

      return values;
    } else {
      return customUserFieldsToOptions(ids as CustomField[]);
    }
  }
  return [];
};

export const customUserFieldsToOptions = (customUserFields: CustomField[]): SelectOption[] => {
  return customUserFields.map((customUserField) => ({
    label: customUserField?.name,
    value: customUserField?.id.toString(),
  }));
};

import { social } from "@config";
import { Course } from "types/entities";

export const getSocialMediaShareURL = (
  type: "facebook" | "twitter",
  catalogUrl: string,
  course: Course,
): string => {
  const domainName = window.location.hostname;
  const catalogLink = `https://${domainName}/plus${catalogUrl}`;

  // This text does not take translation
  const urlText = `I just viewed the course ${course.name}${
    course.code ? ` (${course.code})` : ""
  }. I recommend you give it a try as well.`;

  const URLS = {
    twitter: `https://twitter.com/intent/tweet?text=${urlText}&url=${catalogLink}`,
    facebook: `https://www.facebook.com/dialog/share?app_id=${social.facebookID}&display=page&href=${catalogLink}&redirect_uri=${catalogLink}`,
  };

  return type === "twitter" ? URLS.twitter : URLS.facebook;
};

import HttpClient from "./HttpClient";
import { ENDPOINTS } from "./endpoints";
import { CatalogCategoriesRes } from "types/responses";
// import { getPublicBearerHeaders } from "@utils/helpers";

export const getCategories = async (
  flat = false,
  searchQuery = "",
): Promise<CatalogCategoriesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.categories.categories}${searchQuery}`, {
    params: { flat },
    // headers: getPublicBearerHeaders(), // TODO: uncomment when categories fixed in BE
  });
  return res.data;
};

export const getCourseStoreCategories = async (
  flat = false,
  searchQuery = "",
): Promise<CatalogCategoriesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courseStore.categories}${searchQuery}`, {
    params: { flat },
  });

  return res.data;
};

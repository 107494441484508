import React, { FC } from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import { Button } from "@epignosis_llc/gnosis";
import { GradeFormData, SubmissionStatus, SubmissionStatusEnum } from "types/entities/Assignments";
import { useApplyTranslations } from "@hooks";

type GradeCustomFooterProps = {
  shouldDisabledActions?: boolean;
  shouldShowFooterButtons?: boolean;
  isGraded: boolean;
  isGradeLoading: boolean;
  status?: SubmissionStatus;
  handleSubmit: UseFormHandleSubmit<GradeFormData>;
  onCloseDrawer: () => void;
  onGrade: (data: GradeFormData, shouldShowNotification?: boolean) => void;
};

const { passed, notPassed } = SubmissionStatusEnum;

const GradeCustomFooter: FC<GradeCustomFooterProps> = ({
  shouldDisabledActions = false,
  shouldShowFooterButtons = true,
  isGraded,
  isGradeLoading,
  status: pastStatus,
  handleSubmit,
  onGrade,
  onCloseDrawer,
}) => {
  const { t } = useApplyTranslations();

  const handleGrade = async ({
    status,
    shouldShowNotification,
  }: {
    status?: SubmissionStatus;
    shouldShowNotification?: boolean;
  }): Promise<void> => {
    const handleClick = handleSubmit((data) => {
      const newData = { ...data, status: status ?? pastStatus ?? "pending" };
      if (newData.score === null) {
        delete newData.score;
      }
      onGrade(newData, shouldShowNotification);
    });
    await handleClick();
    onCloseDrawer();
  };

  return (
    <div>
      {shouldShowFooterButtons &&
        (isGraded ? (
          <Button
            isLoading={isGradeLoading}
            color="primary"
            onClick={(): Promise<void> => handleGrade({ shouldShowNotification: true })}
            disabled={shouldDisabledActions}
          >
            {t("general.save")}
          </Button>
        ) : (
          <>
            <Button
              isLoading={isGradeLoading}
              color="success"
              disabled={shouldDisabledActions}
              onClick={(): Promise<void> => handleGrade({ status: passed })}
            >
              {t("general.progress.passed")}
            </Button>
            <Button
              isLoading={isGradeLoading}
              color="danger"
              disabled={shouldDisabledActions}
              onClick={(): Promise<void> => handleGrade({ status: notPassed })}
            >
              {t("general.progress.notPassed")}
            </Button>
          </>
        ))}

      <Button color="secondary" onClick={onCloseDrawer}>
        {t("general.cancel")}
      </Button>
    </div>
  );
};

export default GradeCustomFooter;

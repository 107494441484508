import { useEffect, useState } from "react";

export const useScrollPosition = (): number => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = (): void => {
      setScrollPosition(document.documentElement.scrollTop || document.body.scrollTop);
    };

    document.addEventListener("scroll", updatePosition);

    updatePosition();

    return (): void => document.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

import { useResponsive } from "ahooks";
import React, { FC } from "react";

const SIZE = 40;

type CircleProps = {
  percentage: number;
  color: string;
};

const Circle: FC<CircleProps> = ({ percentage, color }) => {
  const radius = SIZE / 2 - 5;
  const totalCircumference = 2 * Math.PI * radius;
  const strokeCircumference = ((100 - percentage) * totalCircumference) / 100;

  return (
    <circle
      r={radius}
      cx={SIZE / 2}
      cy={SIZE / 2}
      fill="transparent"
      stroke={color}
      strokeWidth={`${SIZE * 0.1}`}
      strokeDasharray={totalCircumference}
      strokeDashoffset={percentage ? strokeCircumference : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const CircularProgress: FC<{ currentValue: number; maxValue: number }> = ({
  currentValue,
  maxValue,
}) => {
  const getCleanPercentage = (num: number): number => Math.min(100, Math.max(0, num));
  const { lg } = useResponsive();
  const cleanPercentage = getCleanPercentage((currentValue / maxValue) * 100);
  const outerStrokeColor = lg ? "#ffffff" : "#74B0EA40";

  return (
    <svg width={SIZE} height={SIZE}>
      <g transform={`rotate(90 ${SIZE / 2} ${SIZE / 2})`}>
        <Circle color={outerStrokeColor} percentage={100} />
        <Circle color="#103E6B" percentage={cleanPercentage} />
      </g>
      {/* Since gnosis Text components are span behind the scenes, they cannot be rendered inside svg */}
      <text
        x="50%"
        y="50%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize={`${SIZE * 0.29}px`}
        fontWeight={700}
      >
        {currentValue}
      </text>
    </svg>
  );
};

export default CircularProgress;

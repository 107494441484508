import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { FileCheckSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type CompleteUnitActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  onClick: (e: MouseEvent) => void;
};

const CompleteUnitAction: FC<CompleteUnitActionProps> = ({
  tooltipContent,
  icon: Icon = FileCheckSVG,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.actions.complete")}
      onClick={onClick}
      data-testid="complete-unit-action"
    >
      <Icon height={30} />
    </ActionButton>
  );
};

export default CompleteUnitAction;

import { css } from "@emotion/react";

export const SmartTagsSelect = ({ formElements }) => css`
  .smart-tags-select {
    [class$="menu"] {
      position: relative !important;
    }
  }

  .select-input-wrapper {
    background: none !important;

    .control-md {
      background-color: ${formElements.input.background};
    }
  }
`;

import React, { FC } from "react";
import { Grid, Input, InputError, ToggleSwitch } from "@epignosis_llc/gnosis";
import { DurationIconSVG } from "@epignosis_llc/gnosis/icons";
import { TextWithIcon } from "@components";
import { Controller, UseFormReturn } from "react-hook-form";
import { Course } from "types/entities";
import { CourseAvailabilityFormData } from "@views/CourseEdit/types";
import { useApplyTranslations } from "@hooks";
import { courseOptionsIds } from "../constants";

type AccessOptionsProps = {
  course: Course;
  form: UseFormReturn<CourseAvailabilityFormData>;
};

const AccessOptions: FC<AccessOptionsProps> = ({ course, form }) => {
  const { t } = useApplyTranslations();
  const { policies } = course;
  const { can_update_level = false } = policies ?? {};
  const showLevel = can_update_level;

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <div id={courseOptionsIds.accessOptions}>
      <TextWithIcon icon={<DurationIconSVG height={32} />} label={t("courseEdit.accessOptions")} />

      <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} className="grid-container">
        <Grid.Item colSpan={1}>
          <Controller
            name="capacity"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => (
              <Input
                id="capacity"
                label={t("general.capacity")}
                type="number"
                value={value ?? undefined}
                min={1}
                step={1}
                placeholder={t("general.capacity")}
                tooltipContent={t("courseEdit.capacityTooltip")}
                status={errors.capacity ? "error" : "valid"}
                onChange={(e): void => {
                  const capacity = e.target.value;
                  onChange(capacity ? capacity : null);
                }}
              />
            )}
          />
          {errors.capacity && <InputError>{errors.capacity.message}</InputError>}
        </Grid.Item>

        {showLevel && (
          <Grid.Item colSpan={1}>
            <Controller
              name="level"
              control={control}
              render={({ field: { onChange, value } }): JSX.Element => (
                <Input
                  id="level"
                  label={t("general.level")}
                  type="number"
                  value={value ?? undefined}
                  min={1}
                  max={20}
                  step={1}
                  placeholder={t("general.level")}
                  tooltipContent={t("courseEdit.levelTooltip")}
                  status={errors.level ? "error" : "valid"}
                  onChange={(e): void => {
                    const level = e.target.value;
                    onChange(level ? level : null);
                  }}
                />
              )}
            />
            {errors.level && <InputError>{errors.level.message}</InputError>}
          </Grid.Item>
        )}

        <Grid.Item colSpan={[1, 1, 1, 2]} className="toggle-option">
          <Controller
            name="is_active"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => {
              return (
                <ToggleSwitch
                  data-testid="active-course"
                  labelAfter={t("courseEdit.makeCourseActive")}
                  defaultChecked={value}
                  onChange={(): void => onChange(!value)}
                />
              );
            }}
          />
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default AccessOptions;

import { MutationOptions, UseMutationResult, useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { UpdateUnitData, patchUnit, deleteBookmarks, publishUnit } from "./api";
import queryKeys from "@constants/queryKeys";
import { postCourseFile } from "@api/courses";
import { UploadUnitType } from "types/entities/Unit";
import { FileRes } from "types/responses";
import { generalNotification } from "@utils/helpers";
import { t } from "@utils/i18n";

export const useUpdateUnit = ({
  unitId,
  options,
}: {
  unitId: string;
  options?: MutationOptions<unknown, AxiosError, UpdateUnitData, unknown>;
}): UseMutationResult<unknown, AxiosError, UpdateUnitData, unknown> => {
  return useMutation(
    [queryKeys.courses.unitUpdate, unitId],
    (data: UpdateUnitData) => patchUnit(unitId, data),
    options,
  );
};

export const useDeleteBookmarks = ({
  unitId,
  options,
}: {
  unitId: string;
  options?: MutationOptions<unknown, unknown, unknown, unknown>;
}): UseMutationResult<unknown, unknown, unknown, unknown> => {
  return useMutation(
    [queryKeys.courses.unitUpdate, unitId],
    () => deleteBookmarks(unitId),
    options,
  );
};

export const usePostCourseFile = ({
  courseId,
  uploadUnitType,
  options,
}: {
  courseId: string;
  uploadUnitType: UploadUnitType;
  options?: MutationOptions<unknown, unknown, File, unknown>;
}): UseMutationResult<FileRes, unknown, File, unknown> => {
  return useMutation(
    [queryKeys.courses.postFile, courseId],
    (file: File) => postCourseFile({ courseId, file, uploadUnitType }),
    options,
  );
};

export const usePublishUnitMutation = ({
  courseId,
  unitId,
  isActive,
}: {
  courseId: string;
  unitId: string;
  isActive: boolean;
}): UseMutationResult<unknown, unknown, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation([queryKeys.courses.unitPublish, unitId], publishUnit, {
    onSuccess: (): void => {
      queryClient.invalidateQueries([queryKeys.units, courseId]);
      generalNotification(
        "success",
        t(`unitEdit.${isActive ? "updatedSuccessfully" : "publishedSuccessfully"}`),
      );
    },
    onError: (err: unknown): void => {
      const error = err as AxiosError;
      generalNotification("error", error?.response?.data._errors[0].title);
    },
  });
};

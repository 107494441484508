import { css } from "@emotion/react";

// UNIT CREATION MENU
export const DropdownStyles = ({ units }) => css`
  margin: 1rem;
  position: relative;

  .create-unit-dropdown {
    position: absolute;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    border: 1px solid ${units.unitsCreationMenu.border};
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    bottom: 48px;

    .main-menu {
      margin: 0;
      padding: 0.5rem;
    }

    // Menu item
    .main-menu > li {
      display: flex;
      height: 63px;
      padding: 0.5rem;
      gap: 0.5rem;
      align-items: center;
      border-radius: 0.5rem;
      cursor: pointer;

      // Creates a border for the first element of the menu in order to separate it from the rest units.
      &:first-of-type {
        border-bottom: 1px solid ${units.unitsCreationMenu.hover};
      }

      .new-tag {
        align-self: flex-start;
        padding: 0 0.5rem;
        background: ${units.unitsCreationMenu.background};
        color: ${units.unitsCreationMenu.textColor};
        font-size: 11px;
        border-radius: 10px;
      }

      // Put all the icons on the end (arrows, new icon)
      svg:nth-of-type(2) {
        margin-inline-start: auto;
      }
    }

    .main-menu li {
      position: relative;
      display: flex;
      padding: 0.5rem;

      &.active {
        background-color: ${units.unitsCreationMenu.hover};

        > .sub-menu {
          display: block;
        }
      }
    }

    .menu-item {
      display: flex;
      flex-direction: column;

      // Unit title
      .menu-title {
        font-size: 0.875rem;
      }

      // Unit description
      .menu-description {
        color: ${units.unitsCreationMenu.description};
        font-size: 0.75rem;
      }
    }

    .back-container {
      padding: 0.5rem;
      border-bottom: 1px solid ${units.unitsCreationMenu.hover};

      button {
        display: flex;
        align-items: center;
        background: none;
        border: none;
      }
    }

    // Sub-menu Styles
    .sub-menu {
      position: absolute;
      display: none;
      width: 100%;
      inset-inline-start: 100%;
      top: 0;
      padding: 0 0.5rem;
      margin: 0;
      z-index: 100;

      .inner-container {
        margin-inline-start: 0.3125rem;
        border-radius: 0.3125rem;
        background: white;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        overflow-x: auto;
      }

      li {
        padding: 0.3125rem 0.5rem;

        &:hover {
          background-color: ${units.unitsCreationMenu.hover};
        }
      }

      .menu-item {
        span {
          display: flex;
          align-items: center;
        }
      }
    }

    // Reset styles on mobile devices.
    &.mobile {
      .main-menu li {
        position: unset;

        > .sub-menu {
          inset-inline-start: 0;
          height: 100%;
        }

        &.active {
          > .sub-menu {
            display: block;
          }
        }
      }

      .main-menu {
        padding: 0;
      }

      .sub-menu {
        padding: 0;

        .inner-container {
          margin: 0;
          height: 100%;
        }

        li {
          padding: 0 0.5rem;
        }
      }
    }
  }

  .add-unit-btn {
    width: 100%;
    justify-content: start;
    padding-inline: 1rem 0.75rem;

    .btn-text {
      width: 100%;
    }

    .icon {
      margin: 0;
      fill: currentColor;

      &:first-of-type {
        margin-inline-end: 0.5rem;
      }
    }
  }
`;

export const TalentCraftDisclaimer = () => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 0.75rem;
`;

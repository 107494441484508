import { PalletThemeColors, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["uploadArea"] => {
  return {
    grayBase: colors.secondary.base,
    uploadFile: {
      borderColor: hexToRGBA(colors.primary.lightest.toString(), 0.25),
      textColor: colors.primary.light,
      textHoverColor: colors.primary.lighter,
    },
  };
};

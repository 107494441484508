import { Theme } from "types/entities";

export const defaultThemes: Theme[] = [
  {
    id: "defaultBlue",
    active: false,
    default: true,
    name: "Fun blue",
    primary_color: "#1B68B3",
  },
  {
    id: "defaultOrange",
    active: false,
    default: true,
    name: "Ecstasy",
    primary_color: "#F77A21",
  },
  {
    id: "defaultGreen",
    active: false,
    default: true,
    name: "Chateau green",
    primary_color: "#41BD54",
  },
  {
    id: "defaultRed",
    active: false,
    default: true,
    name: "Danger red",
    primary_color: "#F20000",
  },
  {
    id: "defaultTurquoise",
    active: false,
    default: true,
    name: "Bright turquoise",
    primary_color: "#2CD1F8",
  },
  {
    id: "defaultBlack",
    active: false,
    default: true,
    name: "Charcoal black",
    primary_color: "#232323",
  },
  // {
  //   id: "defaultGray",
  //   active: false,
  //   default: true,
  //   name: "Athens gray",
  //   primary_color: "#F3F5F7",
  // },
];

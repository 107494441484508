import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { RotateLeftSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type ResetUnitActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  onClick: (e: MouseEvent) => void;
};

const ResetUnitAction: FC<ResetUnitActionProps> = ({
  tooltipContent,
  icon: Icon = RotateLeftSVG,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.actions.reset")}
      onClick={onClick}
      data-testid="reset-unit-action"
    >
      <Icon height={16} />
    </ActionButton>
  );
};

export default ResetUnitAction;

import { css } from "@emotion/react";

export const AICreditsDrawerContainer = css`
  .credits-container {
    display: flex;
    justify-content: center;
    min-height: 300px;
  }

  .AI-text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .request-container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

import React, { FC, useEffect, useState } from "react";
import { Avatar } from "@epignosis_llc/gnosis";
import { useTheme } from "@emotion/react";
import { Image } from "types/entities";
import { isGravatarUrl } from "@utils/helpers";

type UserAvatarProps = React.HTMLAttributes<HTMLElement> & {
  avatar?: Image | null;
  file?: File | string | null;
  imageSize: "sm" | "md" | "lg" | "xl";
  avatarSize: "xs" | "sm" | "md" | "lg" | "xl";
  name: string;
  alt?: string;
  bgColor?: string;
};

const UserAvatar: FC<UserAvatarProps> = ({
  avatar,
  imageSize,
  avatarSize,
  name,
  alt,
  file,
  bgColor,
  ...rest
}) => {
  const {
    avatar: { background },
  } = useTheme();
  const backgroundColor = bgColor ?? background;

  const [url, setUrl] = useState<string | null>(null);
  const defaultUrl = avatar?.md;
  const isGravatar = isGravatarUrl(defaultUrl ?? "");

  useEffect(() => {
    if (avatar) {
      setUrl(isGravatar ? defaultUrl ?? null : avatar[imageSize] ?? avatar.default);
    } else if (file) {
      typeof file === "string" ? setUrl(file) : setUrl(URL.createObjectURL(file));
    } else {
      setUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar, file, imageSize]);

  return (
    <div style={{ position: "relative" }} {...rest}>
      {url && (
        <>
          <div style={{ position: "absolute" }}>
            <Avatar src={url} alt={alt} size={avatarSize} />
          </div>
        </>
      )}
      <Avatar bgColor={backgroundColor} size={avatarSize}>
        {name.slice(0, 1).toUpperCase()}
      </Avatar>
    </div>
  );
};

export default UserAvatar;

import React, { ReactElement } from "react";
import { useApplyTranslations } from "@hooks";
import { SSOSettings } from "../types";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { Button, Input, InputError, Select, Text, ToggleSwitch } from "@epignosis_llc/gnosis";
import { CheckCircledSVG } from "@epignosis_llc/gnosis/icons";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { BorderedSection } from "@components";
import { SSOLoginTypeOptions, guideToLogoutEndpoint } from "../constants";
import { Option } from "@components/MainSearch/types";
import LinkText from "./LinkText";
import { formatLabelSelect } from "@views/AccountAndSettings/components/SettingCard/helpers";
import { CustomField } from "types/entities";
import { customUserFieldsIdsToOptions, customUserFieldsToOptions } from "../helpers";
import { SelectOption } from "types/common";
import { optionsToCustomFieldsIds } from "@utils/helpers";

export type OidcFormProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  control: Control<T>;
  SSOSettings?: SSOSettings;
  customUserFields: CustomField[];
  errors: FieldErrors<T>;
  watch?: UseFormWatch<T>;
  isDisabled: boolean;
  setIsTestClicked: React.Dispatch<React.SetStateAction<boolean>>;
  origin?: "branch" | "portal";
};

const logoutEndpointDescription = (
  <Text fontSize={"sm"}>
    <Trans i18nKey="accountAndSettings.usersSettings.oidc.logoutEndpointInfo">
      <Link to={guideToLogoutEndpoint} className="link" target="_blank">
        Learn more
      </Link>
    </Trans>
  </Text>
);

const OidcForm = <T extends FieldValues>({
  SSOSettings,
  customUserFields,
  control,
  watch,
  register,
  errors,
  isDisabled,
  setIsTestClicked,
  origin = "portal",
}: OidcFormProps<T>): JSX.Element => {
  const { t, i18n } = useApplyTranslations();
  const dir = i18n.dir();

  const availableSSOLoginTypeOptions = SSOLoginTypeOptions?.map((value) => ({
    label: formatLabelSelect("login_type", value, []),
    value: value,
  }));

  const idsPrefix = origin === "branch" ? "sso.oidc" : "oidc";
  const loginTypePrefix = origin === "branch" ? "sso" : "";
  const errorPath = origin === "branch" ? errors?.sso?.["oidc"] : errors?.oidc;

  const authorizedRedirectURLDefaultValue =
    origin === "branch"
      ? watch?.("sso.oidc.idp_configuration.authorized_redirect_url" as Path<T>) ?? ""
      : SSOSettings?.oidc?.idp_configuration?.authorized_redirect_url ?? "";

  const postLogoutRedirectURLDefaultValue =
    origin === "branch"
      ? watch?.("sso.oidc.idp_configuration.post_logout_redirect_url" as Path<T>) ?? ""
      : SSOSettings?.oidc?.idp_configuration?.post_logout_redirect_url ?? "";

  return (
    <>
      <div className="form-wrapper">
        <div className="form-container">
          <div className="single-line-container">
            <div className="input-error-container">
              <Input
                {...register(`${idsPrefix}.client_id` as Path<T>)}
                id="oidc.client_id"
                placeholder={t("general.typeHere")}
                label={t("accountAndSettings.usersSettings.oidc.clientID")}
                status={errorPath?.client_id ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.client_id && <InputError>{errorPath?.client_id?.message}</InputError>}
            </div>
            <div className="input-error-container right-container">
              <Input
                {...register(`${idsPrefix}.client_secret` as Path<T>)}
                id="oidc.client_secret"
                placeholder={t("general.typeHere")}
                label={t("accountAndSettings.usersSettings.oidc.clientSecret")}
                status={errorPath?.client_secret ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.client_secret && (
                <InputError>{errorPath?.client_secret?.message}</InputError>
              )}
            </div>
          </div>

          <div className="single-line-container">
            <div className="input-error-container">
              <Input
                {...register(`${idsPrefix}.token_url` as Path<T>)}
                id="oidc.token_url"
                placeholder={t("general.typeHere")}
                label={t("accountAndSettings.usersSettings.oidc.tokenEndpoint")}
                status={errorPath?.token_url ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.token_url && <InputError>{errorPath?.token_url?.message}</InputError>}
            </div>
            <div className="input-error-container right-container">
              <Input
                {...register(`${idsPrefix}.user_info_url` as Path<T>)}
                id="oidc.user_info_url"
                placeholder={t("general.typeHere")}
                label={t("accountAndSettings.usersSettings.oidc.userInfoEndpoint")}
                status={errorPath?.user_info_url ? "error" : "valid"}
                data-lpignore="true"
                disabled={isDisabled}
              />
              {errorPath?.user_info_url && (
                <InputError>{errorPath?.user_info_url?.message}</InputError>
              )}
            </div>
          </div>

          <div className="form-item">
            <Input
              {...register(`${idsPrefix}.authorization_url` as Path<T>)}
              id="oidc.authorization_url"
              placeholder={t("general.typeHere")}
              label={t("accountAndSettings.usersSettings.oidc.authorizationEndpoint")}
              status={errorPath?.authorization_url ? "error" : "valid"}
              data-lpignore="true"
              disabled={isDisabled}
            />
            {errorPath?.authorization_url && (
              <InputError>{errorPath?.authorization_url.message}</InputError>
            )}
          </div>

          <div className="form-item last">
            <Input
              {...register(`${idsPrefix}.logout_url` as Path<T>)}
              id="oidc.logout_url"
              placeholder={t("general.typeHere")}
              label={t("accountAndSettings.usersSettings.oidc.logoutEndpoint")}
              status={errorPath?.logout_url ? "error" : "valid"}
              data-lpignore="true"
              disabled={isDisabled}
            />
            {errorPath?.logout_url && <InputError>{errorPath?.logout_url.message}</InputError>}
            <div className="subtitle">{logoutEndpointDescription}</div>
          </div>
        </div>
      </div>

      <div className="form-container">
        <div className="form-item">
          <Input
            {...register(`${idsPrefix}.username` as Path<T>)}
            id="oidc.username"
            placeholder={t("general.typeHere")}
            label={t("accountAndSettings.usersSettings.oidc.username")}
            status={errorPath?.username ? "error" : "valid"}
            data-lpignore="true"
            disabled={isDisabled}
          />
          {errorPath?.username && <InputError>{errorPath?.username.message}</InputError>}
        </div>

        <div className="single-line-container">
          <div className="input-error-container">
            <Input
              {...register(`${idsPrefix}.first_name` as Path<T>)}
              id="oidc.first_name"
              placeholder={t("general.typeHere")}
              label={t("accountAndSettings.usersSettings.oidc.firstName")}
              status={errorPath?.first_name ? "error" : "valid"}
              data-lpignore="true"
              disabled={isDisabled}
            />
            {errorPath?.first_name && <InputError>{errorPath?.first_name?.message}</InputError>}
          </div>
          <div className="input-error-container right-container">
            <Input
              {...register(`${idsPrefix}.last_name` as Path<T>)}
              id="oidc.last_name"
              placeholder={t("general.typeHere")}
              label={t("accountAndSettings.usersSettings.oidc.lastName")}
              status={errorPath?.last_name ? "error" : "valid"}
              data-lpignore="true"
              disabled={isDisabled}
            />
            {errorPath?.last_name && <InputError>{errorPath?.last_name?.message}</InputError>}
          </div>
        </div>
        <div className="form-item">
          <Input
            {...register(`${idsPrefix}.email` as Path<T>)}
            id="oidc.email"
            label={t("general.email")}
            placeholder={t("general.typeHere")}
            status={errorPath?.email ? "error" : "valid"}
            data-lpignore="true"
            disabled={isDisabled}
          />
          {errorPath?.email && <InputError>{errorPath?.email.message}</InputError>}
        </div>

        <div className="single-line-container line-with-toggle">
          <div className="input-error-container">
            <Input
              {...register(`${idsPrefix}.group` as Path<T>)}
              id="oidc.group"
              placeholder={t("general.typeHere")}
              label={t("general.group")}
              status={errorPath?.group ? "error" : "valid"}
              data-lpignore="true"
              disabled={isDisabled}
            />
            {errorPath?.group && <InputError>{errorPath?.group?.message}</InputError>}
          </div>
          <div className="input-error-container right-container line-with-toggle">
            <Controller
              name={`${idsPrefix}.add_group_on_each_login` as Path<T>}
              control={control}
              render={({ field: { onChange, value } }): JSX.Element => (
                <div className="toggle-container">
                  <ToggleSwitch
                    labelAfter={t("accountAndSettings.usersSettings.oidc.addGroups")}
                    defaultChecked={value}
                    isDisabled={isDisabled}
                    onChange={(): void => {
                      onChange(!value);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="form-item">
          <Input
            {...register(`${idsPrefix}.scope` as Path<T>)}
            id="oidc.scope"
            placeholder={t("general.typeHereOptional")}
            label={t("accountAndSettings.usersSettings.oidc.scope")}
            status={errorPath?.scope ? "error" : "valid"}
            data-lpignore="true"
            disabled={isDisabled}
          />
          {errorPath?.scope && <InputError>{errorPath?.scope.message}</InputError>}
        </div>

        <div className="custom-user-field-container">
          <Controller
            name={`${idsPrefix}.custom_fields.fields` as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): ReactElement => (
              <Select
                placeholder={t("accountAndSettings.usersSettings.oidc.selectCustomFields")}
                label={t("accountAndSettings.usersSettings.oidc.customFields")}
                options={customUserFieldsToOptions(customUserFields)}
                isMulti
                isSearchable
                isClearable
                value={customUserFieldsIdsToOptions((value ?? []) as number[], customUserFields)}
                defaultValue={customUserFieldsIdsToOptions(
                  (value ?? []) as number[],
                  customUserFields,
                )}
                onChange={(options): void => {
                  const customUserFieldsOptions = optionsToCustomFieldsIds(
                    options as SelectOption[],
                  );
                  onChange(customUserFieldsOptions);
                }}
                maxMenuHeight={150}
              />
            )}
          />
        </div>

        <div className="form-item">
          <Controller
            name={`${idsPrefix}.custom_fields.ignore_namespace` as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => (
              <div className="toggle-container">
                <ToggleSwitch
                  labelAfter={t("accountAndSettings.usersSettings.oidc.ignoreNamespace")}
                  defaultChecked={value}
                  isDisabled={isDisabled}
                  onChange={(): void => {
                    onChange(!value);
                  }}
                />
              </div>
            )}
          />
        </div>

        <Button
          variant="outline"
          className="check-configuration-button"
          iconBefore={CheckCircledSVG}
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            e.preventDefault();
            setIsTestClicked(true);
          }}
        >
          {t("accountAndSettings.usersSettings.oidc.saveAndCheckConfiguration")}
        </Button>

        <BorderedSection>
          <Text fontSize={"sm"} weight="700">
            {t("accountAndSettings.usersSettings.oidc.identityProviderConfig")}
          </Text>
          <LinkText
            text={t("accountAndSettings.usersSettings.oidc.authorizedRedirectURL")}
            url={authorizedRedirectURLDefaultValue}
          />
          <LinkText
            text={t("accountAndSettings.usersSettings.oidc.postLogoutRedirectURL")}
            url={postLogoutRedirectURLDefaultValue}
          />
        </BorderedSection>

        <div className="sso-login-screen">
          <Controller
            name={`${loginTypePrefix}.login_type` as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): ReactElement => {
              const selectedValue = {
                label: formatLabelSelect("login_type", value),
                value: value ?? "",
              };
              return (
                <Select
                  id="login_type"
                  label={t("accountAndSettings.usersSettings.SSOLoginScreen")}
                  isRtl={dir === "rtl"}
                  options={availableSSOLoginTypeOptions}
                  isDisabled={isDisabled}
                  onChange={(option): void => {
                    onChange((option as Option).value);
                  }}
                  value={selectedValue}
                />
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OidcForm;

import { css } from "@emotion/react";

export const massActionsCountContainer = ({ button }, isDropdownOpen, variant) => css`
  .mass-action-count {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${variant === "solid"
      ? button.primary.default.color
      : isDropdownOpen
      ? button.primary.default.color
      : button.primary.default.background};
    color: ${isDropdownOpen ? button.primary.default.background : button.primary.default.color};
    color: ${variant === "solid"
      ? button.primary.default.background
      : isDropdownOpen
      ? button.primary.default.background
      : button.primary.default.color};
    padding: 4px;
    height: 21px;
    width: 30px;
    border-radius: 10px;
    font-size: 12px;
  }
`;

import { URLS } from "@constants/urls";

type GetCourseLinkParams = {
  link?: string;
  isExternalView: boolean;
  courseId: string;
};

export const getCourseLink = ({ link, isExternalView, courseId }: GetCourseLinkParams): string => {
  return link
    ? link
    : isExternalView
    ? URLS.externalCatalog.createCourseLink({ courseId })
    : URLS.catalog.createCourseLink({ courseId });
};

import { PalletThemeColors, getProtectedColor, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["chip"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    textColor: colors.black,
    success: {
      background: protectColorContrast("darker", hexToRGBA(colors.primary.lightest, 0.25), 0.25),
      color: colors.primary.base,
    },
    failed: {
      background: protectColorContrast("darker", hexToRGBA(colors.red.lightest, 0.25), 0.25),
      color: colors.red.base,
    },
    disabled: {
      background: colors.secondary.lighter,
    },
  };
};

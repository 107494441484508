import React, { FC } from "react";
import { Select } from "@epignosis_llc/gnosis";
import { Controller, UseFormReturn } from "react-hook-form";
import { SelectOption } from "types/common";
import { CertificateDuration } from "types/entities";
import { EditCourseData } from "@views/CourseEdit/api";
import { useApplyTranslations } from "@hooks";

type DurationTypeProps = {
  form: UseFormReturn<EditCourseData>;
};

const DurationTypeSelect: FC<DurationTypeProps> = ({ form }) => {
  const { t } = useApplyTranslations();
  const { control, setValue, trigger } = form;

  const durationTypeOptions: SelectOption[] = [
    { value: CertificateDuration.Forever, label: t("certificates.forever") },
    { value: CertificateDuration.OneMonth, label: t("certificates.month", { count: 1 }) },
    { value: CertificateDuration.ThreeMonths, label: t("certificates.month", { count: 3 }) },
    { value: CertificateDuration.SixMonths, label: t("certificates.month", { count: 6 }) },
    { value: CertificateDuration.OneYear, label: t("certificates.year", { count: 1 }) },
    { value: CertificateDuration.TwoYears, label: t("certificates.year", { count: 2 }) },
    { value: CertificateDuration.ThreeYears, label: t("certificates.year", { count: 3 }) },
    { value: CertificateDuration.Custom, label: t("certificates.customDuration") },
    { value: CertificateDuration.Date, label: t("certificates.pickExpirationDate") },
  ];

  const onDurationTypeChange = (value: string): void => {
    // reassign_when must be null when duration_type is Forever
    if (value === CertificateDuration.Forever) {
      setValue("certificate.reassign_when", null);
    }

    // duration_days must not be set when duration_type is not Custom
    if (value !== CertificateDuration.Custom) {
      setValue("certificate.duration_days", undefined);
    }

    // expiration_day and expiration_month must not be set when duration_type is not Date
    if (value !== CertificateDuration.Date) {
      setValue("certificate.expiration_day", undefined);
      setValue("certificate.expiration_month", undefined);
    }

    trigger(["certificate.reassign_when"]);
  };

  return (
    <Controller
      name="certificate.duration_type"
      control={control}
      render={({ field: { value, onChange } }): JSX.Element => {
        const selectedOption = durationTypeOptions.find((template) => template.value === value);

        return (
          <Select
            label={t("general.duration")}
            options={durationTypeOptions}
            value={selectedOption}
            onChange={(option): void => {
              const { value } = (option as SelectOption) ?? {};
              onChange(value);
              onDurationTypeChange(value);
            }}
          />
        );
      }}
    />
  );
};

export default DurationTypeSelect;

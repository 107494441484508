import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { SubmissionStatus, SubmissionStatusEnum } from "types/entities/Assignments";
import { useApplyTranslations } from "@hooks";

type MassActionGradingFooterProps = {
  isGradeLoading: boolean;
  closeDrawer: () => void;
  handleGrade: (status: SubmissionStatus) => void;
};

const MassActionGradingFooter: FC<MassActionGradingFooterProps> = ({
  isGradeLoading,
  closeDrawer,
  handleGrade,
}) => {
  const { t } = useApplyTranslations();

  return (
    <div>
      <>
        <Button
          isLoading={isGradeLoading}
          color="success"
          onClick={(): void => handleGrade(SubmissionStatusEnum.passed)}
        >
          {t("general.progress.passed")}
        </Button>
        <Button
          isLoading={isGradeLoading}
          color="danger"
          onClick={(): void => handleGrade(SubmissionStatusEnum.notPassed)}
        >
          {t("general.progress.notPassed")}
        </Button>
      </>

      <Button color="secondary" onClick={closeDrawer}>
        {t("general.cancel")}
      </Button>
    </div>
  );
};

export default MassActionGradingFooter;

import { CourseMicrostats } from "types/entities/Courses";
import { UnitMicrostats } from "types/entities/Unit";
import { ENDPOINTS } from "./endpoints";
import HttpClient from "./HttpClient";

type UnitMicrostatsRes = {
  _data: UnitMicrostats;
};

type ReportsMicrostatsRes = {
  _data: CourseMicrostats;
};

export const getUnitMicrostats = async (
  type: string,
  id: string,
  queryStr = "",
): Promise<UnitMicrostatsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.microstats.unitMicrostats(type, id)}${queryStr}`);

  return res.data;
};

export const getReportsMicrostats = async (
  type: string,
  queryStr = "",
): Promise<ReportsMicrostatsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.microstats.reportsMicrostats(type)}${queryStr}`);

  return res.data;
};

export const getSingleReportsMicrostats = async (
  type: string,
  id: string,
  queryStr = "",
): Promise<ReportsMicrostatsRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.microstats.singleReportsMicrostats(type, id)}${queryStr}`,
  );

  return res.data;
};

import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["assignment"] => {
  return {
    drawer: {
      infoField: colors.secondary.base,
    },
  };
};

import { css } from "@emotion/react";

export const tocTopStyles = ({ unitsToC: { top } }, { isOpen }) => css`
  background-color: ${top.backgroundColor};
  height: 3.5rem;
  min-height: 3.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: ${isOpen ? "start" : "center"};
  transition: 0.2s all ease;
`;

export const tocHeaderStyles = ({ unitsToC: { header } }) => css`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: ${header.backgroundColor};
  padding: 1.5rem 1rem;
  opacity: 1;
  transition: opacity 0.2s ease;

  &.hidden {
    opacity: 0;
    display: none;
    transition: opacity 0.1s ease;
  }

  &:hover .dropdown {
    visibility: visible;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }

  .dropdown {
    visibility: hidden;
  }
`;

export const tocListContainerStyles = css`
  overflow-y: auto;
  flex: 1;
  opacity: 1;
  transition: opacity 0.2s ease;

  &.hidden {
    opacity: 0;
    display: none;
    transition: opacity 0.1s ease;
  }
`;

export const tocListTitleStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
  padding: 0.5rem;
  height: 3.5rem;
  visibility: visible;
  margin-inline-start: 2.5rem;

  .title-container {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }
`;

export const listItemStyles = ({ unitsToC: { listItem } }, { disabled, readonly }) => css`
  display: flex;
  align-items: center;
  margin-inline-start: 0rem;
  visibility: visible;
  position: relative;
  transition: background-color 0.1s ease-in;
  border-top: 1px solid transparent;

  &.is-active {
    background-color: ${listItem.hoverColor};
    border-top: 1px solid ${listItem.borderColor};

    &::before {
      content: "";
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 5px;
      height: 100%;
      background: ${listItem.activeColor};
    }

    .list-item-children {
      visibility: visible !important;
    }
  }

  &:hover {
    background-color: ${!disabled && !readonly ? listItem.hoverColor : "initial"};
    border-top: 1px solid ${listItem.borderColor};

    .list-item-children {
      visibility: visible !important;
    }
  }

  .list-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
    height: 4rem;
    min-height: 4rem;
    padding: 0.5rem;
    cursor: ${!disabled ? "pointer" : "default"};

    .list-item-content {
      display: flex;
      align-items: center;
      width: 100%;
      color: ${disabled ? listItem.disabledColor : "initial"};

      .list-item-icon {
        margin-inline-end: 0.5rem;
        line-height: 0;

        svg {
          fill: ${disabled ? listItem.disabledColor : "initial"};
        }
      }

      .list-item {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .list-item-text {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }
    }

    .list-item-children {
      visibility: hidden;
      width: fit-content;
    }
  }
`;

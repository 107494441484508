import React, { Dispatch, FC, SetStateAction } from "react";
import { Tabs } from "@epignosis_llc/gnosis";

import { helpContainer } from "./styles";

import { ContactSupport, HelpArticles } from "./components";

import { useApplyTranslations, useTabNavigation } from "@hooks";
import { useConfigurationStore } from "@stores";
import { TabKey } from "./constants";
import { Article } from "./types";
import { AvailableTabsType } from "types/entities/Common";

type HelpCenterProps = {
  setHelpContent?: Dispatch<SetStateAction<string | null>>;
  setSelectedArticle?: Dispatch<SetStateAction<Article | undefined>>;
  setTabKey?: Dispatch<SetStateAction<string>>;
  fullscreen?: boolean;
};
const HelpCenter: FC<HelpCenterProps> = ({
  setSelectedArticle,
  setHelpContent,
  setTabKey,
  fullscreen,
}) => {
  const { t } = useApplyTranslations();
  const { userProfileData, domainSettings } = useConfigurationStore();
  const { available_roles: availRoles } = userProfileData ?? {};
  const { main_portal } = domainSettings ?? {};
  const shouldShowContactSupport =
    availRoles?.includes("administrator") && fullscreen && main_portal;

  const availableTabs: AvailableTabsType[] = [
    {
      info: {
        id: TabKey.helpArticles,
        title: t("general.knowledgeBase"),
        content: (
          <HelpArticles setSelectedArticle={setSelectedArticle} setHelpContent={setHelpContent} />
        ),
      },
      permission: true,
    },
    {
      info: {
        id: TabKey.contactSupport,
        title: t("general.contactSupport"),
        content: <ContactSupport />,
      },
      permission: shouldShowContactSupport ? shouldShowContactSupport : false,
    },
  ];

  const { selectedTabIndex, visibleTabs, updateTabParam } = useTabNavigation(availableTabs);

  const handleTabChange = (newTabIndex: number): void => {
    const newTabKey = visibleTabs[newTabIndex].id;
    setTabKey && setTabKey(String(newTabKey));
    updateTabParam(newTabIndex);
  };

  return (
    <div css={helpContainer}>
      <Tabs tabs={visibleTabs} selectedTab={selectedTabIndex} onChangeTab={handleTabChange} />
    </div>
  );
};

export default HelpCenter;

// Packages or third-party libraries
import React, { FC } from "react";
import { Tag, Text } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";

// Styles
import { StatusTagStyles } from "./styles";

// Other imports
import { useApplyTranslations } from "@hooks";
import { IconType } from "types/common";

export enum TagColor {
  Inactive = "inactive",
  Unpublished = "unpublished",
  Pending = "pending",
}

type StatusTagProps = {
  color?: TagColor;
  text?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick?: () => void;
};

const StatusTag: FC<StatusTagProps> = ({
  color = TagColor.Inactive,
  text = "",
  icon: Icon,
  iconHeight = 16,
  onClick,
}) => {
  const { t } = useApplyTranslations();

  return (
    <Tag
      css={(theme): SerializedStyles => StatusTagStyles(theme, color, Boolean(Icon))}
      onClick={onClick}
    >
      {Icon && <Icon height={iconHeight} />}
      <Text fontSize="2xs" weight="700">
        {text ?? t("general.inactive")}
      </Text>
    </Tag>
  );
};
export default StatusTag;

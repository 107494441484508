import React, { FC, SyntheticEvent, useEffect } from "react";
import { useResponsive } from "ahooks";
import { Drawer } from "@epignosis_llc/gnosis";
import { MainDrawer } from "@layouts/DashboardLayout/components";
import { useUIStore, useUnitStore } from "@stores";
import { MainDrawerContent } from "@stores/ui";
import { drawerBody } from "@layouts/UnitLayout/components/styles";
import MainMenu from "@components/MainNav/MainMenu";
import { GlobalStyleVariables } from "@styles";
import { i18n } from "@utils/i18n";
const { mainDrawerMinWidth } = GlobalStyleVariables;

const UnitMainDrawer: FC = () => {
  const { md } = useResponsive();
  const { isMenuOpen, toggleIsMenuOpen } = useUnitStore();
  const { showMainDrawer, hideMainDrawer } = useUIStore((state) => state);
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "right" : "left";

  const openMainDrawer = (e: SyntheticEvent, contentType: MainDrawerContent): void => {
    e.preventDefault();
    showMainDrawer(contentType);
  };

  // Resets menu
  useEffect(() => {
    hideMainDrawer();
    toggleIsMenuOpen(false);
  }, [hideMainDrawer, toggleIsMenuOpen]);

  return (
    <>
      <MainDrawer layout="unit" />

      {md && (
        <Drawer
          isOpen={isMenuOpen}
          width={mainDrawerMinWidth}
          placement={placement}
          onClose={(): void => toggleIsMenuOpen(false)}
        >
          <Drawer.Body>
            <div css={drawerBody}>
              <MainMenu
                onToggle={(): void => toggleIsMenuOpen(false)}
                bottomMenuOnClick={openMainDrawer}
              />
            </div>
          </Drawer.Body>
        </Drawer>
      )}
    </>
  );
};

export default UnitMainDrawer;

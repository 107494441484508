import { css } from "@emotion/react";

export const widgetContainer = ({ achievementsStats }) => css`
  margin-bottom: 5rem;

  .title {
    margin-bottom: 1.5rem;
  }

  .badge-item-wrapper {
    padding: 1rem;

    &:hover {
      background-color: ${achievementsStats.certificateHoverBackground};
      box-shadow: 0px 0px 20px #00000029;
      border-radius: 10px;

      .criteria {
        visibility: visible;
      }
    }
  }
`;

export const badgeItemContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .name {
    margin-top: 0.5rem;
  }

  .criteria {
    visibility: hidden;
  }
`;

import React, { FC } from "react";
import HoverActions from "./HoverActions";
import OwnedAlreadyActions from "./OwnedAlreadyActions";
import { useConfigurationStore } from "@stores";

export type CourseStoreCardFooterProps = {
  isCourseAcquired: boolean;
};

const CourseStoreCardFooter: FC<CourseStoreCardFooterProps> = ({ isCourseAcquired }) => {
  const { domainSettings } = useConfigurationStore();
  const { features_allowed_in_plan } = domainSettings ?? {};
  const { talent_library: isTalentLibraryEnabled } = features_allowed_in_plan ?? {};

  if (isTalentLibraryEnabled && isCourseAcquired) {
    return <OwnedAlreadyActions />;
  }

  return <HoverActions />;
};

export default CourseStoreCardFooter;

import { capitalize } from "@utils/helpers";
import { t } from "@utils/i18n";
import { Subscription } from "types/entities/Catalog";

export const getPaymentNotificationTranslation = (
  type: string,
  isUserEnrolled: boolean,
  processor: string,
  name: string,
): string => {
  if (type === "payment") {
    return isUserEnrolled
      ? t("payments.paymentSuccess", { name })
      : t("payments.paymentProcessing", { processor: capitalize(processor), name });
  }

  return t("payments.subscriptions.subscribedSuccessfully");
};

export const getSubscriptionInterval = (interval: Subscription["interval"]): string => {
  if (interval === 1) return t("general.month", { count: 1 });

  return t("general.yearLowercase", { count: 1 });
};

export const getSubscriptionInterval2 = (interval: Subscription["interval"]): string => {
  if (interval === 1) return t("general.monthly", { count: 1 });

  return t("general.annually", { count: 1 });
};

import { css } from "@emotion/react";

export const alertBox = ({ alertBox }, { type }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  width: 100%;
  min-height: 4rem;
  border-radius: 5px;
  background: ${alertBox[type]?.backgroundColor};

  &.alert-box-centered {
    justify-content: center;
  }

  .alert-box {
    &-content {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &-icon {
      display: flex;
    }

    &-message {
      white-space: normal;
    }

    &-button {
      flex-shrink: 0;

      &.outline {
        background-color: ${alertBox.outlineButton.backgroundColor};

        &:hover {
          background-color: ${alertBox.outlineButton.hoverColor};
        }
      }
    }
  }
`;

import React, { FC } from "react";
import { Course, MyUnit } from "types/entities";
import CourseTocItem from "./CourseTocItem";
import { isTocItemActive } from "@utils/helpers";

type CourseTocItemsProps = {
  units: MyUnit[] | null;
  course: Course;
  mandatoryUnits: number[];
  incompleteUnits: MyUnit[];
  isReadonly: boolean;
  isLearnerView: boolean;
  isSequentialCourse: boolean;
};

const CourseTocItems: FC<CourseTocItemsProps> = ({
  units,
  course,
  mandatoryUnits,
  incompleteUnits,
  isReadonly,
  isLearnerView,
  isSequentialCourse,
}) => {
  const shownUnits = units?.filter((unit) => unit.is_active);

  return (
    <>
      {shownUnits?.map((unit) => {
        const { id } = unit;
        const isActive = isLearnerView
          ? isTocItemActive(unit, isSequentialCourse, incompleteUnits, isReadonly)
          : true;
        const isMandatory = mandatoryUnits.includes(id);

        return (
          <CourseTocItem
            key={id}
            course={course}
            unit={unit}
            isActive={isActive}
            isReadonly={isReadonly}
            isLearnerView={isLearnerView}
            isMandatory={isMandatory}
          />
        );
      })}
    </>
  );
};

export default CourseTocItems;

import React, { FC, useRef, useState } from "react";
import Cropper from "react-cropper";
import { Button } from "@epignosis_llc/gnosis";
import { imageEditorContainer } from "./styles";
import "cropperjs/dist/cropper.css";
import {
  FlipSVG,
  ImageDisplaySVG,
  RotateLeftSVG,
  RotateRightSVG,
} from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

type ImageEditorProps = {
  imageSrc: string;
  aspectRatio?: number;
  resizable?: boolean;
  croppedImageDimensions?: { width: number; height: number };
  onCropChanged: (canvas: HTMLCanvasElement) => void;
};

const MAX_ZOOM_VALUE = 5;
const ROTATE_ANGLE = 90;

const ImageEditor: FC<ImageEditorProps> = ({
  imageSrc,
  resizable = true,
  croppedImageDimensions,
  aspectRatio = 1,
  onCropChanged,
}) => {
  const { t } = useApplyTranslations();
  const [zoomValue, setZoomValue] = useState(0);
  const [minZoomValue, setMinZoomValue] = useState(0);
  const [imageSettings, setImageSettings] = useState({ scaleX: 1, scaleY: 1 });
  const { scaleX, scaleY } = imageSettings;

  const { width: staticCroppedWidth, height: staticCroppedHeight } = croppedImageDimensions ?? {};
  const cropperRef = useRef<HTMLImageElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imageElement: any = cropperRef?.current;

  const onRotate = (direction: "left" | "right"): void => {
    imageElement.cropper.rotate(direction === "left" ? -ROTATE_ANGLE : ROTATE_ANGLE);
  };

  const onHorizontalFlip = (): void => {
    const newValue = scaleX === 1 ? -1 : 1;
    imageElement.cropper.scaleX(newValue);
    setImageSettings({ ...imageSettings, scaleX: newValue });
  };

  const onVerticalFlip = (): void => {
    const newValue = scaleY === 1 ? -1 : 1;
    imageElement.cropper.scaleY(newValue);
    setImageSettings({ ...imageSettings, scaleY: newValue });
  };

  const handleZoomOut = (): void => {
    zoomValue > minZoomValue && setZoomValue(zoomValue - 0.1);
  };

  const handleZoomIn = (): void => {
    zoomValue < MAX_ZOOM_VALUE && setZoomValue(zoomValue + 0.1);
  };

  const onCrop = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const imageElement: any = cropperRef?.current;
    const croppedCanvasParams = croppedImageDimensions
      ? {
          width: staticCroppedWidth,
          height: staticCroppedHeight,
        }
      : undefined;
    const croppedCanvas = imageElement?.cropper?.getCroppedCanvas(croppedCanvasParams);

    onCropChanged(croppedCanvas);
  };

  const onZoom = (e: Cropper.ZoomEvent<HTMLImageElement>): void => {
    const ratio = e.detail.ratio;

    if (ratio < minZoomValue || ratio > MAX_ZOOM_VALUE) {
      e.preventDefault();
    }

    setZoomValue(Math.round(ratio * 10) / 10);
  };

  const onReady = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const imageData = imageElement?.cropper?.getImageData();

    if (croppedImageDimensions) {
      cropper.setCropBoxData({
        width: staticCroppedWidth,
        height: staticCroppedHeight,
      });
    }

    // set min available zoom for the selected image
    const minSliderZoom = imageData.width / imageData.naturalWidth;
    setMinZoomValue(minSliderZoom);
  };

  return (
    <div css={imageEditorContainer}>
      <Cropper
        style={{ height: "auto", maxHeight: 400, width: "100%" }}
        initialAspectRatio={1}
        aspectRatio={aspectRatio}
        ref={cropperRef}
        src={imageSrc}
        viewMode={2}
        autoCropArea={1}
        dragMode="move"
        cropBoxResizable={resizable}
        minCropBoxWidth={200}
        minCropBoxHeight={200}
        crop={onCrop}
        zoomTo={zoomValue}
        zoom={onZoom}
        ready={onReady}
      />

      <div className="edit-options">
        <div className="zoom-options">
          <Button noGutters variant="ghost" onClick={(): void => handleZoomOut()}>
            <ImageDisplaySVG height={16} />
          </Button>
          <input
            type="range"
            min={minZoomValue}
            max={MAX_ZOOM_VALUE}
            onChange={(e): void => setZoomValue(parseFloat(e.target.value))}
            className="slider"
            value={zoomValue}
            step={0.1}
          />
          <Button noGutters variant="ghost" onClick={(): void => handleZoomIn()}>
            <ImageDisplaySVG height={24} />
          </Button>
        </div>
        <div className="rotate-options">
          <Button noGutters variant="ghost" onClick={(): void => onRotate("left")}>
            <RotateLeftSVG height={24} />
          </Button>
          <Button noGutters variant="ghost">
            <RotateRightSVG height={24} onClick={(): void => onRotate("right")} />
          </Button>
          <Button
            noGutters
            variant="ghost"
            iconBefore={FlipSVG}
            className="horizontal-icon"
            onClick={(): void => onHorizontalFlip()}
          >
            {t("general.flipHorizontal")}
          </Button>
          <Button
            noGutters
            variant="ghost"
            iconBefore={FlipSVG}
            className="vertical-icon"
            onClick={(): void => onVerticalFlip()}
          >
            {t("general.flipVertical")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;

import { PalletThemeColors, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["imageSelector"] => {
  return {
    color: colors.black,
    hoverColor: colors.primary.lighter,
    backgroundOverlayHoverColor: Color(colors.primary.darker).alpha(0.5).string(),
    wrapperHoverColor: hexToRGBA(colors.primary.lightest.toString(), 0.25),
  };
};

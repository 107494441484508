import { format } from "date-fns";
import { t } from "i18next";
import { AiCreditsType } from "types/entities/AICredits";

export const resolveTooltipContent = (
  resetType: string,
  balance?: number,
  nextBillingDate?: string | null,
  aiCreditsType?: AiCreditsType,
): string => {
  if (aiCreditsType === AiCreditsType.OnceOff) {
    return t("AICredits.tooltipContentOneTime", {
      balance,
    });
  }

  if (!nextBillingDate) {
    return "";
  }

  const newDate = new Date(nextBillingDate);
  const formattedDate = format(newDate, "d MMM yyyy");

  return t("AICredits.tooltipContentRecurring", {
    balance,
    resetType: resetType.toLocaleLowerCase(),
    nextBillingDate: formattedDate,
  });
};

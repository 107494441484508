import React, { FC, isValidElement } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { infoPanelContainer } from "./styles";

export type InfoPanelProps = {
  value: number | string | JSX.Element;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  className?: string;
};

const InfoPanel: FC<InfoPanelProps> = ({ value, title, subtitle, className, ...rest }) => {
  const isValueElement = isValidElement(value);
  const isTitleElement = isValidElement(title);

  return (
    <div {...rest} css={infoPanelContainer} className={className}>
      {!isValueElement ? (
        <Text data-testid="info-panel-value" fontSize="4xl" weight="700">
          {value}
        </Text>
      ) : (
        value
      )}

      {!isTitleElement ? (
        <Text data-testid="info-panel-title" as="div" fontSize="sm" className="title">
          {title}
        </Text>
      ) : (
        title
      )}

      {subtitle && <div className="subtitle">{subtitle}</div>}
    </div>
  );
};

export default InfoPanel;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const mainNavContainer = ({ sidebar }) => {
  return css`
    display: none;

    .nav-items-wrapper {
      margin: 0;
    }
    .sidebar-container {
      max-width: 5rem;
      height: 100%;
      padding: 8px 0;
    }

    // This is the hamburger icon item
    .nav-handle {
      display: none;
    }

    ${mq["md"]} {
      --mainHeaderHeight: 72px;
      position: sticky;
      top: var(--mainHeaderHeight);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - var(--mainHeaderHeight));
      background: ${sidebar.background};
      color: ${sidebar.color};
      z-index: 1001;
    }
  `;
};

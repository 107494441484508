import React, { FC } from "react";
import { Card } from "@epignosis_llc/gnosis";
import { cardHeader, cardWrapper } from "./styles";
import FileThumbnail from "./components/FileThumbnail";
import { SerializedStyles } from "@emotion/react";
import FileBody from "./components/FileBody";
import { YoutubeItem } from "@views/UnitEdit/types";

const { Header, Body } = Card;

export type YoutubeCardProps = YoutubeItem & {
  onSelect?: () => void;
};

const YoutubeCard: FC<YoutubeCardProps> = ({ title, image_url, onSelect }) => {
  return (
    <div css={cardWrapper}>
      <Card className="card-container" onClick={onSelect}>
        <Header>
          <div css={(theme): SerializedStyles => cardHeader(theme, { isReadonly: false })}>
            <FileThumbnail fileType="jpeg" fileUrl={image_url} fileName={title} />
          </div>
        </Header>

        <Body>
          <FileBody isReadonly={false} fileName={title} />
        </Body>
      </Card>
    </div>
  );
};

export default YoutubeCard;

import { useQuery, UseQueryResult, UseQueryOptions } from "react-query";
import { getUnit, getMyCourseUnits, getQuestionById } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { MyUnit } from "types/entities";
import { MyUnitRes, MyQuestionRes, UnitsRes } from "types/responses";

export const useGetUnit = <T = MyUnit>(
  unitId: string,
  options?: UseQueryOptions<MyUnitRes, unknown, T>,
  isPreview = false,
  selectedAiSuggestion?: string,
): UseQueryResult<T, unknown> =>
  useQuery<MyUnitRes, unknown, T>(
    [queryKeys.unit, unitId, isPreview, selectedAiSuggestion],
    () => getUnit(unitId, isPreview, selectedAiSuggestion),
    options,
  );

export const useGetUnits = <T = UnitsRes>(
  courseId: string,
  options?: UseQueryOptions<UnitsRes, unknown, T>,
  isPreview = false,
): UseQueryResult<T, unknown> =>
  useQuery<UnitsRes, unknown, T>(
    [queryKeys.units, courseId, isPreview],
    () => getMyCourseUnits(courseId, isPreview),
    options,
  );

export const useGetTestQuestionById = <T = MyQuestionRes>(
  unitId: string,
  index: string,
  options?: UseQueryOptions<MyQuestionRes, unknown, T>,
): UseQueryResult<T, unknown> =>
  useQuery<MyQuestionRes, unknown, T>(
    [queryKeys.units, unitId, index],
    () => getQuestionById(unitId, index),
    options,
  );

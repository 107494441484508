import { css } from "@emotion/react";

export const CourseImageStyles = ({ courseEdit }, disabled) => css`
  position: relative;
  width: 100%;
  min-height: 275px;
  aspect-ratio: 3/2;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .upload-overlay {
    width: 100%;
    height: 100%;
    display: ${disabled ? "none" : "flex"};
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    color: white;
    background-color: ${courseEdit.courseImageOverlayBackground};
    opacity: 0;
    transition: opacity 0.2s linear;

    &:hover {
      opacity: ${disabled ? 0 : 1};
    }

    .input-field {
      position: absolute;
      appearance: initial;
      opacity: 0;
    }

    button:first-of-type {
      margin-bottom: 10px;
    }

    button {
      width: 74px;
      height: 74px;
      cursor: pointer;
    }
  }
`;

import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";

export const courseCardProgressBar = ({ courseCard }, { progress }) => css`
  .percentage-container {
    height: 2rem;
    font-size: ${typeScale.xs};
    background-color: ${courseCard.progressBar.background};

    div {
      min-width: ${progress === 0 ? 0 : null};
      color: ${progress === 0
        ? courseCard.progressBar.percentageColor
        : courseCard.progressBar.color};
      margin-inline-start: ${progress <= 20 ? "0.5rem" : 0};
      padding-inline-start: ${progress === 0 ? 0 : "1rem"};
    }

    &::after {
      min-width: ${progress === 0 ? 0 : null};
    }
  }
`;

export const cardFooterContainer = css`
  .footer-btn {
    height: 2rem;
    border-radius: 0 0 5px;
    padding: 0.25rem 0.5rem;

    .with-icon {
      display: flex;
      gap: 0.325rem;
      align-items: center;
    }
  }

  .subscribe-btn-text {
    display: flex;
    align-items: center;
  }
`;

import React, { FC, useLayoutEffect, useRef } from "react";
import { Tooltip } from "@epignosis_llc/gnosis";
import { nestedCategoryContainer } from "./styles";

type NestedCategoryProps = {
  content: string | JSX.Element;
  tooltipContent: string | JSX.Element;
};

const NestedCategoryText: FC<NestedCategoryProps> = ({ content, tooltipContent }) => {
  const componentRef = useRef<HTMLSpanElement | null>(null);
  const overflowRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    if (componentRef.current) {
      overflowRef.current = componentRef.current.querySelector(".overflow-category-text");
    }
  }, []);

  return (
    <div css={nestedCategoryContainer}>
      <div className="column-category">
        <Tooltip content={tooltipContent}>
          <span ref={componentRef}>{content}</span>
        </Tooltip>
      </div>
    </div>
  );
};

export default NestedCategoryText;

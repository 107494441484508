import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const detailsTabContainer = () => css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0;

  .catalog-options-container {
    .grid-container {
      padding: 1rem 2rem;
    }
    .hide-from-course-catalog {
      padding-top: 1rem;
    }
  }

  .intro-video-options-container {
    .intro-video-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .grid-container {
      padding: 1rem 2rem;
    }

    .intro-video-wrapper {
      .intro-video {
        aspect-ratio: 16/9;

        ${mq["sm"]} {
          max-width: 645px !important;
        }

        ${mq["xl"]} {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .custom-fields-options-container {
    .grid-container {
      padding: 1rem 2rem;
    }
  }
`;

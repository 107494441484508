const STRIPE_PUBLISHABLE_KEY_DEV = "pk_test_o353MFIADSHy6j0Q4IBgM2OP";
const STRIPE_PUBLISHABLE_KEY_PROD = "pk_live_D1ErjITTTOiDPbcqtrfYBmjJ";
const W9_LINK = "/pages/docs/EPIGNOSIS-LLC-W9-2024.pdf";

const payments = {
  STRIPE_PUBLISHABLE_KEY_DEV,
  STRIPE_PUBLISHABLE_KEY_PROD,
  W9_LINK,
};

export default payments;

import React, { FC, useRef } from "react";
import { Dropdown, DropdownItem, Heading, Tooltip } from "@epignosis_llc/gnosis";
import { VerticalEllipsisSVG } from "@epignosis_llc/gnosis/icons";
import classNames from "classnames";
import { tocHeaderStyles } from "./styles";
import { ActionGhostButton, Link } from "@components/ReusableComponents";
import { useIsOverflow } from "@hooks";

export type HeaderProps = {
  title: string;
  titleLink?: string;
  isOpen: boolean;
  dropdownOptions?: DropdownItem[];
  onDropdownOptionSelect?: (item: DropdownItem) => void;
  children?: React.ReactNode;
};

const className = (isOpen: boolean): string => classNames({ hidden: !isOpen });

const Header: FC<HeaderProps> = ({
  title,
  titleLink,
  isOpen,
  dropdownOptions,
  onDropdownOptionSelect,
  children,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(ref);
  const showDropdown = dropdownOptions && dropdownOptions.length > 0;

  const handleDropdownSelect = (item: DropdownItem): void => {
    onDropdownOptionSelect && onDropdownOptionSelect(item);
  };

  return (
    <div id="toc-header" css={tocHeaderStyles} className={className(isOpen)}>
      <div id="title-container" className="title-container">
        <Tooltip content={title} disabled={!isOverflow}>
          <div className="title" ref={ref}>
            {titleLink ? (
              <Link to={titleLink} color="secondary">
                <Heading size="lg">{title}</Heading>
              </Link>
            ) : (
              <Heading size="lg">{title}</Heading>
            )}
          </div>
        </Tooltip>

        {showDropdown && (
          <Dropdown
            list={dropdownOptions}
            placement="bottom-end"
            onListItemSelect={handleDropdownSelect}
          >
            <ActionGhostButton>
              <VerticalEllipsisSVG height={16} />
            </ActionGhostButton>
          </Dropdown>
        )}
      </div>

      {children}
    </div>
  );
};

export default Header;

import React, { FC } from "react";
import { Button, Tooltip } from "@epignosis_llc/gnosis";
import queryKeys from "@constants/queryKeys";
import { useMutation, useQueryClient } from "react-query";
import { generalNotification } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { useNavigate } from "react-router-dom";
import { URLS } from "@constants/urls";
import { acquireCourse } from "@views/CourseStore/Course/api";
import { startButtonStyles } from "../../styles";

type CourseButtonProps = {
  courseId: string;
  mappedCourseId?: string;
  isCourseAcquired: boolean;
  isTalentLibraryEnabled?: boolean;
  isTrialEnabled?: boolean;
};

const CourseButton: FC<CourseButtonProps> = ({
  courseId,
  mappedCourseId,
  isCourseAcquired,
  isTalentLibraryEnabled,
  isTrialEnabled: isInTrial,
}) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: acquireCourseMutation, isLoading } = useMutation(
    [queryKeys.courseStore.acquire],
    () => acquireCourse(courseId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.courseStore.course, courseId]);
        generalNotification("success", <p>{t("courseStore.courseAcquiredWithSuccess")}</p>);
      },
    },
  );

  const handleButtonClick = async (): Promise<void> => {
    acquireCourseMutation();
  };

  const redirectToCourseEdit = (): void => {
    const link = URLS.courses.getCourseEditLink({ courseId: mappedCourseId });
    navigate(link);
  };

  //   Case 1: Paid plan active, course is acquired  -> Course edit (White button)
  if (isTalentLibraryEnabled && isCourseAcquired) {
    return (
      <div css={startButtonStyles}>
        <Button className="start-button" color="secondary" onClick={redirectToCourseEdit} block>
          {t("courseEdit.edit")}
        </Button>
      </div>
    );
  }

  //   Case 2: Paid plan active, course not acquired -> Get this course (White button)
  if (isTalentLibraryEnabled && !isCourseAcquired && !isInTrial) {
    return (
      <div css={startButtonStyles}>
        <Button
          color="secondary"
          className="start-button"
          onClick={handleButtonClick}
          isLoading={isLoading}
        >
          {t("courseCatalog.getCourse")}
        </Button>
      </div>
    );
  }

  //   Case 3: NO paid plan -> Get this course (disabled with tooltip)
  if (isInTrial) {
    return null;
  }

  return (
    <div css={startButtonStyles}>
      <Tooltip content={t("courseStore.getThisCourseTooltip")}>
        <Button color="secondary" className="start-button" disabled>
          {t("courseCatalog.getCourse")}
        </Button>
      </Tooltip>
    </div>
  );
};

export default CourseButton;

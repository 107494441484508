import React, { ReactNode } from "react";
import { Button } from "@epignosis_llc/gnosis";
import SettingCard from "./SettingCard";

type SettingButtonProps = {
  title?: string;
  description?: ReactNode;
  buttonText: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isDisabledButton?: boolean;
  variant?: "link" | "solid" | "outline" | "ghost";
  buttonClassName?: string;
};

const SettingButton = ({
  title,
  description,
  buttonText,
  onClick,
  isDisabledButton,
  variant = "outline",
  buttonClassName,
}: SettingButtonProps): JSX.Element => {
  return (
    <SettingCard title={title} description={description} withSpaceLeft>
      <Button
        variant={variant}
        onClick={onClick}
        disabled={isDisabledButton}
        className={buttonClassName ?? ""}
      >
        {buttonText}
      </Button>
    </SettingCard>
  );
};

export default SettingButton;

import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// map with all available permissions
export const catalogPermissions = {
  read: "catalog.course.acquire",
};

const { read } = catalogPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => catalog.course.acquire
   *
   *  Allowed actions:
   *   GET catalog, categories, course
   *   POST rating
   *   DELETE rating
   *
   * @returns boolean
   */
  canAccessCatalog: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
};

import React, { FC } from "react";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { SpecialZoomLevel, TextDirection, Viewer, Plugin, PdfJs } from "@react-pdf-viewer/core";
import { SerializedStyles } from "@emotion/react";
import PdfViewerToolbar from "./components/PdfViewerToolbar";
import { documentReview } from "./styles";
import { ScreenCollapseSVG } from "@epignosis_llc/gnosis/icons";
import { Button, Loader } from "@epignosis_llc/gnosis";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

type PdfViewerProps = {
  fileUrl: string | null;
  isRTL?: boolean;
  defaultScale?: number | SpecialZoomLevel;
};

const PdfViewer: FC<PdfViewerProps> = ({
  fileUrl,
  isRTL = false,
  defaultScale = SpecialZoomLevel.PageWidth,
}) => {
  const toolbarPluginInstance = toolbarPlugin({
    fullScreenPlugin: {
      renderExitFullScreenButton: (props) => (
        <div className="exit-fullscreen">
          <Button className="action-btn" variant="link" noGutters onClick={props.onClick}>
            <ScreenCollapseSVG width={32} />
          </Button>
        </div>
      ),
    },
  });

  return (
    <div css={(theme): SerializedStyles => documentReview(theme)}>
      <PdfViewerToolbar toolbarPluginInstance={toolbarPluginInstance} />

      <div className="viewer-container">
        {fileUrl ? (
          <Viewer
            fileUrl={fileUrl}
            defaultScale={defaultScale}
            plugins={[toolbarPluginInstance as Plugin]}
            theme={{
              direction: isRTL ? TextDirection.RightToLeft : TextDirection.LeftToRight,
            }}
            renderLoader={(): JSX.Element => <Loader />}
            transformGetDocumentParams={(options): PdfJs.GetDocumentParams =>
              Object.assign({}, options, { isEvalSupported: false })
            }
          />
        ) : (
          <div className="loader-container">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default PdfViewer;

import React, { FC, useState } from "react";
import { Alert, Button, Text } from "@epignosis_llc/gnosis";
import { CloseSVG } from "@epignosis_llc/gnosis/icons";
import { SerializedStyles } from "@emotion/react";
import { useMutation, useQueryClient } from "react-query";
import { AnnouncementsContainer } from "./styles";
import queryKeys from "@constants/queryKeys";
import { putAnnouncementsClose } from "@api/announcements";
import { Announcement, ShowAnnouncements } from "types/entities";
import localStorageKeys from "@constants/localStorageKeys";
import { useApplyTranslations, useIsOverflow } from "@hooks";

type AnnouncementProps = {
  announcements: Announcement[];
  type: Announcement["type"];
  onClose?: () => void;
};

const AnnouncementContent: FC<AnnouncementProps> = ({ announcements, type, onClose }) => {
  const { t } = useApplyTranslations();
  const [showingMore, setShowingMore] = useState(false);
  const queryClient = useQueryClient();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(ref);

  const showCloseBtn = ["internal", "admin"].includes(type);
  const showInternalCloseBtn = type === "internal" && (showingMore || !isOverflow);
  const showMoreBtn = isOverflow || showingMore;
  const showButtons = (showMoreBtn || showInternalCloseBtn) && type !== "admin";

  const handleShowingMore = (): void => {
    setShowingMore((current) => !current);
  };

  const { mutate: putAnnouncementsCloseMutation, isLoading: putAnnouncementsCloseLoading } =
    useMutation([queryKeys.announcements.close], putAnnouncementsClose, {
      onSuccess: () => queryClient.invalidateQueries([queryKeys.announcements.announcements]),
    });

  const handleCloseInternal = (): void => {
    if (type === "internal") {
      putAnnouncementsCloseMutation();
    }
  };

  const handleClose = (): void => {
    if (showCloseBtn) {
      const showAnnouncementsString = localStorage.getItem(localStorageKeys.ANNOUNCEMENTS);

      const showAnnouncements: ShowAnnouncements = showAnnouncementsString
        ? JSON.parse(showAnnouncementsString)
        : { internal: true, admin: true };

      const newShowAnnouncements = { ...showAnnouncements, [type]: false };
      localStorage.setItem(localStorageKeys.ANNOUNCEMENTS, JSON.stringify(newShowAnnouncements));

      if (onClose) onClose();
    }
  };

  return (
    <Alert
      type="info"
      css={(theme): SerializedStyles => AnnouncementsContainer(theme, showingMore, type)}
      className="announcements"
    >
      <div className="announcements-container" ref={ref}>
        {announcements.map(({ html }, index) => {
          return (
            <Text
              fontSize="sm"
              as="div"
              key={`announcement-${index}`}
              className="announcement"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          );
        })}
      </div>

      {showButtons && (
        <div className="buttons-container">
          {showMoreBtn && (
            <Button variant={"outline"} onClick={handleShowingMore}>
              {t(`general.${showingMore ? "viewLess" : "viewMore"}`)}
            </Button>
          )}
          {showInternalCloseBtn && (
            <Button onClick={handleCloseInternal} isLoading={putAnnouncementsCloseLoading}>
              {t("general.gotIt")}
            </Button>
          )}
        </div>
      )}

      {showCloseBtn && (
        <Button
          variant="link"
          aria-label="close button"
          noGutters
          className="close-button"
          color="secondary"
          onClick={handleClose}
        >
          <CloseSVG height={16} />
        </Button>
      )}
    </Alert>
  );
};

export default AnnouncementContent;

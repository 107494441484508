// Packages or third-party libraries
import React, { FC } from "react";
import Lottie from "react-lottie";
import { Heading, Text } from "@epignosis_llc/gnosis";

// Styles
import { completionHeaderStyles } from "./styles";

// Hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import * as successAnimationData from "@assets/animated/course-completion.json";
import * as failureAnimationData from "@assets/animated/course-failure.json";

type CompletionHeaderProps = {
  isCompleted: boolean;
};

const CompletionHeader: FC<CompletionHeaderProps> = ({ isCompleted }) => {
  const { t } = useApplyTranslations();

  const animationData = isCompleted ? successAnimationData : failureAnimationData;
  const title = isCompleted ? t("general.congratulations") : t("myCourses.youDidNotQuiteMakeIt");
  const subtitle = isCompleted
    ? t("myCourses.youSuccessfullyCompletedCourse")
    : t("myCourses.unfortunatelyYouDidNotReachScore");

  return (
    <section css={completionHeaderStyles}>
      <div data-testid="image-banner">
        <Lottie options={{ animationData, loop: 0 }} height={250} width={250} />
      </div>

      <div className="title-container">
        <Heading as="h1" size="2xl" data-testid="completion-title">
          {title}
        </Heading>
        <Text fontSize="sm" className="subtitle" data-testid="completion-subtitle">
          {subtitle}
        </Text>
      </div>
    </section>
  );
};

export default CompletionHeader;

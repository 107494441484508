import React, { FC } from "react";
import { Result } from "@epignosis_llc/gnosis";
import { ProcessingSVG } from "@assets/images";
import { FileResultStyles } from "./styles";
import { useApplyTranslations } from "@hooks";

export type FileProcessingProps = React.HTMLAttributes<HTMLElement> & {
  info?: string;
  size?: "md" | "lg";
};

const FileProcessing: FC<FileProcessingProps> = ({ info, size = "md", ...rest }): JSX.Element => {
  const { t } = useApplyTranslations();

  return (
    <Result
      title={t("errors.units.fileIsProcessing")}
      icon={ProcessingSVG}
      info={info}
      size={size}
      css={FileResultStyles}
      {...rest}
    />
  );
};

export default FileProcessing;

import { getMonth, getYear } from "date-fns";
import { useConfigurationStore } from "@stores";
import { timeZonedDate } from "./date-time";

const { getState } = useConfigurationStore;
const LINKEDIN_SHARE_URL = "https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME";

export const getCertificateLinkedInShareUrl = ({
  course_name,
  issued_at,
  expires_at,
}: {
  course_name: string;
  expires_at: string | null;
  issued_at: string | null;
}): string => {
  const domainSettings = getState().domainSettings;
  const { site_name, timezone = "UTC" } = domainSettings ?? {};

  const name = `&name=${course_name}`;
  const organizationName = site_name ? `&organizationName=${site_name}` : "";

  const issuedAtDate = issued_at ? timeZonedDate(issued_at, timezone) : null;
  const issueYear = issuedAtDate ? `&issueYear=${getYear(issuedAtDate)}` : "";
  const issueMonth = issuedAtDate ? `&issueMonth=${getMonth(issuedAtDate) + 1}` : "";

  const expiresAtDate = expires_at ? timeZonedDate(expires_at, timezone) : null;
  const expirationYear = expiresAtDate ? `&expirationYear=${getYear(expiresAtDate)}` : "";
  const expirationMonth = expiresAtDate ? `&expirationMonth=${getMonth(expiresAtDate) + 1}` : "";

  return `${LINKEDIN_SHARE_URL}${name}${organizationName}${issueYear}${issueMonth}${expirationYear}${expirationMonth}`;
};

import { t } from "@utils/i18n";

export const insertImageBadLInkMessage = (): string => `
  <div>
    <span> ${t("editor.imageCannotBeLoaded")} </span>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://help.talentlms.com/hc/en-us/articles/360014572654-How-to-upload-files-from-Dropbox-to-TalentLMS"
    >
      Dropbox
    </a>
    <span> ${t("editor.or")} </span>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://help.talentlms.com/hc/en-us/articles/360014657713-How-to-upload-files-from-Google-Drive-to-your-courses"
    >
      Google Drive
    </a>
    <span> ${t("editor.toLoadFiles")} </span>
  </div>
`;

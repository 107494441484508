import React, { FC, useEffect, useState } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { useLocation } from "react-router-dom";
import { startButtonStyles } from "../../styles";
import { useApplyTranslations, useIsExternalCatalog, useSearchQuery } from "@hooks";
import { Course, CourseProgress } from "types/entities";
import { getCourseButtonText } from "../helpers";
import { Price } from "@components";
import ExternalPaymentModal from "@components/ReusableModals/ExternalPaymentModal";
import localStorageKeys from "@constants/localStorageKeys";
import userRoles from "@constants/userRoles";

type CatalogButtonProps = {
  course: Course;
  isDisabled: boolean;
  unlocksOnHigherLevel: boolean;
  isUserEnrolled: boolean;
  completionStatus?: CourseProgress["completion_status"];
  isPublicCourse: boolean;
  isLoading: boolean;
  onStartPublicCourse: () => void;
  onEnroll: () => void;
};

// This is used for external views and enrollment after redirection
type SearchQueryFilters = {
  action: "enrollment" | "payment";
};

const CatalogButton: FC<CatalogButtonProps> = ({
  course,
  isDisabled,
  isUserEnrolled,
  completionStatus,
  unlocksOnHigherLevel,
  isPublicCourse,
  isLoading,
  onStartPublicCourse,
  onEnroll,
}) => {
  const { t } = useApplyTranslations();
  const isExternalCatalogView = useIsExternalCatalog();
  const { state } = useLocation();
  const locationState = state as { isPayment?: boolean };
  const { action } = useSearchQuery() as SearchQueryFilters;

  const [isExternalPaymentModalOpen, setIsExternalPaymentModalOpen] = useState(false);

  const { price, discounted_price, role_in_course, availability } = course;
  const hasPrice = price && price.amount !== 0;
  const isInstructor = role_in_course === userRoles.INSTRUCTOR;

  const getButtonText = (): JSX.Element | string => {
    if (isExternalCatalogView && isPublicCourse) return t("general.tryItForFree");

    // We show the price only if the user is not enrolled and there is no level restriction
    if (hasPrice && !unlocksOnHigherLevel && !isUserEnrolled) {
      return (
        <Price
          value={discounted_price ? discounted_price.amount : price.amount}
          prevValue={discounted_price ? price.amount : undefined}
        />
      );
    }

    if (!availability?.is_available && availability?.reason === "cancelled")
      return t("courseCatalog.getCourse");

    return isUserEnrolled
      ? getCourseButtonText(completionStatus)
      : isInstructor
      ? t("courses.roleInCourseInstructorTooltip")
      : t("courseCatalog.getCourse");
  };

  const handleButtonClick = (): void => {
    if (isExternalCatalogView) {
      if (isPublicCourse) {
        onStartPublicCourse();
        return;
      } else {
        setIsExternalPaymentModalOpen(true);
        return;
      }
    }

    onEnroll();
  };

  // In this useEffect we check if the url parameters contain the action parameter "enrollment".
  // This is used when a user signs in or signs up from an external view and choosed to enroll in a course.
  // Also we check if the EXTERNAL_SIGNING_SIGNUP or PENDING_CATALOG_ENROLLMENT storage key exists in order to prevent the user from enrolling though url
  useEffect(() => {
    if (
      (action === "enrollment" || action === "payment") &&
      (localStorage.getItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP) ||
        localStorage.getItem(localStorageKeys.PENDING_CATALOG_ENROLLMENT))
    ) {
      onEnroll();
    }

    localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
    localStorage.removeItem(localStorageKeys.PENDING_CATALOG_ENROLLMENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get the active bundle from history state and set the active bundle and on change action
  useEffect(() => {
    if (locationState?.isPayment) onEnroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  return (
    <>
      <div css={startButtonStyles}>
        <Button
          color="primaryDarker"
          variant="outline"
          className="start-button"
          disabled={isDisabled}
          isLoading={isLoading}
          onClick={handleButtonClick}
        >
          {getButtonText()}
        </Button>
      </div>

      {isExternalPaymentModalOpen && (
        <ExternalPaymentModal
          data={course}
          isOpen={isExternalPaymentModalOpen}
          onClose={(): void => setIsExternalPaymentModalOpen(false)}
        />
      )}
    </>
  );
};

export default CatalogButton;

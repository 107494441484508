import { css } from "@emotion/react";

export const DynamicGaugeChartStyles = (isRTL) => css`
  width: 316px;
  position: relative;

  .score-text-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    position: absolute;
    top: 50%;
    inset-inline-start: 50%;
    transform: ${isRTL ? "translate(50%, -50%)" : "translate(-50%, -50%)"};
    align-items: center;
  }
`;

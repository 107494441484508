import { CourseCompletionRule } from "types/entities/Courses";

export const getMandatoryUnits = (completion: CourseCompletionRule[]): number[] => {
  // mandatory units
  return completion.reduce<number[]>((array, unit) => {
    const { complete_units } = unit;
    const completeUnitIds = complete_units?.map(({ id }) => id) ?? [];

    return [...array, ...completeUnitIds];
  }, []);
};

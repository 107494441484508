import { css } from "@emotion/react";

export const hoverContainer = ({ fileCard }) => css`
  height: 100%;
  width: 100%;
  color: ${fileCard.fileCover.textColor};

  .hover-info-container {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: calc(100% - 1rem);
    margin: 0 0.5rem;
    bottom: 0;

    .hidden {
      visibility: hidden;
    }

    .info {
      svg {
        position: relative;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);

        path:first-of-type {
          fill: transparent;
        }
      }
    }
  }
`;

export const FileThumbnailStyles = ({ fileCard }) => css`
  width: 100%;
  height: 100%;

  .rpv-core__viewer {
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .video-container {
    height: 100%;
    width: 100%;

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .no-thumbnail-cover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      color: ${fileCard.fileCover.color};
    }
  }
`;

export const cardBody = ({ fileCard }, { isReadonly }) => css`
  display: flex;
  flex-direction: column;
  cursor: ${isReadonly ? "default" : "pointer"};

  .name-container {
    display: flex;
    justify-content: space-between;
    flex: 1;

    .file-name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
    }
  }

  .details-container {
    display: flex;
    flex-wrap: wrap;
    color: ${fileCard.detailsColor};
    margin-top: 1rem;

    .detail:not(:last-of-type) {
      &:after {
        content: "·";
        margin-inline: 0.5rem;
      }
    }
  }
`;

import React, { FC } from "react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { useApplyTranslations } from "@hooks";
import { AIButtonStyles } from "./styles";
import { getCraftCredits } from "../../AICreditsDrawer/api";
import { handleAICreditsErrors } from "@errors/errors";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import queryKeys from "@constants/queryKeys";

type AIButtonProps = {
  text: string;
  onClick: () => void;
};

const AIButton: FC<AIButtonProps> = ({ text, onClick }) => {
  const { t } = useApplyTranslations();

  const { data: craftCredits } = useQuery([queryKeys.craft.credits], getCraftCredits, {
    select: (res) => res._data,
    onError: (error: AxiosError) => {
      handleAICreditsErrors(error);
    },
  });

  const { ai_credits: balance } = craftCredits ?? {};

  return (
    <Tooltip content={t("AICredits.AICreditBalanceTooltip", { count: balance ?? 0 })}>
      <div css={AIButtonStyles} onClick={onClick}>
        <Text fontSize="sm">{text}</Text>
      </div>
    </Tooltip>
  );
};

export default AIButton;

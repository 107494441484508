import React, { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router";

import { tabContainerStyles } from "../styles";
import {
  CompletionRule,
  CourseRulesEditData,
  CourseRulesFormData,
  LearningPathObj,
  TraversalRule,
} from "@views/CourseEdit/types";
import { ScoreRule } from "types/entities/Courses";
import { CourseRulesOptionsSchemas } from "@utils/validation/courses/optionsSchemas";
import { flatsUnitsReducer } from "@views/CourseEdit/reducers";
import { CoursePrerequisite, MyUnit, Section } from "types/entities";
import { isSectionItem } from "@views/CourseEdit/helpers";
import { putCourseRules } from "@views/CourseEdit/api";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";
import { handleCourseErrors } from "@errors";
import { TabHandle, TabProps } from "../../CourseOptionsDrawer";
import UnitsOrdering from "./UnitsOrdering";
import { Divider } from "@components/ReusableComponents";
import CompletionRules from "./CompletionRules";
import ScoreRules from "./ScoreRules";
import { groupBy } from "@utils/helpers";
import LearningPathRules from "./LearningPathRules";

const getLearningPaths = (prerequisites: CoursePrerequisite[]): LearningPathObj[] => {
  const grouped = groupBy(prerequisites, ({ rule_set }) => rule_set);

  return Object.keys(grouped).reduce((array: LearningPathObj[], key) => {
    const group = grouped[key];

    array.push({
      rule_set: Number(key),
      courses: group.map(({ id }) => id),
    });

    return array;
  }, []);
};

const mapFormDataToSubmitData = (data: CourseRulesFormData): CourseRulesEditData => {
  const { learning_paths_obj, ...rest } = data;
  const submitData: CourseRulesEditData = rest;

  if (learning_paths_obj && learning_paths_obj.length > 0) {
    submitData.learning_paths = learning_paths_obj.reduce((array: number[][], path) => {
      array.push(path.courses);
      return array;
    }, []);
  }

  return submitData;
};

const RulesAndPathsTab: ForwardRefRenderFunction<TabHandle, TabProps> = (
  { course, sections = [], onSuccess, onValidChange, onLoadingChange },
  ref,
) => {
  const navigate = useNavigate();
  const {
    id,
    rules: { traversal, completion, score_calculation, prerequisites },
  } = course;
  const courseId = id.toString();
  const completionRule = completion[0]?.rule_type ?? CompletionRule.AllUnits;
  const completionPercentage = completion[0]?.unit_percentage ?? null;
  const completionUnitIds = (completion[0]?.complete_units ?? []).map(
    (completionUnit) => completionUnit.id,
  );
  const scoreRule = score_calculation[0]?.rule_type ?? ScoreRule.AllTestsAndAssignments;
  const scoreUnits = (score_calculation[0]?.score_units ?? []).map(({ unit: { id }, weight }) => ({
    id,
    weight,
  }));
  const learningPaths = getLearningPaths(prerequisites);

  const flatsUnits: Array<Section | MyUnit> = sections.reduce(flatsUnitsReducer, []);
  const units = flatsUnits.filter(
    (section) => !isSectionItem(section) && section.is_active,
  ) as MyUnit[];

  const form = useForm<CourseRulesFormData>({
    mode: "onChange",
    resolver: yupResolver(CourseRulesOptionsSchemas),
    defaultValues: {
      traversal_rule: traversal as TraversalRule,
      completion_rule: completionRule as CompletionRule,
      completion_percentage: completionPercentage ?? undefined,
      completion_unit_ids: completionUnitIds.length ? completionUnitIds : undefined,
      score_rule: scoreRule,
      score_units: scoreUnits.length ? scoreUnits : undefined,
      learning_paths_obj: learningPaths.length ? learningPaths : undefined,
    },
  });

  const {
    formState: { isValid },
    getValues,
  } = form;

  const { mutate: editCourseRulesMutation, isLoading: isLoadingEditCourseRules } = useMutation(
    [queryKeys.courses.rulesEdit],
    (data: CourseRulesEditData) => putCourseRules(courseId, data),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (err) => {
        const error = err as AxiosError;
        const handleError = (): void => {
          navigate(URLS.courses.courses);
        };

        handleCourseErrors(error, false, handleError);
      },
    },
  );

  const handleSubmit = async (): Promise<void> => {
    const data = getValues();
    const submitData = mapFormDataToSubmitData(data);
    const isValid = await CourseRulesOptionsSchemas.isValid(submitData);

    if (isValid) {
      editCourseRulesMutation(submitData);
    }
  };

  // The component instance will be extended with whatever returned from the callback
  useImperativeHandle(ref, () => ({
    handleApply: handleSubmit,
  }));

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid, onValidChange]);

  useEffect(() => {
    onLoadingChange(isLoadingEditCourseRules);
  }, [isLoadingEditCourseRules, onLoadingChange]);

  return (
    <section css={tabContainerStyles}>
      <UnitsOrdering form={form} />
      <Divider />
      <CompletionRules form={form} units={units} />
      <Divider />
      <ScoreRules form={form} units={units} />
      <Divider />
      <LearningPathRules form={form} course={course} />
    </section>
  );
};

export default forwardRef(RulesAndPathsTab);

import { css } from "@emotion/react";

export const tocContentWrapperStyles = ({ courseToc }, { hasUnits }) => css`
  height: 100%;
  display: flex;
  flex-direction: column;

  .toc-content-wrapper {
    display: flex;
    flex-direction: column;
    background: ${courseToc.edit.background};
    height: 100%;
    position: relative;

    .outer-scrollable-container {
      display: flex;
      flex-direction: column;
      background: ${courseToc.edit.background};
      height: 100%;
      flex: 1 1 0;
      position: relative;

      .upload-prompt-area {
        flex: 1 1 0;
        visibility: visible;
        z-index: 0;
        display: flex;
        align-items: ${hasUnits ? "flex-start" : "center"};
        justify-content: center;
        overflow-y: hidden;
        scrollbar-width: none;
        position: initial;

        &:hover,
        &:focus {
          overflow: overlay;
        }

        .scrollable-container {
          overflow: visible;
        }
      }
    }
  }
`;

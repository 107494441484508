import { ENDPOINTS } from "./endpoints";
import {
  InvoiceDownloadRes,
  InvoiceRes,
  MyInvoiceRes,
  UpdateCreditCardPostData,
  UpdateCreditCardRes,
} from "./types";
import HttpClient from "@api/HttpClient";

export const getMyInvoices = async (queryStr = ""): Promise<InvoiceRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.myInvoices}${queryStr}`);

  return res.data;
};

export const getMyInvoice = async (id: string): Promise<MyInvoiceRes> => {
  const res = await HttpClient.get(ENDPOINTS.invoice(id));

  return res.data;
};

export const getDownloadInvoice = async (id: string): Promise<InvoiceDownloadRes> => {
  const res = await HttpClient.get(ENDPOINTS.invoiceDownload(id), {
    responseType: "arraybuffer",
    responseEncoding: "binary",
  });

  return res.data;
};

export const deleteSubscription = async (id: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.cancelSubscription(id));
};

export const postUpdateCreditCard = async (
  id: string,
  data: UpdateCreditCardPostData,
): Promise<UpdateCreditCardRes> => {
  const res = await HttpClient.post(ENDPOINTS.updateCreditCard(id), data);

  return res.data;
};

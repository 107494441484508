// Packages
import React, { FC } from "react";

// Components
import { Text } from "@epignosis_llc/gnosis";
import { FileCard, FileFailed, FileProcessing } from "@components";

// Utils
import { useApplyTranslations } from "@hooks";

// Styles
import { answerContainerStyles } from "./styles";

// Others
import { File, RichText } from "types/entities";
import { SerializedStyles } from "@emotion/react";

type AnswerContainerProps = {
  textAnswer?: RichText;
  file?: File;
  maxWidth?: number;
  alignedCenter?: boolean;
};

const AnswerPreview: FC<AnswerContainerProps> = ({
  textAnswer,
  file,
  maxWidth = 300,
  alignedCenter = false,
}) => {
  const { t } = useApplyTranslations();
  const { status } = file ?? {};

  const isFileProcessing = status === "processing";
  const isFileFailed = status === "failed";

  if (textAnswer) {
    return <Text fontSize="sm" as="div" dangerouslySetInnerHTML={{ __html: textAnswer.html }} />;
  }

  if (!file) return null;

  if (isFileProcessing) {
    return <FileProcessing info={t("assignment.fileUnderProcessDescription")} />;
  }

  if (isFileFailed) {
    return <FileFailed info={t("errors.units.fileProcessingSubtitle")} />;
  }

  return (
    <div css={(): SerializedStyles => answerContainerStyles({ maxWidth, alignedCenter })}>
      <div className="card-container">
        <FileCard {...file} />
      </div>
    </div>
  );
};

export default AnswerPreview;

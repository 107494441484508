import React, { FC } from "react";
import { iltTitleContainerStyles } from "./styles";

type IltTitleContainerProps = {
  children?: React.ReactNode;
};

const IltTitleContainer: FC<IltTitleContainerProps> = ({ children }) => (
  <div css={iltTitleContainerStyles}>{children}</div>
);

export default IltTitleContainer;

import { css } from "@emotion/react";

export const ShareDrawerStyles = ({ courseEdit: { grayBase } }) => css`
  .share-text-container {
    margin-inline-start: 2.5rem;

    a {
      margin-inline-start: 0.125rem;
    }
  }

  .note {
    margin-top: 0.5rem;
    color: ${grayBase};
  }

  .copy-link-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 0.5rem;
  }
`;

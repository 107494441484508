import * as React from "react";
import type { Plugin, RenderViewer } from "@react-pdf-viewer/core";

type PageThumbnailPluginProps = {
  PageThumbnail: React.ReactElement;
};

export const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
  const { PageThumbnail } = props;

  return {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    renderViewer: (renderProps: RenderViewer) => {
      const { slot } = renderProps;

      slot.children = PageThumbnail;

      // Reset the sub slot
      if (slot.subSlot) slot.subSlot.attrs = {};
      if (slot.subSlot) slot.subSlot.children = <></>;

      return slot;
    },
  };
};

import { RichText } from "./Common";
import { CourseFile } from "./Files";

export type SubmissionListing = {
  id: number;
  user: User;
  course: Course;
  unit: Unit;
  grade: number;
  grade_date?: string;
  submission_date: string;
  status: SubmissionStatus;
  policies: Policies;
  submission_file: CourseFile;
};

export type SingleSubmission = {
  assignment_description: string;
  grade: number;
  status: SubmissionStatus;
  grade_date: string;
  submissions: Answer[];
  user: User;
};

export type SubmissionStatus = "passed" | "not-passed" | "pending";

export enum SubmissionStatusEnum {
  passed = "passed",
  notPassed = "not-passed",
}

export type Answer = {
  type: string;
  instructor_comment: RichText;
  file: CourseFile;
  text: RichText;
  date: string;
  id: number;
};

type User = {
  id: number;
  name: string;
  surname: string;
  login: string;
};

type Course = {
  id: number;
  name: string;
};

type Unit = {
  id: number;
  name: string;
};

type Policies = {
  can_grade: boolean;
  can_reset_unit_status: boolean;
};

export type GradeFormData = {
  score?: number;
  comments: string | null;
  status: SubmissionStatus;
  shouldShowNotification?: boolean;
};

// Packages or third-party libraries
import React, { FC, HTMLAttributes, useRef } from "react";
import { Tooltip } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";

// Styles
import { listItemStyles } from "./styles";

// Hooks
import { useIsOverflow } from "@hooks";

export type ListItemProps = HTMLAttributes<HTMLElement> & {
  text: string | JSX.Element;
  icon?: JSX.Element;
  active?: boolean;
  disabled?: boolean;
  actions?: JSX.Element;
  readonly?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const contentClassNames = (active: boolean): string => classNames({ "is-active": active });

const ListItem: FC<ListItemProps> = ({
  text,
  icon,
  active = false,
  disabled = false,
  actions,
  readonly = false,
  onClick,
  children,
  ...rest
}) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const overflowActive = useIsOverflow(ref);

  const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
    onClick && !disabled && onClick(e);
  };

  return (
    <dd
      css={(theme): SerializedStyles => listItemStyles(theme, { disabled, readonly })}
      className={contentClassNames(active)}
      onClick={handleClick}
      {...rest}
    >
      <div className="list-item-wrapper">
        <span className="list-item-content">
          {icon ? <div className="list-item-icon">{icon}</div> : null}
          <div className="list-item">
            <Tooltip content={text} disabled={!overflowActive}>
              <span className="list-item-text" ref={ref}>
                {text}
              </span>
            </Tooltip>
            {actions}
          </div>
        </span>

        <div className="list-item-children">{children}</div>
      </div>
    </dd>
  );
};

export default ListItem;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const pageHeader = () => css`
  .pageHeader-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-side {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      margin-bottom: 1rem;

      .title {
        width: 100%;

        ${mq["sm"]} {
          width: auto;
        }
      }

      .subtitle {
        width: 100%;
        margin-inline-start: 0.125rem;

        ${mq["sm"]} {
          padding-inline-start: 0.5rem;
          width: auto;
          margin-inline-start: 0;
        }
      }
    }
  }
`;

import React, { FC, useState } from "react";
import { Button, Modal, RadioGroup, Text } from "@epignosis_llc/gnosis";
import { UnitDeleteModalStyles } from "./styles";
import { useApplyTranslations } from "@hooks";

enum UnitAction {
  KEEP_LINKED_UNITS = "KEEP_LINKED_UNITS",
  REMOVE_LINKED_UNITS = "REMOVE_LINKED_UNITS",
}

export type UnitDeleteModalProps = {
  isOpen: boolean;
  isSection: boolean;
  isLinkedUnit: boolean | null;
  title: string;
  unitId: string;
  onClose: () => void;
  onDelete: ({
    unitId,
    shouldDeleteLinkedUnits,
  }: {
    unitId: string;
    shouldDeleteLinkedUnits?: boolean;
  }) => void;
};

const UnitDeleteModal: FC<UnitDeleteModalProps> = ({
  isOpen,
  isSection,
  isLinkedUnit,
  title,
  unitId,
  onClose,
  onDelete,
}) => {
  const { KEEP_LINKED_UNITS, REMOVE_LINKED_UNITS } = UnitAction;

  const { t } = useApplyTranslations();
  const [selectedOption, setSelectedOption] = useState("keep");
  const shouldDeleteLinkedUnits = selectedOption == REMOVE_LINKED_UNITS;

  const headerText = isSection ? t(`courseEdit.deleteSection`) : t(`courseEdit.shouldDeleteUnit`);

  const bodyTitleText = isSection
    ? t("courseEdit.confirmDeleteSection", { title })
    : t("courseEdit.confirmDeleteUnit", { title });

  const radioViewOptions = [
    {
      label: t("courseEdit.keepLinkedUnits"),
      value: KEEP_LINKED_UNITS,
    },
    {
      label: t("courseEdit.removeLinkedUnits"),
      value: REMOVE_LINKED_UNITS,
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div css={UnitDeleteModalStyles}>
        <Modal.Header>{headerText}</Modal.Header>
        <Modal.Body>
          {isLinkedUnit ? (
            <>
              <Text fontSize="sm" as="div" className="body-text">
                {t("courseEdit.deleteLinkedUnitsText")}
              </Text>
              <RadioGroup
                id="link-units"
                groupname="link-units"
                options={radioViewOptions}
                initialValue={KEEP_LINKED_UNITS}
                onChange={(value): void => {
                  setSelectedOption(value as string);
                }}
              />
            </>
          ) : (
            <Text fontSize="sm" as="div" className="subject-text">
              <span dangerouslySetInnerHTML={{ __html: bodyTitleText }} />
            </Text>
          )}
          <Text fontSize="sm" as="div" className="body-text">
            {t("general.actionCantBeUndone")}
          </Text>
        </Modal.Body>

        <Modal.Footer>
          <div className="unit-delete-modal-footer">
            <Button
              onClick={(): void => onDelete({ unitId, shouldDeleteLinkedUnits })}
              color="danger"
            >
              {t("general.delete")}
            </Button>

            <Button color="secondary" onClick={onClose} className="cancel-btn">
              {t("general.cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default UnitDeleteModal;

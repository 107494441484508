import React, { FC } from "react";
import { useApplyTranslations } from "@hooks";
import { Course } from "types/entities";
import { Text } from "@epignosis_llc/gnosis";
import { useUIStore } from "@stores";
import { courseOptionsIds } from "@views/CourseEdit/components/CourseOptions";
import { completionTextStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";
import { TabKey } from "@views/CourseEdit/components/CourseOptions/CourseOptionsDrawer";

type CourseRules = NonNullable<Course["rules"]>;
type CourseCompletion = NonNullable<CourseRules["completion"]>;

export type CompletionTextProps = {
  completion: CourseCompletion;
  canEditOptions?: boolean;
};

const CompletionText: FC<CompletionTextProps> = ({ completion, canEditOptions = false }) => {
  const { t } = useApplyTranslations();
  const { setCourseOptionsState } = useUIStore();
  const { rule_type, unit_percentage } = completion[0];

  const getCompletionText = (): string => {
    switch (rule_type) {
      case "all_units":
        return t("myCourses.allUnitsMustComplete");
      case "unit_percentage":
        return t("myCourses.youMustCompleteOfTheContent", { number: unit_percentage });
      default:
        return t("myCourses.mandatoryUnitsMustComplete");
    }
  };

  const handleCompletionTextClick = (): void => {
    if (!canEditOptions) return;

    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.RulesAndPaths,
      scrollId: courseOptionsIds.courseCompletionRules,
    });
  };

  return (
    <Text
      onClick={handleCompletionTextClick}
      fontSize="xs"
      css={(theme): SerializedStyles => completionTextStyles(theme, { canEditOptions })}
    >
      {getCompletionText()}
    </Text>
  );
};

export default CompletionText;

import React, { FC } from "react";
import { Tag } from "@epignosis_llc/gnosis";
import { customFieldsStyles } from "./styles";
import { formatUtcDate } from "@utils/helpers";
import { t } from "@utils/i18n";
import { CustomFieldWithValue } from "types/entities";
import { useUIStore } from "@stores";
import { courseOptionsIds } from "@views/CourseEdit/components/CourseOptions";
import { TabKey } from "@views/CourseEdit/components/CourseOptions/CourseOptionsDrawer";
import { SerializedStyles } from "@emotion/react";

type CustomFieldsProps = {
  customFields: CustomFieldWithValue[] | null;
  canEditOptions?: boolean;
};

const getValue = (customField: CustomFieldWithValue): string | null => {
  const { type, value } = customField;
  if (!value) return null;
  if (type === "date") return formatUtcDate(value);
  if (type === "checkbox") return value === "on" ? t("general.yes") : t("general.no");

  return value;
};

const CustomFields: FC<CustomFieldsProps> = ({ customFields, canEditOptions = false }) => {
  const { setCourseOptionsState } = useUIStore();

  const handleTagClicked = (): void => {
    if (!canEditOptions) return;

    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.Details,
      scrollId: courseOptionsIds.customFieldsOptions,
    });
  };

  if (!customFields || customFields.length === 0) return null;

  return (
    <div css={(theme): SerializedStyles => customFieldsStyles(theme, { canEditOptions })}>
      {customFields.map((customField) => {
        const { id, name } = customField;
        const shownValue = getValue(customField);

        if (!shownValue) return null;

        return (
          <Tag key={id} className="custom-field-tag" onClick={handleTagClicked}>
            {name}: {shownValue}
          </Tag>
        );
      })}
    </div>
  );
};

export default CustomFields;

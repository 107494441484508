import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import { SubmissionListingRes, SingleSubmissionRes } from "types/responses";

export const getSingleSubmission = async (
  unitId: string,
  userId: string,
): Promise<SingleSubmissionRes> => {
  const res = await HttpClient.get(ENDPOINTS.gradingHub.assignments.getSingle(unitId, userId));

  return res.data;
};

export const getSubmissions = async (queryStr = ""): Promise<SubmissionListingRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.gradingHub.assignments.submissions}${queryStr}`);

  return res.data;
};

export const getUnitSubmissions = async (
  unitId: string,
  queryStr = "",
): Promise<SubmissionListingRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.gradingHub.assignments.unitSubmissions(unitId)}${queryStr}`,
  );

  return res.data;
};

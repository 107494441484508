import React, { ReactNode } from "react";
import { ArrowButton } from "@components/ReusableComponents";
import SettingCard from "./SettingCard";

type SettingArrowButtonProps = {
  onClick: () => void;
  title?: string;
  description?: ReactNode;
  buttonText: string;
  isDisabled?: boolean;
  tooltipText?: string;
  tooltipEnabled?: boolean;
  testId?: string;
};

const SettingArrowButton = ({
  title,
  description,
  buttonText,
  tooltipText,
  tooltipEnabled,
  onClick,
  isDisabled = false,
  testId,
}: SettingArrowButtonProps): JSX.Element => {
  return (
    <SettingCard title={title} description={description}>
      <ArrowButton
        className="link-button"
        buttonText={buttonText}
        tooltipText={tooltipText}
        tooltipEnabled={tooltipEnabled}
        onClick={onClick}
        disabled={isDisabled}
        testId={testId}
      />
    </SettingCard>
  );
};

export default SettingArrowButton;

import { css } from "@emotion/react";
import { GlobalStyleVariables } from "@styles";
const { unitsMaxWidth } = GlobalStyleVariables;
import {
  UNIT_PAGE_LAYOUT_CONTENT_BODY_PADDING,
  UNIT_CONTENT_WRAPPER_GAP,
} from "@components/UnitPageLayout/constants";

// 100vh - padding top - padding bottom
const height = `calc(100vh - ${UNIT_PAGE_LAYOUT_CONTENT_BODY_PADDING} - ${UNIT_PAGE_LAYOUT_CONTENT_BODY_PADDING})`;

export const unitContentWrapperStyles = (isFullHeight, isFullWidth) => css`
  display: flex;
  flex-direction: column;
  max-width: ${isFullWidth ? "100%" : unitsMaxWidth};
  margin: auto;
  gap: ${UNIT_CONTENT_WRAPPER_GAP};
  height: ${isFullHeight ? `${height}` : "auto"};
`;

export const unitContentStyles = () => css`
  word-break: break-word;
`;

import React, { FC, useState } from "react";
import { CreatableInput } from "@components";
import { Button, Label, Tooltip } from "@epignosis_llc/gnosis";
import { InfoCircledSVG, IFrameSVG } from "@epignosis_llc/gnosis/icons";

import EditableInput, { EditableInputProps } from "./EditableInput";

import { editableChipInputContainer } from "./styles";

import { useApplyTranslations } from "@hooks";
import { IconType, SelectOption } from "types/common";
import { optionArrayToString, stringToOptionArray } from "./helpers";
import { CreatableInputProps } from "./CreatableInput";
import classNames from "classnames";
import { SerializedStyles } from "@emotion/react";

export type EditableChipInputProps = {
  isEditable?: boolean;
  creatableInputProps: CreatableInputProps;
  editableInputProps: EditableInputProps;
  options: SelectOption[];
  buttonIcon?: IconType;
  buttonTooltipContent?: string;
  testId?: string;
  shouldTrimWhiteSpace?: boolean;
  handleInputChange: (options: SelectOption[]) => void;
  onEditModeChange?: (editMode: boolean) => void;
};

const buttonClassNames = (isEditMode: boolean): string =>
  classNames({
    "edit-button": true,
    "is-on-edit-mode": isEditMode,
  });

const EditableChipInput: FC<EditableChipInputProps> = ({
  isEditable = false,
  creatableInputProps,
  editableInputProps,
  options,
  buttonIcon = IFrameSVG,
  buttonTooltipContent = "",
  testId = "editable-chip-input",
  shouldTrimWhiteSpace = false,
  handleInputChange,
  onEditModeChange,
}) => {
  const { t } = useApplyTranslations();
  const Icon = buttonIcon;

  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const changeEditMode = (): void => {
    if (editMode) {
      const options = stringToOptionArray(inputValue, shouldTrimWhiteSpace);
      handleInputChange(options);
    } else {
      const formattedInputValue = optionArrayToString(options);
      setInputValue(formattedInputValue);
    }
    onEditModeChange && onEditModeChange(!editMode);
    setEditMode(!editMode);
  };

  const handleCreatableInputChange = (options: string[]): void => {
    const formattedOptions = options.map((label) => ({ label: label, value: label }));

    handleInputChange(formattedOptions);
  };

  const labelText = editMode ? editableInputProps.label : creatableInputProps.label;
  const tooltipContent = editMode
    ? editableInputProps.tooltipContent
    : creatableInputProps.tooltipContent;

  const { required: editableInputRequired = false } = editableInputProps ?? {};
  const { required: creatableInputRequired = false } = creatableInputProps ?? {};

  const isRequired = editMode ? Boolean(editableInputRequired) : Boolean(creatableInputRequired);

  return (
    <div
      css={(theme): SerializedStyles => editableChipInputContainer(theme, { isRequired })}
      data-testid={testId}
    >
      <div className="label-container">
        {labelText && <Label>{labelText}</Label>}
        {Boolean(tooltipContent) && (
          <Tooltip content={tooltipContent}>
            <InfoCircledSVG height={20} />
          </Tooltip>
        )}
      </div>

      <div className="inputs-wrapper">
        {!editMode ? (
          <CreatableInput
            className="creatable-input-container"
            defaultValues={creatableInputProps.defaultValues}
            onChange={handleCreatableInputChange}
            {...creatableInputProps}
            showLabel={false}
          />
        ) : (
          <EditableInput
            className="creatable-input-container"
            placeholder={editableInputProps?.placeholder ?? t("general.separateItems")}
            defaultValue={inputValue}
            onChange={(value): void => {
              setInputValue(value);
            }}
            {...editableInputProps}
            showLabel={false}
          />
        )}

        {isEditable && (
          <Tooltip content={buttonTooltipContent} disabled={buttonTooltipContent.length === 0}>
            <Button
              data-testid="edit-html-button"
              variant="ghost"
              as="div"
              aria-label="edit-button"
              className={buttonClassNames(editMode)}
              noGutters
              onClick={changeEditMode}
            >
              <Icon height={32} />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default EditableChipInput;

// Packages or third-party libraries
import React, { FC, useState } from "react";
import { useResponsive } from "ahooks";
import { Button, Text } from "@epignosis_llc/gnosis";

// Styles
import { iltSessionDescriptionStyles } from "./styles";

// Other imports
import { useApplyTranslations, useIsOverflow } from "@hooks";
import { RichText } from "types/entities";
import { SerializedStyles } from "@emotion/react";

type IltSessionDescriptionProps = {
  description: RichText | null;
};

const IltSessionDescription: FC<IltSessionDescriptionProps> = ({ description }) => {
  const { t } = useApplyTranslations();
  const { lg } = useResponsive();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(ref);
  const [showMore, setShowMore] = useState<boolean>(false);

  const isMobile = !lg;
  const showMoreBtn = isMobile && (isOverflow || showMore);

  const handleShowingMore = (): void => {
    setShowMore((current) => !current);
  };

  if (!description) return null;

  return (
    <div css={(theme): SerializedStyles => iltSessionDescriptionStyles(theme, { showMore })}>
      <div ref={ref} className="description">
        <Text fontSize="md" dangerouslySetInnerHTML={{ __html: description.html }} />
      </div>

      {showMoreBtn && (
        <div>
          <Button variant="ghost" noGutters onClick={handleShowingMore}>
            {showMore ? t("general.viewLess") : t("general.viewMore")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default IltSessionDescription;

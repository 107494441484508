import { yup } from "./validation";

export const SSOSchema = yup.object().shape({
  oidc: yup.object().shape({
    client_id: yup.string().trim().max(160),
    client_secret: yup.string().trim().max(160),
    token_url: yup.string().trim().nullable().max(1024),
    user_info_url: yup.string().trim().nullable().max(1024),
    authorization_url: yup.string().trim().nullable().max(1024),
    logout_url: yup.string().trim().nullable().max(1024),
    username: yup.string().trim().max(80),
    first_name: yup.string().trim().max(80),
    last_name: yup.string().trim().max(80),
    email: yup.string().trim().max(80),
    group: yup.string().trim().max(80),
    scope: yup.string().trim().max(80),
  }),
  saml: yup.object().shape({
    requires_protocol: yup.boolean(),
    idp: yup.string().trim().max(1024),
    key: yup.string().trim().max(80),
    key_alt: yup.string().trim().max(80),
    saml_certificate: yup.string().trim().max(3000),
    saml_certificate_alt: yup.string().trim().max(3000),
    sign_in_url: yup.string().trim().nullable().max(1024),
    sing_out_url: yup.string().trim().nullable().max(1024),
    username: yup.string().trim().max(150),
    firstname: yup.string().trim().max(150),
    lastname: yup.string().trim().max(150),
    email: yup.string().trim().max(150),
    group: yup.string().trim().max(80),
    add_group_on_each_login: yup.boolean(),
    validate_requests: yup.boolean(),
    sign_requests: yup.boolean(),
    enable_scim2_api: yup.boolean(),
    scim2_api_key: yup.string().trim().nullable().min(30).max(80),
  }),
  ldap: yup.object().shape({
    server: yup.string().trim().max(1024),
    port: yup.string().matches(/^\d{1,80}$/),
    tls: yup.boolean(),
    bind_dn: yup.string().trim().max(80),
    bind_password: yup.string().trim().max(80),
    dn_pattern: yup.string().trim().max(80),
    username: yup.string().trim().max(80),
    full_name: yup.string().trim().max(80),
    email: yup.string().trim().max(80),
  }),
});

// Packages or third-party libraries
import React, { FC } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { EditIconSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { certificateStyles } from "./styles";

// Utils, hooks
import { formatUtcDate, getCertificateLinkedInShareUrl } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore, useUIStore } from "@stores";

// Other imports
import { CourseCertificate } from "types/entities/Courses";
import { CertificateSVG, CertificateCompletedSVG } from "@assets/images";
import { courseOptionsIds } from "@views/CourseEdit/components/CourseOptions";
import { TabKey } from "@views/CourseEdit/components/CourseOptions/CourseOptionsDrawer";

type CertificateProps = {
  acquiredCertificate: CourseCertificate | null;
  canEditOptions?: boolean;
};

const Certificate: FC<CertificateProps> = ({
  acquiredCertificate: acquiredCertificate,
  canEditOptions = false,
}) => {
  const { t } = useApplyTranslations();
  const { setCourseOptionsState } = useUIStore();
  const { domainSettings } = useConfigurationStore();
  const { social_share_certification = false } = domainSettings ?? {};

  const { course_name = "", issued_at = null, expires_at = null, url } = acquiredCertificate ?? {};
  const expiresAtText = expires_at
    ? t("myCourses.certificateExpires", { date: `<strong>${formatUtcDate(expires_at)}</strong>` })
    : t("myCourses.certificateNotExpires");
  const canShareCertificate = !!acquiredCertificate && social_share_certification;
  const shareUrl = canShareCertificate
    ? getCertificateLinkedInShareUrl({ course_name, issued_at, expires_at })
    : null;
  const downloadText = shareUrl
    ? t("myCourses.certificateDownloadOrShare", { url, shareUrl: `"${shareUrl}"` })
    : t("myCourses.certificateDownload", { url });

  const handleEditCertificate = (): void => {
    if (!canEditOptions) return;

    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.Certificate,
      scrollId: courseOptionsIds.certificateOptions,
    });
  };

  return (
    <div css={certificateStyles}>
      <div className="certificate-container">
        <div className="certificate-image-container">
          {canEditOptions && (
            <div className="upload-overlay">
              <Button rounded onClick={handleEditCertificate}>
                <EditIconSVG height={32} />
              </Button>
              <Text fontSize="sm">{t("accountAndSettings.courseSettings.editCertificate")}</Text>
            </div>
          )}
          <div className="certificate-image">
            {acquiredCertificate ? <CertificateCompletedSVG /> : <CertificateSVG />}
          </div>
        </div>
        <div className="certificate-text-container">
          {acquiredCertificate ? (
            <>
              <Text fontSize="md" as="div" weight="700">
                {t("general.congratulations")}
              </Text>
              <Text fontSize="sm" as="div" dangerouslySetInnerHTML={{ __html: expiresAtText }} />
              <Text fontSize="sm" as="div" dangerouslySetInnerHTML={{ __html: downloadText }} />
            </>
          ) : (
            <>
              <Text fontSize="md" weight="700">
                {t("myCourses.awardsCertificate")}
              </Text>
              <Text fontSize="sm">{t("myCourses.completeToAcquire")}</Text>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Certificate;

import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["mainHeader"] => {
  return {
    background: colors.white,
    boxShadowColor: colors.secondary.light, // TODO: replace with theme colour
    mobileSearch: {
      color: colors.black, // TODO: remove it and make btns secondary
    },
    borderBottom: colors.secondary.light,
  };
};

import React, { FC, HTMLAttributes } from "react";
import { Drawer } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { m, useReducedMotion, Variants } from "framer-motion";
import classNames from "classnames";
import { GlobalStyleVariables } from "@styles";
import { tocContainerStyles, tocDrawerBodyContainer } from "./styles";
import { i18n } from "@utils/i18n";
import { useUIStore } from "@stores";
import ToCTrigger from "./ToCTrigger";

const { unitsSidebarMaxWidth } = GlobalStyleVariables;

export const asideContainerVariants: Variants = {
  expanded: (isReducedMotion) => ({
    maxWidth: unitsSidebarMaxWidth,
    minWidth: unitsSidebarMaxWidth,
    opacity: 1,
    transition: {
      when: "beforeChildren",
      duration: isReducedMotion ? 0 : 0.2,
    },
  }),
  hidden: (isReducedMotion) => ({
    maxWidth: "4.5rem", // button width + padding
    minWidth: "4.5rem",
    opacity: 1,
    transition: {
      when: "afterChildren",
      duration: isReducedMotion ? 0 : 0.2,
    },
  }),
};

const asideContentCVariants: Variants = {
  expanded: (isReducedMotion) => ({
    height: "100%",
    transition: {
      duration: isReducedMotion ? 0 : 0.1,
    },
  }),
  hidden: (isReducedMotion) => ({
    height: "100%",
    transition: {
      duration: isReducedMotion ? 0 : 0.1,
    },
  }),
};

const tocContainerClassNames = (isOpen: boolean): string =>
  classNames({
    "is-closed": !isOpen,
  });

const ToCContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
  const { lg } = useResponsive();
  const { showUnitSidebar, toggleUnitSidebar } = useUIStore();
  const isReducedMotion = useReducedMotion();
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "right" : "left";

  const handleContainerClick = (): void => {
    if (!showUnitSidebar) toggleUnitSidebar();
  };

  if (!lg) {
    return (
      <>
        <Drawer isOpen={showUnitSidebar} placement={placement} onClose={toggleUnitSidebar}>
          <Drawer.Body>
            <div css={tocDrawerBodyContainer} {...rest}>
              {children}
            </div>
          </Drawer.Body>
        </Drawer>
        <ToCTrigger isOpen={showUnitSidebar} toggleTocMenu={toggleUnitSidebar} />
      </>
    );
  }

  return (
    <>
      <m.aside
        css={tocContainerStyles}
        className={tocContainerClassNames(showUnitSidebar)}
        initial="expanded"
        animate={showUnitSidebar ? "expanded" : "hidden"}
        variants={asideContainerVariants}
        custom={isReducedMotion}
        onClick={handleContainerClick}
      >
        <m.div variants={asideContentCVariants} custom={isReducedMotion}>
          {children}
        </m.div>
      </m.aside>
      <ToCTrigger isOpen={showUnitSidebar} toggleTocMenu={toggleUnitSidebar} />
    </>
  );
};

export default ToCContainer;

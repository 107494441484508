import React, { FC, HTMLAttributes } from "react";
import { SerializedStyles } from "@emotion/react";
import { iltStyles } from "../styles";

type IltWrapperProps = {
  sessionColor: string;
};

const IltWrapper: FC<IltWrapperProps & HTMLAttributes<HTMLElement>> = ({
  sessionColor,
  children,
  ...props
}) => (
  <section css={(theme): SerializedStyles => iltStyles(theme, { sessionColor })} {...props}>
    {children}
  </section>
);

export default IltWrapper;

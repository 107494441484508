import { PalletThemeColors, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["widgetsDashboard"] => {
  return {
    background: colors.secondary.lighter,
    droppableBackground: hexToRGBA(colors.primary.lightest, 0.25),
    previewBackground: colors.white,
  };
};

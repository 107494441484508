import { CheckboxOption } from "./types";

export const handleReorder = (
  list: CheckboxOption[],
  startIndex: number,
  endIndex: number,
): CheckboxOption[] => {
  const result = Object.values(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { MagnifierSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type ViewActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const ViewAction: FC<ViewActionProps> = ({
  tooltipContent,
  icon: Icon = MagnifierSVG,
  iconHeight = 20,
  onClick,
  ...rest
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.actions.view")}
      onClick={onClick}
      data-testid="view-action"
      {...rest}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default ViewAction;

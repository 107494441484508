import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Text } from "@epignosis_llc/gnosis";
import { AttachmentSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";
import { drawerMessageContainer } from "./styles";
import { URLS } from "@constants/urls";
import { useUIStore } from "@stores";
import { Message } from "types/entities";
import { getDistanceFromNow, getFormattedUserName, truncate } from "@utils/helpers";
import { UserAvatar } from "@components/ReusableComponents";

const DrawerMessage: FC<Message> = ({ id, subject, sender, sent_at, attachment, read }) => {
  const { name, login, surname, avatar } = sender;
  const { hideMainDrawer } = useUIStore((state) => state);
  const { sm, md } = useResponsive();

  return (
    <article css={drawerMessageContainer} aria-placeholder={`message-${id}`}>
      <Link
        to={URLS.user.createInboxMessageLink({ messageId: id.toString() }, { messageAction: "" })}
        onClick={(): void => hideMainDrawer()}
        className="link-wrapper"
      >
        <UserAvatar
          avatar={avatar}
          imageSize="sm"
          avatarSize="xs"
          name={name}
          alt={`${name} ${surname}`}
        />
        <div className="message-info">
          <div className="header">
            <Text fontSize="sm" className="message-heading" weight={read ? "400" : "700"}>
              {getFormattedUserName({ name, surname, login })}
            </Text>
            <Text fontSize="xs" className="date">
              {attachment && <AttachmentSVG height={24} />}
              {getDistanceFromNow(sent_at)}
            </Text>
          </div>
          <div className="message-content">
            <Text fontSize="xs" className="subject">
              {truncate(subject, !sm ? 40 : !md ? 80 : 60)}
            </Text>
          </div>
        </div>
      </Link>
    </article>
  );
};

export default DrawerMessage;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const noSessionsStyles = css`
  svg {
    height: 207px;
  }
`;

export const iltStyles = ({ units: { ilt } }, { sessionColor }) => css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
  border: 1px solid ${ilt.borderColor};
  border-left: 4px solid ${sessionColor};
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0px ${ilt.boxShadowColor};

  ${mq["lg"]} {
    gap: 1rem;
  }
`;

import { css } from "@emotion/react";

export const AssignmentInfoContainer = ({ assignment }) => {
  return css`
    display: flex;
    gap: 2.3125rem;
    padding: 0.75rem;

    .status-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 0.75rem;

      .grade-info {
        display: flex;
        flex-direction: row;
        gap: 5rem;
      }
    }

    .info-nudge {
      display: flex;
      flex-direction: column;
      .field {
        line-height: normal;
        color: ${assignment.drawer.infoField};
      }
    }
  `;
};

import { css } from "@emotion/react";

export const selectCardStyles = ({ selectCard }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  background: ${selectCard.backgroundColor};
  border: 1px solid ${selectCard.borderColor};
  cursor: pointer;
  padding: 0.75rem;
  text-align: center;
  border-radius: 5px;

  &:hover {
    background: ${selectCard.hoverBackgroundColor};
    box-shadow: 0 3px 6px 0 ${selectCard.boxShadowColor};
    border: 1px solid ${selectCard.hoverBackgroundColor};
  }
`;

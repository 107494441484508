// Packages or third-party libraries
import React, { FC } from "react";
import ReactECharts from "echarts-for-react";
import { SerializedStyles, useTheme } from "@emotion/react";
import { Text } from "@epignosis_llc/gnosis";

// Styles
import { DynamicGaugeChartStyles } from "./styles";

// Hooks
import { useApplyTranslations } from "@hooks";

// Utils
import { getChartOption } from "./helpers";
import { i18n } from "@utils/i18n";

type DynamicGaugeChartProps = {
  score?: number | null;
  title?: string;
  isPassed?: boolean;
  maxScore?: number;
  isPercentage?: boolean;
  isFullCircle?: boolean;
};

const DynamicGaugeChart: FC<DynamicGaugeChartProps> = ({
  title = "general.score",
  score,
  isPassed = true,
  maxScore = 100,
  isPercentage = false,
  isFullCircle = false,
}) => {
  const { t } = useApplyTranslations();
  const {
    progressBar,
    units: {
      test: {
        results: { success, fail },
      },
    },
  } = useTheme();

  const isRTL = i18n.dir() === "rtl";
  const progressColor = isPassed ? success.color : fail.color;
  const hasScore = typeof score === "number";
  const normalizedScore = hasScore ? Math.round((score / maxScore) * 100) : 0;

  return (
    <div css={(): SerializedStyles => DynamicGaugeChartStyles(isRTL)}>
      {hasScore && (
        <>
          <ReactECharts
            option={getChartOption({
              progressColor,
              progressBackgroundColor: progressBar.success.background,
              score: normalizedScore,
              isFullCircle,
            })}
          />
          <div className="score-text-container">
            <Text fontSize="sm">{t(title)}</Text>
            <Text fontSize="4xl" weight="700">
              {score} {isPercentage && <span>%</span>}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};

export default DynamicGaugeChart;

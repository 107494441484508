import React, { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LazyMotion, domMax } from "framer-motion";
import { Drawer, Loader } from "@epignosis_llc/gnosis";
import { unitLayoutContainer } from "./styles";
import UnitMainDrawer from "./components/UnitMainDrawer";
import { GamificationDrawer } from "@layouts/DashboardLayout/components";
import { useIsPublicCourse, useScreenSizeAlert } from "@hooks";
import { ScreenSizeAlert } from "@components/ReusableComponents";

const UnitLayout: FC = () => {
  const isPublicCourse = useIsPublicCourse();

  const shouldShowScreenSizeAlert = useScreenSizeAlert(["administrator", "instructor"]);

  return (
    <main css={unitLayoutContainer}>
      <Suspense fallback={<Loader fullScreen />}>
        <Drawer.Root />
        <UnitMainDrawer />
        {!isPublicCourse && <GamificationDrawer />}
        <LazyMotion features={domMax}>
          {shouldShowScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
        </LazyMotion>
      </Suspense>
    </main>
  );
};

export default UnitLayout;

import React, { FC } from "react";
import FooterActions from "./FooterActions";
import HoverActions from "./HoverActions";
import { Course } from "types/entities";

export type MyCourseCardFooterProps = {
  isHovering?: boolean;
  course: Course;
  children?: never;
};

const MyCourseCardFooter: FC<MyCourseCardFooterProps> = ({ isHovering, course }) => {
  return <>{isHovering ? <HoverActions course={course} /> : <FooterActions course={course} />}</>;
};

export default MyCourseCardFooter;

import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import PaymentHeader from "./PaymentHeader";
import AvailableCredits from "./AvailableCredits";
import { creditsContainer } from "./styles";
import { Bundle, Course } from "types/entities";
import Price from "@components/Price/Price";
import { Processors } from "types/entities/Catalog";
import { useApplyTranslations } from "@hooks";

export type PaymentInfoProps = {
  data: Course | Bundle;
  processor: Processors;
  categoryText?: string;
};

const PaymentInfo: FC<PaymentInfoProps> = ({ data, processor, categoryText }) => {
  const { t } = useApplyTranslations();
  const { price, discounted_price } = data;
  const isCreditsProcessor = processor === "credits";

  return (
    <div css={creditsContainer}>
      <PaymentHeader data={data} categoryText={categoryText} />

      <div className="credits-info">
        {price && (
          <div className="price-wrapper">
            <Price
              value={discounted_price ? discounted_price.amount : price.amount}
              prevValue={discounted_price ? price.amount : undefined}
              hideCurrency={isCreditsProcessor}
            />
            {isCreditsProcessor && <Text fontSize="md">{t("general.credits")}</Text>}
          </div>
        )}

        {/* If the current processor is credits, then we show a text which contains information about
        the amount of users available credits */}
        {isCreditsProcessor && <AvailableCredits data={data} />}
      </div>
    </div>
  );
};

export default PaymentInfo;

import React, { FC } from "react";
import { Button, ProgressBar } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { courseCardProgressBar } from "../../components/styles";
import { getButtonColor } from "../../helpers";
import { Course } from "types/entities";
import { formatUtcDate, getButtonAvailabilityText } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

export type MyCourseCardFooterProps = {
  course: Course;
  children?: never;
};

const MAIN_BUTTON_TEXT = {
  completed: "general.progress.completed",
  incomplete: "myCourses.mainButtonText.incomplete",
  not_attempted: "myCourses.mainButtonText.notAttempted",
  failed: "general.progress.notPassed",
};

const FooterActions: FC<MyCourseCardFooterProps> = ({ course }) => {
  const { t } = useApplyTranslations();
  const { progress, availability, expires_at, user_access_expires_at, role_in_course } = course;
  const isCompleted =
    progress?.completion_status === "completed" || progress?.completion_status === "failed";
  const isCourseAvailable = Boolean(availability?.is_available);

  return (
    <>
      {isCourseAvailable ? (
        <>
          {isCompleted ? (
            <Button block color={getButtonColor(progress.completion_status)} className="footer-btn">
              {t(MAIN_BUTTON_TEXT[progress.completion_status])}
            </Button>
          ) : (
            <>
              {(expires_at || user_access_expires_at) && role_in_course === "learner" ? (
                <Button block color="danger" className="footer-btn">
                  {t("restrictions.expires", {
                    expiresAt: user_access_expires_at && formatUtcDate(user_access_expires_at),
                  })}
                </Button>
              ) : (
                <ProgressBar
                  percent={progress?.completion_percentage ?? 0}
                  rounded={false}
                  css={(theme): SerializedStyles =>
                    courseCardProgressBar(theme, { progress: progress?.completion_percentage ?? 0 })
                  }
                />
              )}
            </>
          )}
        </>
      ) : (
        <Button block color="secondary" className="footer-btn">
          {getButtonAvailabilityText(course)}
        </Button>
      )}
    </>
  );
};

export default FooterActions;

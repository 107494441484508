import { css } from "@emotion/react";

export const overlayWrapper = css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .hover-btn {
    width: 4rem;
    height: 4rem;
  }
`;

export const hoverWrapper = css`
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  .hover-btn {
    width: 4rem;
    height: 4rem;
  }
`;

export const bodyWrapper = ({ courseCard }) => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: inherit;

  &:hover {
    color: inherit;
  }

  &.disabled {
    pointer-events: none;
  }

  .body-container {
    .code {
      color: ${courseCard.cardBody.code};
      min-height: 20px;
    }

    .card-title {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 4em;
    }

    .rating-container {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }

  .price,
  .previous-price {
    color: inherit !important;
  }
`;

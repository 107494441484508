import queryKeys from "@constants/queryKeys";
import { handleCourseErrors, handleGroupsErrors } from "@errors";
import { handleBranchesErrors, handleUserTypesErrors } from "@errors/errors";
import {
  getCoursesFilter,
  getGroupsFilter,
  getBranchesFilter,
  getUserTypesFilter,
} from "@views/Reports/CustomReports/api";
import { AxiosError } from "axios";
import { GenericFilterRes, SelectOption } from "types/common";

export const resolveDefaultValues = (labels: LabelMap[]): SelectOption[] => {
  const updatedLabels = labels.map((obj) => {
    return {
      value: obj.id.toString(),
      label: obj.name,
    };
  });

  return updatedLabels;
};

type LabelMap = {
  name: string;
  id: number;
};

export const updateSearchQuery = (
  baseSearchQuery: string,
  searchValue = "",
  pageParam = 1,
): string => {
  const searchQueryWithPageNumber = baseSearchQuery.replace(
    /(page\[number\]=)\d+/,
    `$1${pageParam}`,
  );
  const finalSearchQuery =
    searchQueryWithPageNumber + `&filter[name][like]=${encodeURIComponent(searchValue)}`;
  return finalSearchQuery;
};

export const fetchData = (
  filterType: string,
  updatedQueryString: string,
): Promise<GenericFilterRes> => {
  switch (filterType) {
    case "courses":
      return getCoursesFilter(updatedQueryString);

    case "groups":
      return getGroupsFilter(updatedQueryString);

    case "branches":
      return getBranchesFilter(updatedQueryString);

    case "userTypes":
      return getUserTypesFilter(updatedQueryString);

    default:
      return getCoursesFilter(updatedQueryString);
  }
};

export const getQueryKey = (filterType: string): string => {
  switch (filterType) {
    case "courses":
      return queryKeys.filters.courses;
    case "groups":
      return queryKeys.filters.groups;
    case "branches":
      return queryKeys.filters.branches;
    case "userTypes":
      return queryKeys.filters.userTypes;
    default:
      return "";
  }
};

export const handleError = (filterType: string, error: AxiosError): void => {
  switch (filterType) {
    case "courses":
      handleCourseErrors(error);
      break;
    case "groups":
      handleGroupsErrors(error);
      break;
    case "branches":
      handleBranchesErrors(error);
      break;
    case "userTypes":
      handleUserTypesErrors(error);
      break;
    default:
      handleCourseErrors(error);
  }
};

import { PalletThemeColors, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["infoGrid"] => {
  return {
    info: {
      backgroundColor: hexToRGBA(colors.primary.lightest, 0.25),
      textColor: colors.black,
      borderColor: colors.primary.base,
    },
    failed: {
      backgroundColor: hexToRGBA(colors.red.lightest, 0.25),
      textColor: colors.white,
      borderColor: colors.red.base,
    },
  };
};

import { ResponsiveValues } from "types/common";
import { useUIStore } from "@stores";

const { getState } = useUIStore;

export const getResponsiveGridValues = (): ResponsiveValues => {
  const { mainNavState } = getState();
  const isMainNavCollapsed = mainNavState === "collapsed";

  return [
    1,
    2,
    isMainNavCollapsed ? 3 : 2,
    isMainNavCollapsed ? 4 : 3,
    isMainNavCollapsed ? 5 : 4,
    5,
    isMainNavCollapsed ? 6 : 5,
  ];
};

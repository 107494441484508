import { PalletThemeColors, hexToRGBA, isShadeOfWhite } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["announcements"] => {
  const isLightTheme = isShadeOfWhite(colors.primary.base);

  return {
    internal: {
      background: isLightTheme ? colors.primary.base : hexToRGBA(colors.primary.lightest, 0.25),
    },
    external: {
      background: colors.white,
    },
    admin: {
      background: isLightTheme ? colors.primary.base : hexToRGBA(colors.primary.lightest, 0.25),
    },
    color: colors.black,
    borderColor: colors.secondary.base,
  };
};

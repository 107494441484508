// Packages or third-party libraries
import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";

// Styles
import { AssignmentInfoContainer } from "./styles";

// Utils, hooks
import {
  extractDate,
  formatUtcDate,
  getFormattedUserName,
  getIconFromStatus,
  getTranslationFromStatus,
  isSubmission,
} from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { IltSession, SingleSubmission, SubmissionListing } from "types/entities";
import { SingleIltSession } from "types/entities/Ilts";

type SubmissionInfoProps = {
  submission: SubmissionListing | IltSession;
  singleSubmission?: SingleSubmission | SingleIltSession;
};

type SubmissionRenderInfoProps = {
  field: string;
  value?: string | number;
  isValueBold?: boolean;
};

const BasicInfo: FC<SubmissionRenderInfoProps> = ({ field, value, isValueBold }): JSX.Element => {
  return (
    <div className="info-nudge">
      <Text weight="700" fontSize="xs" className="field">
        {field.toUpperCase()}
      </Text>
      <Text weight={isValueBold ? "700" : "400"} fontSize="sm" className="value">
        {value ?? "-"}
      </Text>
    </div>
  );
};

const SubmissionInfo: FC<SubmissionInfoProps> = ({ submission, singleSubmission }) => {
  const { t } = useApplyTranslations();
  const { grade, status, grade_date } = singleSubmission ?? {};
  const { user } = submission ?? {};

  const date = extractDate(submission);
  const { name, surname, login } = user;

  const formattedUserName = getFormattedUserName({ name, surname, login });
  const isSubmissionType = isSubmission(submission);

  if (!status) return null;

  return (
    <div css={(theme): SerializedStyles => AssignmentInfoContainer(theme)}>
      <div className="status-icon"> {getIconFromStatus(status, 40)}</div>
      <div className="info">
        <div>
          <BasicInfo field={t("general.user")} value={formattedUserName} isValueBold />
        </div>
        <div className="grade-info">
          <BasicInfo
            field={t("general.status")}
            value={t(getTranslationFromStatus(status))}
            isValueBold
          />
          <BasicInfo field={t("general.actions.grade")} value={grade} />
          <BasicInfo
            field={isSubmissionType ? t("gradingHub.submissionDate") : t("gradingHub.sessionDate")}
            value={formatUtcDate(date)}
          />
          <BasicInfo
            field={t("gradingHub.gradeDate")}
            value={grade_date ? formatUtcDate(grade_date) : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default SubmissionInfo;

import { css } from "@emotion/react";

export const ImageSelectorContainerStyles = (
  { imageSelector, colors },
  {
    aspectRatio = 3 / 2,
    height = "150px",
    minHeight = "150px",
    imageHeight = "150px",
    imageWidth = "150px",
    disabled = false,
    actionButtonsInline = false,
  },
) => css`
  position: relative;
  width: 100%;
  height: ${height};
  min-height: ${minHeight};
  aspect-ratio: ${aspectRatio};
  overflow: hidden;
  z-index: 2;
  background: ${colors.secondary.lighter};

  .image-preview {
    &-fit {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-center {
      height: ${imageHeight};
      width: ${imageWidth};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .input-field {
    display: none;
  }

  .upload-overlay {
    width: 100%;
    height: 100%;
    gap: 0.625rem;
    z-index: 1;
    display: ${disabled ? "none" : "flex"};
    justify-content: center;
    align-items: center;
    flex-direction: ${actionButtonsInline ? "row" : "column"};
    position: absolute;
    color: white;
    background-color: ${imageSelector.backgroundOverlayHoverColor};
    opacity: 0;
    transition: opacity 0.1s linear;

    &:hover {
      opacity: ${disabled ? 0 : 1};
    }

    .input-field {
      position: absolute;
      appearance: initial;
      opacity: 0;
    }

    .action-button {
      width: 4.625rem;
      height: 4.625rem;
    }
  }
`;

import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import { AICreditsRes, CreditCatalogRes } from "types/responses";

export const getCraftCatalog = async (): Promise<CreditCatalogRes> => {
  const res = await HttpClient.get(ENDPOINTS.craft.creditCatalog);

  return res.data;
};

export const getCraftCredits = async (): Promise<AICreditsRes> => {
  const res = await HttpClient.get(ENDPOINTS.craft.credits);

  return res.data;
};

export const postCreditRequest = async (): Promise<void> => {
  await HttpClient.post(ENDPOINTS.craft.creditRequest);
};

export const putCraftDisclaimer = async (): Promise<void> => {
  await HttpClient.put(ENDPOINTS.craft.disclaimer);
};

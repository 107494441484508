import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { generalNotification } from "@utils/helpers";
import { t } from "@utils/i18n";
import queryKeys from "@constants/queryKeys";
import { deleteCourseImage, postCourseImage } from "@views/CourseEdit/api";

type UseEditImageProps = { postImage: (file: File) => void; deleteImage: () => void };

export const useEditImage = (courseId: string): UseEditImageProps => {
  const queryClient = useQueryClient();

  const { mutate: postImage } = useMutation(
    [queryKeys.courses.postImage, courseId],
    (file: File) => postCourseImage(file, courseId),
    {
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
      },
      onSuccess: () => {
        generalNotification("success", <p>{t("courseEdit.postCourseImageSuccess")}</p>);
      },
      onError: () => {
        generalNotification("error", <p>{t("courseEdit.postCourseImageError")}</p>);
      },
    },
  );

  const { mutate: deleteImage } = useMutation(() => deleteCourseImage(courseId), {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
    },
    onSuccess: () => {
      generalNotification("success", <p>{t("courseEdit.deleteCourseImageSuccess")}</p>);
    },
    onError: () => {
      generalNotification("error", <p>{t("courseEdit.deleteCourseImageError")}</p>);
    },
  });

  return {
    postImage: (file: File): void => postImage(file),
    deleteImage: (): void => deleteImage(),
  };
};

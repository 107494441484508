import { t } from "@utils/i18n";
import { GroupedOption, QueryFilter } from "types/common";
import { Group } from "types/entities";

export const applyFilter = ({
  filters,
  filter,
}: {
  filters: QueryFilter[];
  filter: QueryFilter;
}): QueryFilter[] => {
  const { key, value } = filter;

  const index = filters.findIndex((filter) => filter.key === key);

  // remove filter
  if (!value) return filters.filter((filter) => filter.key !== key);

  // add search filter
  if (index === -1) return filters.concat(filter);

  // update search filter
  filters[index].value = value;

  return filters;
};

export const hasPolicies = (policies: Group[]): boolean => {
  return policies.some((item) => Object.values(item.policies).some((value) => value === true));
};

// Sort the formattedResult array, placing "Uncategorized Courses" last
export const sortFormattedCourses = (groupedCourses: GroupedOption[]): GroupedOption[] => {
  return groupedCourses.sort((a, b) => {
    if (a.label === t("courses.uncategorizedCourses")) return 1;
    if (b.label === t("courses.uncategorizedCourses")) return -1;
    return a.label.localeCompare(b.label);
  });
};

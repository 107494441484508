import { RefObject } from "react";

// Define a return type for the custom hook
type UseInfiniteScrollReturn = {
  observeScroll: () => () => void;
};

// Custom hook for creating the IntersectionObserver
const useInfiniteScroll = (
  loadingRef: RefObject<HTMLDivElement>,
  fetchNextPage: () => void,
  hasNextPage: boolean,
): UseInfiniteScrollReturn => {
  // Function that sets up the IntersectionObserver and returns a cleanup function
  const observeScroll = (): (() => void) => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { root: null, rootMargin: "200px", threshold: 0.1 },
    );

    const currentRef = loadingRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    // Return cleanup function to unobserve when necessary
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  };

  return { observeScroll };
};

export default useInfiniteScroll;

import { Column, DropdownItem } from "@epignosis_llc/gnosis";

// This helper function is used to get the list of filters for branches, groups and statuses for tables
export const getSortingDropdownOptions = (columns: Column[]): DropdownItem[] => {
  const dropdownOptions: DropdownItem[] = columns.map((column) => {
    return {
      label: column.cell,
      value: column.accessor,
      id: column?.sortOrder ? "desc" : "asc",
    } as DropdownItem;
  });

  return dropdownOptions;
};

import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["rangeInput"] => {
  return {
    color: colors.primary.base,
    backgroundColor: colors.secondary.lighter,
    disabled: colors.secondary.base,
  };
};

import { userHasRequiredPermissions } from "./index";
import useConfigurationStore from "@stores/configuration";
import authService from "@utils/services/AuthService";

// map with all the available permissions
export const automationsPermissions = {
  read: "events_engine.automations.read",
  create: "events_engine.automations.create",
  update: "events_engine.automations.update",
  delete: "events_engine.automations.delete",
};

const { read, delete: automationDelete, create, update } = automationsPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => events_engine.automations.read
   * automations.read is required in order to have all the other automation related permissions
   *
   *  Allowed actions:
   *  GET automations
   *
   * @returns boolean
   */
  canAccessAutomations: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => events_engine.automations.create
   *
   * Allowed actions:
   *  none
   *
   * @returns boolean
   */
  canCreateAutomations: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => events_engine.automations.update
   *
   * Allowed actions:
   *  none
   *
   * @returns boolean
   */
  canUpdateAutomations: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => events_engine.automations.delete
   *
   * Allowed actions:
   * DELETE automations.delete
   *
   * @returns boolean
   */
  canDeleteAutomations: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions(
      [automationDelete],
      getState().userProfileData?.permissions[userRole],
    );
  },
};

// Packages or third-party libraries
import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";
import { UseFormReturn } from "react-hook-form";

// Styles
import { courseHeaderStyles } from "../styles";

// Components
import TitleContainer from "../TitleContainer";
import Restrictions from "../Restrictions";
import ExpiresDate from "../ExpiresDate";
import { CourseImage } from "@views/CourseEdit";
import IntoVideo from "../IntoVideo";
import CourseEditButton from "./CourseEditButton";

// Utils, hooks
import { getCourseCategoryText } from "../helpers";

// Other imports
import { Course, CourseCategory } from "types/entities";
import { EditCourseData } from "@views/CourseEdit/api";

type CourseEditHeaderProps = {
  course: Course;
  categoryTree: CourseCategory[];
  form: UseFormReturn<EditCourseData>;
  onSave: () => void;
};

const CourseEditHeader: FC<CourseEditHeaderProps> = ({ course, categoryTree, form, onSave }) => {
  const {
    id,
    name,
    category,
    code,
    cover_image,
    intro_video,
    level,
    rules,
    starts_at,
    user_access_expires_at,
    expires_at,
    policies,
  } = course;
  const { prerequisites } = rules;
  const { can_edit = false, can_view_rules = false, can_update_rules = false } = policies ?? {};
  const courseId = id.toString();
  const { categoryText, allCategoryParent } = getCourseCategoryText({ category, categoryTree });
  const courseLevel = level ?? 0;
  const unlocksOnHigherLevel = Boolean(level);
  const canEditOptions = can_edit || (can_view_rules && can_update_rules);

  return (
    <>
      <div css={(theme): SerializedStyles => courseHeaderStyles(theme, { isSticky: false })}>
        <div className="start-container">
          <TitleContainer
            category={category}
            categoryText={categoryText}
            allCategoryParent={allCategoryParent}
            name={name}
            code={code}
            form={form}
            canEditOptions={canEditOptions}
            canEdit={can_edit}
            onSave={onSave}
          />

          <div className="additional-info-container">
            <div className="restrictions-wrapper">
              <Restrictions
                level={courseLevel}
                unlocksOnHigherLevel={unlocksOnHigherLevel}
                prerequisites={prerequisites}
                startsAt={starts_at}
                userAccessExpiresAt={user_access_expires_at}
                canEditOptions={canEditOptions}
                isEdit
              />
              <ExpiresDate expiresAt={expires_at} isEdit canEditOptions={canEditOptions} />
            </div>
          </div>
        </div>

        <div className="end-container">
          <CourseImage image={cover_image?.lg ?? ""} courseId={courseId} />
          <CourseEditButton course={course} canEditOptions={canEditOptions} />
          <IntoVideo introVideo={intro_video} name={name} canEditOptions={canEditOptions} />
        </div>
      </div>
    </>
  );
};

export default CourseEditHeader;

import { css } from "@emotion/react";

export const formContainer = ({ scheduledReportsDrawer }) => css`
  margin-top: 1.5rem;

  .recipients-btn {
    padding: 0;
  }

  .textarea-wrapper {
    margin-top: 38px;
  }

  .day-interval-label-wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .creatable-input-container {
    width: 100%;
  }

  .edit-mode-info-text {
    margin-top: 0.25rem;
    color: ${scheduledReportsDrawer.titleColor};
  }

  .range-input-wrapper {
    padding: 1.5rem 0.75rem;
    border: 1px solid ${scheduledReportsDrawer.borderColor};
    border-radius: 0.3125rem;
  }
`;

import React, { FC } from "react";
import { t } from "i18next";
import { sidebarIconsStyles } from "./styles";
import { Course } from "types/entities";
import { LockIconSVG, ShareSVG } from "@epignosis_llc/gnosis/icons";
import SidebarIcon from "./SidebarIcon";
import { TALENT_LMS_HELP_LINKS } from "@constants/staticLinks";

type SidebarIconsProps = {
  course: Course;
  toggleShareDrawer: () => void;
};

const SidebarIcons: FC<SidebarIconsProps> = ({ course, toggleShareDrawer }) => {
  const { public_key, is_locked = false } = course;
  const isShared = Boolean(public_key);

  if (!isShared && !is_locked) return null;

  return (
    <div className="sidebar-icons" css={sidebarIconsStyles}>
      {isShared && (
        <div onClick={toggleShareDrawer} className="shared-icon">
          <SidebarIcon icon={ShareSVG} tooltipContent={t("courseEdit.publicTooltipText")} />
        </div>
      )}
      {is_locked && (
        <SidebarIcon
          icon={LockIconSVG}
          tooltipContent={
            <div
              dangerouslySetInnerHTML={{
                __html: t("courseEdit.lockCourseTooltip", {
                  url: TALENT_LMS_HELP_LINKS.COURSE_LOCKED,
                }),
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default SidebarIcons;

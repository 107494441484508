import React, { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LazyMotion, domAnimation } from "framer-motion";
import { Drawer, Loader } from "@epignosis_llc/gnosis";

import CustomHomePageLayoutStyles from "./styles";

import { ScreenSizeAlert } from "@components/ReusableComponents";
import { useScreenSizeAlert } from "@hooks";

const CustomHomePageLayout: FC = () => {
  const shouldShowScreenSizeAlert = useScreenSizeAlert(["administrator"]);

  return (
    <LazyMotion features={domAnimation} strict>
      <Suspense fallback={<Loader fullScreen />}>
        <div css={CustomHomePageLayoutStyles} role="main">
          <LazyMotion features={domAnimation} strict>
            <Drawer.Root />
          </LazyMotion>
          <Suspense fallback={<Loader fullScreen />}>
            {shouldShowScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
          </Suspense>
        </div>
      </Suspense>
    </LazyMotion>
  );
};

export default CustomHomePageLayout;

import React, { FC, HTMLAttributes } from "react";
import { borderedSectionStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";

type BorderedSectionProps = HTMLAttributes<HTMLElement> & {
  isThick?: boolean;
};

const BorderedSection: FC<BorderedSectionProps> = ({ isThick = false, children, ...rest }) => {
  return (
    <section
      className="bordered-section"
      css={(theme): SerializedStyles => borderedSectionStyles(theme, { isThick })}
      {...rest}
    >
      {children}
    </section>
  );
};

export default BorderedSection;

import React, { useState } from "react";
import { ActionDrawer } from "@components";
import { useApplyTranslations } from "@hooks";
import { Controller, FieldValues, Path } from "react-hook-form";
import { BranchDrawersProps, BranchPropertiesNames } from "@views/Branches/types";
import { Textarea, InputError, ToggleSwitch } from "@epignosis_llc/gnosis";
import { checkTextValue } from "@views/AccountAndSettings/PortalSettings/components/helpers";
import { tosContainer } from "@views/Branches/styles";

type ToSDrawerProps<T extends FieldValues> = BranchDrawersProps<T> & {
  showEnforceTermsOfService: boolean;
};

const TermsOfServiceDrawer = <T extends FieldValues>({
  control,
  isDisabled,
  resetField,
  toggleDrawer,
  watch,
  showEnforceTermsOfService,
  errors,
  isOpen,
}: ToSDrawerProps<T>): JSX.Element => {
  const { t } = useApplyTranslations();
  const headerTitle = t("accountAndSettings.usersSettings.termsOfService");
  const watchTOS = watch?.(BranchPropertiesNames.BRANCH_TERMS_OF_SERVICE as Path<T>);
  const [tempValue, setTempValue] = useState(watchTOS);

  const handleClose = (): void => {
    const resetValue = tempValue ? { defaultValue: tempValue } : undefined;

    resetField?.(BranchPropertiesNames.BRANCH_TERMS_OF_SERVICE as Path<T>, resetValue);

    toggleDrawer();
  };
  const actionButton = {
    text: t("general.back"),
    isDisabled: isDisabled || Boolean(errors?.terms_of_service),
  };
  const onApply = (): void => {
    setTempValue(watchTOS);
    toggleDrawer();
  };

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={handleClose}
      showFooter={true}
      size="md"
      headerTitle={headerTitle}
      actionButton={actionButton}
      onApply={onApply}
    >
      <div css={tosContainer}>
        <Controller
          name={BranchPropertiesNames.BRANCH_TERMS_OF_SERVICE as Path<T>}
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => (
            <div className="form-container">
              <Textarea
                className="announcementTextarea"
                id="branch_terms_of_service"
                status={errors?.terms_of_service ? "error" : "valid"}
                value={checkTextValue(value ?? "")}
                label={t("accountAndSettings.usersSettings.terms")}
                placeholder={t("general.typeHere")}
                disabled={isDisabled}
                onChange={(text): void => {
                  onChange(text);
                }}
              />
            </div>
          )}
        />
        {errors && errors.terms_of_service && (
          <InputError style={{ margin: 0 }}>{errors.terms_of_service?.message}</InputError>
        )}

        {showEnforceTermsOfService && (
          <Controller
            name={BranchPropertiesNames.BRANCH_ENFORCE_TERMS_OF_SERVICE as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => (
              <div className="enforce-container">
                <ToggleSwitch
                  labelAfter={t("accountAndSettings.usersSettings.enforceNewTerms")}
                  defaultChecked={Boolean(value)}
                  isDisabled={isDisabled}
                  onChange={(): void => {
                    onChange(!value);
                  }}
                />
              </div>
            )}
          />
        )}
      </div>
    </ActionDrawer>
  );
};

export default TermsOfServiceDrawer;

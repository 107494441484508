import React, { FC } from "react";
import { Modal, Text } from "@epignosis_llc/gnosis";
import ExternalFooterButtons from "./components/ExternalFooterButtons";
import { useConfigurationStore } from "@stores";
import { useApplyTranslations } from "@hooks";

export type ExternalPaymentModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ExternalSubscriptionModal: FC<ExternalPaymentModalProps> = ({ isOpen, onClose }) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const isSignUpEnabled = Boolean(domainSettings?.signup.enabled);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>{t("general.subscription")}</Modal.Header>
      <Modal.Body>
        <Text fontSize="sm">
          {t(
            isSignUpEnabled
              ? "payments.subscriptions.youSelectedToSubscribeSignUp"
              : "payments.subscriptions.youSelectedToSubscribe",
          )}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <ExternalFooterButtons onClose={onClose} actionType="payment" />
      </Modal.Footer>
    </Modal>
  );
};

export default ExternalSubscriptionModal;

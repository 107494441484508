import React, { FC, useState } from "react";
import { Button, Grid, Input, Label, Text, ToggleSwitch } from "@epignosis_llc/gnosis";
import { OpenedMessageSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ShareDrawerStyles } from "./styles";
import queryKeys from "@constants/queryKeys";
import { yupResolver } from "@hookform/resolvers/yup";
import { shareCourse } from "@views/CourseEdit/api";
import { Course } from "types/entities";
import { yup } from "@utils/validation";
import { handleCourseErrors } from "@errors";
import { URLS } from "@constants/urls";
import { TALENT_LMS_HELP_LINKS } from "@constants/staticLinks";
import { getCoursePublicUrl } from "@views/CourseEdit";
import { ActionDrawer, CopyToClipboardButton, Link, Skeletons } from "@components";
import { Divider } from "@components/ReusableComponents";
import InvitationsDrawer from "../InvitationsDrawer/InvitationsDrawer";

type ShareDrawerProps = {
  isOpen: boolean;
  course: Course;
  onClose: () => void;
};

type ShareSchema = { shared: boolean };

const ShareValidationSchema = yup.object().shape({
  shared: yup.boolean().required(),
});

const ShareDrawer: FC<ShareDrawerProps> = ({ isOpen, course, onClose }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isInvitationsDrawerOpen, setIsInvitationsDrawerOpen] = useState(false);

  const { id, policies, public_key } = course;
  const { can_invite_public_course = false } = policies ?? {};
  const courseId = id.toString();

  const publicUrl = getCoursePublicUrl(public_key as string);

  const { control, watch } = useForm<ShareSchema>({
    mode: "onChange",
    resolver: yupResolver(ShareValidationSchema),
    defaultValues: {
      shared: !!public_key,
    },
  });

  const isShared = watch("shared");

  const {
    mutate: shareCourseMutation,
    isLoading: shareCourseIsLoading,
    status: shareCourseStatus,
    error: shareCourseError,
  } = useMutation([queryKeys.courses.share], (shared: boolean) => shareCourse(courseId, shared), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
    },
    onError: (error: AxiosError) => {
      const handleError = (): void => navigate(URLS.courses.courses);
      handleCourseErrors(error, false, handleError);
    },
  });

  const handleOpenInvitationsDrawer = (): void => {
    if (isShared) {
      setIsInvitationsDrawerOpen(true);
    }
  };

  const handleCloseInvitationsDrawer = (): void => {
    setIsInvitationsDrawerOpen(false);
  };

  return (
    <>
      <ActionDrawer
        isOpen={isOpen}
        headerTitle={t("courseEdit.share")}
        size="md"
        showFooter={false}
        onClose={onClose}
      >
        <Grid templateColumns={1} gap={1.5} css={ShareDrawerStyles}>
          <Grid.Item colSpan={1}>
            <Controller
              name="shared"
              control={control}
              render={({ field: { onChange, value } }): JSX.Element => {
                const label = t(`general.${value ? "enabled" : "disabled"}`);

                return (
                  <ToggleSwitch
                    labelAfter={label}
                    defaultChecked={value}
                    isDisabled={shareCourseIsLoading}
                    onChange={(): void => {
                      const shared = !value;
                      onChange(!value);
                      shareCourseMutation(shared);
                    }}
                  />
                );
              }}
            />
            <Text fontSize="sm" as="div" className="share-text-container note">
              <span>{t("courseEdit.shareText")}</span>
              <Link to={TALENT_LMS_HELP_LINKS.COURSE_SHARE} target="_blank">
                {t("general.learnMore")}.
              </Link>
            </Text>
          </Grid.Item>

          {isShared && (
            <>
              <Grid.Item colSpan={1}>
                <Divider />
              </Grid.Item>
              <Skeletons.Loader status={shareCourseStatus} error={shareCourseError}>
                <Grid.Item colSpan={1}>
                  <div className="copy-link-container">
                    <Input
                      key={public_key}
                      id="course-public-link"
                      defaultValue={publicUrl}
                      label={t("general.copyLink")}
                      readOnly
                    />
                    <CopyToClipboardButton textToCopy={publicUrl} buttonColor="primary" />
                  </div>
                  <Text fontSize="sm" as="div" className="note">
                    {t("courseEdit.publicLinkText")}
                  </Text>
                </Grid.Item>
              </Skeletons.Loader>

              {can_invite_public_course && (
                <Grid.Item colSpan={1}>
                  <Label>{t("courseEdit.emailLinkToUsers")}</Label>
                  <Button
                    iconBefore={OpenedMessageSVG}
                    variant="outline"
                    disabled={shareCourseIsLoading}
                    onClick={handleOpenInvitationsDrawer}
                  >
                    {t("general.sendInvitations")}
                  </Button>
                </Grid.Item>
              )}
            </>
          )}
        </Grid>
      </ActionDrawer>

      {isInvitationsDrawerOpen && (
        <InvitationsDrawer
          isOpen={isInvitationsDrawerOpen}
          course={course}
          onClose={handleCloseInvitationsDrawer}
        />
      )}
    </>
  );
};

export default ShareDrawer;

// Packages or third-party libraries
import React, { FC } from "react";
import { UseFormReturn } from "react-hook-form";

// Styles
import { courseContentStyles } from "../styles";

// Components
import CustomFields from "../CustomFields";
import EditDescription from "./EditDescription";
import Certificate from "../Certificate";
import CourseEditTabs from "./CourseEditTabs";
import Prerequisites from "../Prerequisites";

// Other imports
import { Course, Section } from "types/entities";
import { EditCourseData } from "@views/CourseEdit/api";

type CourseEditContentProps = {
  course: Course;
  sections: Section[];
  form: UseFormReturn<EditCourseData>;
  onSave: () => void;
};

const CourseEditContent: FC<CourseEditContentProps> = ({ course, sections, form, onSave }) => {
  const { custom_fields = [], certificate, rules, policies } = course;
  const { prerequisites } = rules;
  const hasCertificate = Boolean(certificate);
  const hasPrerequisites = prerequisites.length > 0;

  const { can_edit = false, can_view_rules = false, can_update_rules = false } = policies ?? {};
  const canEditOptions = can_edit || (can_view_rules && can_update_rules);

  return (
    <div css={courseContentStyles}>
      <div className="content-wrapper">
        <div className="description-container has-max-width">
          <CustomFields customFields={custom_fields} canEditOptions={canEditOptions} />
          <EditDescription course={course} form={form} onSave={onSave} />
        </div>
        {hasCertificate && (
          <Certificate acquiredCertificate={null} canEditOptions={canEditOptions} />
        )}
        <CourseEditTabs course={course} sections={sections} />
        {hasPrerequisites && (
          <Prerequisites prerequisites={prerequisites} canEditOptions={canEditOptions} isEdit />
        )}
      </div>
    </div>
  );
};

export default CourseEditContent;

import React, { FC } from "react";
import { Tag, Text } from "@epignosis_llc/gnosis";
import { getUnitIconByType, getIconByCompletion } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { UnitProgress, UnitType } from "types/entities";

type CourseTocItemContentProps = {
  status: UnitProgress["status"];
  type: UnitType;
  name: string;
  isReadonly: boolean;
  isSampleUnit?: boolean;
  isLearnerView?: boolean;
  isMandatory?: boolean;
};

const CourseTocItemContent: FC<CourseTocItemContentProps> = ({
  status,
  type,
  name,
  isSampleUnit = false,
  isReadonly,
  isLearnerView,
  isMandatory = false,
}) => {
  const isCompleted = status === "completed" || status === "failed";
  const { t } = useApplyTranslations();

  return (
    <div className="list-item-content">
      {!isLearnerView || isReadonly || !isCompleted
        ? getUnitIconByType(type)
        : getIconByCompletion(status, 32)}

      <Text className="unit-name" fontSize="sm">
        {name}
      </Text>

      {isSampleUnit && <Tag className="sample-unit-tag">{t("general.sample").toUpperCase()}</Tag>}
      {isMandatory && (
        <Tag className="mandatory-unit-tag">{t("general.mandatory").toUpperCase()}</Tag>
      )}
    </div>
  );
};

export default CourseTocItemContent;

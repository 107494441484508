import React from "react";
import { DropdownItem, Text } from "@epignosis_llc/gnosis";
import {
  ArrowChevronRightSVG,
  ArrowLeftChevronSVG,
  LockIconSVG,
} from "@epignosis_llc/gnosis/icons";
import { findCategoryParents, flatten, formatUtcDate, getCategoryText } from "@utils/helpers";
import { i18n, t } from "@utils/i18n";
import { CourseCategory } from "types/entities";
import { Restriction } from "./types";

type CategoryText = {
  category: CourseCategory | null;
  categoryTree: CourseCategory[];
};

type ReturnCategoryText = {
  categoryText: string;
  allCategoryParent: string[];
};

type DropdownOptionsProps = {
  unlocksOnHigherLevel: boolean;
  level: number;
  hasPrerequisites: boolean;
  showStartRestriction: boolean;
  startsAt: string | null;
  showExpiredRestriction: boolean;
  userAccessExpiresAt: string | null;
  canEditOptions?: boolean;
};

export const getCourseCategoryText = ({
  category,
  categoryTree,
}: CategoryText): ReturnCategoryText => {
  const categoryTreeText = category ? getCategoryText(category, categoryTree) : "";
  const allCategoryParent = category ? findCategoryParents(category, flatten(categoryTree)) : [];
  const categoryText = `${allCategoryParent.length > 2 ? "... / " : ""} ${categoryTreeText}`;

  return {
    categoryText,
    allCategoryParent,
  };
};

export const getDropdownOptions = ({
  unlocksOnHigherLevel,
  level,
  hasPrerequisites,
  showStartRestriction,
  startsAt,
  showExpiredRestriction,
  userAccessExpiresAt,
  canEditOptions = false,
}: DropdownOptionsProps): DropdownItem[] => {
  const options: DropdownItem[] = [];

  if (unlocksOnHigherLevel) {
    options.push({
      label: (
        <Text
          fontSize="sm"
          as="div"
          dangerouslySetInnerHTML={{
            __html: t("restrictions.levelRequired", { level: `<strong>${level}</strong>` }),
          }}
        />
      ),
      value: Restriction.Level,
      icon: <LockIconSVG height={32} />,
      isDisabled: !canEditOptions,
      className: !canEditOptions ? "readonly" : "",
    });
  }

  if (hasPrerequisites) {
    const isRTL = i18n.dir() === "rtl";
    const Icon = isRTL ? ArrowLeftChevronSVG : ArrowChevronRightSVG;

    options.push({
      label: (
        <Text fontSize="sm" as="div" className="restrictions-option">
          {t("restrictions.explorePrerequisites")}
          <Icon height={32} />
        </Text>
      ),
      value: Restriction.Prerequisites,
      icon: <LockIconSVG height={32} />,
    });
  }

  if (showStartRestriction && startsAt) {
    options.push({
      label: (
        <Text
          fontSize="sm"
          as="div"
          dangerouslySetInnerHTML={{
            __html: t("restrictions.startsAt", {
              date: `<strong>${formatUtcDate(startsAt)}</strong>`,
            }),
          }}
        />
      ),
      value: Restriction.StartsAt,
      icon: <LockIconSVG height={32} />,
      isDisabled: !canEditOptions,
      className: !canEditOptions ? "readonly" : "",
    });
  }

  if (showExpiredRestriction && userAccessExpiresAt) {
    options.push({
      label: (
        <Text
          fontSize="sm"
          as="div"
          dangerouslySetInnerHTML={{
            __html: t("restrictions.expired", {
              date: `<strong>${formatUtcDate(userAccessExpiresAt)}</strong>`,
            }),
          }}
        />
      ),
      value: Restriction.ExpiresAt,
      icon: <LockIconSVG height={32} />,
      isDisabled: !canEditOptions,
      className: !canEditOptions ? "readonly" : "",
    });
  }

  return options;
};

import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { AllUsersSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type EnrollUserActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  disabled?: boolean;
  onClick: (e: MouseEvent) => void;
};

const EnrollUserAction: FC<EnrollUserActionProps> = ({
  tooltipContent,
  icon: Icon = AllUsersSVG,
  iconHeight = 32,
  disabled = false,
  onClick,
  ...rest
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.actions.add")}
      onClick={onClick}
      data-testid="enroll-action"
      disabled={disabled}
      {...rest}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default EnrollUserAction;

import React, { FC, ReactElement } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Grid, Input, InputError, Select, ToggleSwitch } from "@epignosis_llc/gnosis";
import { CatalogSVG } from "@epignosis_llc/gnosis/icons";
import { TextWithIcon } from "@components";
import { useConfigurationStore } from "@stores";
import { getCurrencySymbol } from "@utils/helpers/currency";
import { CreatableSelectOption, NestedSelectOption } from "types/common";
import { Course } from "types/entities";
import { EditCourseData } from "@views/CourseEdit/api";
import { useApplyTranslations } from "@hooks";
import { courseOptionsIds } from "../../constants";

type CatalogOptionsProps = {
  course: Course;
  form: UseFormReturn<EditCourseData>;
  categories?: NestedSelectOption[];
};

const CatalogOptions: FC<CatalogOptionsProps> = ({ course, form, categories }) => {
  const { t } = useApplyTranslations();
  const { policies } = course ?? {};
  const { can_update_price: canUpdatePrice, can_create_category: canCreateCategory } =
    policies ?? {};
  const { domainSettings } = useConfigurationStore();
  const { currency = null } = domainSettings ?? {};

  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <div className="catalog-options-container" id={courseOptionsIds.catalogOptions}>
      <TextWithIcon icon={<CatalogSVG height={32} />} label={t("general.catalog")} />
      <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} className="grid-container">
        <Grid.Item colSpan={1}>
          <Input
            {...register("code")}
            type="text"
            label={t("sorting.courseCode")}
            id="catalog-code"
            maxLength={20}
          />
          {errors.code && <InputError className="error-msg">{errors.code.message}</InputError>}
        </Grid.Item>
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <Grid templateColumns={[1, 1, 1, 2]} gap={1}>
            <Grid.Item colSpan={1}>
              <Controller
                name="category"
                control={control}
                render={({ field: { value: category, onChange } }): ReactElement => {
                  const selectedCategory = categories?.find((cat) => {
                    const matchByLabel = cat.label.toString() === category?.name?.toString();
                    const matchByValue = cat.value.toString() === category?.id?.toString();
                    return matchByLabel || matchByValue;
                  });
                  return (
                    <Select
                      type={canCreateCategory ? "creatable" : "select"}
                      isClearable={true}
                      noOptionsMessage={(): string => t("general.noOptions")}
                      id="catalog-category"
                      label={t("general.category")}
                      placeholder={t("general.selectCategories", { count: 1 })}
                      value={selectedCategory}
                      options={categories}
                      isSearchable
                      onChange={(option): void => {
                        const { label, value, __isNew__ } = (option as CreatableSelectOption) ?? {};

                        const cat = value
                          ? {
                              name: !__isNew__ ? null : label,
                              id: !__isNew__ ? value : null,
                            }
                          : null;

                        onChange(cat);
                      }}
                    />
                  );
                }}
              />
              {errors.category?.name && (
                <InputError className="error-msg">{errors.category.name.message}</InputError>
              )}
            </Grid.Item>
            {canUpdatePrice && (
              <Grid.Item colSpan={1}>
                <Controller
                  name="price"
                  control={control}
                  render={({ field: { onChange, value } }): ReactElement => {
                    return (
                      <Input
                        type="number"
                        label={t("general.price")}
                        size="md"
                        id="course-price"
                        iconAfter={getCurrencySymbol(currency)}
                        tooltipContent={t("courseEdit.priceInfo")}
                        value={value ?? ""}
                        onChange={(e): void => {
                          const price = e.target.value;
                          onChange(price ? price : null);
                        }}
                      />
                    );
                  }}
                />

                {errors.price && (
                  <InputError className="error-msg">{errors.price.message}</InputError>
                )}
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
        <Grid.Item colSpan={1} className="hide-from-course-catalog">
          <Controller
            name="is_hidden_from_catalog"
            control={control}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <ToggleSwitch
                  data-testid="hide-from-catalog"
                  labelAfter={t("courseEdit.hideFromCourseCatalog")}
                  onChange={(): void => onChange(!value)}
                  defaultChecked={value}
                />
              );
            }}
          />
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default CatalogOptions;

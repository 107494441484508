import React, { FC, ReactNode } from "react";
import Slider from "react-slick";
import { carousel } from "./styles";
import { i18n } from "@utils/i18n";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SerializedStyles } from "@emotion/react";

type CarouselProps = {
  children?: ReactNode;
  customSettings?: Record<string, unknown>;
};

const Carousel: FC<CarouselProps> = ({ children, customSettings }) => {
  const isRtl = i18n.dir() === "rtl";

  const settings = customSettings
    ? customSettings
    : {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

  return (
    <Slider {...settings} css={(theme): SerializedStyles => carousel(theme, { isRtl })} rtl={isRtl}>
      {children}
    </Slider>
  );
};

export default Carousel;

import React, { FC, MouseEvent, useState } from "react";
import { IconChevronDownSVG, IconChevronUpSVG } from "@epignosis_llc/gnosis/icons";
import { dropdownButtonWithArrowContainer } from "./styles";
import { Dropdown, Button, Text, DropdownItem } from "@epignosis_llc/gnosis";
import MassActionsCount from "../MassActionsCount/MassActionsCount";
import { SerializedStyles } from "@emotion/react";

export type PlacementOptions = "bottom-start" | "bottom-end" | "top-start" | "top-end" | "end-top";

type DropdownWithCountProps = {
  label: string;
  buttonType?: "button" | "submit" | "reset";
  selectedRows?: number | "all";
  options?: DropdownItem[];
  variant?: "solid" | "outline";
  placement?: PlacementOptions;
  renderOptionsAsButton?: boolean;
  handleChange?: (item: DropdownItem) => void;
};

const DropdownButtonWithArrow: FC<DropdownWithCountProps> = ({
  label,
  buttonType = "button",
  selectedRows = 0,
  options = [],
  variant = "outline",
  placement,
  renderOptionsAsButton = false,
  handleChange,
}) => {
  const showMassActionsCount = typeof selectedRows === "number" && selectedRows > 0;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggleList = (isListOpen: boolean): void => {
    setIsDropdownOpen(isListOpen);
  };

  const icon = isDropdownOpen ? IconChevronUpSVG : IconChevronDownSVG;
  const buttonVariant = isDropdownOpen ? "solid" : variant;
  const firstOption = options[0];
  const showSingleOptionButton = renderOptionsAsButton && firstOption;

  return (
    <div
      css={(theme): SerializedStyles =>
        dropdownButtonWithArrowContainer(theme, isDropdownOpen, variant)
      }
      data-testid="dropdown-button-with-arrow"
    >
      {showSingleOptionButton ? (
        <Button
          variant={buttonVariant}
          type={buttonType}
          className="single-option-button"
          onClick={(): void => handleChange && handleChange(firstOption)}
        >
          <Text fontSize="sm">{firstOption.label}</Text>
        </Button>
      ) : (
        <Dropdown
          list={options}
          onListItemSelect={handleChange}
          onToggleList={handleToggleList}
          placement={placement}
          onClick={(e: MouseEvent): void => {
            e.stopPropagation();
          }}
        >
          <Button
            variant={buttonVariant}
            iconAfter={icon}
            type={buttonType}
            className="multiple-option-button"
          >
            <Text fontSize="sm">{label}</Text>
            {showMassActionsCount && (
              <MassActionsCount
                count={selectedRows}
                isDropdownOpen={isDropdownOpen}
                variant={variant}
              />
            )}
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

export default DropdownButtonWithArrow;

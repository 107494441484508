// Packages or third-party libraries
import React, { FC, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Tabs } from "@epignosis_llc/gnosis";

// Components
import { ActionDrawer, GradeCustomFooter, Skeletons, SubmissionInfo } from "@components";
import AssignmentTab from "./Tabs/AssignmentTab";
import SubmissionTab from "./Tabs/SubmissionTab";
import GradeTab from "./Tabs/GradeTab";

// Utils, hooks
import { generalNotification } from "@utils/helpers";
import { gradeValidationSchema } from "@utils/validation/gradeSchema";
import { useApplyTranslations } from "@hooks";
import { handleAssignmentErrors } from "@errors/assignmentErrors";

// Other imports
import { gradeSubmission } from "@api/gradingHub";
import { getSingleSubmission } from "@api/submissions";
import { Answer, SingleSubmission, SubmissionListing } from "types/entities";
import { GradeFormData } from "types/entities/Assignments";
import queryKeys from "@constants/queryKeys";
import { DrawerSize } from "types/common";

type GradingDrawerProps = {
  tabIndex: number;
  listingSubmission: SubmissionListing;
  isDrawerOpen: boolean;
  size?: DrawerSize;
  hasBackButton?: boolean;
  showAssignmentTab?: boolean;
  onCloseDrawer: () => void;
  invalidateSubmissionTable: () => void;
};

const GradingDrawer: FC<GradingDrawerProps> = ({
  tabIndex,
  listingSubmission,
  isDrawerOpen,
  size = "md",
  hasBackButton = false,
  showAssignmentTab = false,
  onCloseDrawer,
  invalidateSubmissionTable,
}) => {
  const { t } = useApplyTranslations();

  const [selectedAnswer, setSelectedAnswer] = useState<Answer | null>(null);

  const { unit, user, id } = listingSubmission;
  const unitId = unit.id.toString();
  const userId = user.id.toString();

  const {
    data: singleSubmission,
    status: fetchingStatus,
    error,
  } = useQuery(
    [queryKeys.gradingHub.getSingleSubmission, id, unitId, userId],
    () => getSingleSubmission(unitId, userId),
    {
      select: (assignment) => ({
        data: assignment._data,
      }),
      onSuccess: (res) => {
        const defaultAnswer = res.data.submissions[0];
        setSelectedAnswer(defaultAnswer);
        setValue("comments", defaultAnswer.instructor_comment?.text ?? null);
        setValue("status", res.data.status);
        setValue("score", res.data.grade);
      },
      onError: (error: AxiosError) => {
        handleAssignmentErrors(error);
      },
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const submissionStatus = singleSubmission?.data.status;
  const isGraded = submissionStatus === "passed" || submissionStatus === "not-passed";

  const form = useForm<GradeFormData>({
    mode: "onChange",
    resolver: yupResolver(gradeValidationSchema),
    defaultValues: {
      score: listingSubmission.grade ?? null,
      comments: null,
      status: submissionStatus,
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  const { mutate: gradeMutation, isLoading: isGradeLoading } = useMutation(
    [queryKeys.gradingHub.gradeSubmission, unitId, userId],
    (data: GradeFormData) => {
      const { shouldShowNotification: _shouldShowNotification, ...rest } = data;

      return gradeSubmission(unitId, userId, rest);
    },
    {
      onSuccess: (_res, data: GradeFormData) => {
        const { shouldShowNotification } = data;

        shouldShowNotification &&
          generalNotification("success", <p>{t("gradingHub.messages.grade.update")}</p>);

        invalidateSubmissionTable();
      },
      onError: (error: AxiosError) => {
        handleAssignmentErrors(error);
      },
    },
  );

  const onGrade = (data: GradeFormData, shouldShowNotification?: boolean): void => {
    gradeMutation({ ...data, shouldShowNotification });
  };

  const handleAnswerSelect = (answer: Answer): void => {
    setSelectedAnswer(answer);
  };

  const handleValueChange = (name: keyof GradeFormData, value: string): void => {
    setValue(name, value);
  };

  const shouldShowFooterButtons =
    selectedAnswer && selectedAnswer?.id === singleSubmission?.data.submissions[0]?.id;

  const availableTabs = [
    {
      title: t("gradingHub.submission"),
      content: (
        <SubmissionTab
          submission={singleSubmission?.data as SingleSubmission}
          selectedAnswer={selectedAnswer}
          handleAnswerSelect={handleAnswerSelect}
          handleValueChange={handleValueChange}
        />
      ),
    },
    {
      title: t("general.actions.grade"),
      content: <GradeTab form={form} isGradeEnabled={Boolean(shouldShowFooterButtons)} />,
    },
  ];

  if (showAssignmentTab) {
    availableTabs.unshift({
      title: t("general.assignment"),
      content: <AssignmentTab submission={singleSubmission?.data as SingleSubmission} />,
    });
  }

  return (
    <ActionDrawer
      isOpen={isDrawerOpen}
      headerTitle={t("assignment.gradeUser")}
      onClose={onCloseDrawer}
      size={size}
      hasBackButton={hasBackButton}
      customFooter={
        <GradeCustomFooter
          shouldDisabledActions={Boolean(!(Object.keys(errors).length === 0))}
          shouldShowFooterButtons={Boolean(shouldShowFooterButtons)}
          status={submissionStatus}
          isGradeLoading={isGradeLoading}
          isGraded={isGraded}
          handleSubmit={handleSubmit}
          onGrade={onGrade}
          onCloseDrawer={onCloseDrawer}
        />
      }
    >
      <Skeletons.Loader error={error} status={fetchingStatus} fullScreen>
        <SubmissionInfo submission={listingSubmission} singleSubmission={singleSubmission?.data} />
        <Tabs selectedTab={tabIndex} tabs={availableTabs} />
      </Skeletons.Loader>
    </ActionDrawer>
  );
};

export default GradingDrawer;

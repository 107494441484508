import { css } from "@emotion/react";

export const IltsStatusStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export const UnitSecondaryText = ({ iltContainer }) => css`
  color: ${iltContainer.textColor};
`;

export const selectedSessionStyles = css`
  margin-bottom: 1rem;
`;

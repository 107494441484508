import React, { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card } from "@epignosis_llc/gnosis";
import { PlayUnitSVG, ShowIconSVG, CertificateWithViewboxSVG } from "@epignosis_llc/gnosis/icons";
import CardBody from "../components/CardBody";
import CardFooter from "../components/CardFooter";
import CardHeader from "../components/CardHeader";
import { bodyWrapper } from "../styles";
import MyCourseCardFooter from "./components/MyCourseCardFooter";
import { Course } from "types/entities";
import { URLS } from "@constants/urls";
import permissions from "@utils/permissions";
import { useConfigurationStore } from "@stores";
import { downloadFile, getCatalogCourseOverviewLink } from "@utils/helpers";
import { useApplyTranslations, useAuth } from "@hooks";
import authService from "@utils/services/AuthService";
import userRoles from "@constants/userRoles";

const { Header, Body } = Card;

export type CourseCardProps = {
  course: Course;
  isCustomHomePageView?: boolean;
};

const MyCourseCard: FC<CourseCardProps> = ({ course, isCustomHomePageView = false }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useApplyTranslations();
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const { domainSettings } = useConfigurationStore();
  const { is_hidden_from_catalog } = course;
  const userRole = authService.getDefaultRole();
  const isLearner = userRole === userRoles.LEARNER;
  // permissions related
  const { canAccessCourseUnits } = permissions.coursesPermissions;
  const isAvailableInCatalog = !is_hidden_from_catalog && domainSettings?.external_catalog;
  const allowCourseUnitsView =
    canAccessCourseUnits() ||
    (isCustomHomePageView && isAvailableInCatalog && !is_hidden_from_catalog);
  const allowHover = allowCourseUnitsView ? isHovering : false;
  const showCourseInfoPage =
    Boolean(domainSettings?.show_course_info_page && allowCourseUnitsView) || isCustomHomePageView;
  // This code seems shady. TODO: rethink this
  const isDisabled = isCustomHomePageView && is_hidden_from_catalog;
  const unitContinueLink = URLS.user.createUnitLink({
    courseId: course.id.toString(),
    unitId: "continue",
  });
  const routeState = {
    isCustomHomePage: isCustomHomePageView,
    pathname: pathname ?? "",
  };

  // Custom home page should point to different routes based on role and authentication:
  // Administrator: Admin course overview
  // Instructor: Admin course overview
  // Learner: Catalog course overview
  // Public: External-catalog course overview
  const customHomePageLink =
    isLearner || !isAuthenticated
      ? getCatalogCourseOverviewLink({
          courseId: course.id.toString(),
          isPublic: !isAuthenticated,
          isAvailableInCatalog: Boolean(isAvailableInCatalog),
        })
      : URLS.catalog.adminCourseOverviewLink({ courseId: course.id.toString() });

  const courseLink = isCustomHomePageView
    ? customHomePageLink
    : showCourseInfoPage || !course.availability?.is_available
    ? URLS.user.createCourseLink({ courseId: course.id.toString() })
    : unitContinueLink;

  const actions = [
    ...(course.progress?.certificate
      ? [
          {
            label: t("courseCatalog.downloadCertificate"),
            icon: CertificateWithViewboxSVG,
            onClick: async (): Promise<void> =>
              await downloadFile({
                fileUrl: course.progress?.certificate?.url ?? "",
                fileName: course.progress?.certificate?.course_name ?? "",
              }),
          },
        ]
      : []),
  ];

  return (
    <Card
      onMouseEnter={(): void => setIsHovering(true)}
      onMouseLeave={(): void => setIsHovering(false)}
      className="card-container"
    >
      <Header ratio={[3, 2]}>
        <CardHeader
          course={course}
          actions={actions}
          routeState={routeState}
          allowCourseUnitsView={allowCourseUnitsView}
          courseLink={courseLink}
          icon={showCourseInfoPage ? ShowIconSVG : PlayUnitSVG}
          isDisabled={isDisabled}
        />
      </Header>
      <Link
        to={allowCourseUnitsView ? courseLink : "#"}
        state={routeState}
        css={bodyWrapper}
        className={!allowCourseUnitsView || isDisabled ? "disabled" : ""}
        data-testid="card-body"
      >
        <Body>
          <CardBody course={course} className="body-container" />
        </Body>

        <CardFooter>
          <MyCourseCardFooter isHovering={allowHover} course={course} />
        </CardFooter>
      </Link>
    </Card>
  );
};

export default MyCourseCard;

// Packages or third-party libraries
import React, { FC } from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

// Components
import { ActionDrawer } from "@components";
import CreditBreakdownTable from "./CreditBreakdownTable";

// Hooks
import { useApplyTranslations } from "@hooks";

// Constants
import queryKeys from "@constants/queryKeys";
import { handleAICreditsErrors } from "@errors/errors";

// API
import { getCraftCatalog } from "../api";

type CreditBreakdownDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CreditBreakdownDrawer: FC<CreditBreakdownDrawerProps> = ({ isOpen, onClose }) => {
  const { t } = useApplyTranslations();

  const {
    data: creditCatalog,
    status,
    error,
  } = useQuery([queryKeys.craft.creditCatalog], getCraftCatalog, {
    select: (res) => res._data,
    onError: (error: AxiosError) => {
      handleAICreditsErrors(error);
    },
  });

  const creditsBreakdown =
    creditCatalog && Object.keys(creditCatalog).map((key) => ({ ...creditCatalog[key], key }));

  const tableStatus = {
    status,
    error,
  };

  return (
    <ActionDrawer
      size="md"
      showFooter={false}
      isOpen={isOpen}
      headerTitle={t("AICredits.AICreditBreakdown")}
      onClose={onClose}
      hasBackButton
    >
      <CreditBreakdownTable tableStatus={tableStatus} creditsBreakdown={creditsBreakdown} />
    </ActionDrawer>
  );
};

export default CreditBreakdownDrawer;

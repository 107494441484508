import { css } from "@emotion/react";

export const startReverseTrialButtonStyles = ({ colors }) => css`
  padding: 0.5rem 1.5rem;
  width: 100%;
  display: flex;

  .button-text {
    color: ${colors.black};
    white-space: nowrap;
  }
`;

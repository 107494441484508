import { t } from "@utils/i18n";
import { Column, TableProps } from "@epignosis_llc/gnosis";

export const SORTING: TableProps["sorting"] = { column: "", isDescending: true };

export const getAICreditBreakdownColumns = (): Column[] => {
  return [
    {
      accessor: "action",
      cell: t("general.action"),
      classNames: ["craft_action"],
      sortableHeader: false,
    },
    {
      accessor: "credits",
      cell: t("general.creditsUpper"),
      classNames: ["credits"],
      sortableHeader: true,
    },
  ];
};

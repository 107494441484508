import React, { FC } from "react";
import { cardActionsContainer } from "./styles";
import ActionButton from "@components/Cards/components/ActionButton";
import { IconType } from "types/common";
import { i18n } from "@utils/i18n";

export type Actions = {
  label: string;
  icon: IconType;
  onClick: () => Promise<void> | void;
};

export type CourseActionsProps = {
  actions: Actions[];
  children?: never;
};

const CardActions: FC<CourseActionsProps> = ({ actions }) => {
  const dir = i18n.dir();

  return (
    <div css={cardActionsContainer(dir)}>
      {actions.map((action, i) => (
        <div key={i} className="action">
          <ActionButton {...action} />
        </div>
      ))}
    </div>
  );
};

export default CardActions;

import { PalletThemeColors, getProtectedColor, hexToRGBA } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["courseEdit"] => {
  const protectColorContrast = getProtectedColor(colors.primary.base, colors);

  return {
    boxShadowColor: "#00000029",
    certificatePreview: {
      backgroundColor: colors.secondary.lighter,
      borderColor: colors.secondary.lighter,
    },
    courseImageOverlayBackground: hexToRGBA(colors.secondary.darker.toString(), 0.4),
    courseCertificateOverlayBackground: hexToRGBA(colors.primary.darker.toString(), 0.4),
    error: {
      color: colors.white,
      backgroundColor: colors.red.base,
    },
    grayBase: colors.secondary.base,
    linkedUnit: {
      tagBackground: hexToRGBA(colors.primary.lightest.toString(), 0.25),
      tagTextColor: colors.primary.base,
    },
    pathItem: {
      dotColor: colors.primary.base,
      dotBorderColor: colors.primary.base,
      emptyDotColor: colors.white,
      lineColor: colors.primary.base,
    },
    topBarBackgroundColor: colors.secondary.lightest,
    verticalLine: colors.black,
    uploadFile: {
      borderColor: hexToRGBA(colors.primary.lightest.toString(), 0.25),
      textColor: colors.primary.light,
      textHoverColor: protectColorContrast("darker", colors.primary.lighter),
    },
    weights: {
      borderColor: colors.secondary.lighter,
      percentageColor: colors.black,
      percentageBackgroundColor: colors.secondary.lighter,
    },
  };
};

import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// map with all the available permissions
export const categoriesPermissions = {
  read: "category.read",
  update: "category.update",
  create: "category.create",
  delete: "category.delete",
};

const { read, delete: categoryDelete, create, update } = categoriesPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => category.read
   *
   * Allowed actions:
   *  GET categories
   *
   * @returns boolean
   */
  canAccessCategories: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => category.update
   *
   * Allowed actions:
   *  none
   *
   * @returns boolean
   */
  canUpdateCategories: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([update], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => category.create
   *
   * Allowed actions:
   *  none
   *
   * @returns boolean
   */
  canCreateCategories: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions([create], getState().userProfileData?.permissions[userRole]);
  },

  /**
   * Permission => category.delete
   *
   * Allowed actions:
   * DELETE categories.delete
   *
   * @returns boolean
   */
  canDeleteCategories: (): boolean => {
    const userRole = authService.getDefaultRole();

    return userHasRequiredPermissions(
      [categoryDelete],
      getState().userProfileData?.permissions[userRole],
    );
  },
};

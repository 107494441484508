// Packages or third-party libraries
import { t } from "@utils/i18n";

// Components
import { MicrostatsEntry } from "@components/ReusableComponents/MicrostatsPanel/types";

// Other imports
import { UnitMicrostats } from "types/entities/Unit";
import { formatLargeValues, formatTrainingTime } from "./numeric";

export const getFormattedUnitMicrostats = (
  entries: UnitMicrostats | { [k: string]: number },
): MicrostatsEntry[] => {
  return Object.entries(entries).map(([key, value]) => {
    const title = t(`microstats.${key}`);

    // Percentage value
    if (key === "completion_rate") {
      return {
        title,
        value: `${value.toFixed(1)}%`,
      };
    }

    // Date value
    if (key === "training_time") {
      return {
        title,
        value: formatTrainingTime(value),
      };
    }

    // Numeric value
    return {
      title,
      value: formatLargeValues(value),
    };
  });
};

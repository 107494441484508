import React, { FC, HTMLAttributes } from "react";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";
import { Button, Text } from "@epignosis_llc/gnosis";
import { alertBox } from "@components/ReusableComponents/AlertBox/styles";
import { InfoIconSVG, WarningIconSVG } from "@epignosis_llc/gnosis/icons";

type AlertType = "info" | "warning";
type ButtonType = "solid" | "outline";

type AlertBoxProps = HTMLAttributes<HTMLDivElement> & {
  type: AlertType;
  message: string;
  buttonType?: ButtonType;
  buttonText?: string;
  onClick?: () => void;
  isLoading?: boolean;
  contentCenter?: boolean;
  testId?: string;
};

const ICONS = {
  info: <InfoIconSVG height={16} />,
  warning: <WarningIconSVG height={16} />,
};

const AlertBox: FC<AlertBoxProps> = ({
  type,
  message,
  buttonType,
  buttonText,
  onClick,
  isLoading = false,
  contentCenter = false,
  testId = "alert-box",
  ...rest
}) => {
  const alertBoxClassName = classNames({
    "alert-box-centered": contentCenter,
  });

  return (
    <div
      data-testid={testId}
      css={(theme): SerializedStyles => alertBox(theme, { type })}
      className={alertBoxClassName}
      {...rest}
    >
      <div className="alert-box-content">
        <span className="alert-box-icon">{ICONS[type]}</span>
        <Text
          as="div"
          fontSize="sm"
          key="alert-message"
          className="alert-box-message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </div>
      {buttonText && (
        <Button
          data-testid="alert-box-button"
          className="alert-box-button"
          variant={buttonType}
          type="button"
          onClick={onClick}
          isLoading={isLoading}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default AlertBox;

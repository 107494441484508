import { PalletThemeColors, hexToRGBA, isShadeOfWhite } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["courseToc"] => {
  const isLightTheme = isShadeOfWhite(colors.primary.base);

  return {
    color: colors.black,
    disabled: colors.secondary.base,
    hover: isLightTheme ? colors.primary.darker : colors.primary.lighter,
    hoverBackground: colors.secondary.lighter,
    icon: {
      color: colors.black,
      backgroundColor: colors.secondary.lighter,
    },
    edit: {
      active: colors.primary.light,
      background: colors.secondary.lighter,
      inactive: {
        color: colors.red.base,
        background: hexToRGBA(colors.red.light, 0.15),
      },
      pending: {
        color: colors.primary.base,
        background: hexToRGBA(colors.primary.light, 0.15),
      },
      selected: colors.primary.base,
      unitBackground: colors.white,
      unpublished: {
        color: colors.orange.base,
        background: hexToRGBA(colors.orange.light, 0.15),
      },
    },
    mandatoryUnit: {
      background: colors.secondary.lighter,
      border: colors.white,
      color: colors.black,
    },
    sampleTagBackground: colors.primary.base,
    sampleTagColor: colors.white,
  };
};

import { css } from "@emotion/react";

export const link = (theme, { color }) => css`
  color: ${theme.customLink[color].color};

  &:hover {
    color: ${theme.customLink[color].hoverColor};
  }

  &.readonly {
    color: inherit;
    cursor: not-allowed;
  }
`;

import { EChartsOption } from "echarts-for-react";

export const getChartOption = ({
  progressColor,
  progressBackgroundColor,
  score,
  isFullCircle = true,
}: {
  progressColor: string;
  progressBackgroundColor: string;
  score: number;
  isFullCircle?: boolean;
}): EChartsOption => {
  return {
    series: [
      {
        type: "gauge",
        startAngle: isFullCircle ? -90 : 245,
        endAngle: isFullCircle ? 270 : -60,
        radius: "75%",
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 2,
            borderColor: progressColor,
            color: progressColor,
          },
        },
        axisLine: {
          roundCap: true,
          lineStyle: {
            color: [[1, progressBackgroundColor]],
            width: 8,
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        data: [
          {
            value: score,
          },
        ],
        title: {
          show: false,
        },
        detail: {
          show: false,
        },
      },
    ],
  };
};
